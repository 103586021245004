import * as types from '../type';
import { IMajor } from '../../utils/interfaces/major.interface';

interface IInitialState {
  majors: IMajor[];
  major: IMajor | {};
  status: any;
}

const initialState: IInitialState = {
  majors: [],
  major: {},
  status: null,
};

const majorReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.SEARCH_MAJOR_SUCCESS:
      return { ...state, majors: action.payload };
    case types.SEARCH_MAJOR_ERROR:
      return { ...state, status: action.payload };
    default:
      return {
        ...state,
      };
  }
};

export default majorReducer;
