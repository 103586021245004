import { BaseRepository } from './base';
import { ICandidate } from '../../utils/interfaces';
import Repository from './base/repository';

export class CandidateRepository extends BaseRepository<ICandidate> {
  resource = 'candidate';
  token = localStorage.getItem('access-token');

  /**
   * Method search Career Group
   * @param query string
   * @return Array
   */
  async getCandidate(query?: any) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.get(
        `${this.resource}/job/${query && query.job_id}?search_string=${
          query && query.search_string ? query.search_string : ''
        }&p_order=${query && query.p_order ? query.p_order : '1'}&p_skip=${
          query && query.p_skip ? query.p_skip : '0'
        }&p_limit=${query && query.p_limit ? query.p_limit : '20'}&p_status=${
          query && query.p_status ? query.p_status : '0'
        }`,
        { headers: { Authorization: `Bearer ${this.token}` } },
      )
    ).data;
  }

  async getMatchCandidateSearch(query?: any) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.get(
        `${this.resource}/match_candidate/search?p_careers_code=${
          query && query.p_careers_code ? query.p_careers_code : ''
        }&p_skills=${
          query && query.p_skills ? query.p_skills : ''
        }&p_provinces=${
          query && query.p_provinces ? query.p_provinces : ''
        }&p_degrees=${
          query && query.p_degrees ? query.p_degrees : ''
        }&p_gender=${
          query && query.p_gender ? query.p_gender : 0
        }&p_discharged_army=${
          query && query.p_discharged_army ? query.p_discharged_army : 0
        }&p_min_salary=${
          query && query.p_min_salary ? query.p_min_salary : 0
        }&p_max_salary=${
          query && query.p_max_salary ? query.p_max_salary : 0
        }&p_from_age=${
          query && query.p_from_age ? query.p_from_age : 0
        }&p_to_age=${
          query && query.p_to_age ? query.p_to_age : 0
        }&p_heightFrom=${
          query && query.p_heightFrom ? query.p_heightFrom : 0
        }&p_heightTo=${
          query && query.p_heightTo ? query.p_heightTo : 0
        }&p_weightFrom=${
          query && query.p_weightFrom ? query.p_weightFrom : 0
        }&p_weightTo=${
          query && query.p_weightTo ? query.p_weightTo : 0
        }&p_eyes=${query && query.p_eyes ? query.p_eyes : 0}&p_chung_nhan=${
          query && query.p_chung_nhan ? query.p_chung_nhan : ''
        }&p_skip=${query && query.p_skip ? query.p_skip : '0'}&p_limit=${
          query && query.p_limit ? query.p_limit : '20'
        }`,
        { headers: { Authorization: `Bearer ${this.token}` } },
      )
    ).data;
  }

  async getCandidateFit(user_id, job_id) {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.get(
      `${this.resource}/${user_id}/job/${job_id}`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }

  async getCandidateSkill(user_id) {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.get(
      `${this.resource}/${user_id}/skills`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );

    return response.data;
  }

  async getCandidateCareers(user_id) {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.get(
      `${this.resource}/${user_id}/careers`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );

    return response.data;
  }

  async getCandidateEducations(user_id) {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.get(
      `${this.resource}/${user_id}/educations`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );

    return response.data;
  }

  async getCandidateExp(user_id) {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.get(
      `${this.resource}/${user_id}/experience`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );

    return response.data;
  }

  async viewProfile(user_id, data) {
    const response = await Repository.post(
      `${this.resource}/${user_id}/view-profile`,
      data,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }
}
