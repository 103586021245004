import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CandidateRepository } from '../../../services/repositories/candidate.repository';

//Repository
const candidateRepository: CandidateRepository = RepositoryFactory.get(
  'candidate',
) as CandidateRepository;

//Async Fetch
export const fetchCandidate = async (query?: any) => {
  const response = await candidateRepository.getCandidate(query);

  let result = { data: [], total_job: response.total_job };
  if (response.data && response.data.length > 0) {
    for (const item of response.data) {
      const fetchAll = await Promise.all([
        candidateRepository.getCandidateSkill(item.user_id),
        candidateRepository.getCandidateEducations(item.user_id),
      ]);
      result.data.push({
        ...item,
        skill: fetchAll[0],
        educations: fetchAll[1],
      });
    }
  }

  return result;
};

function* handlerCandidate(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const candidate = yield call(fetchCandidate, action.payload);
    yield put({ type: types.GET_CANDIDATE_SUCCESS, payload: candidate });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.GET_CANDIDATE_ERROR,
      payload: {},
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetCandidate() {
  yield takeLatest(types.GET_CANDIDATE_REQUEST, handlerCandidate);
}
