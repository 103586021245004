import React, { useState } from 'react';
import {
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import ModalDetailTransaction from '../../../molecules/Modal/ModalDetailTransaction';
import {checkPaymentMethod, vnPayVNDFormat} from '../../../../utils/commons';
import { DetailOrder } from 'components/organisms';

const styles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      '&:focus': {
        outline: 'none',
      },
    },
  },
  buttonTab: {
    backgroundColor: 'white !improtant',
    color: '#000000 !important',
    fontWeight: 'bold',
    fontSize: '14px',
    minWidth: 100,
    textTransform: 'none',
    padding: '0 12px',
    opacity: 1,
    '&:focus': {
      outline: '2px solid transparent',
      outlineOffset: '2px',
      backgroundColor: '#B50829 !important',
      color: 'white !important',
    },
    '&:active': {
      backgroundColor: '#B50829 !important',
      color: 'white !important',
    },
  },
  Roboto: {
    fontFamily: 'Roboto',
  },
}));
export const CLC_IPointOrder = ({
                                    per_page,
                                    fromSearch,
                                    handlePage,
                                    rows,
                                    page
                                  }) => {
  const classes = styles();
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    let a = (value - 1) * 20;
    handlePage(value);
    fromSearch('p_skip', a);
  };
  const { loading } = useSelector((state) => state['loading']);
  const [showModal, setShowModal] = useState(false);
  const [historyItem, setHistoryItem] = useState({});
  const handleModal = (e, name: string, item) => {
    setHistoryItem(item ? item : {});
    setShowModal(name !== 'close')
  }
  return (
    <TableContainer className='pt-7'>
      {loading || rows == null ? (
        <div className="flex justify-center py-10">
          <CircularProgress />
        </div>
      ) : (
        <Table aria-label='simple table' className='border border-solid'>
          <TableHead style={{ backgroundColor: '#F4F2FF' }}>
            <TableRow>
              {columns &&
              columns.map((e, key) => {
                return (
                  <TableCell
                    align={'left'}
                    key={key}
                    style={{
                      width: `${e.width}`,
                      textAlign: `${e.field == 'Số tiền (VNĐ)' ? 'right' : 'left'}`,
                    }}
                  >
                    <span className='font-semibold text-left'>{e.field}</span>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              rows.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      height='20px'
                      className='items-center text-left'
                    >
                    <span>
                      {
                        (new Date(item?.created_at)).toLocaleString()
                      }
                    </span>
                    </TableCell>
                    <TableCell
                      align='left'
                      height='20px'
                      className='items-center text-left'
                    >
                      <span>{item?.service}</span>
                    </TableCell>
                    <TableCell
                      align='left'
                      height='20px'
                      className='items-center text-right'
                      style={{ textAlign: 'right' }}
                    >
                      <span>{vnPayVNDFormat(item?.amount)}</span>
                    </TableCell>
                    <TableCell
                      align='left'
                      height='20px'
                      className='items-center text-right'
                    >
                      {checkPaymentMethod(item?.payment_method)} <br/>
                      {
                        <DetailOrder status={item.status} paymentMethod={item.payment_method} vnpTransactionNo={item.vnp_TransactionNo}/>
                      }
                    </TableCell>
                    <TableCell
                      align='left'
                      height='20px'
                      className='items-center text-right'
                    >
                      <a onClick={(e) => handleModal(e, 'open', item)} className={'text-red-800 cursor-pointer'}>Xem</a>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <div className='mx-auto absolute left-1/2 text-xl py-2'>
                Chưa có lịch sử mua iPoint
              </div>
            )}
          </TableBody>
        </Table>
      )}
      <div className={`${classes.root} flex justify-end`}>
        <Pagination
          count={Math.ceil(Number(per_page) / 20)}
          variant='outlined'
          onChange={handleChangePage}
          page={page}
          shape='rounded'
        />
      </div>
      <ModalDetailTransaction showModal={showModal} handleModal={handleModal} historyItem={historyItem}  />
    </TableContainer>
  );
};


const columns = [
  {
    field: 'Thời gian',
    width: '20%',
  },
  {
    field: 'Dịch vụ',
    width: '20%',
  },
  {
    field: 'Số tiền (VNĐ)',
    width: '20%',
  },
  {
    field: 'Nội dung',
    width: '30%',
  },
  {
    field: '',
    width: '10%',
  },
];