import React from 'react';
import { TrimmingTextProps } from './TrimmingText.type';

export const Trimming = ({
  className = '',
  lines,
  children,
}: TrimmingTextProps) => {
  const styles: any = {
    display: '-webkit-box',
    WebkitLineClamp: `${lines}`,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  };
  return (
    <p style={styles} className={`block ${className}`}>
      {children}
    </p>
  );
};
