import * as types from '../type';

export const getCourses = (query?) => ({
  type: types.GET_COURSES_REQUEST,
  payload: query,
});

export const getCoursesById = (id?: string) => ({
  type: types.GET_COURSES_BY_ID_REQUEST,
  payload: id,
});

export const updateCoursesById = (id?: string, data?: any) => ({
  type: types.UPDATE_COURSES_REQUEST,
  payload: { id, data },
});

export const createCourse = (data?: any) => ({
  type: types.CREATE_COURSES_REQUEST,
  payload: data,
});

export const deleteCourses = (id: string) => ({
  type: types.DELETE_COURSES_REQUEST,
  payload: id,
});

export const getStudentById = (query?: any) => ({
  type: types.GET_STUDENT_BY_ID_REQUEST,
  payload: query,
});

export const getActiveCourse = () => ({
  type: types.GET_ACTIVE_COURSE_REQUEST,
});

export const resetStore = () => ({
  type: types.RESET_STORE_COURSE,
});

export const applyStatusCourse = (data, query) => ({
  type: types.UPDATE_APPLY_COURSE_REQUEST,
  payload: { data: data, query: query },
});
