import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { CoursesRepository } from 'services/repositories/course.repository';

//Initial State
const courseRepository = RepositoryFactory.get('course') as CoursesRepository;

const setStatus = (status) => {
  switch (status) {
    case 1:
      return 'Chờ duyệt';
    case 2:
      return 'Đang hoạt động';
    case 4:
      return 'Hết hạn';
    case 5:
      return 'Đã xóa';
    default:
      return;
  }
};

const exportExcelCourse = async (query?) => {
  const response = await courseRepository.getQueryCourse(query);
  let result = [];
  if (response) {
    response.data.map((item) => {
      result.push({
        ...item,
        statusName: setStatus(item.status),
        end_date: `${('0' + new Date(item.end_date).getDate()).slice(-2)}/${(
          '0' +
          (new Date(item.end_date).getMonth() + 1)
        ).slice(-2)}/${new Date(item.end_date).getFullYear()}`,
      });
    });
  }
  return result;
};

function* handlerExportExcelCourse(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const courses = yield call(exportExcelCourse, action.payload);
    yield put({ type: types.EXPORT_EXCEL_COURSE_SUCCESS, payload: courses });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.EXPORT_EXCEL_COURSE_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchExportExcelCourse() {
  yield takeLatest(types.EXPORT_EXCEL_COURSE_REQUEST, handlerExportExcelCourse);
}
