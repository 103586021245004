/* eslint-disable react/jsx-pascal-case */
import { CircularProgress } from '@material-ui/core';
import { LF_Breadcumbs, LF_ConfirmModal } from 'components/molecules';
import { LF_DataTable } from 'components/organisms';
import { LF_FindTrainee } from 'components/organisms/Modules/FindTrainee';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchCareer,
  searchCareerGroup,
  searchSkill,
  getTrainee, viewProfile, getIPoint, viewTraineeProfile,
} from 'redux/actions';
import { RootState } from 'redux/store';
import { TITLE } from "../../../../../constants";

export const LF_SearchCourse = () => {
  const [searchForm, setSearchForm] = React.useState({
    careers: [],
    skills: [],
    online: 0,
    status: false,
    schedule: '',
  });
  const [search, setSearch] = React.useState({
    p_careers_code: '',
    p_skills: '',
    p_learning_form: 0,
    p_discharged_army: 0,
    p_schedule: '',
    p_skip: '0',
    p_limit: '20',
  });

  //Page
  const [page, setPage] = React.useState(1);
  const [title, setTitle] = React.useState(TITLE[0]);
  const [openModal, setOpenModal] = React.useState(false);
  const [user, setUser] = React.useState(null);

  const handlePage = (data) => {
    setPage(data);
  };
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state['loading']);

  //Trainee
  const { trainee } = useSelector((state) => state['trainee']);

  //CAREERS
  const searchCareers = useSelector((state: RootState) => state.searchcareers);

  const { corporates, corporate, status } = useSelector((state: RootState) => state.corporate);
  const { ipoint } = useSelector((state: RootState) => state.ipoint);
  //List Career Choosen
  const [listCareerChoose, setCareerChoose] = React.useState([]);
  const [careerQuery, setCareerQuery] = React.useState(null);
  // console.log(listCareerChoose);

  //Delete Career Choosen
  const onDeleteChoose = (career_code: string) => {
    let listCurrent = [...listCareerChoose];
    const check = listCurrent?.filter((e) => e.career_code !== career_code);
    setCareerChoose(check);
  };

  //Choose Career Choosen
  const onChooseCareer = (data) => {
    let listCurrent = [...listCareerChoose];
    const check = listCurrent?.filter(
      (e) => e.career_code === data.career_code,
    );

    if (check && check.length === 0) {
      setCareerChoose([...listCareerChoose, data]);
    }
  };

  React.useEffect(() => {
    if (careerQuery && careerQuery !== '') {
      dispatch(searchCareerGroup(careerQuery));
    }
  }, [careerQuery]);

  //SKILL
  const skills = useSelector((state: RootState) => state.skills);

  //list Skill choosen
  const [listSkillChoose, setSkillChoose] = React.useState([]);
  const [skillsQuery, setSkillsQuery] = React.useState(null);

  //hanlde Deltele Skill
  const onDeleteSkill = (skill_code: string) => {
    const mapList = [...listSkillChoose];
    const newList = mapList?.filter((item) => item.skill_code !== skill_code);
    setSkillChoose(newList);
  };

  const handleSearchSkill = (value) => {
    if (value && value !== '') dispatch(searchSkill(value));
    if (value === '') dispatch(searchSkill(''));
  };

  //handel Add Skill
  const onChooseSkill = (data) => {
    const mapList = [...listSkillChoose];
    const checkDuplicate = mapList?.filter(
      (item) => item.skill_code === data.skill_code,
    );

    if (checkDuplicate.length === 0)
      setSkillChoose((prevState) => [...prevState, data]);
  };

  React.useEffect(() => {
    dispatch(searchSkill(skillsQuery));
  }, [skillsQuery]);

  const handleSearchCareers = (value) => {
    if (value && value !== '') dispatch(searchCareer(value));
    if (value === '') dispatch(searchCareer(''));
  };

  // Function setSearch
  const handleSearch = (name, value) => {
    if (typeof name === 'object') {
      setSearch((prevState) => {
        return { ...prevState, ...name };
      });

      // dispatch({ ...search, name });
    } else {
      setSearch({
        ...search,
        [name]: value,
      });
      dispatch(
        getTrainee({
          ...search,
          [name]: value,
        }),
      );
    }
  };

  // React.useEffect(() => {
  //   dispatch(getTrainee(search));
  // }, [search]);
  const acceptSee = () => {
    if (ipoint?.ipoint < user.ipoint) {
      window.location.href = '/admin/ipoint/buy-ipoint';
    } else {
      const data = {
        p_corporate_code: corporates && corporates[0].corporate_code,
        p_user_id: user.user_id,
        p_corporate_name: corporates && corporates[0].corporate_name,
        p_image_name: corporates && corporates[0].logo,
        p_user_slug: user.slug,
        p_corporate_slug: corporates && corporates[0].slug,
        p_user_name: `${user.last_name} ${user.first_name}`,
        p_user_email: user.email,
      };
      dispatch(viewTraineeProfile(data));
      setTimeout(() => {
        dispatch(getIPoint(`${corporate.corporate_code}`));
      }, 200);

      setOpenModal(false);
    }
  }
  const onHanlerSearch = () => {
    const career_gr = listCareerChoose?.map((e) => e.career_code);
    const skill = listSkillChoose?.map((e) => e.skill_code);

    setSearch({
      ...search,
      p_careers_code: career_gr.join(','),
      p_skills: skill.join(','),
      p_learning_form: searchForm.online,
      p_discharged_army: searchForm.status === true ? 1 : 0,
      p_schedule: searchForm.schedule,
    });

    dispatch(
      getTrainee({
        ...search,
        p_careers_code: career_gr.join(','),
        p_skills: skill.join(','),
        p_learning_form: searchForm.online,
        p_discharged_army: searchForm.status === true ? 1 : 0,
        p_schedule: searchForm.schedule,
      }),
    );
  };
  const seeStudent = (user) => {
    if (ipoint.ipoint < user?.ipoint) {
      setTitle(TITLE[1]);
      setUser(user);
      setOpenModal(true);
      return;
    } else {
      TITLE[0].title = `Để xem thông tin của học viên này bạn cần tiêu ${user?.ipoint} iPoint Bạn có muốn mở thông tin học viên không?`;
      setTitle(TITLE[0])
      setUser(user);
      setOpenModal(true);
    }
  }
  return (
    <>
      <LF_Breadcumbs
        route={`/admin/job/searchEmployee`}
        routeName={'Tìm học viên phù hợp'}
        className="font-bold text-lg"
      />
      <LF_ConfirmModal
        open={openModal}
        title={title.title}
        onProceed={acceptSee}
        onCancel={() => setOpenModal(false)}
      />
      <section className="p-4 lg:px-10">
        <LF_FindTrainee
          form={searchForm}
          setForm={setSearchForm}
          onClick={onHanlerSearch}
          //multi seclet
          careers={searchCareers && searchCareers.searchCareers}
          listCareerChoose={listCareerChoose}
          careerQuery={careerQuery}
          onChooseCareer={onChooseCareer}
          onDeleteCareer={onDeleteChoose}
          setCareerQuery={setCareerQuery}
          onChangeSearchCareers={handleSearchCareers}
          //multi Skill
          listShowSkill={skills && skills.skills}
          listChooseSkill={listSkillChoose}
          onClickAddChooseSkill={onChooseSkill}
          onClickRemoveChooseSkill={onDeleteSkill}
          querySkill={skillsQuery}
          setQuerySkill={setSkillsQuery}
          onChangeSearchSkill={handleSearchSkill}
        />
        {loading ? (
          <div className="flex justify-center py-10">
            <CircularProgress />
          </div>
        ) : (
          <React.Fragment>
            <div className="container pt-6">
              <div className="px-2 text-base leading-5">
                Tìm thấy: {trainee?.total_data || 0} kết quả phù hợp
              </div>
              <LF_DataTable
                rows={trainee.data}
                type="CourseSearch"
                per_page={trainee.total_data}
                page={page}
                handlePage={handlePage}
                fromSearch={handleSearch}
                loading={loading}
                seeStudent={seeStudent}
              />
            </div>
          </React.Fragment>
        )}
      </section>
    </>
  );
};
