/* eslint-disable react/jsx-pascal-case */
import React from 'react';

// Components
import { LF_Breadcumbs } from 'components/molecules';
import { ProfileCompanyFrom } from 'components/organisms/Modules/Profile/ProfileCompanyFrom.organisms';

// React- Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getCorporate, updateCorporate } from 'redux/actions';

export const ProfileCompany = () => {
  // React- Redux
  const dispatch = useDispatch();
  const { corporates, status } = useSelector(
    (state: RootState) => state.corporate,
  );

  // useEffect
  React.useEffect(() => {
    dispatch(getCorporate());
  }, []);

  // handel action
  const handleUpdate = (data) => {
    // console.log(data);
    dispatch(updateCorporate(data));
    // setTimeout(function () {
    //   window.location.reload();
    // }, 3000);
  };

  return (
    <React.Fragment>
      <LF_Breadcumbs
        route={`/admin/company`}
        routeName={'Hồ sơ công ty'}
        className="font-bold text-lg"
      />
      {/* Page */}
      <ProfileCompanyFrom data={corporates[0]} onUpdate={handleUpdate} />
    </React.Fragment>
  );
};
