import React, { useState, useEffect } from 'react';


export const Count = ({handleCount}) => {
  const [count, setCount] = useState(0);

  const handleChange = async (e, name) => {
    const value = e.target.value;
    const numericReg = /^(\d?[0-9]|[0-9])$/;
    switch (name) {
      case 'up':
        setCount(Number(count) + 1);
        break;
      case 'down':
        if (count == 0) return;
        setCount(Number(count) - 1);
        break;
      case 'edit':
      default:
        if (!numericReg.test(value)) return;
        setCount(value);
        break;
    }
  }
  useEffect(() => {
    handleCount(count)
  }, [count]);
  return (
    <div className='number-input'>
      <button onClick={(e) => handleChange(e, 'down')}/>
      <input onChange={(e) => handleChange(e, 'edit')} className="quantity" min="0" max="99" name="quantity" value={count} type="number"/>
      <button onClick={(e) => handleChange(e, 'up')} className='plus'/>
    </div>
  )
}