/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import LogoiHR from './../../../assets/images/Logo_rounded_512.png';

//Context
import { useAuth } from './../../wrapper/Auth.wrapper';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Action
import { resetPassword, checkToken } from 'redux/actions/employee.action';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function ResetPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams();
  let query = useQuery();
  const { status, statusReset } = useSelector((state) => state['employee']);
  const [form, setForm] = React.useState({
    new_password: '',
    confirm_password: '',
  });

  const [valid, setValid] = React.useState({
    new_password: '',
    confirm_password: '',
  });

  //UseEffect
  React.useEffect(() => {
    dispatch(checkToken({ token: query.get('token') }));
  }, []);

  //Handle Action
  const onValidation = (data) => {
    let validation = { ...valid };
    let check = true;
    if (data.new_password === '') {
      validation.new_password = 'Mật khẩu mới không được để trống';
      check = false;
    } else {
      validation.new_password = '';
    }

    if (data.confirm_password === '') {
      validation.confirm_password = 'Vui lòng nhập lại mật khẩu mới';
      check = false;
    } else if (data.confirm_password != data.new_password) {
      validation.confirm_password =
        'Mật khẩu nhập lại không giống mật khẩu mới';
      check = false;
    } else {
      validation.confirm_password = '';
    }

    //End
    if (check) {
      setValid({ new_password: '', confirm_password: '' });
      return true;
    } else {
      setValid(validation);
      return false;
    }
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const result = onValidation(form);
    if (result) {
      dispatch(resetPassword({ ...form, token: query.get('token') }));
    }
  };

  return (
    <React.Fragment>
      <div className="w-full min-h-screen">
        <div
          className="mx-auto flex flex-col items-center justify-center min-h-screen h-auto"
          style={{ background: '#E5E5E5' }}
        >
          <div className="relative flex flex-col items-center pb-4">
            <img src={LogoiHR} alt="..." className="h-28 w-28" />
          </div>
          <div className="w-11/12 relative flex flex-col items-center bg-white rounded-xl border border-gray-500 border-solid pb-6 lg:max-w-[400px] lg:min-h-[512px]">
            <div className="lg:px-16 py-10">
              <p className=" normal-case text-center  font-normal text-4xl">
                Tạo mật khẩu mới
              </p>
              <p className=" normal-case  font-normal text-sm text-black text-opacity-50 pt-4 text-center">
                Vui lòng nhập mật khẩu mới
              </p>
            </div>
            <form
              action=""
              className=" flex flex-col"
              style={{ padding: '0 50px', width: '400px' }}
            //   onSubmit={onSubmitLogin}
            >
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-base font-bold mb-2 "
                  htmlFor="username"
                >
                  Mật khẩu mới <span style={{ color: '#B50829' }}>*</span>
                </label>
                <input
                  className=" p-3 appearance-none border border-gray-400 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="new_password"
                  name="new_password"
                  type="password"
                  onChange={onChange}
                />
                <span className="text-sm " style={{ color: 'red' }}>
                  {valid.new_password !== '' ? valid.new_password : ''}
                </span>
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 text-base font-bold mb-2 "
                  htmlFor="username"
                >
                  Nhập lại mật khẩu mới{' '}
                  <span style={{ color: '#B50829' }}>*</span>
                </label>
                <input
                  className=" p-3 appearance-none border border-gray-400 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  onChange={onChange}
                />
                <span className="text-sm " style={{ color: 'red' }}>
                  {valid.confirm_password !== '' ? valid.confirm_password : ''}
                </span>
              </div>

              <div className="mt-12 text-center">
                <button
                  type="submit"
                  className=" bg-[#B50829] uppercase text-[18px] text-white px-3  w-3/4 rounded py-2  focus:outline-none"
                  onClick={(e) => onSubmit(e)}
                >
                  Đổi mật khẩu
                </button>
              </div>
              {/* <div className="mt-6 text-center">
                <a
                  href="#"
                  className="font-bold text-sm  hover:text-[#B50829] cursor-pointer text-black text-opacity-75"
                  onClick={() => {
                    history.push('/auth/login');
                  }}
                >
                  Đăng nhập hệ thống
                </a>
              </div> */}
            </form>
          </div>
          <div className={' flex flex-col lg:flex-row justify-center text-black text-opacity-75 text-sm lg:min-w-[400px] text-center'}>
            <div className="p-3">
              <a href="#">Trợ giúp</a>
            </div>
            <div className="p-3">
              <a href="#">Chính sách bảo mật</a>
            </div>
            <div className="p-3">
              <a href="#">Điều khoản sử dụng</a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
