import { FC } from 'react';

//Component
import { DashBoard } from '../../views/modules/admin/dashboard';
import { ProfileUser } from '../../views/modules/admin/profile/proflieUser';
import { ProfileCompany } from 'views/modules/admin/profile/profileCompany';

//Job
import { EmployeeList } from '../../views/modules/admin/job';
import { Post } from '../../views/modules/admin/job';
import { EditJob } from '../../views/modules/admin/job/post/edit';
import { Recruitment } from '../../views/modules/admin/job';
import { SearchEmployee } from '../../views/modules/admin/job';

//Course
import { LF_RegisterCourse } from 'views/modules/admin/course';
import { LF_CourseList } from 'views/modules/admin/course';
import { LF_PostCourse } from 'views/modules/admin/course';
import { LF_SearchCourse } from 'views/modules/admin/course';
import { LF_EditCourse } from 'views/modules/admin/course/post/edit';
import { IPoint } from '../../views/modules/admin/ipoint';
import { IPointOrder } from '../../views/modules/admin/ipointOrder';
import { IPointBuy } from '../../views/modules/admin/buyIpoint';

import { PaymentResponse } from '../../views/modules/payment';

interface Route {
  path: string;
  component: FC;
}

export const routes: Route[] = [
  {
    path: '/admin/dashboard',
    component: DashBoard,
  },
  {
    path: '/admin/user',
    component: ProfileUser,
  },
  {
    path: '/admin/company',
    component: ProfileCompany,
  },
  {
    path: '/admin/job/post',
    component: Post,
  },
  {
    path: '/admin/job/edit/:id',
    component: EditJob,
  },
  {
    path: '/admin/job/recruitment',
    component: Recruitment,
  },
  {
    path: '/admin/job/employee',
    component: EmployeeList,
  },
  {
    path: '/admin/job/searchEmployee',
    component: SearchEmployee,
  },
  {
    path: '/admin/course/list',
    component: LF_CourseList,
  },
  {
    path: '/admin/course/register',
    component: LF_RegisterCourse,
  },
  {
    path: '/admin/course/post',
    component: LF_PostCourse,
  },
  {
    path: '/admin/course/search',
    component: LF_SearchCourse,
  },
  {
    path: '/admin/course/edit/:id',
    component: LF_EditCourse,
  },
  {
    path: '/admin/ipoint',
    component: IPoint,
  },
  {
    path: '/admin/ipoint/history',
    component: IPointOrder,
  },
  {
    path: '/admin/ipoint/buy-ipoint',
    component: IPointBuy,
  },
  {
    path: '/admin/payment/response',
    component: PaymentResponse,
  },
];
