import React from 'react';
import {LF_Breadcumbs} from "../../../components/molecules";
import {Link} from 'react-router-dom';
import ipointFormat from "../../../utils/commons/math/ipointFormat";

const RESPONSE_CODE = [
    {
        code: "00",
        desc: "Giao dịch thành công"
    },
    {
        code: "07",
        desc: "Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường)."
    },
    {
        code: "09",
        desc: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng."
    },
    {
        code: "10",
        desc: "Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần"
    },
    {
        code: "11",
        desc: "Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch."
    },
    {
        code: "12",
        desc: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng bị khóa."
    },
    {
        code: "13",
        desc: "Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch."
    },
    {
        code: "24",
        desc: "Giao dịch không thành công do: Khách hàng hủy giao dịch"
    },
    {
        code: "51",
        desc: "Giao dịch không thành công do: Tài khoản của quý khách không đủ số dư để thực hiện giao dịch."
    },
    {
        code: "65",
        desc: "Giao dịch không thành công do: Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày."
    },
    {
        code: "75",
        desc: "Ngân hàng thanh toán đang bảo trì."
    },
    {
        code: "79",
        desc: "Giao dịch không thành công do: KH nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch"
    },
    {
        code: "99",
        desc: "Có lỗi xảy ra"
    },
    {
        code: "000",
        desc: "Hãy chuyển khoản sớm nhất để tin đăng của bạn được duyệt"
    },
    {
        code: "001",
        desc: "Hãy chuyển khoản sớm nhất để chúng tôi cộng iPoint cho bạn!"
    },
    {
        code: "undefined",
        desc: "Có lỗi xảy ra"
    },
    {
        code: "null",
        desc: "Có lỗi xảy ra"
    },
]
const BANKS = [
    {
        name: "Ngân hàng thương mại cổ phần Á Châu (ACB Bank) chi nhánh Bát Đàn",
        stk: "79791188888",
        holder: "Công ty Cổ phần Cung ứng nhân lực chất lượng cao CLC",
    },
    {
        name: "MB Bank - Ngân hàng Quân Đội - Chi nhánh Thanh Xuân",
        stk: "79791188888",
        holder: "Công ty Cổ phần Cung ứng nhân lực chất lượng cao CLC",
    },
    {
        name: "BIDV - Ngân hàng Thương mại cổ phần Đầu tư và Phát triển Việt Nam - Chi nhánh Hà Tây",
        stk: "45010005342105",
        holder: "Công ty Cổ phần Cung ứng nhân lực chất lượng cao CLC",
    },
]
export const PaymentResponse = (props) => {
    const search = props.location.search.split('&');
    const code = search[0].split('=')[1];
    const amount = search.length > 2 ? search[1].split('=')[1] : 0;
    const orderId = search.length > 2 ? search[2].split('=')[1] : 0;
    const successCode = ["00", "000", "001"];
    return (
        <>
            <LF_Breadcumbs
                route={`/admin/ipoint/buy-ipoint`}
                routeName={'Mua dịch vụ'}
                className="font-bold text-lg"
            />
            <div className={'flex flex-col items-center justify-center mt-10'}>
                {
                    successCode.includes(code) ? (
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M30 60.001C46.5687 60.001 60 46.5697 60 30.001C60 13.4322 46.5687 0.000976562 30 0.000976562C13.4312 0.000976562 0 13.4322 0 30.001C0 46.5697 13.4312 60.001 30 60.001ZM16.375 29.001L13.625 31.626L26.125 44.001L47.625 18.751L44.875 16.251L25.875 38.501L16.375 29.001Z"
                                  fill="#03AB00"/>
                        </svg>) : (<svg
                        width="61"
                        height="60"
                        viewBox="0 0 61 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0)">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M30.5 60.001C47.0687 60.001 60.5 46.5697 60.5 30.001C60.5 13.4322 47.0687 0.000976562 30.5 0.000976562C13.9312 0.000976562 0.5 13.4322 0.5 30.001C0.5 46.5697 13.9312 60.001 30.5 60.001ZM30.9412 27.7885L21.45 18.2972C20.4538 17.2972 19.5275 17.2697 18.6213 18.0385C17.7162 18.806 17.9212 20.0597 18.4662 20.6222L28.2812 30.431L18.6313 40.0847C17.7225 40.9947 17.9312 42.1422 18.5487 42.806C19.165 43.471 20.49 43.6597 21.4363 42.5885L30.9212 33.1085L40.4762 42.6372C41.4713 43.6347 42.4863 43.5422 43.2388 42.8185C43.995 42.0935 44.1388 41.016 43.1463 40.0185L33.575 30.431L43.0063 21.031C44.0188 20.1597 44.1312 18.831 43.3775 18.106C42.2275 16.9997 41.065 17.6835 40.5475 18.2035L30.9412 27.7872V27.7885Z"
                                fill="#F13642"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect
                                    width="60"
                                    height="60"
                                    fill="white"
                                    transform="translate(0.5 0.000976562)"
                                />
                            </clipPath>
                        </defs>
                    </svg>)
                }


                {
                    RESPONSE_CODE.map((e, index) => {
                        if (e.code === code) {
                            return (<div key={index}>
                                {
                                    successCode.includes(code) ?
                                        <>
                                            <p className='py-4 text-black text-xl font-medium'>
                                                {e.desc}
                                            </p>
                                            <p>{`${code === "000" ? `Tin của bạn sẽ được xét duyệt trước khi hiển thị trên hệ thống` : ""}`}</p>
                                        </>
                                        :
                                        <p className='py-4 text-black text-xl font-medium'>
                                            {e.desc}
                                        </p>
                                }

                            </div>)
                        }
                    })
                }
                {
                    code === "000" || code === "001" ?
                        <div className={"mt-4 border-dotted border-[#B50829] border-2 p-6"}>
                            <h4 className={'text-center font-bold text-lg'}>Quý khách chuyển khoản vào 1 trong các tài khoản dưới đây</h4>
                            <p className={"text-center mt-2"} style={{color: "#B50829"}}>Nội dung thanh toán ghi chính xác mã đơn hàng <b>{orderId}</b> để chúng tôi xử lý được nhanh chóng.</p>
                            {
                                BANKS.map((item, index) => {
                                    return (
                                        <div key={index} className='flex-1 mt-2 bg-gray-100 p-4 rounded-xl'>
                                            <p className='font-bold'>{item.name}</p>
                                            <p className='mt-1'>Số Tài khoản: {item.stk}</p>
                                            <p className='mt-1'>Chủ Tài Khoản: {item.holder}</p>
                                            <p className='mt-1'>Nội dung thanh toán: {orderId}</p>
                                            <p>Số tiền: {ipointFormat(amount)} đ</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        : ""
                }
                <Link to="/admin" className="custom-btn-done mt-10">
                    <div
                        className="rounded bg-[#B50829] text-white py-3 text-center cursor-pointer text-lg font-medium">
                        Quay về trang chủ
                    </div>
                </Link>
            </div>
        </>
    );
};