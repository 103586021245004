/* eslint-disable jsx-a11y/anchor-is-valid */
import { Trimming } from 'components/atoms';
import React from 'react';

export const GirdPost = ({ prop }) => {
  const data = prop.slice(0, 4);

  return (
    <div className="grid grid-cols-2 gap-4 py-6 mt-3">
      {data &&
        data.map((ele, index) => (
          <div className="overflow-hidden mb-5" key={index}>
            <div className="flex">
              <div className="flex-shrink-0 h-full" style={{}}>
                <img
                  className=" object-cover "
                  src={ele.src}
                  alt="Man looking at item at a store"
                />
              </div>
              <div className="pl-3">
                <a
                  href={ele.href}
                  className="block leading-tight font-bold text-black hover:underline text-sm"
                  target="_blank"
                >
                  <Trimming lines={1}>{ele.title}</Trimming>
                </a>

                <Trimming lines={2} className="mt-3 text-gray-500 text-sm ">
                  {ele.context}
                </Trimming>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
