import React from 'react';
import { LF_Breadcumbs } from '../../../../components/molecules';
import { CLC_IPointBuy } from '../../../../components/organisms';
export const IPointBuy = () => {
  return(
    <React.Fragment>
      <LF_Breadcumbs
        route={`/admin/ipoint/buy-ipoint`}
        routeName={'Mua iPoint'}
        className="font-bold text-lg"
      />
      <CLC_IPointBuy />
    </React.Fragment>
  )
}