import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CandidateRepository } from '../../../services/repositories/candidate.repository';
import {getIPoint} from "../../actions";
import {useDispatch} from "react-redux";

//Repository
const candidateRepository: CandidateRepository = RepositoryFactory.get(
  'candidate',
) as CandidateRepository;

//Async Fetch
const fetchUserViewProfile = async ({ user_id, data }) => {
  const response = await candidateRepository.viewProfile(user_id, data);
  return response;
};

function* handlerViewProfile(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const userViewProfile = yield call(fetchUserViewProfile, {
      user_id: action.payload.user_id,
      data: action.payload.data,
    });
    if (userViewProfile.status === 0){
      window.open(`https://i-hr.vn/profile/${userViewProfile.slug}`);
    }else {
      alert('Số dư i-Point không đủ');
    }
    yield put({
      type: types.POST_USER_VIEW_SUCCESS,
      payload: userViewProfile,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.POST_USER_VIEW_ERROR,
      payload: err.response.data,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchUserViewProfile() {
  yield takeLatest(types.POST_USER_VIEW_REQUEST, handlerViewProfile);
}
