/* eslint-disable no-sparse-arrays */
import React from 'react';
import cx from 'classnames';
//Types
import { MultipleSelectProps } from './multiple.type';

export const MultipleSelect = ({
  careers,
  skills,
  degree,
  address,
  searchCareer,
  listChoose,
  listChooseDegree,
  listChooseSkill,
  listChooseAddress,
  listChooseCareer,
  onAddChooseDegree,
  onAddChooseSkill,
  onAddChooseAddress,
  onAddChooseCareer,
  onDeleteChooseSkill,
  onDeleteChooseDegree,
  onDeleteChooseAddress,
  onDeleteChooseCareer,
  onAddChoose,
  onDeleteChoose,
  query,
  setQuery,
}: Partial<MultipleSelectProps>) => {
  //State
  const [show, setShow] = React.useState(false);
  const ref = React.useRef();

  useOnClickOutside(ref, () => setShow(!show));
  return (
    <React.Fragment>
      <div
        className={cx([
          'appearance-none flex flex-row flex-wrap border border-gray-400 rounded w-full p-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-text items-center',
          listChoose?.length ? '' : 'py-3',
        ])}
        onClick={() => {
          setShow(!show);
        }}
      >
        {listChoose
          ? listChoose?.map((item) => (
              <div
                className="px-3 py-1 rounded-full m-1"
                style={{ background: ' rgba(33, 33, 33, 0.08)' }}
                key={item.career_group_code}
              >
                <span className="mr-3 text-black normal-case">
                  {item.career_group_name}
                </span>
                <span
                  className="text-gray-800 cursor-pointer"
                  onClick={() => {
                    onDeleteChoose(item.career_group_code);
                  }}
                >
                  <i className="fas fa-times-circle"></i>
                </span>
              </div>
            ))
          : null}
        {listChooseCareer
          ? listChooseCareer?.map((item) => (
              <div
                className="px-3 py-1 rounded-full m-1"
                style={{ background: ' rgba(33, 33, 33, 0.08)' }}
                key={item.career_code}
              >
                <span className="mr-3 text-black normal-case">
                  {item.career_name}
                </span>
                <span
                  className="text-gray-800 cursor-pointer"
                  onClick={() => {
                    onDeleteChooseCareer(item.career_code);
                  }}
                >
                  <i className="fas fa-times-circle"></i>
                </span>
              </div>
            ))
          : null}
        {listChooseSkill
          ? listChooseSkill?.map((item) => (
              <div
                className="px-3 py-1 rounded-full m-1"
                style={{ background: ' rgba(33, 33, 33, 0.08)' }}
                key={item.skill_code}
              >
                <span className="mr-3 text-black normal-case">
                  {item.skill_name}
                </span>
                <span
                  className="text-gray-800 cursor-pointer"
                  onClick={() => {
                    onDeleteChooseSkill(item.skill_code);
                  }}
                >
                  <i className="fas fa-times-circle"></i>
                </span>
              </div>
            ))
          : null}
        {listChooseDegree
          ? listChooseDegree?.map((item) => (
              <div
                className="px-3 py-1 rounded-full m-1"
                style={{ background: ' rgba(33, 33, 33, 0.08)' }}
                key={item.id}
              >
                <span className="mr-3 text-black normal-case">{item.name}</span>
                <span
                  className="text-gray-800 cursor-pointer"
                  onClick={() => {
                    onDeleteChooseDegree(item.id);
                  }}
                >
                  <i className="fas fa-times-circle"></i>
                </span>
              </div>
            ))
          : null}
        {listChooseAddress
          ? listChooseAddress?.map((item) => (
              <div
                className="px-3 py-1 rounded-full m-1"
                style={{ background: ' rgba(33, 33, 33, 0.08)' }}
                key={item.province_code}
              >
                <span className="mr-3 text-black normal-case">
                  {item.province_name}
                </span>
                <span
                  className="text-gray-800 cursor-pointer"
                  onClick={() => {
                    onDeleteChooseAddress(item.province_code);
                  }}
                >
                  <i className="fas fa-times-circle"></i>
                </span>
              </div>
            ))
          : null}
        <div
          className="h-[18px] w-[1px] mx-1 "
          style={{ background: '#495057' }}
        ></div>
      </div>
      {show && (
        <div ref={ref} className="w-full border border-gray-400 p-5">
          {degree?.length > 0 ? (
            <div
              className="text-gray-800 cursor-pointer text-right"
              onClick={() => setShow(false)}
            >
              <i className="fas fa-times-circle"></i>{' '}
            </div>
          ) : (
            <React.Fragment>
              <div className="flex justify-between mb-1">
                <div>Từ khoá:</div>
                <div
                  className="text-gray-800 cursor-pointer"
                  onClick={() => setShow(false)}
                >
                  <i className="fas fa-times-circle"></i>{' '}
                </div>
              </div>
              <input
                type="text"
                value={query || ''}
                onChange={(e) => setQuery(e.target.value)}
                className="rounded border border-gray-400 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </React.Fragment>
          )}
          {careers?.length > 0 ? (
            <React.Fragment>
              <div className="mt-5 mb-1">Lĩnh vực:</div>
              <div
                className="p-5 border border-gray-400 rounded overflow-y-auto"
                style={{ height: '300px' }}
              >
                {careers.map((career) => (
                  <div
                    className="p-2 hover:bg-gray-500 hover:text-white cursor-pointer"
                    key={career.career_group_code}
                    onClick={() => {
                      onAddChoose(career);
                    }}
                  >
                    <h4>{career.career_group_name}</h4>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ) : null}
          {searchCareer?.length > 0 ? (
            <React.Fragment>
              <div className="mt-5 mb-1">Lĩnh vực:</div>
              <div
                className="p-5 border border-gray-400 rounded overflow-y-auto"
                style={{ height: '300px' }}
              >
                {searchCareer.map((searchCareer) => (
                  <div
                    className="p-2 hover:bg-gray-500 hover:text-white cursor-pointer"
                    key={searchCareer.career_code}
                    onClick={() => {
                      onAddChooseCareer(searchCareer);
                    }}
                  >
                    <h4>{searchCareer.career_name}</h4>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ) : null}
          {skills?.length > 0 ? (
            <React.Fragment>
              <div className="mt-5 mb-1">Lĩnh vực:</div>
              <div
                className="p-5 border border-gray-400 rounded overflow-y-auto"
                style={{ height: '300px' }}
              >
                {skills.map((skills) => (
                  <div
                    className="p-2 hover:bg-gray-500 hover:text-white cursor-pointer"
                    key={skills.skill_code}
                    onClick={() => {
                      onAddChooseSkill(skills);
                    }}
                  >
                    <h4>{skills.skill_name}</h4>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ) : null}
          {degree?.length > 0 ? (
            <React.Fragment>
              <div className=" mb-1">Lĩnh vực:</div>
              <div
                className="p-5 border border-gray-400 rounded overflow-y-auto"
                style={{ height: '300px' }}
              >
                {degree.map((degree) => {
                  return (
                    <div
                      className="p-2 hover:bg-gray-500 hover:text-white cursor-pointer"
                      key={degree.id}
                      onClick={() => {
                        onAddChooseDegree(degree);
                      }}
                    >
                      <h4>{degree.name}</h4>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          ) : null}
          {address?.length > 0 ? (
            <React.Fragment>
              <div className="mt-5 mb-1">Địa chỉ học</div>
              <div
                className="p-5 border border-gray-400 rounded overflow-y-auto"
                style={{ height: '300px' }}
              >
                {address.map((address) => {
                  return (
                    <div
                      className="p-2 hover:bg-gray-500 hover:text-white cursor-pointer"
                      key={address.province_code}
                      onClick={() => {
                        onAddChooseAddress(address);
                      }}
                    >
                      <h4>{address.province_name}</h4>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

function useOnClickOutside(ref, handler) {
  React.useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
