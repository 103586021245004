import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SpinnerLofi } from '../../components/atoms';
import { useAuth } from '../../views/wrapper/Auth.wrapper';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        !auth.isLoading ? (
          auth.isAuthenticate ? (
            <Component {...props} />
          ) : (
            <Redirect to="/auth/login" />
          )
        ) : (
          <SpinnerLofi className="w-full h-full absolute top-0 left-0 bg-opacity-5 overlay z-50" />
        )
      }
    />
    // <Route {...rest} render={(props) => <Component {...props} />} />
  );
};

export default PrivateRoute;
