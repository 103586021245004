/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import {
  LF_Avatar,
  LF_DesiredPosition,
  LF_Education,
  LF_Infor,
  LF_LiList,
  LF_StatusText,
  LF_Status,
} from 'components/atoms';
import {
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Button,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';

import { useDispatch, useSelector } from 'react-redux';

//Actions
import { updateJobEmployee, viewProfile } from '../../../../redux/actions';

//csv
import { CSVLink } from 'react-csv';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      '&:focus': {
        outline: 'none',
      },
    },
  },
  buttonTab: {
    backgroundColor: 'white !improtant',
    color: '#000000 !important',
    fontWeight: 'bold',
    fontSize: '14px',
    minWidth: 100,
    textTransform: 'none',
    padding: '0 12px',
    opacity: 1,
    '&:focus': {
      outline: '2px solid transparent',
      outlineOffset: '2px',
      backgroundColor: '#B50829 !important',
      color: 'white !important',
    },
    '&:active': {
      backgroundColor: '#B50829 !important',
      color: 'white !important',
    },
  },
  btnRed: {
    backgroundColor: '#B50829',
    '&:hover': {
      backgroundColor: '#B50829',
    },
  },
}));

export const LF_JobEmployee = ({
  rows,
  data,
  per_page,
  fromSearch,
  corporates,
  onChangeStatusEmp,
  page,
  handlePage,
  dataExportExcel,
}) => {
  const classes = useStyles();
  //React - Redux
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state['loading']);

  const [title, setTitle] = React.useState(false);

  const [value, setValue] = React.useState(0);
  const [listUserChecked, setListUserChecked] = React.useState([]);
  const [onCheck, setOncheck] = React.useState(false);
  const [rowsCustom, setCustom] = React.useState([]);
  const [company, setCompany] = React.useState(corporates);
  const [statusEmp, setStatusEmp] = React.useState(0);

  // useEffect
  React.useEffect(() => {
    setCompany(corporates);
  }, [corporates]);

  React.useEffect(() => {
    if (rows !== null) {
      setCustom(rows);
    } else {
      setCustom([]);
    }
  }, [rows]);

  const onClick = (user_id) => {
    dispatch(
      viewProfile(user_id, {
        p_corporate_code: company[0]?.corporate_code,
        p_user_id: user_id,
        p_corporate_name: company[0]?.corporate_name,
        p_image_name: company[0]?.logo,
      }),
    );
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    let a = (value - 1) * 20;
    handlePage(value);
    fromSearch('p_skip', a.toString());
  };

  const [status, setChangeStatus] = React.useState({
    p_user_id: null,
    p_job_id: parseInt(data.job_id),
    p_status: 2,
  });

  React.useEffect(() => {
    setChangeStatus({ ...status, p_job_id: parseInt(data.job_id) });
  }, [data]);

  React.useEffect(() => {
    if (rows && rows.length > 0)
      setCustom(rows.map((e) => ({ ...e, checked: false })));
  }, [rows]);

  React.useEffect(() => {
    function checkAllCustom() {
      let checkAll = true;
      let filter = rowsCustom?.filter((e) => e.checked === false);
      if (filter.length > 0) checkAll = false;
      setOncheck(checkAll);
    }

    checkAllCustom();
  }, [rowsCustom]);

  //update status
  const changeStatus = () => {
    const users = rowsCustom
      .filter((user) => user.checked === true)
      .map((user) => {
        return {
          user_id: user.user_id,
          job_id: user.job_id
        }
      });
    if (users.length > 0) {
      setChangeStatus({
        ...status,
        p_user_id: users,
      });
      onChangeStatusEmp({
        ...status,
        p_user_id: users,
      });
    }
  };

  const onCheckAll = () => {
    if (!onCheck) {
      setListUserChecked(rows?.map((e) => e.user_id));
      setCustom((prev) => {
        return prev?.map((e) => ({ ...e, checked: true }));
      });
    } else {
      setListUserChecked([]);
      setCustom((prev) => {
        return prev?.map((e) => ({ ...e, checked: false }));
      });
    }
  };

  const onChangeCheckbox = (event, idx) => {
    let nextState = [...rowsCustom];
    if (event.target.checked) {
      nextState[idx] = { ...nextState[idx], checked: true };
    } else {
      nextState[idx] = { ...nextState[idx], checked: false };
    }

    setCustom(nextState);
  };

  const onChangeStatus = (event) => {
    setChangeStatus({
      ...status,
      p_status: parseInt(event.target.value),
    });
  };

  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const onUpdateStatus = (data) => {
    onChangeStatusEmp(data);
  };

  //View HTML
  const titleTab = (label, totalData) => {
    return (
      <React.Fragment>
        <span className="flex">
          <span>{label}</span>
          <span className="pl-2">{totalData}</span>
        </span>
      </React.Fragment>
    );
  };

  const getPageTotal = () => {
    switch (statusEmp) {
      case 1:
        return per_page?.ung_tuyen;
      case 2:
        return per_page?.phong_van;
      case 3:
        return per_page?.da_chon;
      case 4:
        return per_page?.tu_choi;
      case 5:
        return per_page?.da_xoa;
      default:
        return per_page?.total;
    }
  };

  //Export Excel
  const headers = [
    { label: 'Họ', key: 'last_name' },
    { label: 'Tên', key: 'first_name' },
    { label: 'Giới tính', key: 'genderText' },
    { label: 'Số điện thoại', key: 'phone' },
    { label: 'Email', key: 'email' },
    { label: 'Kỹ năng', key: 'skill' },
    { label: 'Học vấn', key: 'educations' },
    { label: 'Mức độ phù hợp (%)', key: 'fit_percent' },
    { label: 'Phân loại', key: 'statusName' },
  ];

  return (
    <div>
      {rowsCustom.length > 0 && (
        <div
          className="text-right uppercase"
          style={{
            color: '#B50829',
          }}
        >
          <CSVLink
            data={dataExportExcel ?? []}
            headers={headers}
            filename={`danh-sach-ung-vien-${data?.title}.csv`}
          >
            Xuất ra Excel
          </CSVLink>
        </div>
      )}

      <TableContainer className="pt-7">
        <Tabs
          value={value}
          onChange={handleChangeTabs}
          style={{ width: '100%', border: ' 1px solid rgba(0, 0, 0, 0.25)', minWidth: '600px' }}
          className={"mt-10"}
        >
          <Tab
            label={titleTab('Tất cả', per_page?.total)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '20%' }}
            onClick={() => {
              fromSearch({ p_status: '0', p_skip: '0' });
              setStatusEmp(0);
            }}
          />
          <Tab
            label={titleTab('Mới ứng tuyển', per_page?.ung_tuyen)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '20%' }}
            onClick={() => {
              fromSearch({ p_status: '1', p_skip: '0' });
              setStatusEmp(1);
            }}
          />
          <Tab
            label={titleTab('Phỏng vấn', per_page?.phong_van)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch({ p_status: '2', p_skip: '0' });
              setStatusEmp(2);
            }}
          />
          <Tab
            label={titleTab('Đã chọn', per_page?.da_chon)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch({ p_status: '3', p_skip: '0' });
              setStatusEmp(3);
            }}
          />
          <Tab
            label={titleTab('Từ chối', per_page?.tu_choi)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch({ p_status: '4', p_skip: '0' });
              setStatusEmp(4);
            }}
          />
          <Tab
            label={titleTab('Đã xóa', per_page?.da_xoa)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch({ p_status: '5', p_skip: '0' });
              setStatusEmp(5);
            }}
          />
        </Tabs>
        {loading || rows == null ? (
          <div className="flex justify-center py-10">
            <CircularProgress />
          </div>
        ) : (
          <Table aria-label="simple table" className="border border-solid">
            <TableHead style={{ backgroundColor: '#F4F2FF' }}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    onChange={onCheckAll}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    checked={onCheck}
                  />
                </TableCell>
                {columns &&
                  columns.map((e, key) => {
                    return (
                      <TableCell
                        key={key}
                        style={{
                          width: `${e.width}`,
                          textAlign: 'start',
                          paddingLeft: '22px',
                        }}
                      >
                        {e.field === 'Họ & Tên' ? (
                          <button
                            className=" focus:outline-none"
                            onClick={() => {
                              setTitle(!title);
                              if (title) {
                                fromSearch('p_order', '1');
                              } else {
                                fromSearch('p_order', '2');
                              }
                            }}
                          >
                            <span className="font-semibold">
                              {e.field}
                              {title ? (
                                <i className="px-2 fas fa-angle-down text-[#B50829]"></i>
                              ) : (
                                <i className="px-2 fas fa-angle-up text-[#B50829]"></i>
                              )}
                            </span>
                          </button>
                        ) : e.field === 'Phù Hợp' ? (
                          <button
                            className=" focus:outline-none"
                            onClick={() => {
                              setTitle(!title);
                              if (title) {
                                fromSearch('p_order', '3');
                              } else {
                                fromSearch('p_order', '4');
                              }
                            }}
                          >
                            <span className="font-semibold">
                              {e.field}
                              {title ? (
                                <i className="px-2 fas fa-angle-down text-[#B50829]"></i>
                              ) : (
                                <i className="px-2 fas fa-angle-up text-[#B50829]"></i>
                              )}
                            </span>
                          </button>
                        ) : (
                          <span className="font-semibold">{e.field}</span>
                        )}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsCustom && rowsCustom.length > 0 ? (
                rowsCustom.map((e, index) => {
                  return (
                    <TableRow key={index} className="h-16 ">
                      <TableCell className="items-center ">
                        <Checkbox
                          inputProps={{ 'aria-label': 'select all desserts' }}
                          onChange={(event) => onChangeCheckbox(event, index)}
                          value={e.checked}
                          checked={e.checked}
                          name={'p_user_id'}
                        />
                      </TableCell>
                      <TableCell className="items-center ">
                        <LF_Avatar
                          src={e.avatar}
                        // width={e.avatar.width }
                        // height={e.avatar.height}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        className="items-center flex justify-center"
                      >
                        <LF_Infor
                          to={"application"}
                          name={`${e.last_name} ${e.first_name}`}
                          date={e.dob}
                          gender={e.gender}
                          address={e.province_name}
                          slug={e.slug}
                          phone={e.phone}
                          email={e.email}
                          covidStatus={e.covid_status}
                          onClick={(event) => onClick(e.user_id)}
                        />
                      </TableCell>
                      <TableCell align="left" className="items-center">
                        <LF_DesiredPosition
                            id={e?.job_id}
                            title={e?.title}
                            slug={e?.job_slug}
                            start_date={e?.start_date}
                            props={data ? data : ''}
                        />
                      </TableCell>
                      <TableCell align="left" className="items-center">
                        <LF_LiList props={e.skill.data ? e.skill.data : ''} />
                      </TableCell>
                      <TableCell align="left" className="items-center">
                        <LF_Education
                          props={e.educations.data ? e.educations.data : ''}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        className="items-center"
                        style={{ paddingLeft: ' 22px' }}
                      >
                        <LF_StatusText
                          status={e.fit_percent ? e.fit_percent : ''}
                        />
                      </TableCell>
                      <TableCell align="left" className="items-center">
                        <LF_Status
                          interview={e.status}
                          userId={e.user_id}
                          // jobID={data}
                          jobID={e?.job_id}
                          onUpdateStatus={onUpdateStatus}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <div className="mx-auto text-center text-xl">
                      Chưa có danh sách tuyển dụng
                    </div>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={3} >
                  <span className="font-semibold p-2">
                    Phân loại ứng viên được chọn:
                  </span>
                </TableCell>
                <TableCell style={{ minWidth: '200px' }} colSpan={1}>
                  <select
                    name="p_status"
                    onChange={onChangeStatus}
                    value={status.p_status}
                    className="appearance-none border border-gray-400 w-full py-3 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value={2}>Phỏng vấn</option>
                    <option value={3}>Đã chọn</option>
                    <option value={4}>Từ chối</option>
                    <option value={5}>Xóa</option>
                  </select>
                </TableCell>
                <TableCell style={{ minWidth: '200px' }} colSpan={4}>
                  <Button
                    variant="outlined"
                    onClick={changeStatus}
                    className={`${classes.btnRed} focus:outline-none`}
                    style={{
                      height: '43px',
                      color: '#ffffff',
                      alignItems: 'center',
                    }}
                  >
                    <span>Thực hiện</span>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <div className={`${classes.root} flex justify-end`}>
          <Pagination
            count={
              rows !== null && rows.length >= 1
                ? Math.ceil(getPageTotal() / 20)
                : 0
            }
            variant="outlined"
            page={page}
            onChange={handleChangePage}
            shape="rounded"
          />
        </div>
      </TableContainer>
    </div>
  );
};

const columns = [
  {
    field: 'Ảnh',
    width: '5%',
  },
  {
    field: 'Họ & Tên',
    width: '20%',
  },
  {
    field: 'Việc làm ứng tuyển',
    width: '20%',
  },
  {
    field: 'Kỹ Năng',
    width: '22%',
  },
  {
    field: 'Học Vấn',
    width: '22%',
  },
  {
    field: 'Phù Hợp',
    width: '10%',
    type: 'number',
  },
  {
    field: '',
    width: '10%',
  },
];
