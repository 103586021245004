import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';

//Repository
const provinceRepository = RepositoryFactory.get('province');

const fetchProvinces = async () => {
  const response = await provinceRepository.get();

  return response;
};

function* handlerGetProvinces(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const provinces = yield call(fetchProvinces);
    yield put({
      type: types.PROVINCE_SUCESS,
      payload: provinces,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.PROVINCE_ERROR,
      payload: err.response,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetProvinces() {
  yield takeLatest(types.PROVINCE_REQUEST, handlerGetProvinces);
}
