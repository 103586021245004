import * as types from '../type';
interface IInitialState {
  packageTime: null,
}

const initialState: IInitialState = {
  packageTime: null,
}

const packageTimeReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.GET_PACKAGES_TIME_SUCCESS:
      return {...state, packageTime: action.payload};
    case types.GET_PACKAGES_TIME_ERROR:
      return {...state, packageTime: action.payload};
    case types.GET_PACKAGES_TIME_REQUEST:
      return {...state, packageTime: action.payload};
    default:
      return {
        ...state,
      }
  }
}

export default packageTimeReducer;