/* eslint-disable react/jsx-pascal-case */
import React from 'react';

// Components
import { ProfileUserFrom } from 'components/organisms/Modules/Profile';
import { LF_Breadcumbs } from 'components/molecules';

// react-redux
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePassword,
  getEmployee,
  updateEmployee,
} from 'redux/actions/employee.action';

export const ProfileUser = () => {
  // React redux
  const dispatch = useDispatch();
  const { employee, status } = useSelector(
    (state: RootState) => state.employee,
  );

  // useEffect
  React.useEffect(() => {
    dispatch(getEmployee());
  }, []);

  // handle actions
  const handleUpdate = (data) => {
    dispatch(updateEmployee(data));
    // setTimeout(function () {
    //   window.location.reload();
    // }, 3000);
  };

  const handleChangePass = (data) => {
    dispatch(changePassword(data));
  };

  return (
    <React.Fragment>
      <LF_Breadcumbs
        route={`/admin/user`}
        routeName={'Thông tin tài khoản'}
        className="font-bold text-lg"
      />
      {/* Page */}
      <ProfileUserFrom
        data={employee}
        onUpdate={handleUpdate}
        onChangePass={handleChangePass}
        status={status && status}
      />
    </React.Fragment>
  );
};
