import * as types from './../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { RepositoryFactory } from '../../../services/repositories';
import { StorageRepository } from '../../../services/repositories/storage.repository';

const storageRepository: StorageRepository = RepositoryFactory.get(
  'storage',
) as StorageRepository;

const callUploadMedia = async (data) => {
  const corporate_code = localStorage.getItem('corporate_code');
  const { accessToken } = await storageRepository.getStorageAccessToken(corporate_code);
  if (accessToken) {
    const image = await axios.post(
      `https://assets.i-hr.vn/upload?accessToken=${accessToken}${data.target ? `&target=${data.target}` : ''}`,
      data.image,
    );

    return image.data;
  }
};

function* uploadMedia(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const image = yield call(callUploadMedia, action.payload);
    yield put({ type: types.UPLOAD_MEDIA_SUCCESS, payload: image });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (error) {
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchUploadImage() {
  yield takeLatest(types.UPLOAD_MEDIA_REQUEST, uploadMedia);
}
