/* eslint-disable react/jsx-pascal-case */
import { LF_Breadcumbs } from 'components/molecules';
import { LF_DataTable } from 'components/organisms';
import { LF_HeaderCourses } from 'components/organisms/Modules/BoxHeadCourse';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveCourse,
  getCoursesById,
  getStudentById,
  resetStore,
  applyStatusCourse,
  exportExcelStudent,
} from 'redux/actions';
import { RootState } from 'redux/store';
import { useParams, useLocation } from 'react-router-dom';

export const LF_RegisterCourse = () => {
  //dispatch
  const dispatch = useDispatch();
  let query = useQuery();
  //Page
  const [page, setPage] = React.useState(1);
  const handlePage = (data) => {
    setPage(data);
  };
  //selectter
  const { courses, course, students, courseActive } = useSelector(
    (state: RootState) => state.courses,
  );

  const [form, setForm] = React.useState({
    // course_id: corporate,
    course_id: course?.data?.course_id || null,
    search_string: '',
    p_skip: '0',
    p_limit: '20',
    status: '0',
    p_order: '1',
  });

  //effect
  React.useEffect(() => {
    dispatch(getStudentById(form));
    dispatch(getCoursesById(form?.course_id || null));
    return () => {
      dispatch(resetStore());
    };
  }, [form]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const fetchCourseById = () => {
    if (query.get('course_id') !== null) {
      setForm({ ...form, course_id: query.get('course_id') });
      dispatch(getStudentById({ course_id: query.get('course_id') }));
      dispatch(getCoursesById(query.get('course_id')));
    }
  };
  React.useEffect(() => {
    dispatch(getActiveCourse());
    fetchCourseById();
  }, []);

  //hanle
  const handleSearch = (name, value) => {
    if (typeof name === 'object') {
      setForm((prevState) => {
        return { ...prevState, ...name };
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };
  //updateStatus
  const updateStatus = (data) => {
    let users = {};
    data.p_user_id.forEach(user => {
      if (users[user.course_id]) {
        users[user.course_id]["p_user_id"] = `${users[user.course_id]["p_user_id"]},${user.user_id}` ;
      } else {
        users[user.course_id] = {
          p_user_id: `${user.user_id}`,
          p_course_id: user.course_id,
          p_status: data.p_status,
        }
      }
    });
    dispatch(applyStatusCourse(users, form));
  };

  //Export Excel
  const getPageTotal = (statusEmp) => {
    switch (statusEmp) {
      case 1:
        return students?.total_data?.dang_ky;
      case 2:
        return students?.total_data?.trung_tuyen;
      case 3:
        return students?.total_data?.da_chon;
      case 4:
        return students?.total_data?.tu_choi;
      case 5:
        return students?.total_data?.da_xoa;
      default:
        return students?.total_data?.total;
    }
  };

  const dataExportExcel = useSelector((state) => state['exportExcel'].student);
  React.useEffect(() => {
    dispatch(
      exportExcelStudent({
        ...form,
        p_limit: getPageTotal(parseInt(form.status)),
        p_skip: '0',
      }),
    );
  }, [students]);
  return (
    <>
      <LF_Breadcumbs
        route={`/admin/course/registerTrainee`}
        routeName={'Học viên đăng ký'}
        className="font-bold text-lg"
      />
      <div className={'p-4 lg:p[33px]'}>
        <LF_HeaderCourses
          title="Chọn khoá học"
          button="trúng tuyển"
          data={courseActive}
          onSearch={handleSearch}
          selectData={course?.data}
        />
        <div className="container pt-6">
          <LF_DataTable
            rows={students && students?.data}
            student={course?.data}
            type="CourseList"
            fromSearch={handleSearch}
            per_page={students && students.total_data}
            page={page}
            handlePage={handlePage}
            onChangeStatusStudent={updateStatus}
            dataExportExcel={dataExportExcel.data}
          />
        </div>
      </div>
    </>
  );
};
