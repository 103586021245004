import React, { useState } from 'react';
import { Link } from 'react-router-dom';


export const GirdDashBoard = ({ data }) => {
  const [state, setState] = useState(null);
  React.useEffect(() => {
    setState(data.data);
  }, [data]);

  // console.log(state);

  // const data1 = data.slice(0, 4);
  // const data2 = data.slice(4, data.length);
  return (
    <>
      <div className={`grid grid-cols-2 gap-4 mt-6`}>
        <div
          className="text-center rounded p-1"
          style={{ border: '1px solid rgba(0, 0, 0, 0.25)' }}
        >
          <div
            className="pt-6 py-6 pb-2 font-bold text-6xl lg:text-6xl"
            style={{
              color: '#B50829 ',
            }}
          >
            {state && state.count_jobs}
          </div>
          <div
            className="font-bold text-sm pb-4"
            style={{ color: 'rgba(0, 0, 0, 0.75' }}
          >
            <Link to={'/admin/job/recruitment'}> Việc làm đang tuyển </Link>
          </div>
        </div>
        <div
          className="text-center rounded p-1"
          style={{ border: '1px solid rgba(0, 0, 0, 0.25)' }}
        >
          <div
            className="pt-6 py-6 pb-2 font-bold text-6xl lg:text-6xl"
            style={{
              color: '#B50829 ',
            }}
          >
            {state && state.count_candidates}
          </div>
          <div
            className="font-bold text-sm pb-4"
            style={{ color: 'rgba(0, 0, 0, 0.75' }}
          >
            <Link to={'/admin/job/employee'}>Hồ sơ mới ứng tuyển</Link>
          </div>
        </div>

        <div
          className="text-center rounded "
          style={{ border: '1px solid rgba(0, 0, 0, 0.25)' }}
        >
          <div
            className="pt-6 py-6 pb-2 font-bold text-6xl lg:text-6xl"
            style={{
              color: '#B50829 ',
              fontSize: '64px',
              lineHeight: '75px',
            }}
          >
            {state && state.count_courses}
          </div>
          <div
            className="font-bold text-sm pb-4"
            style={{ color: 'rgba(0, 0, 0, 0.75' }}
          >
            <Link to={'/admin/course/list'}>Khóa học đang tuyển</Link>
          </div>
        </div>
        <div
          className="text-center rounded "
          style={{ border: '1px solid rgba(0, 0, 0, 0.25)' }}
        >
          <div
            className="pt-6 py-6 pb-2 font-bold"
            style={{
              color: '#B50829 ',
              fontSize: '64px',
              lineHeight: '75px',
            }}
          >
            {state && state.count_applied_student}
          </div>
          <div
            className="font-bold text-sm pb-4"
            style={{ color: 'rgba(0, 0, 0, 0.75' }}
          >
            <Link to={'/admin/course/register'}>Học viên đang ứng tuyển</Link>
          </div>
        </div>
      </div>
      {/* <div className={`grid grid-cols-3 gap-4 mt-6`}>
        {data2 &&
          data2.map((ele, index) => (
            <div
              key={index}
              className="text-center rounded "
              style={{ border: '1px solid rgba(0, 0, 0, 0.25)' }}
            >
              <div
                className="pt-6 py-6 pb-2 font-bold"
                style={{
                  color: '#B50829 ',
                  fontSize: ' 64px',
                  lineHeight: '75px',
                }}
              >
                {ele.amount}
              </div>
              <div
                className="font-bold text-sm pb-4"
                style={{ color: 'rgba(0, 0, 0, 0.75' }}
              >
                {ele.textarea}
              </div>
            </div>
          ))} 
      </div> */}
    </>
  );
};
