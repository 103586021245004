import { fetchJobs } from './getJobs.saga';
import * as types from './../../type';
import { RepositoryFactory } from 'services/repositories';
import { JobsRepository } from './../../../services/repositories/jobs.repository';
import { call, put, takeLatest } from 'redux-saga/effects';

const jobsRepository = RepositoryFactory.get('jobs') as JobsRepository;

const deleteJobs = async (id) => {
  const response = await jobsRepository.delete(id);
  return response;
};

function* handlerDeleteJob(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const jobs = yield call(deleteJobs, action.payload);
    yield put({ type: types.DELETE_JOB_SUCCESS, action: jobs });
    // const refetchJobs =  yield call(fetchJobs, action.payload);
    // yield put({ type: types.GET_JOBS_SUCCESS, action: refetchJobs });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.DELETE_JOB_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}
export default function* watchDeleteJob() {
  yield takeLatest(types.DELETE_JOB_REQUEST, handlerDeleteJob);
}
