import React from 'react';
import { Button, ButtonGroup, ClickAwayListener } from '@material-ui/core';
import { TagMaker } from './Status.maker';

export const LF_StatusRecuit = (props) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.interview);
  const [title, setTitle] = React.useState('');
  const classes = TagMaker();

  React.useEffect(() => {
    setValue(props.interview);
  }, [props]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const JSX = () => {
    return (
      <div className="absolute z-50 bg-white" onClick={handleClickAway}>
        <ButtonGroup className=" w-[180px]" orientation="vertical">
          {value === 2 ? (
            <Button
              className="min-w-[180px] focus:outline-none pointer-events-none"
              style={{ backgroundColor: '#000', color: 'white' }}
              onClick={() => {
                setValue(3);
              }}
            >
              Tạm Dừng
            </Button>
          ) : value === 3 ? (
            <Button
              className="min-w-[180px] pointer-events-none"
              style={{ backgroundColor: '#01A930', color: 'white' }}
              onClick={() => {
                setValue(2);
              }}
            >
              Đang Hoạt Động
            </Button>
          ) : null}
        </ButtonGroup>
      </div>
    );
  };

  React.useEffect(() => {
    Value(value);
  }, [value]);

  const Value = (e) => {
    if (props.type === 'course') {
      if (!e || e === 1) return setTitle('Mới tạo');
      if (e === 2) return setTitle('Đang hoạt động');
      if (e === 3) return setTitle('Tạm dừng');
      if (e === 4) return setTitle('Hết hạn');
      if (e === 5) return setTitle('Đã xoá');
      if (e === 7) return setTitle('Chờ thanh toán');
      if (e === 8) return setTitle('Chờ duyệt');
    } else {
      if (!e || e === 1) return setTitle('Mới tạo');
      if (e === 2) return setTitle('Đang hoạt động');
      if (e === 3) return setTitle('Tạm dừng');
      if (e === 5) return setTitle('Hết hạn');
      if (e === 6) return setTitle('Đã xoá');
      if (e === 7) return setTitle('Chờ thanh toán');
      if (e === 8) return setTitle('Chờ duyệt');
    }
  };

  if (!props.status) {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="static focus:outline-none ">
          <Button
            variant="contained"
            className={`${classes.tag} + ${
                [1, 7, 8].includes(value) || !value
                ? classes.tagRed
                : value === 2
                ? classes.tagGreen
                : value === 5
                ? classes.tagGary
                : classes.tagBlack
              // ? classes.tagGreen
              // : classes.tagBlack
            } + static focus:outline-none pointer-events-none`}
            style={{
              textTransform: 'none',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '15px',
            }}
          >
            {title}
          </Button>
          {open ? JSX() : null}
        </div>
      </ClickAwayListener>
    );
  }
};
