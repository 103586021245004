import * as types from '../../type';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { IPointRepository } from '../../../services/repositories/ipoint.repository';

//Repository
const ipointRepositoryTotal: IPointRepository = RepositoryFactory.get(
  'ipoint',
) as IPointRepository;

const fetchIPointTotal = async (p_corporate_code) => {
  const response = await ipointRepositoryTotal.getIPointTotal(p_corporate_code);
  return response;
};

function* handleIPoint(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const ipoint = yield call(fetchIPointTotal, action.payload);
    yield put({ type: types.GET_IPOINT_TOTAL_SUCCESS, payload: ipoint });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.GET_IPOINT_TOTAL_ERROR,
      payload: err.response,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetIPoint() {
  yield takeLatest(types.GET_IPOINT_TOTAL_REQUEST, handleIPoint);
}