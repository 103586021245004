import * as types from '../type';
import { ICandidate } from '../../utils/interfaces';

interface IInitialState {
  candidates: { data: any; total_job: any };
  candidate: ICandidate | {};
  candidateMatch: { data: []; total_data: any };
  status: any;
}

const initialState: IInitialState = {
  candidates: { data: [], total_job: null },
  candidateMatch: { data: [], total_data: null },
  candidate: {},
  status: null,
};

const candidateReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.GET_CANDIDATE_SUCCESS:
      return { ...state, candidates: action.payload };
    case types.GET_MATCHCANDIDATESEARCH_SUCCESS:
      return { ...state, candidateMatch: action.payload };
    case types.POST_USER_VIEW_SUCCESS:
      return { ...state, status: action.payload };
    case types.RESET_STORE_CANDIDATE:
      return {
        ...state,
        candidates: { ...state.candidates, data: null },
      };
    default:
      return {
        ...state,
      };
  }
};

export default candidateReducer;
