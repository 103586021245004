/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

import { GirdDashBoard, GirdPost } from 'components/molecules';

import Job from './../../../../assets/images/Job.png';
import Candidate from './../../../../assets/images/Candidate.png';
import createJob from './../../../../assets/images/createJob.png';
import matchCandidate from './../../../../assets/images/matchCandidate.png';

const defaultProp = [
  {
    src: Job,
    title: 'Tạo việc làm mới',
    context: 'Hướng dẫn bạn cách tạo việc làm mới nhanh chóng',
    href: 'https://docs.google.com/document/d/1Be8DrhqRdqE6wGCnbqh5zQDV8M4bTSilfa4mmKEjtwM/edit#heading=h.17dp8vu',
  },
  {
    src: createJob,
    title: 'Quản lý đăng tuyển',
    context: 'Hướng dẫn bạn quản lý các tin đăng tuyển của mình',
    href: 'https://docs.google.com/document/d/1Be8DrhqRdqE6wGCnbqh5zQDV8M4bTSilfa4mmKEjtwM/edit#heading=h.3rdcrjn',
  },
  {
    src: Candidate,
    title: 'Quản lý ứng viên',
    context: 'Hướng dẫn bạn cách quản lý, phân loại và sắp xếp hồ sơ ứng viên',
    href: 'https://docs.google.com/document/d/1Be8DrhqRdqE6wGCnbqh5zQDV8M4bTSilfa4mmKEjtwM/edit#heading=h.26in1rg',
  },
  {
    src: matchCandidate,
    title: 'Tìm kiếm ứng viên',
    context: 'Hướng dẫn bạn tìm kiếm ứng viên theo các tiêu chí mong muốn',
    href: 'https://docs.google.com/document/d/1Be8DrhqRdqE6wGCnbqh5zQDV8M4bTSilfa4mmKEjtwM/edit#heading=h.lnxbz9',
  },
];

export const LF_Dashboard = ({ data }) => {
  const data2 = defaultProp;

  return (
    <div className="" style={{ padding: '25px', color: 'rgba(0, 0, 0, 0.75)' }}>
      <div className="uppercase font-bold" style={{ color: ' #000000' }}>
        Bảng tin
      </div>
      <GirdDashBoard data={data} />

      <div className="uppercase font-bold pt-10" style={{ color: ' #000000' }}>
        hướng dẫn sử dụng
      </div>
      <GirdPost prop={data2} />
    </div>
  );
};
