import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { EmployeeRepository } from 'services/repositories/employee.repository';

//Initial State
const employeeRepository: EmployeeRepository = RepositoryFactory.get(
  'emp',
) as EmployeeRepository;

const fetchEmployee = async (aciton) => {
  const response = await employeeRepository.get();

  return response;
};

function* gteEmployee(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const employee = yield call(fetchEmployee, action.payload);
    yield put({ type: types.EMPLOYEE_SUCCESS, payload: employee });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.EMPLOYEE_ERROR, payload: {} });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetEmployee() {
  yield takeLatest(types.EMPLOYEE_REQUEST, gteEmployee);
}
