//Token services
import axios from 'axios';
import { APP_CONFIG } from '../../constants';

interface UserDetails {
  username: string;
  password: string;
}
const token = localStorage.getItem('access-token');
const refreshToken = localStorage.getItem('refresh-token');
class AuthenticationServices {
  request;
  baseUrl = `${APP_CONFIG.BACKEND_DOMAIN}/${APP_CONFIG.BACKEND_API_END_POINT}/${APP_CONFIG.BACKEND_API_VERSION}/`;
  constructor() {
    this.request = axios.create({
      // xsrfCookieName: 'XSRF-TOKEN',
      // xsrfHeaderName: 'XSRF-Token',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async login(data: UserDetails) {
    let url = this.baseUrl + `auth/login`;
    const response = await (await this.request.post(url, data)).data;

    return response;
  }

  async refreshToken() {
    try {
      let url = `${this.baseUrl}auth/refresh-token`;
      const response = await (
        await this.request.post(url, { refresh_token: refreshToken })
      ).data;
      // const userId = response._id;
      // const permissions = await (await this.request.get(`${this.baseUrl}`))
      //   .data;
      // return { ...response, permissions: permissions.permissions };

      return response;
    } catch {
      return false;
    }
  }

  async logout() {
    let url = this.baseUrl + 'auth/logout';
    const response = await this.request.post(url);

    return response;
  }

  async logOutAll() {
    let url = this.baseUrl + 'logout_all';
    const response = await this.request.post(url);

    return response;
  }
}

export default AuthenticationServices;
