import * as _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { RepositoryFactory } from '../../../../services/repositories';
import { IPointRepository } from '../../../../services/repositories/ipoint.repository';
import ipointFormat from "../../../../utils/commons/math/ipointFormat";

const iPointRepository: IPointRepository = RepositoryFactory.get(
  'ipoint',
) as IPointRepository;

const ORDER_INFO_DEFAULT = 'Thanh toán dịch vụ';

const BANK_LIST = [
  // { code: null, name: '---------- Chọn ngân hàng ----------' },
  { code: 'ABBANK', name: 'Ngân hàng thương mại cổ phần An Bình (ABBANK)' },
  { code: 'ACB', name: 'Ngân hàng ACB (ACB)' },
  { code: 'AGRIBANK', name: 'Ngân hàng Nông nghiệp (Agribank)' },
  { code: 'BACABANK', name: 'Ngân Hàng TMCP Bắc Á (BAB)' },
  { code: 'BIDV', name: 'Ngân hàng đầu tư và phát triển Việt Nam (BIDV)' },
  { code: 'DONGABANK', name: 'Ngân hàng Đông Á (DongABank)' },
  { code: 'EXIMBANK', name: 'Ngân hàng EximBank (Eximbank)' },
  { code: 'HDBANK', name: 'Ngan hàng HDBank (HDBank)' },
  { code: 'IVB', name: 'Ngân hàng TNHH Indovina (IVB) (INDOVINABANK)' },
  { code: 'MBBANK', name: 'Ngân hàng thương mại cổ phần Quân đội (MBBank)' },
  { code: 'MSBANK', name: 'Ngân hàng Hàng Hải (MSBANK)' },
  { code: 'NAMABANK', name: 'Ngân hàng Nam Á (NamABank)' },
  { code: 'NCB', name: 'Ngân hàng Quốc dân (NCB)' },
  { code: 'OCB', name: 'Ngân hàng Phương Đông (OCB)' },
  { code: 'OJB', name: 'Ngân hàng Đại Dương (OceanBank)' },
  { code: 'PVCOMBANK', name: 'Ngân hàng TMCP Đại Chúng Việt Nam (PVcomBank)' },
  { code: 'SACOMBANK', name: 'Ngân hàng TMCP Sài Gòn Thương Tín (SacomBank)' },
  { code: 'SAIGONBANK', name: 'Ngân hàng thương mại cổ phần Sài Gòn Công Thương (Saigonbank)' },
  { code: 'SCB', name: 'Ngân hàng TMCP Sài Gòn (SCB)' },
  { code: 'SHB', name: 'Ngân hàng Thương mại cổ phần Sài Gòn - Hà Nội(SHB)' },
  { code: 'TECHCOMBANK', name: 'Ngân hàng Kỹ thương Việt Nam (TechcomBank)' },
  { code: 'TPBANK', name: 'Ngân hàng Tiên Phong (TPBank)' },
  { code: 'VPBANK', name: 'Ngân hàng Việt Nam Thịnh vượng (VPBank)' },
  { code: 'SEABANK', name: 'Ngân Hàng TMCP Đông Nam Á (SEABANK)' },
  { code: 'VIB', name: 'Ngân hàng Thương mại cổ phần Quốc tế Việt Nam (VIB)' },
  { code: 'VIETABANK', name: 'Ngân hàng TMCP Việt Á (VietABank)' },
  { code: 'VIETBANK', name: 'Ngân hàng thương mại cổ phần Việt Nam Thương Tín (VietBank)' },
  { code: 'VIETCAPITALBANK', name: 'Ngân Hàng Bản Việt (VietCapitalBank)' },
  { code: 'VIETCOMBANK', name: 'Ngân hàng Ngoại thương (Vietcombank)' },
  { code: 'VIETINBANK', name: 'Ngân hàng Công thương (Vietinbank)' },
  { code: 'BIDC', name: 'Ngân Hàng BIDC (BIDC)' },
  { code: 'LAOVIETBANK', name: 'NGÂN HÀNG LIÊN DOANH LÀO - VIỆT (LAOVIETBANK)' },
  { code: 'WOORIBANK', name: 'Ngân hàng TNHH MTV Woori Việt Nam (Wooribank)' },
  { code: 'AMEX', name: 'American Express (AMEX)' },
  { code: 'VISA', name: 'Thẻ quốc tế Visa (VISA)' },
  { code: 'MASTERCARD', name: 'Thẻ quốc tế MasterCard (MASTERCARD)' },
  { code: 'JCB', name: 'Thẻ quốc tế JCB (JCB)' },
  { code: 'UPI', name: 'UnionPay International (UPI)' },
  { code: 'VNMART', name: 'Ví điện tử VnMart (VNMART)' },
  { code: 'VNPAYQR', name: 'Cổng thanh toán VNPAYQR (VNPAYQR)' },
  { code: '1PAY', name: 'Ví điện tử 1Pay (1PAY)' },
  { code: 'FOXPAY', name: 'Ví điện tử FOXPAY (FOXPAY)' },
  { code: 'VIMASS', name: 'Ví điện tử Vimass (VIMASS)' },
  { code: 'VINID', name: 'Ví điện tử VINID (VINID)' },
  { code: 'VIVIET', name: 'Ví điện tử Ví Việt (VIVIET)' },
  { code: 'VNPTPAY', name: 'Ví điện tử VNPTPAY (VNPTPAY)' },
  { code: 'YOLO', name: 'Ví điện tử YOLO (YOLO)' },
];

export const Payment = ({ paymentList, packageList, orderInfo = ORDER_INFO_DEFAULT, object, object_id }) => {

  const { employee } = useSelector((state: RootState) => state.employee);
  const { corporate } = useSelector((state: RootState) => state.corporate);
  const [needReceipt, setNeedReceipt] = useState(false);
  const [termsPayment, setTermsPayment] = useState(false);
  const [bankCode, setBankCode] = useState(BANK_LIST[0].code);
  const [payment_method, setPaymentMethod] = useState(1);
  const [corporateState, setCorporateState] = useState(corporate);
  const total = _.reduce(paymentList, (_total, { amount }, index) => index ? _total + amount : _total, 0);
  const totalIPoint = _.reduce(paymentList, (_total, { point }, index) => index ? _total + point : _total, 0);
  const totalWithVAT = (total * 1.1).toFixed(0);

  const orderedKeys = _.keys(_.first(paymentList));

  const createOrder = async () => {
    if (!termsPayment) {
      alert("Hãy đồng ý với Điều khoản thanh toán của chúng tôi!")
      return;
    }
    if (!bankCode) {
      alert("Vui lòng chọn ngân hàng thanh toán");
      return;
    }
    if (needReceipt) {
      if (!/[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}/gim.test(corporateState.corporate_email)) {
        alert("Email không hợp lệ");
        return;
      }
    }
    const data = {
      orderInfo,
      bankCode,
      amount: total,
      corporate_code: employee['corporate_code'],
      payment_method: payment_method,
      object: object,
      object_id: object_id,
      packageList,
      needReceipt: needReceipt ? 1 : 0,
      corporate_name: corporateState?.corporate_name,
      corporate_so_dkkd: corporateState?.so_dkkd,
      corporate_address: corporateState?.address,
      corporate_email: corporateState?.corporate_email,
    };
    const { vnp_url } = await iPointRepository.getPaymentUrl(data);
    if (!vnp_url) {
      return;
    }
    window.location.href = vnp_url;
  }

  const getPaymentItemClassName = (list, index): string => {
    let className = 'flex pt-4 pb-4';
    const isFirst = index === 0;
    const isLast = index === list.length - 1;
    if (isFirst) {
      className += ' font-bold';
    }
    if (isFirst || isLast) {
      className += ' border-b border-black';
    }
    return className;
  }

  const getPaymentFieldClassName = (list, index): string => {
    let className = 'flex-1';
    const isFirst = index === 0;
    const isLast = index === list.length - 1;
    if (isFirst) {
    }
    if (isLast) {
      className += ' text-right';
    }
    if (!isFirst && !isLast) {
      className += ' text-center';
    }
    return className;
  }

  const handleChangeCorporate = (e) => {
    const { name, value } = e.target;
    setCorporateState({
      ...corporateState,
      [name]: value,
    });
  }

  const renderItemEmpty = () => {
    let html = [];
    orderedKeys.forEach((e, index) => {
      if (index <= orderedKeys.length-4) {
        html.push(<span key={index} className="flex-1"/>);
      }
    })
    return html;
  }
  return (
    <section className=''>
      {
        paymentList.map((payment, index) => {
          return (
            <div key={index} className={getPaymentItemClassName(paymentList, index)}>
              {
                orderedKeys.map((key, i) => (
                  <span key={i} className={getPaymentFieldClassName(orderedKeys, i)}>
                    {typeof payment[key] === "number" ? ipointFormat(Math.round(payment[key])) : (payment[key] !== null ? payment[key] : "i-Point lẻ" )}
                  </span>
                ))
              }
            </div>
          )
        })
      }
      <div className='flex pt-4 font-bold'>
        {renderItemEmpty()}
        <span className='flex-1 text-left' >Tổng</span>
        {/*style={{ flex: orderedKeys.length - 2 }}*/}
        <span className='flex-1 text-center'>{ipointFormat(Math.round(totalIPoint))}</span>
        <span className='flex-1 text-right'>{ipointFormat(total)} đ</span>
      </div>
      <div className='flex pt-4 font-bold'>
        {renderItemEmpty()}
        <span className='text-left' style={{ flex: 1 }}>VAT</span>
        <span className="flex-1"/>
        <span className='flex-1 text-right'>10%</span>
      </div>
      <div className='flex pt-4 font-bold'>
        {renderItemEmpty()}
        <span className='text-left' style={{ flex: 1 }}>Thành tiền</span>
        <span className="flex-1"/>
        <span className='flex-1 text-right'>{ipointFormat(totalWithVAT)} đ</span>
      </div>

      <div className='mt-6'>
        <div className='cursor-pointer' onClick={() => setNeedReceipt(!needReceipt)} >
          <input type='checkbox' checked={needReceipt} />
          <span className='font-bold ml-2'>Tôi cần xuất hoá đơn qua email</span>
        </div>
        {
          !!needReceipt &&
          <div>
            <p className='mt-4 text-red-500'>Lưu ý: Thông tin cần chính xác tuyệt đối để chúng tôi xuất hoá đơn VAT cho bạn</p>
            <div className='flex items-center mt-4'>
              <p style={{ flexBasis: 150 }}>Tên công ty</p>
              <input type='text' onChange={handleChangeCorporate} name={"corporate_name"} value={corporateState?.corporate_name} style={{ borderRadius: '5px', border: '1px solid #cccccc' }} className='flex-1' />
            </div>
            <div className='flex items-center mt-4'>
              <p style={{ flexBasis: 150 }}>Mã số thuế</p>
              <input type='text' onChange={handleChangeCorporate} name={"so_dkkd"} value={corporateState?.so_dkkd} style={{ borderRadius: '5px', border: '1px solid #cccccc' }} className='flex-1' />
            </div>
            <div className='flex items-center mt-4'>
              <p style={{ flexBasis: 150 }}>Địa chỉ công ty</p>
              <input type='text' onChange={handleChangeCorporate} name={"address"} value={corporateState?.address} style={{ flex: 4, borderRadius: '5px', border: '1px solid #cccccc' }} />
            </div>
            <div className='flex items-center mt-4'>
              <p style={{ flexBasis: 150 }}>Email nhận</p>
              <input type='email' onChange={handleChangeCorporate} name={"corporate_email"} value={corporateState?.corporate_email} style={{ borderRadius: '5px', border: '1px solid #cccccc' }} className='flex-1'/>
            </div>
          </div>
        }
      </div>

      <div className='mt-12'>
        <p className='font-bold text-lg border-b border-gray-200 pb-2'>Phương thức thanh toán</p>
        <div className='flex items-center mt-4 mb-4'>
          <div style={{ flexBasis: 250 }}>
            <input
              type='radio'
              name='paymentMethod'
              id='paymentVnpay'
              value={1}
              checked={payment_method === 1}
              onClick={() => setPaymentMethod(1)}
            />
            <label htmlFor='paymentVnpay' className="pl-3">
              Thẻ ngân hàng nội địa
            </label>
          </div>
          <div className='flex-1' style={{opacity: `${payment_method === 2 ? "0.5" : 1}`}}>
            <select
              onChange={(e) => setBankCode(e.target.value)}
              style={{ border: '1px solid #CED4DA', width: '100%' }}
              className="appearance-none  block py-3 px-3 text-black rounded leading-tight focus:outline-none focus:shadow-outline"
              disabled={payment_method === 2}
            >
              {
                BANK_LIST.map(({ code, name }, index) => (
                  <option
                    value={code}
                    selected={bankCode === code}
                    key={index}
                  >
                    {name}
                  </option>
                ))
              }
            </select>
          </div>
        </div>
        <div className='flex mt-2'>
          <div style={{ flexBasis: 250 }} >
            <input
              type='radio'
              name='paymentMethod'
              id='paymentManual'
              value={2}
              checked={payment_method === 2}
              onClick={() => setPaymentMethod(2)}
            />
            <label htmlFor='paymentManual' className="pl-3">
              Chuyển khoản
            </label>
          </div>
        </div>
      </div>
      <div onClick={() => setTermsPayment(!termsPayment)}  style={{ margin: '20px auto' }} className="flex items-center mt-4 lg:my-10 lg:ml-4-5 w-max">
        <input checked={termsPayment} type="checkbox"/>
        <label className={"ml-2 cursor-pointer"}>Bằng việc tiếp tục thanh toán, bạn đã đồng ý với
          <a href={'/chinh_sach_thanh_toan.html'} target="_blank" style={{color: "#B50829"}} rel="noreferrer"> Điều khoản thanh toán </a>
          của chúng tôi
        </label>
      </div>
      <div onClick={createOrder}  style={{ margin: '20px auto' }} className="flex mt-4 lg:my-10 lg:ml-4-5 w-max rounded-sm bg-[#B50829] text-white custom-btn cursor-pointer">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 18.5C5.9 18.5 5.01 19.4 5.01 20.5C5.01 21.6 5.9 22.5 7 22.5C8.1 22.5 9 21.6 9 20.5C9 19.4 8.1 18.5 7 18.5ZM1 3.5C1 4.05 1.45 4.5 2 4.5H3L6.6 12.09L5.25 14.53C4.52 15.87 5.48 17.5 7 17.5H18C18.55 17.5 19 17.05 19 16.5C19 15.95 18.55 15.5 18 15.5H7L8.1 13.5H15.55C16.3 13.5 16.96 13.09 17.3 12.47L20.88 5.98C21.25 5.32 20.77 4.5 20.01 4.5H5.21L4.54 3.07C4.38 2.72 4.02 2.5 3.64 2.5H2C1.45 2.5 1 2.95 1 3.5ZM17 18.5C15.9 18.5 15.01 19.4 15.01 20.5C15.01 21.6 15.9 22.5 17 22.5C18.1 22.5 19 21.6 19 20.5C19 19.4 18.1 18.5 17 18.5Z" fill="white" />
        </svg>
        <span style={{ marginTop: '4px' }} className="ml-5 pr-2 uppercase font-medium">Thực hiện thanh toán</span>
      </div>
    </section >
  )
}