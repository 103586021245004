import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { CorporateRepository } from 'services/repositories/corporate.repository';
import { AlertType, showNotification } from 'utils/commons/Alert';

//Initial State
const corporateRepository: CorporateRepository = RepositoryFactory.get(
  'corporate',
) as CorporateRepository;

const fetchCorporate = async (data) => {
  
  const response = await corporateRepository.updateCorporate(data);

  return response;
};

function* updateCorporate(action) {
  try {
    
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const corporate = yield call(fetchCorporate, action.payload);
    yield put({ type: types.UPDATE_CORPORATE_SUCCESS, payload: corporate });
    showNotification({
      type: AlertType.SUCCESS,
      msg: 'Cập nhật thành công',
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });

  } catch (err) {
    yield put({ type: types.UPDATE_CORPORATE_ERROR, payload: err.response.data });
    showNotification({
      type: AlertType.ERROR,
      msg: 'Cập nhật thất bại! Vui lòng kiểm tra lại thông tin',
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchUpdateCorporate() {
  yield takeLatest(types.UPDATE_CORPORATE_REQUEST, updateCorporate);
}
