import * as types from '../type';
import { IDistricts } from '../../utils/interfaces';

interface IInitialState {
  districts: IDistricts[];
  district: IDistricts | {};
  status: any;
}

const initialState: IInitialState = {
  districts: [],
  district: {},
  status: null,
};

const districtReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.DISTRICT_SUCCESS:
      return {
        ...state,
        districts: action.payload,
      };
    case types.DISTRICT_ERROR:
      return {
        ...state,
        district: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default districtReducer;
