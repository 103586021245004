import * as types from '../type';
import { ICommune } from '../../utils/interfaces';

interface IInitialState {
  communes: ICommune[];
  commune: ICommune | {};
  status: any;
}

const initialState: IInitialState = {
  communes: [],
  commune: {},
  status: null,
};

const communeReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.COMMUNE_SUCCESS:
      return { ...state, communes: action.payload };
    case types.COMMUNE_ERROR:
      return { ...state, commune: action.payload };
    default:
      return {
        ...state,
      };
  }
};

export default communeReducer;
