export const APP_CONFIG = {
  BACKEND_DOMAIN: window._env_.API_URL || 'http://localhost:3001',
  BACKEND_API_END_POINT: 'api',
  BACKEND_API_VERSION: 'v1',
};

export const URL_IMG_PACKAGE = [
  {id: 0, url: 'https://assets.i-hr.vn/static/d/corporate/images/20210923/cor0040259-d0e177b0-1c3e-11ec-91e4-2d07da3bfb0b.jpg'},
  {id: 1, url: 'https://assets.i-hr.vn/static/d/corporate/images/20210923/cor0040259-d0e177b0-1c3e-11ec-91e4-2d07da3bfb0b.jpg'},
  {id: 2, url: 'https://assets.i-hr.vn/static/d/corporate/images/20210923/cor0040259-cee336b0-1c3e-11ec-91e4-2d07da3bfb0b.jpg'},
  {id: 3, url: 'https://assets.i-hr.vn/static/d/corporate/images/20210923/cor0040259-d22c9550-1c3e-11ec-91e4-2d07da3bfb0b.jpg'},
  {id: 4, url: 'https://assets.i-hr.vn/static/d/corporate/images/20210923/cor0040259-d84f0490-1c3e-11ec-91e4-2d07da3bfb0b.jpg'},
  {id: 5, url: 'https://assets.i-hr.vn/static/d/corporate/images/20210923/cor0040259-d9e0c820-1c3e-11ec-91e4-2d07da3bfb0b.jpg'},
];

export const TRANSACTION_TYPES = [
  {id: 0, value: 0, title: 'Tất cả'},
  {id: 1, value: 1, title: 'Thu'},
  {id: 2, value: 2, title: 'Chi'},
];

export let TITLE = [
  {id: 1, title: `Để xem thông tin của ứng viên này bạn cần tiêu 5 iPoint Bạn có muốn mở thông tin ứng viên không?`},
  {id: 2, title: 'Tài khoản không đủ i-Point, bạn có muốn nạp thêm i-Point?'},
];
