import React from 'react';
import { LF_Breadcumbs } from '../../../../components/molecules';
import { CLC_IPointOrder } from '../../../../components/organisms';
export const IPointOrder = () => {
  return(
    <React.Fragment>
      <LF_Breadcumbs
        route={`/admin/ipoint/history`}
        routeName={'Lịch sử mua iPoint'}
        className="font-bold text-lg"
      />
      <CLC_IPointOrder />
    </React.Fragment>
  )
}