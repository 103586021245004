import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { HomeRepository } from 'services/repositories/home.repository';

//Repository
const homeRepository = RepositoryFactory.get('home') as HomeRepository;

const fetchHome = async () => {
  const response = await homeRepository.get();

  return response;
};

function* handlerGetHome(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const home = yield call(fetchHome);
    yield put({
      type: types.HOME_SUCCESS,
      payload: home,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.HOME_ERROR,
      payload: err.response,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetHome() {
  yield takeLatest(types.HOME_REQUEST, handlerGetHome);
}
