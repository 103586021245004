import * as types from '../type';
import { IProvince } from '../../utils/interfaces/province.interface';

interface IInitialState {
  provinces: IProvince[];
  province: IProvince | {};
  status: any;
}

const initialState: IInitialState = {
  provinces: [],
  province: {},
  status: null,
};

const provinceReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.PROVINCE_SUCESS:
      return {
        ...state,
        provinces: action.payload,
      };
    case types.REGISTER_ERROR:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return { ...state };
  }
};

export default provinceReducer;
