import { combineReducers } from 'redux';

//Reducer
import loadingReducer from './loading.reducer';
import registerReducer from './register.reducer';
import careerGrReducer from './career.reducer';
import provinceReducer from './province.reducer';
import districtReducer from './district.reducer';
import communeReducer from './commune.reducer';
import skillReducer from './skill.reducer';
import positionReducer from './position.reducer';
import jobsReducer from './jobs.reducers';
import corporateReducer from './corporate.reducers';
import employeeReducer from './employee.reducer';
import coursesReducers from './courses.reducer';
import homeReducer from './home.reducer';
import careerReducer from './searchcareer.reducer';
import majorReducer from './major.reducer';
import candidateReducer from './candidate.reducer';
import addressReducer from './address.reducer';
import uploadReducer from './upload.reducer';
import careerCodeReducer from './careerCode.reducer';
import traineeReducer from './trainee.reducer';
import exportExcelReducer from './exportExcel.reducer';
import packagesReducer from './packages.reducer';
import packageTimeReducer from './packageTime.reducer';
import packageVip from './packageVip.reducer';
import packageBuy from './packageBuy.reducer';
import ipointReducer from './ipoint.reducer';

const rootReducers = combineReducers({
  loading: loadingReducer,
  register: registerReducer,
  careers: careerGrReducer,
  provinces: provinceReducer,
  districts: districtReducer,
  communes: communeReducer,
  skills: skillReducer,
  positions: positionReducer,
  jobs: jobsReducer,
  employee: employeeReducer,
  corporate: corporateReducer,
  courses: coursesReducers,
  home: homeReducer,
  searchcareers: careerReducer,
  major: majorReducer,
  candidate: candidateReducer,
  address: addressReducer,
  upload: uploadReducer,
  careerCode: careerCodeReducer,
  trainee: traineeReducer,
  exportExcel: exportExcelReducer,
  packages: packagesReducer,
  packageTime: packageTimeReducer,
  packageVip: packageVip,
  packageBuy: packageBuy,
  ipoint: ipointReducer,
});

export default rootReducers;
