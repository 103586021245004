import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export function RadioButtonsGroup({ action }) {
  const [value, setValue] = React.useState('0');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  React.useEffect(() => {
    action(value);
  }, [value]);

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="p_online"
        name="p_online"
        value={value}
        onChange={handleChange}
        // style={{ display: 'block' }}
        className={'flex flex-col lg:flex-row '}
      >
        <FormControlLabel value="0" control={<Radio />} label="Tất cả" />
        <FormControlLabel value="1" control={<Radio />} label="Online" />
        <FormControlLabel value="2" control={<Radio />} label="Offline" />
      </RadioGroup>
    </FormControl>
  );
}
