import * as types from '../type';

export const exportExcelJob = (query?) => ({
  type: types.EXPORT_EXCEL_JOB_REQUEST,
  payload: query,
});

export const exportExcelCourse = (query?) => ({
  type: types.EXPORT_EXCEL_COURSE_REQUEST,
  payload: query,
});

export const exportExcelCandidate = (query?) => ({
  type: types.EXPORT_EXCEL_CANDIDATE_REQUEST,
  payload: query,
});

export const exportExcelStudent = (query?) => ({
  type: types.EXPORT_EXCEL_STUDENT_REQUEST,
  payload: query,
});
