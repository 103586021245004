import React, { useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { formatDateHHMS } from '../../../utils/commons';
import ipointFormat from '../../../utils/commons/math/ipointFormat';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalDetailTransaction({ showModal, handleModal, historyItem }) {
  const handleShow = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>, name: string) => {
    handleModal(e, name);
  };
  const closeModal = (event, reason) => {
    if (reason === 'backdropClick') {
      handleModal(event, 'close');
    }
  }
  return (
    <div style={{ borderRadius: '15px' }}>
      <Dialog onClose={(event, reason) => closeModal(event, reason)} aria-labelledby='customized-dialog-title' open={showModal}>
        <DialogTitle id='customized-dialog-title' onClose={null}>
          <p className={'uppercase text-center font-bold'}
             style={{
               borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
               paddingBottom: '15px',
             }}
          >
            Chi tiết giao dịch</p>
        </DialogTitle>
        <DialogContent style={{ border: 'none', height: '350px' }} dividers>
          <div className={'grid grid-cols-2'}>
            <span className='font-bold'>Thời gian</span>
            <span>{formatDateHHMS(historyItem?.created_at)}</span>
          </div>
          {
            historyItem?.transaction_type ? <div className={'grid grid-cols-2 mt-4'}>
              <span className='font-bold'>Loại giao dịch</span>
              <span>{historyItem?.transaction_type}</span>
            </div> : ""
          }
          {
            historyItem?.ipoint ? <div className={'grid grid-cols-2 mt-4'}>
              <span className='font-bold'>Số lượng</span>
              <span>{ipointFormat(historyItem?.ipoint)}</span>
            </div> : ""
          }
          {
            historyItem?.quantity ? <div className={'grid grid-cols-2 mt-4'}>
              <span className='font-bold'>Số lượng</span>
              <span>{ipointFormat(historyItem?.quantity)}</span>
            </div> : ""
          }


          <div className={'grid grid-cols-2 mt-4'}>
            <span className='font-bold'>Dịch vụ</span>
            <span>{historyItem?.service}</span>
          </div>
          <div className={'grid grid-cols-2 mt-4'}>
            <span className='font-bold'>Nội dung</span>
            <span>{historyItem?.description}</span>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            style={{ padding: '13px 50px 10px 50px', backgroundColor: '#B50829', borderRadius: '5px', color: 'white' }}
            onClick={(e) => handleShow(e, 'close')}>
            ĐÓNG
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}