import * as types from '../type';

export const getCorporate = () => ({
  type: types.CORPORATE_REQUEST,
});
export const updateCorporate = (data) => ({

  type: types.UPDATE_CORPORATE_REQUEST,
  payload:data,

});