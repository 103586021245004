import * as types from '../../type';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { IPointPackageRepository } from '../../../services/repositories/ipointPackage.repository';


//Repository
const ipointRepository: IPointPackageRepository = RepositoryFactory.get(
  'package',
) as IPointPackageRepository;


const fetchPackageBuy = async () => {
  const response = await ipointRepository.getPackageBuy();
  return response;
};

function* handlerPackageBuy(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const packageBuy = yield call(fetchPackageBuy);
    yield put({ type: types.GET_PACKAGE_BUY_SUCCESS, payload: packageBuy });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.GET_PACKAGE_BUY_ERROR,
      payload: err.response,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetPackageBuy() {
  yield takeLatest(types.GET_PACKAGE_BUY_REQUEST, handlerPackageBuy);
}