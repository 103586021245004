/* eslint-disable react/jsx-pascal-case */
import { LF_Breadcumbs, LF_ConfirmModal } from 'components/molecules';
import { CourseListTable, LF_DataTable } from 'components/organisms';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCourses, exportExcelCourse, getCourses } from 'redux/actions';
import { RootState } from 'redux/store';
import { useHistory } from 'react-router-dom';

export const LF_CourseList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteItem, setDeleteItem] = React.useState(undefined);
  const [search, setSearch] = React.useState({
    start_date: '1970-01-01',
    end_date: '2100-01-01',
    status: 0,
    skip: 0,
    limit: 20,
    search_string: '',
  });

  const handleSearch = (name, value) => {
    if (typeof name === 'object') {
      setSearch((prevState) => {
        return { ...prevState, ...name };
      });
    } else {
      setSearch({
        ...search,
        [name]: value,
      });
    }
  };

  React.useEffect(() => {
    dispatch(getCourses(search));
  }, [search]);

  const { courses, course, status } = useSelector(
    (state: RootState) => state.courses,
  );

  React.useEffect(() => {
    dispatch(getCourses());
  }, [status]);

  const getPageTotal = (statusEmp) => {
    switch (statusEmp) {
      case 1:
        return courses?.total_course?.cho_duyet;
      case 2:
        return courses?.total_course?.dang_ht;
      case 3:
        return courses?.total_course?.tam_dung;
      case 4:
        return courses?.total_course?.het_han;
      case 5:
        return courses?.total_course?.da_xoa;
      default:
        return courses?.total_course?.total;
    }
  };

  const dataExcelCourse = useSelector((state) => state['exportExcel'].course);

  React.useEffect(() => {
    dispatch(
      exportExcelCourse({
        ...search,
        limit: getPageTotal(search.status),
        skip: 0,
      }),
    );
  }, [courses]);

  const handleDelete = (id: string) => {
    dispatch(deleteCourses(id));
  };

  const handlerDeleteFunc = (value: string) => {
    const state = value;
    setDeleteItem(state);
  };

  const handleClick = () => {
    history.push('/admin/course/post');
  };

  const handleFilter = (data: [], name: string) => { };

  return (
    <React.Fragment>
      <LF_ConfirmModal
        open={deleteItem !== undefined}
        title="Bạn xác nhận xóa mục này?"
        onProceed={() => {
          if (deleteItem) {
            handleDelete(deleteItem);
            setDeleteItem(undefined);
          }
        }}
        onCancel={() => setDeleteItem(undefined)}
      />
      <LF_Breadcumbs
        route={`/admin/course/courseList`}
        routeName={'Danh sách khóa học'}
        className="font-bold text-lg"
      />
      <CourseListTable onClick={handleClick} fromSearch={handleSearch} />
      <div
        className="container p-[10px] xxl:pl-[35px] pr-4 xxl:pr-0"
      >
        <div className="flex flex-row items-center w-full bg-white pt-6">
          <LF_DataTable
            rows={courses && courses.data}
            type="CourseMember"
            per_page={courses && courses.total_course}
            fromSearch={handleSearch}
            onDelete={handlerDeleteFunc}
            onFilter={handleFilter}
            dataExportExcel={dataExcelCourse}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
