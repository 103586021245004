import * as types from '../type';

export const getCandidate = (query?: any) => ({
  type: types.GET_CANDIDATE_REQUEST,
  payload: query,
});
export const getMatchCandidateSearch = (query?: any) => ({
  type: types.GET_MATCHCANDIDATESEARCH_REQUEST,
  payload: query,
});
export const viewProfile = (user_id, data) => ({
  type: types.POST_USER_VIEW_REQUEST,
  payload: {
    user_id: user_id,
    data: data,
  },
});
export const resetStoreCandidate = () => ({
  type: types.RESET_STORE_CANDIDATE,
});
