/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

//Dependencies
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Radio,
  RadioGroup,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

//Actions
import {
  searchSkill,
  searchAddress,
  getMatchCandidateSearch,
  searchCareer,
} from '../../../../redux/actions';

//Types
import { RootState } from '../../../../redux/store';

import { MultipleSelect } from '../../../molecules';

export const LF_FindEmployee = ({ onSearch, fromSearch }) => {
  const classes = useStyles();
  //React - Redux
  const dispatch = useDispatch();

  //Address
  const address = useSelector((state: RootState) => state.address);

  //Career
  const careers = useSelector((state: RootState) => state.searchcareers);

  //Skill
  const skills = useSelector((state: RootState) => state.skills);

  const [listCareerChoose, setCareerChoose] = React.useState([]);
  const [listSkillChoose, setSkillChoose] = React.useState([]);
  const [listDegreeChoose, setDegreeChoose] = React.useState([]);
  const [listAddressChoose, setAddressChoose] = React.useState([]);
  const [careerQ, setCareer] = React.useState(null);
  const [skillQ, setSkill] = React.useState(null);
  const [degreeQ, setDegree] = React.useState(null);
  const [addressQ, setAddress] = React.useState(null);

  const [state, setState] = React.useState({
    p_careers_code: '',
    p_skills: '',
    p_provinces: '',
    p_degrees: '',
    p_gender: null,
    p_discharged_army: 0,
    p_min_salary: null,
    p_max_salary: null,
    p_from_age: null,
    p_to_age: null,
    p_heightFrom: null,
    p_heightTo: null,
    p_weightFrom: null,
    p_weightTo: null,
    p_eyes: 0,
    p_chung_nhan: '',
  });

  //Effect
  React.useEffect(() => {
    if (careerQ && careerQ !== '') {
      dispatch(searchCareer(careerQ));
    }
  }, [careerQ]);
  React.useEffect(() => {
    if (skillQ && skillQ !== '') {
      dispatch(searchSkill(skillQ));
    }
  }, [skillQ]);
  React.useEffect(() => {
    if (addressQ && addressQ !== '') {
      dispatch(searchAddress(addressQ));
    }
  }, [addressQ]);
  // React.useEffect(() => {
  //   dispatch(getProvince());
  // }, []);

  // Action Handler
  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: parseInt(value) });
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleChangeValueInput = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked == true ? 1 : 0,
    });
  };

  const onSubmit = (even) => {
    even.preventDefault();
    const career_gr = listCareerChoose?.map((e) => e.career_code);
    const skill = listSkillChoose?.map((e) => e.skill_code);
    const address = listAddressChoose?.map((e) => e.province_code);
    const degree = listDegreeChoose?.map((e) => e.id);

    fromSearch({
      ...state,
      p_careers_code: career_gr.join(','),
      p_skills: skill.join(','),
      p_provinces: address.join(','),
      p_degrees: degree.join(','),
    });
  };

  /**
   * Choose adding Careers
   * @param data
   * @return NewArray & SetState
   */
  const onChooseCareer = (data) => {
    let listCurrent = [...listCareerChoose];
    const check = listCurrent?.filter(
      (e) => e.career_code === data.career_code,
    );

    if (check && check.length === 0) {
      setCareerChoose([...listCareerChoose, data]);
    }
  };

  /**
   * Delete Choose
   * @param career_group_code
   * @return NewArray & SetState
   */
  const onDeleteChoose = (career_code: string) => {
    let listCurrent = [...listCareerChoose];
    const check = listCurrent?.filter((e) => e.career_code !== career_code);
    setCareerChoose(check);
  };

  /**
   * Choose adding skill
   * @param data
   * @return NewArray & SetState
   */
  const onChooseSkill = (data) => {
    let listCurrent = [...listSkillChoose];
    const check = listCurrent?.filter((e) => e.skill_code === data.skill_code);

    if (check && check.length === 0) {
      setSkillChoose([...listSkillChoose, data]);
    }
  };

  /**
   * Delete Choose
   * @param skill_code
   * @return NewArray & SetState
   */
  const onDeleteChooseSkill = (skill_code: string) => {
    let listCurrent = [...listSkillChoose];
    const check = listCurrent?.filter((e) => e.skill_code !== skill_code);
    setSkillChoose(check);
  };

  /**
   * Choose adding degree
   * @param data
   * @return NewArray & SetState
   */
  const onChooseDegree = (data) => {
    let listCurrent = [...listDegreeChoose];

    const check = listCurrent?.filter((e) => e.id === data.id);

    if (check && check.length === 0) {
      setDegreeChoose([...listDegreeChoose, data]);
    }
  };

  /**
   * Delete Choose
   * @param id
   * @return NewArray & SetState
   */
  const onDeleteChooseDegree = (id: string) => {
    let listCurrent = [...listDegreeChoose];
    const check = listCurrent?.filter((e) => e.id !== id);
    setDegreeChoose(check);
  };

  /**
   * Choose adding Address
   * @param data
   * @return NewArray & SetState
   */
  const onChooseAddress = (data) => {
    let listCurrent = [...listAddressChoose];
    const check = listCurrent?.filter(
      (e) => e.province_code === data.province_code,
    );

    if (check && check.length === 0) {
      setAddressChoose([...listAddressChoose, data]);
    }
  };
  /**
   * Delete Choose Address
   * @param id
   * @return NewArray & SetState
   */
  const onDeleteChooseAddress = (id: string) => {
    let listCurrent = [...listAddressChoose];
    const check = listCurrent?.filter((e) => e.province_code !== id);
    setAddressChoose(check);
  };

  return (
    <div className="container">
      {/* body */}
      <Grid container direction="row" justify="flex-start">
        <Grid sm={12}>
          <div
            className="uppercase text-xl font-bold"
            style={{
              padding: '16px 0px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
            }}
          >
            Lọc theo chuyên môn
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ paddingTop: '30px' }}
        // className={'justify-start'}
      >
        <Grid style={{width: '100%'}} sm={6}>
          <div className="mr-4 mb-4">
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="Quy mô công ty"
            >
              Ngành nghề
            </label>
            <MultipleSelect
              searchCareer={careers?.searchCareers}
              listChooseCareer={listCareerChoose}
              query={careerQ}
              onAddChooseCareer={onChooseCareer}
              onDeleteChooseCareer={onDeleteChoose}
              setQuery={setCareer}
            />
          </div>
          <div className="mr-4 mb-4">
            <label
              className="block text-black font-bold mb-2"
              htmlFor="Quy mô công ty"
            >
              Kỹ năng chuyên môn
            </label>
            <MultipleSelect
              skills={skills?.skills}
              listChooseSkill={listSkillChoose}
              query={skillQ}
              onAddChooseSkill={onChooseSkill}
              onDeleteChooseSkill={onDeleteChooseSkill}
              setQuery={setSkill}
            />
          </div>

          <div className="mr-2 mb-4 block">
            <label className=" text-black font-bold mt-2 mb-2 pr-2">
              Mức lương
            </label>
            <div className={'flex flex-col xl:flex-row item-center  mt-4'}>
              <div className="mr-14 mt-4 xl:mt-0 h-10 rounded overflow-hidden flex items-center">
                <label className="h-9 inline-block w-16 p-y6-x12 bg-gray-200 border border-gray-200 border-solid">
                  Từ
                </label>
                <input
                  name="p_min_salary"
                  type="number"
                  onChange={handleChange}
                  className="w-16 h-9 py-6px px-1 text-center border-t border-b border-gray-200 focus:outline-none"
                />
                <label className="h-9 inline-block whitespace-nowrap p-y6-x12 bg-gray-200 border border-gray-200 border-solid">
                  triệu VNĐ
                </label>
              </div>
              <div className="rounded mt-4 xl:mt-0 h-9 overflow-hidden flex items-center">
                <label className="h-9 inline-block w-16 p-y6-x12 bg-gray-200 border border-gray-200 border-solid">
                  Đến
                </label>
                <input
                  name="p_max_salary"
                  type="number"
                  onChange={handleChange}
                  className="w-16 h-9 py-6px px-1 text-center border-t border-b border-gray-200 focus:outline-none"
                />
                <label className="h-9 inline-block whitespace-nowrap p-y6-x12 bg-gray-200 border border-gray-200 border-solid">
                  triệu VNĐ
                </label>
              </div>
            </div>
          </div>
        </Grid>
        <Grid sm={6}>
          <div className="mb-4">
            <label
              className="block text-black font-bold mb-2"
              htmlFor="Quy mô công ty"
            >
              Địa điểm làm việc
            </label>
            <MultipleSelect
              address={address?.addresses}
              listChooseAddress={listAddressChoose}
              query={addressQ}
              onAddChooseAddress={onChooseAddress}
              onDeleteChooseAddress={onDeleteChooseAddress}
              setQuery={setAddress}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-black font-bold mb-2"
              htmlFor="Quy mô công ty"
            >
              Bằng cấp
            </label>
            <MultipleSelect
              degree={degree}
              listChooseDegree={listDegreeChoose}
              query={degreeQ}
              onAddChooseDegree={onChooseDegree}
              onDeleteChooseDegree={onDeleteChooseDegree}
              setQuery={setDegree}
            />
          </div>
          <div className="mr-2 mb-2 flex flex-col lg:flex-row">
            <label
              className="block text-black font-bold mb-2 mt-2 pr-8"
              htmlFor="Quy mô công ty"
            >
              Giới tính
            </label>
            <RadioGroup
              aria-label="p_gender"
              name="p_gender"
              className={'gap-4'}
              onClick={handleChange}
              row
            >
              <FormControlLabel value="0" control={<Radio />} label="Tất cả" />
              <FormControlLabel value="1" control={<Radio />} label="Nam" />
              <FormControlLabel value="2" control={<Radio />} label="Nữ" />
            </RadioGroup>
          </div>
          <div className="mr-2 mb-4 flex">
            <label
              className="block text-black font-bold mb-2 mt-2 pr-2"
              htmlFor="Quy mô công ty"
            >
              Là quân nhân xuất ngũ
            </label>
            <Switch
              value={state.p_discharged_army}
              onChange={handleChangeValueInput}
              name="p_discharged_army"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          <div className="mr-2 mb-4 flex flex-col lg:flex-row">
            <label
              className="block text-black font-bold mb-2 mt-2 pr-2"
              htmlFor="Quy mô công ty"
            >
              Độ tuổi
            </label>
            <span className="block text-black font-bold mb-2 mt-2 pr-2">
              Từ:
            </span>
            <TextField
              onChange={handleChange}
              name="p_from_age"
              className="w-20"
              size="small"
              variant="outlined"
            />
            <span className="block text-black font-bold mb-2 mt-2 px-2">
              Đến:
            </span>
            <TextField
              onChange={handleChange}
              name="p_to_age"
              className="w-20"
              size="small"
              variant="outlined"
            />
          </div>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start">
        <Grid sm={12}>
          <div
            className="uppercase text-xl font-bold"
            style={{
              padding: '16px 0px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
            }}
          >
            Lọc theo sức khỏe
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        // justify="space-evenly"
        alignItems="flex-start"
        style={{ paddingTop: '30px' }}
      >
        <Grid sm={6}>
          <div className="mr-2 mb-4 flex flex-col lg:flex-row justify-start">
            <label
              className="block text-black font-bold mb-2 mt-2 "
              style={{ paddingRight: '1.6rem' }}
              htmlFor="Quy mô công ty"
            >
              Chiều cao (cm)
            </label>
            <span className="block text-black font-bold mb-2 mt-2 pr-2">
              Từ:
            </span>
            <TextField
              onChange={handleChange}
              className="w-20"
              name="p_heightFrom"
              size="small"
              variant="outlined"
            />
            <span className="block text-black font-bold mb-2 mt-2 px-2">
              Đến:
            </span>
            <TextField
              onChange={handleChange}
              className="w-20"
              name="p_heightTo"
              size="small"
              variant="outlined"
            />
          </div>
          <div className="mr-2 mb-4 flex flex-col lg:flex-row justify-start">
            <label
              className="block text-black font-bold mb-2 mt-2 pr-5"
              htmlFor="Quy mô công ty"
            >
              Cân nặng (kg)
            </label>
            <span className="block text-black font-bold mb-2 mt-2 pr-2 pl-3">
              Từ:
            </span>
            <TextField
              onChange={handleChange}
              className="w-20"
              name="p_weightFrom"
              size="small"
              variant="outlined"
            />
            <span className="block text-black font-bold mb-2 mt-2 px-2">
              Đến:
            </span>
            <TextField
              onChange={handleChange}
              className="w-20"
              name="p_weightTo"
              size="small"
              variant="outlined"
            />
          </div>
        </Grid>
        <Grid sm={6}>
          <div className="mr-2 mb-4 flex flex-col lg:flex-row">
            <label
              className="block text-black font-bold mb-2 mt-2 pr-2"
              htmlFor="Quy mô công ty"
            >
              Không có tật về mắt (cận, viễn, loạn thị, mù màu,...)
            </label>
            <Switch
              value={state.p_eyes}
              onChange={handleChangeValueInput}
              name="p_eyes"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          <div className="mr-2 mb-4">
            <label
              className="block text-black font-bold mb-2 mt-2 pr-2"
              htmlFor="Quy mô công ty"
            >
              Chứng nhận sức khỏe
            </label>
            <RadioGroup
              aria-label="p_chung_nhan"
              name="p_chung_nhan"
              className="gap-12"
              onChange={handleOnChange}
              row
            >
              <FormControlLabel
                value="con01"
                control={<Radio />}
                label="Không"
              />
              <FormControlLabel value="con02" control={<Radio />} label="A" />
              <FormControlLabel value="con03" control={<Radio />} label="B1" />
              <FormControlLabel value="con04" control={<Radio />} label="B2" />
            </RadioGroup>
          </div>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start">
        <Grid sm={12}>
          <Button
            variant="outlined"
            className={`${classes.btnRed} focus:outline-none	`}
            onClick={(event) => {
              onSubmit(event);
            }}
            style={{
              width: '20%',
              height: '56px',
              color: '#ffffff',
              alignItems: 'center',
              marginTop: '10px',
              minWidth: '200px'
            }}
          >
            <span className="normal-case whitespace-nowrap text-base">Tìm Kiếm Ứng Viên</span>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles({
  margin: {},
  textField: {
    width: '25ch',
  },
  btnRed: {
    backgroundColor: '#B50829',
    '&:hover': {
      backgroundColor: '#B50829',
    },
  },
});

const degree = [
  { id: 'deg01', name: 'Trung học cơ sở' },
  { id: 'deg02', name: 'Trung học phổ thông' },
  { id: 'deg03', name: 'Đào tạo ngắn hạn' },
  { id: 'deg04', name: 'Sơ cấp (6 tháng)' },
  { id: 'deg05', name: 'Trung cấp (2 năm)' },
  { id: 'deg06', name: 'Cao đẳng (3 năm)' },
  { id: 'deg07', name: 'Đại học' },
  { id: 'deg08', name: 'Thạc sĩ' },
  { id: 'deg09', name: 'Tiến sĩ' },
];
