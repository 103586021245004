import React  from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import Cancel from '../Icon/Cancel';
import CheckCircle from '../Icon/CheckCircle';
import TableListServices from '../Table/TableListServices';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalListServices({ showModal, handleModal }) {
  const handleShow = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>, name: string) => {
    handleModal(e, name);
  };
  return (
    <div style={{ borderRadius: '15px' }}>
      <Dialog className={'max-w-[initial]'} style={{maxWidth: 'initial'}} onClose={handleModal} aria-labelledby='customized-dialog-title' open={showModal}>
        <DialogTitle id='customized-dialog-title' onClose={null}>
          <p className={'uppercase text-center font-bold'}>
            Danh sách gói dịch vụ
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <TableListServices/>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            style={{ padding: '13px 50px 10px 50px', backgroundColor: '#B50829', borderRadius: '5px', color: 'white' }}
            onClick={(e) => handleShow(e, 'close')}>
            ĐÓNG
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}