import React from 'react';
import { CustomMultipleSelect } from 'components/molecules';
import Switch from '@material-ui/core/Switch';
import { Autocomplete } from '@material-ui/lab';
import {TextField} from "@material-ui/core";
export const LF_DetailsInfo = ({
  data,
  onChangeInput,
  onClickNextStep,
  onClickBack,
  // onClickAddField,
  // onClickDeleteField,
  // quantityAddress,
  onChangeSalaryField,
  onChangeAttachCode,
  msgCheckValid,
  listProvinces,
  listDistricts,
  listPositions,
  // ----- //
  listShow,
  listChoose,
  onClickAddChoose,
  onClickRemoveChoose,
  query,
  setQuery,
  onChangeSearch,
  // ----- //
  listShowSkill,
  listChooseSkill,
  onClickAddChooseSkill,
  onClickRemoveChooseSkill,
  querySkill,
  setQuerySkill,
  onChangeSearchSkill,
}) => {
  return (
    <>
      <div className="flex flex-col lg:flex-row">
        <label className="font-bold mr-3 w-56">
          Lĩnh vực nghề nghiệp <span className="text-[#B50829]">*</span>
        </label>
        <div className="mt-3 lg:mt-0 flex-grow flex flex-col w-full lg:w-min">
          <div className="flex-grow">
            <CustomMultipleSelect
              name="career_"
              listShow={listShow}
              listChoose={listChoose}
              onAddChoose={onClickAddChoose}
              onDeleteChoose={onClickRemoveChoose}
              query={query}
              setQuery={setQuery}
              onSubmit={onChangeSearch}
            />
            {msgCheckValid && msgCheckValid.listChooseCareers && (
              <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5">
                {msgCheckValid.listChooseCareers}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row mt-10">
        <label className="font-bold mr-3 w-56">
          Kỹ năng chuyên môn cần có <span className="text-[#B50829]">*</span>
        </label>
        <div className="mt-3 lg:mt-0 flex-grow flex flex-col  w-full lg:w-min">
          <div className="flex-grow">
            <CustomMultipleSelect
              name="skill_"
              listShow={listShowSkill}
              listChoose={listChooseSkill}
              onAddChoose={onClickAddChooseSkill}
              onDeleteChoose={onClickRemoveChooseSkill}
              query={querySkill}
              setQuery={setQuerySkill}
              onSubmit={onChangeSearchSkill}
            />
            {msgCheckValid && msgCheckValid.listChooseSkills && (
              <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5">
                {msgCheckValid.listChooseSkills}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row mt-6 lg:items-center xl:justify-start lg:justify-between">
        <div className="flex flex-col lg:flex-row ">
          <label className="font-bold mr-3 mt-4 lg:w-56 lg:min-w-[224px]">Cấp bậc cần tuyển</label>
          <select
            name="level"
            className="rounded border-gray-300 flex-2 p-y0-x12 w-full lg:w-1/2 mt-4 focus:outline-none h-9 lg:min-w-[222px]"
            value={data.detailsInfo.level || ''}
            onChange={(e) =>
              onChangeInput(e.target.name, e.target.value, 'detailsInfo')
            }
          >
            <option value="">Lựa chọn cấp bậc</option>
            <option value="pos01">Thực tập, Fresher</option>
            <option value="pos02">Nhân viên, Chuyên viên</option>
            <option value="pos03">Trưởng nhóm, Team Leader</option>
            <option value="pos04">Trưởng phòng, Manager</option>
            <option value="pos05">Trưởng bộ phận, Director</option>
            <option value="pos06">Giám đốc, Lãnh đạo</option>
          </select>
        </div>
        <div className="flex flex-col lg:flex-row lg:ml-8">
          <label className="font-bold mr-3 mt-4 lg:w-56 lg:min-w-[180px]">Bằng cấp tối thiểu</label>
          <select
            name="degree"
            className="rounded border-gray-300 flex-2 p-y0-x12 w-full lg:w-1/2 mt-4 focus:outline-none h-9"
            value={data.detailsInfo.degree || ''}
            onChange={(e) =>
              onChangeInput(e.target.name, e.target.value, 'detailsInfo')
            }
          >
            <option value="">Lựa chọn bằng cấp</option>
            <option value="deg01">Trung học cơ sở</option>
            <option value="deg02">Trung học phổ thông</option>
            <option value="deg03">Đào tạo ngắn hạn</option>
            <option value="deg04">Sơ cấp (6 tháng)</option>
            <option value="deg05">Trung cấp (2 năm)</option>
            <option value="deg06">Cao đẳng (3 năm)</option>
            <option value="deg07">Đại học</option>
            <option value="deg08">Thạc sĩ</option>
            <option value="deg09">Tiến sĩ</option>
          </select>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56 lg:min-w-[222px]" htmlFor={"position"}>Vị trí công việc</label>
        {listPositions &&
        listPositions.length > 0 && <Autocomplete
            id="position"
            className="rounded border-gray-300 w-full h-full flex-2 focus:outline-none"
            options={listPositions}
            getOptionLabel={(option) => option.name}
            value={listPositions.find(e => e.job_position_code === data.detailsInfo.position)}
            filterSelectedOptions
            renderInput={(params) => <TextField{...params} variant="outlined"/>}
            onChange={(event, value) =>
                onChangeInput('position', value?.job_position_code, 'detailsInfo')
            }
        />
        }
        {/*<select*/}
        {/*  name="position"*/}
        {/*  className="rounded border-gray-300 w-full mt-4 flex-2 p-y0-x12 focus:outline-none h-9"*/}
        {/*  value={data.detailsInfo.position}*/}
        {/*  onChange={(e) =>*/}
        {/*    onChangeInput(e.target.name, e.target.value, 'detailsInfo')*/}
        {/*  }*/}
        {/*>*/}
        {/*  <option value="">Lựa chọn vị trí công việc</option>*/}
        {/*  {listPositions &&*/}
        {/*    listPositions.length > 0 &&*/}
        {/*    listPositions.map((position, index) => (*/}
        {/*      <option value={position.job_position_code} key={index}>*/}
        {/*        {position.name}*/}
        {/*      </option>*/}
        {/*    ))}*/}
        {/*</select>*/}
      </div>

      <div className="flex flex-col lg:flex-row lg:items-center xl:justify-start lg:justify-between">
        <div className="flex flex-col lg:flex-row ">
          <label className="font-bold mr-3 mt-4 lg:w-56 lg:min-w-[224px]">Số năm kinh nghiệm</label>
          <select
            name="experience"
            className="rounded border-gray-300 flex-2 p-y0-x12 w-full lg:w-1/2 mt-4 focus:outline-none h-9 lg:min-w-[222px]"
            value={data.detailsInfo.experience || ''}
            onChange={(e) =>
              onChangeInput(e.target.name, e.target.value, 'detailsInfo')
            }
          >
            <option value="">Không yêu cầu</option>
            <option value="yex00">Dưới 1 năm</option>
            <option value="yex01">1 năm</option>
            <option value="yex02">2 năm</option>
            <option value="yex03">3 năm</option>
            <option value="yex04">4 năm</option>
            <option value="yex05">5 năm</option>
            <option value="yex06">Trên 5 năm</option>
            <option value="yex07">Trên 10 năm</option>
          </select>
        </div>
        <div className="flex flex-col lg:flex-row lg:ml-8">
          <label className="font-bold mr-3 mt-4 lg:w-56 ">Giới tính</label>
          <select
            name="gender"
            className="rounded border-gray-300 flex-2 p-y0-x12 w-full lg:w-1/2 mt-4 focus:outline-none h-9 lg:min-w-[180px]"
            value={data.detailsInfo.gender || 0}
            onChange={(e) =>
              onChangeInput(
                e.target.name,
                parseInt(e.target.value),
                'detailsInfo',
              )
            }
          >
            <option value={0}>Không yêu cầu</option>
            <option value={1}>Nam</option>
            <option value={2}>Nữ</option>
          </select>

        </div>
      </div>

      <div className="flex flex-col lg:flex-row mb-5 mt-4">
        <label className="font-bold mr-3 w-56">Giới hạn độ tuổi</label>
        <div>
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col flex-grow mt-4">
              <div className="mr-16 h-9 rounded overflow-hidden flex items-center">
                <label className="h-9 inline-block w-16 p-y6-x12">Từ</label>
                <input
                  name="minAge"
                  type="number"
                  className="w-16 h-9 py-6px px-1 text-center border-t border-b border-gray-200 focus:outline-none"
                  value={data.detailsInfo.minAge}
                  onChange={(e) =>
                    onChangeInput(e.target.name, e.target.value, 'detailsInfo')
                  }
                />
                <label className="h-9 inline-block p-y6-x12 bg-gray-200 border border-gray-200 border-solid">
                  tuổi
                </label>
              </div>
              {msgCheckValid && msgCheckValid.minAge && (
                <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5">
                  {msgCheckValid.minAge}
                </span>
              )}
            </div>
            <div className="flex flex-col flex-grow mt-4">
              <div className="rounded h-9 overflow-hidden flex items-center">
                <label className="h-9 inline-block w-16 p-y6-x12">Đến</label>
                <input
                  name="maxAge"
                  type="number"
                  className="w-16 h-9 py-6px px-1 text-center border-t border-b border-gray-200 focus:outline-none"
                  value={data.detailsInfo.maxAge}
                  onChange={(e) =>
                    onChangeInput(e.target.name, e.target.value, 'detailsInfo')
                  }
                />
                <label className="h-9 inline-block p-y6-x12 bg-gray-200 border border-gray-200 border-solid">
                  tuổi
                </label>
              </div>
              {msgCheckValid && msgCheckValid.maxAge && (
                <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5">
                  {msgCheckValid.maxAge}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Field: Địa điểm làm việc */}
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56">
          Địa điểm làm việc <span className="text-[#B50829]">*</span>
        </label>
        <div className="flex-grow">
          <div className="flex flex-col lg:flex-row address">
            <div className="flex flex-col flex-grow mt-4 lg:mt-0">
              <input
                name="address"
                type="text"
                className="h-9 p-y6-x12 rounded border-gray-300"
                placeholder="Số nhà, tên đường,..."
                value={data.detailsInfo.address}
                onChange={(e) =>
                  onChangeInput(e.target.name, e.target.value, 'detailsInfo')
                }
              />
              {msgCheckValid && msgCheckValid.address && (
                <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5">
                  {msgCheckValid.address}
                </span>
              )}
            </div>
            <div className="flex flex-col mt-4 lg:mt-0">
              <select
                className="h-9 rounded border-gray-300 w-60 p-y0-x12 lg:ml-3 focus:outline-none"
                name="city"
                value={data.detailsInfo.city || ''}
                onChange={(e) =>
                  onChangeAttachCode(
                    e.target.name,
                    e.target.value,
                    e.target.selectedOptions[0].text,
                    'detailsInfo',
                  )
                }
              >
                <option value="">-- Chọn thành phố --</option>
                {listProvinces &&
                  listProvinces.length > 0 &&
                  listProvinces.map((province, index) => (
                    <option value={province.province_code} key={index}>
                      {province.province_name}
                    </option>
                  ))}
              </select>
              {msgCheckValid && msgCheckValid.city && (
                <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5 ml-3">
                  {msgCheckValid.city}
                </span>
              )}
            </div>
            <div className="flex flex-col mt-4 lg:mt-0">
              <select
                className="h-9 rounded border-gray-300 w-52 p-y0-x12 lg:ml-3 focus:outline-none"
                name="district"
                value={data.detailsInfo.district}
                onChange={(e) =>
                  onChangeAttachCode(
                    e.target.name,
                    e.target.value,
                    e.target.selectedOptions[0].text,
                    'detailsInfo',
                  )
                }
              >
                <option value="">-- Chọn quận --</option>
                {listDistricts &&
                  listDistricts.length > 0 &&
                  listDistricts.map((district, index) => (
                    <option value={district.district_code} key={index}>
                      {district.name}
                    </option>
                  ))}
              </select>
              {msgCheckValid && msgCheckValid.district && (
                <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5 ml-3">
                  {msgCheckValid.district}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Field: salary */}
      <div className="flex flex-col lg:flex-row mb-5 mt-4">
        <label className="font-bold mr-3 w-56">Mức lương</label>
        <div>
          <div className="mb-2">
            <Switch
              checked={data.detailsInfo.agreedSalary === 0 ? false : true}
              onChange={(e) => onChangeSalaryField(e.target.checked)}
              name="agreedSalary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <span>Lương thỏa thuận</span>
          </div>
          {data.detailsInfo.agreedSalary === 0 && (
            <div className="flex flex-col lg:flex-row">
              <div className="flex flex-col flex-grow mt-4 lg:mt-0">
                <div className="rounded h-9 overflow-hidden flex items-center">
                  <label className="h-9 inline-block w-24 p-y6-x12 ">
                    Tối thiểu
                  </label>
                  <input
                    name="salaryFrom"
                    type="number"
                    className="w-16 h-9 py-6px px-1 text-center border-t border-b border-gray-200 focus:outline-none"
                    value={data.detailsInfo.salaryFrom}
                    onChange={(e) =>
                      onChangeInput(
                        e.target.name,
                        e.target.value,
                        'detailsInfo',
                      )
                    }
                  />
                  <label className="h-9 inline-block p-y6-x12 bg-gray-200 border border-gray-200 border-solid">
                    triệu VNĐ
                  </label>
                </div>
                {msgCheckValid && msgCheckValid.salaryFrom && (
                  <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5">
                    {msgCheckValid.salaryFrom}
                  </span>
                )}
              </div>
              <div className="flex flex-col flex-grow mt-6 lg:mt-0">
                <div className="rounded h-9 overflow-hidden flex items-center">
                  <label className="h-9 inline-block w-24 p-y6-x12 ">
                    Tối đa
                  </label>
                  <input
                    name="salaryTo"
                    type="number"
                    className="w-16 h-9 py-6px px-1 text-center border-t border-b border-gray-200 focus:outline-none"
                    value={data.detailsInfo.salaryTo}
                    onChange={(e) =>
                      onChangeInput(
                        e.target.name,
                        e.target.value,
                        'detailsInfo',
                      )
                    }
                  />
                  <label className="h-9 inline-block p-y6-x12 bg-gray-200 border border-gray-200 border-solid">
                    triệu VNĐ
                  </label>
                </div>
                {msgCheckValid && msgCheckValid.salaryTo && (
                  <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5">
                    {msgCheckValid.salaryTo}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Field: job type */}
      <div className="flex flex-col lg:flex-row">
        <label className="font-bold mr-3 w-56">Tính chất công việc</label>
        <div className="flex-1 flex items-center flex-wrap mt-4 lg:mt-0">
          <div className="w-1/2 flex items-center mb-4">
            <input
              type="radio"
              name="jobType"
              id="fulltime"
              className="translate-y-1px"
              checked={data.detailsInfo.jobType === 'jot01'}
              value="jot01"
              onChange={(e) =>
                onChangeInput(e.target.name, e.target.value, 'detailsInfo')
              }
            />
            <label htmlFor="fulltime" className="pl-3">
              Toàn thời gian
            </label>
          </div>
          <div className="w-1/2 flex items-center mb-4">
            <input
              type="radio"
              name="jobType"
              id="parttime"
              className="translate-y-1px"
              checked={data.detailsInfo.jobType === 'jot02'}
              value="jot02"
              onChange={(e) =>
                onChangeInput(e.target.name, e.target.value, 'detailsInfo')
              }
            />
            <label htmlFor="parttime" className="pl-3">
              Bán thời gian
            </label>
          </div>
          <div className="w-1/2 flex items-center mb-4">
            <input
              type="radio"
              name="jobType"
              id="internship"
              className="translate-y-1px"
              checked={data.detailsInfo.jobType === 'jot03'}
              value="jot03"
              onChange={(e) =>
                onChangeInput(e.target.name, e.target.value, 'detailsInfo')
              }
            />
            <label htmlFor="internship" className="pl-3">
              Thực tập
            </label>
          </div>
          <div className="w-1/2 flex items-center mb-4">
            <input
              type="radio"
              name="jobType"
              id="collaborator"
              className="translate-y-1px"
              checked={data.detailsInfo.jobType === 'jot04'}
              value="jot04"
              onChange={(e) =>
                onChangeInput(e.target.name, e.target.value, 'detailsInfo')
              }
            />
            <label htmlFor="collaborator" className="pl-3">
              Cộng tác viên
            </label>
          </div>
          <div className="w-full flex items-center mb-4">
            <input
              type="radio"
              name="jobType"
              id="volunteer"
              className="translate-y-1px"
              checked={data.detailsInfo.jobType === 'jot05'}
              value="jot05"
              onChange={(e) =>
                onChangeInput(e.target.name, e.target.value, 'detailsInfo')
              }
            />
            <label htmlFor="volunteer" className="pl-3">
              Tình nguyện viên
            </label>
          </div>
        </div>
      </div>

      {/* Btn back + next */}
      <div className="lg:my-32 w-full flex items-center justify-center">
        <div
          className="flex w-max rounded-lg bg-gray-400 text-white custom-btn cursor-pointer"
          onClick={() => onClickBack('detailsInfo')}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.3285 10.9997V12.9997L7.50017 12.9997L10.7427 16.2423L9.32849 17.6565L3.67163 11.9996L9.32849 6.34277L10.7427 7.75699L7.49994 10.9997L20.3285 10.9997Z"
              fill="white"
            />
          </svg>
          <span className="pl-2 font-medium">Quay lại</span>
        </div>
        <div
          className="flex w-max ml-40 rounded-lg bg-[#B50829] text-white custom-btn cursor-pointer"
          onClick={() => onClickNextStep('detailsInfo')}
        >
          <span className="pr-2 font-medium">Đăng tuyển</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0377 6.34277L13.6268 7.76029L16.897 11.0153L3.29199 11.029L3.294 13.0289L16.8618 13.0153L13.6466 16.2455L15.0641 17.6564L20.7078 11.9864L15.0377 6.34277Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </>
  );
};
