import * as types from '../type';

export const getPackages = () => ({
    type: types.GET_PACKAGES_REQUEST,
});

export const getPackageTime = () => ({
    type: types.GET_PACKAGES_TIME_REQUEST,
});

export const getVipPackage = () => ({
    type: types.GET_VIP_PACKAGES_REQUEST,
});
export const getPackageBuy = () => ({
    type: types.GET_PACKAGE_BUY_REQUEST,
});
export const getOldPackages = (object_id, object_name) => ({
    type: types.GET_OLD_PACKAGES_REQUEST,
    payload: {object_id, object_name}
});