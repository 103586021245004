import React from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
//import * as Editor from '../../organisms/CkEditor/build/ckeditor';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyUploadAdapter from '../../../utils/commons/myUploadAdapter';

import CKEditor from 'ckeditor4-react';

const DEFAULT_CONFIGS = {
  placeholder: 'Type the content here!',
  toolbar: {
    items: [
      'heading',
      '|',
      'Bold',
      'Italic',
      'Underline',
      'strikethrough',
      'highlight',
      '|',
      'fontSize',
      'fontFamily',
      '|',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      '|',
      'indent',
      'outdent',
      '|',
      'todoList',
      'code',
      'CodeBlock',
      'link',
      'blockQuote',
      '|',
      'undo',
      'redo',
    ],
  },
  language: 'en',
  alignment: {
    options: ['left', 'center', 'right'],
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
  },
  licenseKey: '',
};

export const LF_CkEditor = ({ name, data, onChange }) => {
  return (
    // <CKEditor
    //   editor={ClassicEditor}
    //   data={data ?? ''}
    //   config={DEFAULT_CONFIGS}
    //   onReady={(editor) => {
    //     if (editor && editor) {
    //       editor.plugins.get('FileRepository').createUploadAdapter = (
    //         loader,
    //       ) => {
    //         // console.log(loader, 'loaderCK5');
    //         return new MyUploadAdapter(loader);
    //       };
    //     }
    //     // You can store the "editor" and use when it is needed.
    //     // console.log('Editor is ready to use!', editor);
    //   }}
    //   onChange={(event, editor) => {
    //     const data = editor.getData();
    //     if (onChange) onChange(name, data);
    //   }}
    // />
    <CKEditor
      data={data ?? ''}
      onChange={(event) => {
        const data = event.editor.getData();
        if (onChange) onChange(name, data);
      }}
      config={{
        toolbar: [
          { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
          {
            name: 'paragraph',
            items: [
              'NumberedList',
              'BulletedList',
              '-',
              'JustifyLeft',
              'JustifyCenter',
              'JustifyRight',
              'JustifyBlock',
            ],
          },
          { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
          { name: 'colors', items: ['TextColor', 'BGColor'] },
        ],
      }}
    />
  );
};
