import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { CorporateRepository } from 'services/repositories/corporate.repository';

//Initial State
const corporateRepository: CorporateRepository = RepositoryFactory.get(
  'corporate',
) as CorporateRepository;

const fetchCorporate = async (aciton) => {
  const response: any = await corporateRepository.get();
  if (response) {
    localStorage.setItem('corporate_code', response.corporate_code);
  }

  return response;
};

function* getCorporate(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const corporate = yield call(fetchCorporate, action.payload);
    yield put({ type: types.CORPORATE_SUCCESS, payload: corporate });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.CORPORATE_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetCorporate() {
  yield takeLatest(types.CORPORATE_REQUEST, getCorporate);
}
