import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { JobsRepository } from 'services/repositories/jobs.repository';

//Repository
const jobsRepository = RepositoryFactory.get('jobs') as JobsRepository;

const callApiUpdateJob = async ({ data, id }) => {
  const response = await jobsRepository.update(id, data);
  return response;
};

function* handlerUpdateJob(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const newJob = yield call(callApiUpdateJob, action.payload);
    yield put({ type: types.UPDATE_JOB_SUCCESS, payload: newJob });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.UPDATE_JOB_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchUpdateJob() {
  yield takeLatest(types.UPDATE_JOB_REQUEST, handlerUpdateJob);
}
