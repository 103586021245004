/* eslint-disable react/jsx-no-undef */
import {
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  FormLabel,
  FormGroup,
  Checkbox,
  makeStyles,
  Button,
} from '@material-ui/core';
import {
  CustomMultipleSelect,
  MultipleSelect,
  RadioButtonsGroup,
  SelectScheduleSearch,
} from 'components/molecules';
import React from 'react';

export const LF_FindTrainee = ({
  form,
  setForm,
  onClick,
  //carres
  careers,
  listCareerChoose,
  careerQuery,
  onChooseCareer,
  onDeleteCareer,
  setCareerQuery,
  onChangeSearchCareers,
  //skill
  listShowSkill,
  listChooseSkill,
  onClickAddChooseSkill,
  onClickRemoveChooseSkill,
  querySkill,
  setQuerySkill,
  onChangeSearchSkill,
}) => {
  const classes = useStyles();

  const handleChangeRole = (e: string) => {
    if (e === '0') {
      // data.basicInfo.p_online = 0;
      setForm({ ...form, online: 0 });
    }
    if (e === '1') {
      // data.basicInfo.p_online = 1;
      setForm({ ...form, online: 1 });
    }
    if (e === '2') {
      // data.basicInfo.p_online = 2;
      setForm({ ...form, online: 2 });
    }
  };
  return (
    <div className="container">
      <Grid container direction="row" justify="flex-start">
        <Grid sm={12}>
          <div
            className="uppercase text-xl font-bold"
            style={{
              padding: '16px 0px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
            }}
          >
            Lọc theo chuyên môn
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="flex-start"
        style={{ paddingTop: '30px' }}
      >
        <Grid sm={6} className={"w-full"}>
          <div className="mr-2 mb-5 ">
            <label
              className="block text-black font-bold mb-4 "
              htmlFor="Quy mô công ty"
            >
              Ngành nghề
            </label>
            <CustomMultipleSelect
              name="career_"
              listShow={careers}
              listChoose={listCareerChoose}
              onAddChoose={onChooseCareer}
              onDeleteChoose={onDeleteCareer}
              query={careerQuery}
              setQuery={setCareerQuery}
              onSubmit={onChangeSearchCareers}
            />
          </div>
          <div className="mr-2 mb-2">
            <label
              className="block text-black font-bold my-4 "
              htmlFor="Quy mô công ty"
            >
              Kỹ năng chuyên môn
            </label>
            <CustomMultipleSelect
              name="skill_"
              listShow={listShowSkill}
              listChoose={listChooseSkill}
              onAddChoose={onClickAddChooseSkill}
              onDeleteChoose={onClickRemoveChooseSkill}
              query={querySkill}
              setQuery={setQuerySkill}
              onSubmit={onChangeSearchSkill}
            />
          </div>
        </Grid>
        <Grid sm={6} className={"w-full"}>
          <div className="lg:mx-10 mb-2  flex flex-col xl:flex-row">
            <label
              className="block text-black font-bold mb-2  mt-2 pr-6"
              htmlFor="Quy mô công ty"
            >
              Hình thức học
            </label>
            <RadioButtonsGroup action={handleChangeRole} />
          </div>
          <div className="lg:mx-10 mb-2 flex flex-col lg:flex-row">
            <Switch
              checked={form.status}
              onClick={(e) => {
                e.isDefaultPrevented();
                setForm({ ...form, status: !form.status });
              }}
              name="checked"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <label
              className="block text-black font-bold mb-2  mt-2 pr-2"
              htmlFor="Quy mô công ty"
            >
              Có thẻ học nghề bộ đội xuất ngũ còn hạn
            </label>
          </div>
        </Grid>
        <Grid sm={7} className={'w-full'}>
          <div className="mr-2 lg:my-5">
            <label
              className="block text-black font-bold mb-6 "
              htmlFor="Quy mô công ty"
            >
              Khung giờ có thể học
            </label>
            <div className="flex justify-between mt-5">
              {/* {listTimeFrame &&
                listTimeFrame.map((ele, index) => (
                  <FormControl component="fieldset" key={index}>
                    <FormLabel
                      className={'text-center mb-2'}
                      style={{ color: 'rgba(0, 0, 0)', fontWeight: 700 }}
                    >
                      {ele.time_frame_code}
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name={`morning${index}`}
                          />
                        }
                        label="Sáng"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name={`afternoon${index}`}
                          />
                        }
                        label="Chiều"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name={`evening${index}`}
                          />
                        }
                        label="Tối"
                      />
                    </FormGroup>
                  </FormControl>
                ))} */}
              <SelectScheduleSearch
                action={(e: string) => {
                  // console.log(e);

                  setForm({ ...form, schedule: e });
                }}
                disable={false}
              />
            </div>
          </div>
        </Grid>
        <Grid sm={5}></Grid>
      </Grid>
      <Grid container direction="row" className="text-center py-5">
        <Grid sm={12}>
          <Button
            variant="outlined"
            className={`${classes.btnRed} focus:outline-none lg:w-[20%] lg:h-[56px]`}
            style={{
              // width: '20%',
              // height: '56px',
              color: '#ffffff',
              alignItems: 'center',
              // marginTop: '10px',
            }}
            onClick={onClick}
          >
            <span>Tìm kiếm học viên</span>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles({
  btnRed: {
    backgroundColor: '#B50829',
    '&:hover': {
      backgroundColor: '#B50829',
    },
  },
});

const listTimeFrame = [
  { time_frame_code: 'Thứ 2' },
  { time_frame_code: 'Thứ 3' },
  { time_frame_code: 'Thứ 4' },
  { time_frame_code: 'Thứ 5' },
  { time_frame_code: 'Thứ 6' },
  { time_frame_code: 'Thứ 7' },
  { time_frame_code: 'CN' },
];
