import * as types from './../../type';
import { RepositoryFactory } from 'services/repositories';
import { call, put, takeLatest } from 'redux-saga/effects';
import { CoursesRepository } from 'services/repositories/course.repository';

const coursesRepository = RepositoryFactory.get('course') as CoursesRepository;

const deleteCourses = async (id) => {
  const response = await coursesRepository.delete(id);
  return response;
};

function* handleDeleteCourse(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const courses = yield call(deleteCourses, action.payload);
    yield put({ type: types.DELETE_COURSES_SUCCESS, action: courses });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.DELETE_COURSES_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}
export default function* watchDeleteCourse() {
  yield takeLatest(types.DELETE_COURSES_REQUEST, handleDeleteCourse);
}
