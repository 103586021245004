import * as types from '../type';

interface IInitialState {
  trainee: { data: []; total_data: any };
  status: any;
}

const initialState: IInitialState = {
  trainee: { data: [], total_data: null },
  status: null,
};

const traineeReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.GET_TRAINEE_SUCCESS:
      return { ...state, trainee: action.payload };
    case types.POST_VIEW_TRAINEE_SUCCESS:
      return { ...state, status: action.payload };
    default:
      return state;
  }
};

export default traineeReducer;
