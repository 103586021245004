import * as types from './../type';

export const getTrainee = (query?: any) => ({
  type: types.GET_TRAINEE_REQUEST,
  payload: query,
});
export const viewTraineeProfile = (data) => ({
  type: types.POST_VIEW_TRAINEE_REQUEST,
  payload: data,
});
