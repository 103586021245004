import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CareerRepository } from '../../../services/repositories/career.repository';

//Repository
const careerRepository: CareerRepository = RepositoryFactory.get(
  'career',
) as CareerRepository;

//Async Fetch
const fetchCareer = async (query: string) => {
  const response = await careerRepository.search(query);

  return response;
};

function* handlerCareer(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const career = yield call(fetchCareer, action.payload);

    yield put({ type: types.SEARCH_CAREER_SUCCESS, payload: career });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.SEARCH_CAREER_ERROR,
      payload: err.response.data,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchSearchCareers() {
  yield takeLatest(types.SEARCH_CAREER_REQUEST, handlerCareer);
}
