import axios from 'axios';
import { CropImageBannerCourse, LF_CkEditor } from 'components/molecules';
import { CustomMultipleSelect } from 'components/molecules';
import { RadioButtonsGroup } from 'components/molecules/RadioButton';
import {
  SelectButtonsGroup,
  SelectScheduleGroup,
} from 'components/molecules/SelectButton';
import { format } from 'path';
import React from 'react';
import { url } from 'inspector';
import { async } from 'q';
import DatePicker from "react-date-picker";
import { RepositoryFactory } from '../../../../services/repositories';
import { StorageRepository } from '../../../../services/repositories/storage.repository';

const storageRepository: StorageRepository = RepositoryFactory.get(
  'storage',
) as StorageRepository;

export const LF_BasicInfo = ({
  data,
  onChangeCKE,
  onChangeInput,
  onClickNextStep,
  msgCheckValid,
  onChangeAttachCode,
  // ----- //
  listShowCareers,
  listChooseCareers,
  onClickAddChooseCareers,
  onClickRemoveChooseCareers,
  queryCareers,
  setQueryCareers,
  onChangeSearchCareers,
  //-----//
  listProvinces,
  listDistricts,
  listPositions,
}) => {
  let inputFileRef = React.useRef(null);

  const [file, setFile] = React.useState([]);

  React.useEffect(() => {
    setFile(data?.basicInfo?.p_file);
  }, [data && data?.basicInfo?.p_file]);

  const handleSliderImages = async (e, key) => {
    if (Math.round(e.target.files[0].size / 1024) < 2048) {
      let formData = new FormData();
      formData.append('file', e.target.files[0]);
      const { accessToken } = await storageRepository.getStorageAccessToken(data?.basicInfo?.p_corporate_code);
      if (accessToken) {
        const response = await axios.post(
          `https://assets.i-hr.vn/upload?accessToken=${accessToken}`,
          formData,
        );
        if (response && response.status === 200) {
          if (key === 'p_file') {
            let list: string[] = data?.basicInfo?.p_file;
            if (list.length < 3) {
              list.push(response.data.data.url);
              onChangeInput(key, list, 'basicInfo');
            } else {
              alert('Chỉ được upload tối đa 3 tài liệu');
            }
          }
        }
      }
    } else {
      alert('File tài liệu không được lớn hơn 2MB');
    }
  };

  const handleUploadImage = async (e, key) => {
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    const { accessToken } = await storageRepository.getStorageAccessToken(data?.basicInfo?.p_corporate_code);
    if (accessToken) {
      const response = await axios.post(
        `https://assets.i-hr.vn/upload?accessToken=${accessToken}`,
        formData,
      );
      if (response && response.status === 200) {
        onChangeInput(key, response.data.data.url, 'basicInfo');
      }
    }
  };

  const removeSliderImages = (key) => {
    onChangeInput(key, '', 'basicInfo');
  };

  const handleformat = (e: number) => {
    if (e) {
      return Number(e).toLocaleString('en-US');
    }
  };

  const removeFile = (index, key) => {
    inputFileRef.current.value = null;
    let newFile = [...file];
    if (index !== null) {
      newFile.splice(index, 1);
    }
    onChangeInput(key, newFile, 'basicInfo');
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row">
        <label htmlFor="job-title" className="font-bold mr-3 w-40 ">
          Tên khóa học<span className="text-red-500">*</span>
        </label>
        <div className="flex-1 flex flex-col">
          <input
            name="p_course_name"
            type="text"
            id="p_course_name"
            className="border border-gray-300 border-solid flex-grow h-11 block"
            value={data.basicInfo.p_course_name}
            onChange={(e) =>
              onChangeInput(e.target.name, e.target.value, 'basicInfo')
            }
          />
          <span className="text-xs leading-5 h-5 text-red-500 pt-0.5">
            {msgCheckValid && msgCheckValid.p_course_name}
          </span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row mt-5">
        <label htmlFor="desc-job" className="font-bold mr-3 w-40">
          Nội dung <span className="text-red-500">*</span>
        </label>
        <div className="flex-grow flex flex-col w-full lg:max-w-[82%]">
          <div className="flex-grow">
            <LF_CkEditor
              name="p_course_desc"
              data={data.basicInfo.p_course_desc}
              onChange={onChangeCKE}
            />
          </div>
          <span className="text-xs leading-5 h-5 text-red-500 pt-0.5">
            {msgCheckValid && msgCheckValid.p_course_desc}
          </span>
        </div>
      </div>

      <div className="flex  flex-col lg:flex-row mt-5">
        <label htmlFor="desc-job" className="font-bold mr-3 w-40">
          Hồ sơ <span className="text-red-500">*</span>
        </label>
        <div className="flex-grow flex flex-col w-full lg:max-w-[82%]">
          <div className="flex-grow">
            <LF_CkEditor
              name="p_cv_content"
              data={data.basicInfo.p_cv_content}
              onChange={onChangeCKE}
            />
          </div>
          <span className="text-xs leading-5 h-5 text-red-500 pt-0.5">
            {msgCheckValid && msgCheckValid.p_cv_content}
          </span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row justify-start mt-5">
        <div className="flex flex-col lg:flex-row items-start lg:min-w-[400px] xl:min-w-[500px]">
          <label className="font-bold mt-4 lg:mt-0 mr-3 w-[160px]">
            Hạn đăng Ký <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-col mt-4 lg:mt-0">
            <DatePicker
              format={"dd-MM-yyyy"}
              value={data.basicInfo.p_end_date}
              onChange={(value) => onChangeInput("p_end_date", value, 'basicInfo')}
            />
            {msgCheckValid && msgCheckValid.p_end_date && (
              <span className="text-xs leading-5 h-5 text-red-500 pt-0.5">
                {msgCheckValid.p_end_date}
              </span>
            )}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-start">
          <label htmlFor="desc-job" className="font-bold mr-3 w-[160px] mt-4 lg:mt-0">
            Thời gian đào tạo <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-col mt-4 lg:mt-0">
            <div>
              <input
                name="p_timeTrain"
                type="number"
                className="h-8 w-48 border-gray-300 rounded focus:outline-none text-right"
                value={data.basicInfo.p_timeTrain}
                onChange={(e) =>
                  onChangeInput(e.target.name, e.target.value, 'basicInfo')
                }
              />
              <span className="ml-2">tháng</span>
            </div>
            <span className="text-xs text-red-500 pt-0.5">
              {msgCheckValid && msgCheckValid.p_timeTrain}
            </span>
          </div>
        </div>
      </div >

      <div className="flex flex-col lg:flex-row justify-start mt-5">
        <div className="flex flex-col lg:flex-row items-start lg:min-w-[400px] xl:min-w-[500px]">
          <label className="font-bold mr-3 w-[160px] mt-4 lg:mt-0">
            Khai giảng dự kiến <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-col mt-4 lg:mt-0">
            <DatePicker
              format={"dd-MM-yyyy"}
              value={data.basicInfo.p_openning_date}
              onChange={(value) => onChangeInput("p_openning_date", value, 'basicInfo')}
            />
            {msgCheckValid && msgCheckValid.p_openning_date && (
              <span className="text-xs leading-5 h-5 text-red-500 pt-0.5">
                {msgCheckValid.p_openning_date}
              </span>
            )}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-start">
          <label htmlFor="desc-job" className="font-bold mr-3 w-[160px] mt-4 lg:mt-0">
            Học phí <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-col mt-4 lg:mt-0">
            <div>
              <input
                name="p_price"
                type="number"
                className="h-8 w-48 border-gray-300 rounded focus:outline-none text-right"
                value={data && data?.basicInfo?.p_price}
                onChange={(e) => {
                  // const price = e.target.value.replace(/[,]*/g, '');
                  onChangeInput(e.target.name, e.target.value, 'basicInfo');
                }}
              />
              <span className="ml-2">VNĐ</span>
            </div>
            <span className="text-xs leading-5 h-5 text-red-500 pt-0.5 block">
              {msgCheckValid && msgCheckValid.p_price}
            </span>
          </div >
        </div >
      </div >

      {/* multi selector */}
      < div className="flex flex-col lg:flex-row justify-between mt-5" >
        <label htmlFor="desc-job" className="font-bold mr-3 w-40 ">
          Ngành đào tạo <span className="text-red-500">*</span>
        </label>
        <div className="flex-grow flex flex-col lg:w-min mt-4 lg:mt-0">
          <div className="flex-grow">
            <CustomMultipleSelect
              name="career_"
              listShow={listShowCareers}
              listChoose={listChooseCareers}
              onAddChoose={onClickAddChooseCareers}
              onDeleteChoose={onClickRemoveChooseCareers}
              query={queryCareers}
              setQuery={setQueryCareers}
              onSubmit={onChangeSearchCareers}
            />
            {msgCheckValid && msgCheckValid.listChooseCareers && (
              <span className="text-xs leading-5 h-5 text-red-500 pt-0.5">
                {msgCheckValid.listChooseCareers}
              </span>
            )}
          </div>
        </div>
      </div>

      {/* radio button */}
      <div className="flex flex-col lg:flex-row justify-between">
        <label className="font-bold mr-3 w-40  mt-4 lg:mt-0">
          Hình thức học <span className="text-red-500">*</span>
        </label>
        <div className="flex-grow mt-4 lg:mt-0">
          <SelectButtonsGroup
            data={data?.basicInfo?.p_online}
            action={(e) => {
              onChangeInput('p_online', e, 'basicInfo');
            }}
            disabled={false}
          />
          <span className="text-xs leading-5 h-5 text-red-500 pt-0.5 block">
            {msgCheckValid && msgCheckValid.p_online}
          </span>
        </div>
      </div>

      {/*TKB*/}
      <div className="flex flex-col lg:flex-row  justify-between mt-5">
        <label className="font-bold mr-3 w-full lg:w-40 ">
          Lịch học dự kiến hàng tuần
        </label>

        <div className="flex-grow">
          <div className="max-w-[600px]">
            Chọn lịch học hàng tuần để hệ thống tìm kiếm các học viên phù hợp
            nhất với khoá học này. Nếu không chắc chắn vui lòng bỏ trống toàn bộ
          </div>
          <SelectScheduleGroup
            data={data?.basicInfo?.p_schedule}
            action={(e: string) => {
              onChangeInput('p_schedule', e, 'basicInfo');
            }}
            disable={false}
          />
          {/*doi API */}
        </div>
      </div>

      {/*Address */}
      <div className="flex flex-col lg:flex-row mt-4 mb-4">
        <label className="font-bold mr-3 w-40 ">
          Địa điểm học{' '}
          {data.basicInfo.p_online !== 1 && (
            <span className="text-red-500">*</span>
          )}
        </label>
        <div className="flex-grow mt-4 lg:mt-0">
          <div className="flex flex-col lg:flex-row address">
            <div className="flex flex-col flex-grow mt-4 lg:mt-0">
              <input
                name="p_address"
                type="text"
                className="h-9 p-y0-x12 rounded border-gray-300"
                placeholder="Số nhà, đường phố, thôn xóm"
                value={data.basicInfo.p_address}
                onChange={(e) =>
                  onChangeInput(e.target.name, e.target.value, 'basicInfo')
                }
              />
              {msgCheckValid && msgCheckValid.p_address && (
                <span className="text-xs leading-5 h-5 text-red-500 pt-0.5">
                  {msgCheckValid.p_address}
                </span>
              )}
            </div>
            <div className="flex flex-col mt-4 lg:mt-0">
              <select
                className="h-9 rounded border-gray-300 w-60 p-y0-x12 lg:ml-3 focus:outline-none"
                name="p_province"
                value={data.basicInfo.p_province_code || ''}
                onChange={(e) => {
                  return onChangeAttachCode(
                    e.target.name,
                    e.target.value,
                    e.target.selectedOptions[0].text,
                    'basicInfo',
                  );
                }}
              >
                <option value="">-- Chọn thành phố --</option>
                {listProvinces &&
                  listProvinces.length > 0 &&
                  listProvinces.map((province, index) => (
                    <option value={province.province_code} key={index}>
                      {province.province_name}
                    </option>
                  ))}
              </select>
              {msgCheckValid && msgCheckValid.p_province_text && (
                <span className="text-xs leading-5 h-5 text-red-500 pt-0.5 lg:ml-3">
                  {msgCheckValid.p_province_text}
                </span>
              )}
            </div>
            <div className="flex flex-col mt-4 lg:mt-0">
              <select
                className="h-9 rounded border-gray-300 w-52 p-y0-x12  lg:ml-3 focus:outline-none"
                name="p_district"
                value={data.basicInfo.p_district_code}
                onChange={(e) =>
                  onChangeAttachCode(
                    e.target.name,
                    e.target.value,
                    e.target.selectedOptions[0].text,
                    'basicInfo',
                  )
                }
              >
                <option value="">-- Chọn quận --</option>
                {listDistricts &&
                  listDistricts.length > 0 &&
                  listDistricts.map((district, index) => (
                    <option value={district.district_code} key={index}>
                      {district.name}
                    </option>
                  ))}
              </select>
              {msgCheckValid && msgCheckValid.p_district_text && (
                <span className="text-xs leading-5 h-5 text-red-500 pt-0.5 lg:ml-3">
                  {msgCheckValid.p_district_text}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* input multi file */}
      <div className="flex flex-col lg:flex-row mt-5">
        <label htmlFor="desc-job" className="font-bold mr-3 w-40 ">
          Tài liệu
        </label>
        <div className="flex-grow flex flex-col w-min">
          <div className="flex-grow">
            <div className="flex flex-wrap">
              {file.length > 0 &&
                file.map((e: string, index) => {
                  if (e != '') {
                    return (
                      <React.Fragment key={index}>
                        <span className="flex mr-6 text-blue-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-black mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                            />
                          </svg>
                          {e
                            ? e.replace(
                              /^static[/]d[/]corporate[/]docs[/][0-9]{8}[/]/g,
                              ' ',
                            )
                            : null}
                        </span>
                        <span
                          className="pl-3 text-red-500 cursor-pointer"
                          onClick={(e) => removeFile(index, 'p_file')}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </span>
                      </React.Fragment>
                    );
                  }
                })}
            </div>
            <label className="flex text-red-600 cursor-pointer mt-2 w-32">
              <input
                ref={inputFileRef}
                className="hidden"
                type="file"
                onChange={(e) => {
                  handleSliderImages(e, 'p_file');
                }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              TẢI FILE LÊN
            </label>
          </div>
        </div>
      </div>

      {/* input file */}
      {/* <div className="flex mt-5">
        <label className="font-bold mr-3 w-40 ">Ảnh bìa</label>

        <div className="flex-2 mr-14">
          {data && data?.basicInfo?.p_background ? (
            <div className=" px-4 mb-8  ">
              <img
                className=" shadow-md max-w-[192px] max-h-[108px] mx-auto w-52 h-32"
                src={`https://assets.i-hr.vn/${data?.basicInfo?.p_background}`}
                alt={'demoBanner'}
              />
            </div>
          ) : (
            <img
              className="w-52 h-32 border-2 border-gray-300"
              src={'http://via.placeholder.com/300'}
            />
          )}
        </div>
        <div className="flex-1">
          {data && data?.basicInfo?.p_background === '' ? (
            <p className="">
              Lưu ý: Ảnh hình chữ nhật nằm ngang, tỉ lệ 16:9 để hiển thị tốt
              nhất.
            </p>
          ) : null}
          <div className="flex justify-between">
            <label className=" text-red-600 cursor-pointer flex mt-2 w-28">
              <input
                name="flies"
                className="hidden"
                type="file"
                accept="image/*"
                onChange={(e) => handleUploadImage(e, 'p_background')}
                ref={(input) => (inputFileRef = input)}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              CHỌN ẢNH
            </label>
            <label className="  cursor-pointer flex mt-2 w-28">
              <input
                className="hidden"
                onClick={() => removeSliderImages('p_background')}
              />
              <svg
                version="1.1"
                className="h-5 w-5 mr-2"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512.001 512.001"
                enableBackground="new 0 0 512.001 512.001"
                xmlSpace="preserve"
              >
                <path d="M294.111,256.001L504.109,46.003c10.523-10.524,10.523-27.586,0-38.109c-10.524-10.524-27.587-10.524-38.11,0L256,217.892 L46.002,7.894c-10.524-10.524-27.586-10.524-38.109,0s-10.524,27.586,0,38.109l209.998,209.998L7.893,465.999 c-10.524,10.524-10.524,27.586,0,38.109c10.524,10.524,27.586,10.523,38.109,0L256,294.11l209.997,209.998 c10.524,10.524,27.587,10.523,38.11,0c10.523-10.524,10.523-27.586,0-38.109L294.111,256.001z" />
              </svg>
              XÓA ẢNH
            </label>
          </div>
        </div>
      </div> */}
      <div className="w-full" style={{ padding: '1rem 0 0 0' }}>
        <div className="block text-base text-black font-bold mb-2 ">
          Ảnh bìa
        </div>
        <div className="block text-sm leading-6 text-[#B50829]">
          Lưu ý: Ảnh hình chữ nhật nằm ngang, tỉ lệ 16:9 để hiển thị tốt
        </div>
        <CropImageBannerCourse
          state={data && data?.basicInfo}
          setState={onChangeInput}
        />
      </div>

      {/* Btn click next step */}
      <div
        className="flex w-full justify-center py-8 cursor-pointer"
        onClick={() => onClickNextStep('basicInfo')}
      >
        <span className="px-3 py-3 flex pr-2 cursor-pointer font-medium rounded-lg bg-[#B50829] text-white w-max">
          Tiếp tục
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0377 6.34277L13.6268 7.76029L16.897 11.0153L3.29199 11.029L3.294 13.0289L16.8618 13.0153L13.6466 16.2455L15.0641 17.6564L20.7078 11.9864L15.0377 6.34277Z"
              fill="white"
            />
          </svg>
        </span>
      </div>
    </>
  );
};
