import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from 'redux-logger';
import rootReducers from "./reducers/index";
import rootSagas from "./sagas/index";
//sagas
import createSagaMiddleware from "redux-saga";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
// if (process.env.NODE_ENV === 'development') {
//   middleware.push(logger);
// }
function configureStore() {
  const store = createStore(
    rootReducers,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSagas);

  return store;
}
const store = configureStore();
export type RootState = ReturnType<typeof store.getState>;
export default store;
