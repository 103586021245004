import React from 'react';

//Dependencies
import {
  Button,
  Grid,
  InputBase,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export const LF_HeaderCourses = ({
  selectData,
  title,
  button,
  data,
  onSearch,
}) => {
  const classes = useStyles();
  const [fromSearch, setFromSearch] = React.useState({
    course_id: null,
    search_string: '',
  });

  const [validate, setValidate] = React.useState({
    course_id: '',
    search_string: '',
  });
  const onValidation = (data) => {
    let validation = { ...validate };
    let check = true;
    // if (data.course_id.length === 0 || data.course_id === null) {
    //   validation.course_id = 'Vui lòng chọn khóa học';
    //   check = false;
    // }
    if (check) {
      setValidate({
        course_id: '',
        search_string: '',
      });
      return true;
    } else {
      setValidate(validation);
      return false;
    }
  };
  const handleSearch = () => {
    const result = onValidation(fromSearch);
    if (result) {
      onSearch(fromSearch);
    }
  };

  const [select, setSelect] = React.useState(false);

  React.useEffect(() => {
    const i = data.findIndex((e) => e.course_id === selectData?.course_id);
    if (data[i]) {
      setSelect(true);
    }
  }, [selectData && data]);

  return (
    <div className="container">
      {/*body*/}
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="flex-start"
        style={{ paddingTop: '30px' }}
      >
        <Grid sm={6} className={'w-full mt-4'}>
          <label
            className="px-2 block font-bold text-base"
            htmlFor="Chọn tin đăng tuyển"
          >
            {title}
          </label>
          <select
            onChange={(e) => {
              setFromSearch({
                ...fromSearch,
                course_id: e.target.value === "0" ? null : e.target.value,
              });
            }}
            style={{ width: '90%', border: '1px solid #CED4DA' }}
            className="appearance-none mt-3 block py-3 px-3 text-black rounded leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value={0}>
              Tất cả
            </option>
            {data &&
              data?.map((ele, index) => {
                return (
                  <option
                    value={ele.course_id}
                    key={index}
                    // className={`${
                    //   select && ele.course_id === selectData?.course_id
                    //     ? 'hidden'
                    //     : 'visible'
                    // }`}
                    selected={ele.course_id === selectData?.course_id}
                  >
                    {ele.course_name}
                  </option>
                );
              })}
          </select>
          <span className="text-base" style={{ color: '#B50829' }}>
            {validate.course_id !== '' ? validate.course_id : ''}
          </span>
        </Grid>
        <Grid sm={6} container className={"mt-4"} direction="row">
          <p className="px-2 font-bold text-base">Tìm kiếm</p>
          <Grid container justify="space-between" alignItems="center">
            <InputBase
              className={`${classes.searchBox}`}
              placeholder="Nhập thông tin bất kỳ để tìm...."
              inputProps={{ 'roboto-label': 'search' }}
              onBlur={(e) =>
                setFromSearch({ ...fromSearch, search_string: e.target.value })
              }
            />
            <Button
              variant="outlined"
              className={`${classes.btnRed} focus:outline-none`}
              onClick={handleSearch}
              style={{
                width: '20%',
                height: '47px',
                color: '#ffffff',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <span>Tìm Kiếm</span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles({
  btn: {
    borderRadius: '4px',
  },

  btnRed: {
    backgroundColor: '#B50829',
    '&:hover': {
      backgroundColor: '#B50829',
    },
  },

  btnGray: {
    backgroundColor: '#000000',
    opacity: '75%',
    '&:hover': {
      backgroundColor: '#000000',
    },
  },

  text: {
    width: '210px',
    height: '36px',
    color: '#ffffff',
    fontSize: '16px',
    lineHeight: '16px',
    fontFamily: 'roboto',
    fontWeight: 500,
    padding: '12px 0px',
    textTransform: 'none',
  },

  selectBox: {
    width: '90%',
    height: '56px',
    marginTop: '10px',
  },

  searchBox: {
    padding: '9px 65px 9px 9px',
    width: '75%',
    height: '47px',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    marginTop: '10px',
  },
});
