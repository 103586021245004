import React from 'react';
import { convertDate } from 'utils/commons';

export const LF_DesiredPositionCourse = (props) => {
  return (
      <div>
          <a href={`edit/${props?.course_id}`} target="_blank">
              <span className="text-base font-normal ">{props?.course_name}</span>
          </a>
          <div className="flex flex-row justify-between items-center pt-3">
              <ul className="list-disc list-inside">
                  <li className="text-sm font-light">
                      <span>Mã tin: {props?.course_id} </span>
                  </li>
                  <li className="text-sm font-light">
                      <span>Ngày: {props?.opening_date && convertDate(props?.opening_date.substr(0, 10))}</span>
                  </li>
              </ul>
          </div>
      </div>
  )
};
