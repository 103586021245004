import { CorporateRepository } from './corporate.repository';
import { CareerGroupRepository } from './careerGroup.repository';
import { ProvinceRepository } from './province.repository';
import { SkillRepository } from './skill.repository';
import { PositionRepository } from './position.repository';
import { JobsRepository } from './jobs.repository';
import { EmployeeRepository } from './employee.repository';
import { CoursesRepository } from './course.repository';
import { HomeRepository } from './home.repository';
import { CareerRepository } from './career.repository';
import { CandidateRepository } from './candidate.repository';
import { AddressRepository } from './address.repository';
import { CareerCodeRepository } from './careerCode.repository';
import { TraineeRepository } from './trainee.repository';
import { IPointPackageRepository } from './ipointPackage.repository';
import { IPointRepository } from './ipoint.repository';
import { StorageRepository } from './storage.repository';

const repositories = {
  corporate: new CorporateRepository(),
  'career-group': new CareerGroupRepository(),
  province: new ProvinceRepository(),
  skill: new SkillRepository(),
  position: new PositionRepository(),
  jobs: new JobsRepository(),
  emp: new EmployeeRepository(),
  course: new CoursesRepository(),
  home: new HomeRepository(),
  career: new CareerRepository(),
  candidate: new CandidateRepository(),
  address: new AddressRepository(),
  careerCode: new CareerCodeRepository(),
  trainee: new TraineeRepository(),
  package: new IPointPackageRepository(),
  ipoint: new IPointRepository(),
  storage: new StorageRepository(),
};

/**
 * Factory Design Pattern nMethods
 * @params name
 * @type string
 * @return Repository
 */
export const RepositoryFactory = {
  get: (
    name: string,
  ):
    | CorporateRepository
    | CareerGroupRepository
    | ProvinceRepository
    | JobsRepository
    | EmployeeRepository
    | SkillRepository
    | PositionRepository
    | CoursesRepository
    | HomeRepository
    | CareerRepository
    | CandidateRepository
    | CareerCodeRepository
    | AddressRepository
    | TraineeRepository
    | StorageRepository
    | IPointPackageRepository
    | IPointRepository => repositories[name],
};
