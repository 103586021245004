import * as types from '../type';
import { ICareerGroup } from '../../utils/interfaces/career.interface';

interface IInitialState {
  careers: ICareerGroup[];
  career: ICareerGroup | {};
  status: any;
}

const initialState: IInitialState = {
  careers: [],
  career: {},
  status: null,
};

const careerGrReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.SEARCH_CAREER_GR_SUCCESS:
      return { ...state, careers: action.payload };
    case types.SEARCH_CAREER_GR_ERROR:
      return { ...state, status: action.payload };
    case types.SEARCH_CAREER_SUCCESS:
      return { ...state, careers: action.payload };
    case types.SEARCH_CAREER_ERROR:
      return { ...state, status: action.payload };
    default:
      return {
        ...state,
      };
  }
};

export default careerGrReducer;
