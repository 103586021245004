import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { JobsRepository } from 'services/repositories/jobs.repository';

//Repository
const jobsRepository = RepositoryFactory.get('jobs') as JobsRepository;

const fetchJobActive = async (query?) => {
  const response = await jobsRepository.getJobActive();

  return response;
};

function* handlerGetJobActive(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const jobs = yield call(fetchJobActive);

    yield put({
      type: types.GET_JOB_ACTIVE_SUCCESS,
      payload: jobs,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.GET_JOB_ACTIVE_ERROR,
      payload: err.response,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetJobActive() {
  yield takeLatest(types.GET_JOB_ACTIVE_REQUEST, handlerGetJobActive);
}
