/* eslint-disable react/jsx-pascal-case */
import React from 'react';

//component
import { LF_Breadcumbs } from 'components/molecules';

import {
  LF_DataTable,
  LF_HeaderListEmployee,
} from '../../../../../components/organisms';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getEmployee } from 'redux/actions/employee.action';
import {
  getCandidate,
  getJobs,
  getJobActive,
  getJobById,
  getCorporate,
  updateJobEmployee,
  resetStoreCandidate,
  exportExcelCandidate,
} from 'redux/actions';
import { useParams, useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

export const EmployeeList = () => {
  const dispatch = useDispatch();
  let query = useQuery();
  //Page
  const [page, setPage] = React.useState(1);
  const handlePage = (data) => {
    setPage(data);
  };

  const { jobs, job, jobActive } = useSelector(
    (state: RootState) => state.jobs,
  );

  const { corporates } = useSelector((state: RootState) => state.corporate);
  const [search, setSearch] = React.useState({
    job_id: job?.job_id || null,
    search_string: '',
    p_order: '1',
    p_skip: '0',
    p_limit: '20',
    p_status: '0',
  });
  // Function setSearch
  const handleSearch = (name, value) => {
    if (typeof name === 'object') {
      setSearch((prevState) => {
        return { ...prevState, ...name };
      });
    } else {
      setSearch({
        ...search,
        [name]: value,
      });
    }
  };

  React.useEffect(() => {
    dispatch(getCandidate(search));
    dispatch(getJobById(search?.job_id || null));
    return () => {
      dispatch(resetStoreCandidate());
    };
  }, [search]);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const fetchJobById = () => {
    if (query.get('job_id') !== null) {
      setSearch({ ...search, job_id: query.get('job_id') });
      dispatch(getCandidate({ job_id: query.get('job_id') }));
      dispatch(getJobById(query.get('job_id')));
    }
  };
  React.useEffect(() => {
    dispatch(getJobActive());
    fetchJobById();
  }, []);

  // useEffect
  React.useEffect(() => {
    dispatch(getCorporate());
  }, []);

  const { candidates, candidate, status } = useSelector(
    (state: RootState) => state.candidate,
  );
  const updateStatus = (data) => {
    let users = {};
    data.p_user_id.forEach(user => {
      if (users[user.job_id]) {
        users[user.job_id]["p_user_id"] = `${users[user.job_id]["p_user_id"]},${user.user_id}` ;
      } else {
        users[user.job_id] = {
          p_user_id: `${user.user_id}`,
          p_job_id: user.job_id,
          p_status: data.p_status,
        }
      }
    });
    dispatch(updateJobEmployee(users, search));
  };

  const getPageTotal = (statusEmp) => {
    switch (statusEmp) {
      case 1:
        return candidates?.total_job?.ung_tuyen;
      case 2:
        return candidates?.total_job?.phong_van;
      case 3:
        return candidates?.total_job?.da_chon;
      case 4:
        return candidates?.total_job?.tu_choi;
      case 5:
        return candidates?.total_job?.da_xoa;
      default:
        return candidates?.total_job?.total;
    }
  };

  const dataExportExcel = useSelector(
    (state) => state['exportExcel'].candidate,
  );
  React.useEffect(() => {
    dispatch(
      exportExcelCandidate({
        ...search,
        p_limit: getPageTotal(parseInt(search.p_status)),
        p_skip: '0',
      }),
    );
  }, [candidates]);
  return (
    <React.Fragment>
      <LF_Breadcumbs
        route={`/admin/job/employee`}
        routeName={'Danh sách ứng viên'}
        className="font-bold text-lg"
      />
      <div style={{ padding: 33 }}>
        <LF_HeaderListEmployee
          title="Chọn tin đăng tuyển"
          button="phỏng vấn"
          // onSearch={onSearch}
          fromData={handleSearch}
          data={jobActive}
          selectData={job}
        />
        <div className="container pt-6">
          <React.Fragment>
            <LF_DataTable
              rows={candidates.data}
              data={job}
              fromSearch={handleSearch}
              type="JobEmployee"
              per_page={candidates?.total_job}
              corporates={corporates}
              onChangeStatusEmp={updateStatus}
              page={page}
              handlePage={handlePage}
              dataExportExcel={dataExportExcel.data}
            />
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};
