import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CareerCodeRepository } from 'services/repositories/careerCode.repository';

const careerCodeRepository = RepositoryFactory.get(
  'careerCode',
) as CareerCodeRepository;

const fetchCareerCode = async (career_code: string) => {
  const stringArr = career_code.split(',');
  const result = [];
  if (stringArr.length > 0) {
    for (const item of stringArr) {
      const res = await careerCodeRepository.getCareerCode(item);
      if (res) {
        result.push(res);
      }
    }
  }
  if (result.length > 0) {
    return result;
  }
};

function* handlerCareerCode(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const careerCodes = yield call(fetchCareerCode, action.payload);

    yield put({ type: types.GET_CAREER_CODE_SUCCESS, payload: careerCodes });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    // yield put({
    //   type: types.GET_CAREER_CODE_ERROR,
    //   payload: err.response.data,
    // });
    // yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchCareerCode() {
  yield takeLatest(types.GET_CAREER_CODE_REQUEST, handlerCareerCode);
}
