import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CareerGroupRepository } from '../../../services/repositories/careerGroup.repository';

//Repository
const careerGrRepository: CareerGroupRepository = RepositoryFactory.get(
  'career-group',
) as CareerGroupRepository;

//Async Fetch
const fetchCareers = async (query: string) => {
  const response = await careerGrRepository.search(query);

  return response;
};

function* handlerCareers(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const careers = yield call(fetchCareers, action.payload);

    yield put({ type: types.SEARCH_CAREER_GR_SUCCESS, payload: careers });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.SEARCH_CAREER_GR_ERROR,
      payload: err.response.data,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchSearchCareersGr() {
  yield takeLatest(types.SEARCH_CAREER_GR_REQUEST, handlerCareers);
}
