import { call, put, takeLatest } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { EmployeeRepository } from 'services/repositories/employee.repository';
import { AlertType, showNotification } from 'utils/commons/Alert';
import * as types from '../../type';

const employeeRepository: EmployeeRepository = RepositoryFactory.get(
  'emp',
) as EmployeeRepository;

const fetchForgetPass = async (data) => {
  const response = await employeeRepository.forgetPass(data);

  return response;
};

function* forgetPass(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const change = yield call(fetchForgetPass, action.payload);
    if (change.status !== 403 || change.status !== 400) {
      yield put({
        type: types.FORGET_PASSWORD_SUCCESS,
        payload: {
          data: change,
          message:
            'Yêu cầu lấy lại mật khẩu thành công. Vui lòng kiểm tra Email',
        },
      });
      //   showNotification({
      //     type: AlertType.SUCCESS,
      //     msg: 'Yêu cầu lấy lại mật khẩu thành công',
      //   });
      yield put({ type: types.LOADING_SUCCESS, payload: false });
    }
    if (change.status === 403 || change.status === 400) {
      yield put({
        type: types.FORGET_PASSWORD_ERROR,
        payload: {
          data: change,
          message: 'Email không tồn tại',
        },
      });
      //   showNotification({
      //     type: AlertType.ERROR,
      //     msg: 'Cập nhật thất bại! Vui lòng kiểm tra mật khẩu cũ!',
      //   });
      yield put({ type: types.LOADING_SUCCESS, payload: false });
    }
  } catch (err) {}
}

export default function* watchForgetPass() {
  yield takeLatest(types.FORGET_PASSWORD_REQUEST, forgetPass);
}
