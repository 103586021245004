import React from 'react';
import {
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { LF_StatusRecuit } from 'components/atoms/Status/StatusRecuit.atom';
import { useHistory } from 'react-router-dom';

//csv
import { CSVLink } from 'react-csv';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      '&:focus': {
        outline: 'none',
      },
    },
  },
  buttonTab: {
    backgroundColor: 'white !improtant',
    color: '#000000 !important',
    fontWeight: 'bold',
    fontSize: '14px',
    minWidth: 100,
    textTransform: 'none',
    padding: '0 12px',
    opacity: 1,
    '&:focus': {
      outline: '2px solid transparent',
      outlineOffset: '2px',
      backgroundColor: '#B50829 !important',
      color: 'white !important',
    },
    '&:active': {
      backgroundColor: '#B50829 !important',
      color: 'white !important',
    },
  },
  Roboto: {
    fontFamily: 'Roboto',
  },
}));

export const LF_CourseMember = ({
  rows,
  per_page,
  fromSearch,
  onDelete,
  onFilter,
  dataExportExcel,
}) => {
  const history = useHistory();

  const [dataRow, setDataRow] = React.useState([]);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [title, setTitle] = React.useState(false);

  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    let a = (value - 1) * 20;
    fromSearch('skip', a);
  };

  React.useEffect(() => {
    if (rows) {
      setDataRow(rows);
    } else {
      setDataRow([]);
    }
  }, [rows]);

  const [statusEmp, setStatusEmp] = React.useState(0);

  //View HTML
  const titleTab = (label, totalData) => {
    return (
      <React.Fragment>
        <span className="flex items-center">
          <span>{label}</span>
          <span className="pl-2">{totalData}</span>
        </span>
      </React.Fragment>
    );
  };

  const getPageTotal = () => {
    switch (statusEmp) {
      case 1:
        return per_page?.cho_duyet;
      case 2:
        return per_page?.dang_ht;
      case 3:
        return per_page?.tam_dung;
      case 4:
        return per_page?.het_han;
      case 5:
        return per_page?.da_xoa;
      default:
        return per_page?.total;
    }
  };

  //Export Excel
  const headers = [
    { label: 'Mã tin', key: 'course_id' },
    { label: 'Tiêu đề', key: 'course_name' },
    { label: 'Ngày hết hạn', key: 'end_date' },
    { label: 'Đã xem', key: 'view' },
    { label: 'Ứng tuyển', key: 'applied' },
    { label: 'Trạng thái', key: 'statusName' },
  ];

  return (
    <div className={"w-full"}>
      <div
        className="text-right uppercase"
        style={{
          color: '#B50829',
        }}
      >
        <CSVLink
          data={dataExportExcel}
          headers={headers}
          filename={'danh-sach-khoa-hoc.csv'}
        >
          {' '}
          Xuất ra Excel
        </CSVLink>
      </div>
      <TableContainer className="pt-7">
        <Tabs
          value={value}
          onChange={handleChangeTabs}
          style={{ width: '100%', border: ' 1px solid rgba(0, 0, 0, 0.25)', minWidth: '500px' }}
        >
          <Tab
            label={titleTab('Tất cả', per_page?.total)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '20%' }}
            onClick={() => {
              fromSearch('status', 0);
            }}
          />
          <Tab
            label={titleTab('Chờ duyệt', per_page?.cho_duyet)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '20%' }}
            onClick={() => {
              fromSearch('status', 1);
              setStatusEmp(1);
            }}
          />
          <Tab
            label={titleTab('Đang hoạt động', per_page?.dang_ht)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch('status', 2);
              setStatusEmp(2);
            }}
          />
          <Tab
            label={titleTab('Tạm dừng', per_page?.tam_dung)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch('status', 3);
              setStatusEmp(3);
            }}
          />
          <Tab
            label={titleTab('Hết hạn', per_page?.het_han)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch('status', 4);
              setStatusEmp(4);
            }}
          />
          <Tab
            label={titleTab('Đã xóa', per_page?.da_xoa)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch('status', 5);
              setStatusEmp(5);
            }}
          />
        </Tabs>
        <Table aria-label="simple table" className="border border-solid">
          <TableHead style={{ backgroundColor: '#F4F2FF' }}>
            <TableRow>
              {columns &&
                columns.map((e, key) => {
                  return (
                    <TableCell
                      key={key}
                      style={{
                        width: `${e.width}`,
                        textAlign: 'center',
                      }}
                    >
                      {e.field === 'Tiêu Đề' ? (
                        <>
                          <button
                            className=" focus:outline-none"
                            onClick={() => {
                              setTitle(!title);
                              if (title) {
                                fromSearch('p_order_title', 1);
                              } else {
                                fromSearch('p_order_title', 2);
                              }
                            }}
                          >
                            <span className="font-semibold">
                              {e.field}
                              {title ? (
                                <i className="px-2 fas fa-angle-down text-[#B50829]"></i>
                              ) : (
                                <i className="px-2 fas fa-angle-up text-[#B50829]"></i>
                              )}
                            </span>
                          </button>
                        </>
                      ) : (
                        <span className="font-semibold">{e.field}</span>
                      )}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRow.length > 0 ? (
              dataRow.map((e, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      height="20px"
                      className="items-center"
                      style={{ paddingLeft: '36px' }}
                    >
                      <span>{e.course_id} </span>
                    </TableCell>
                    <TableCell
                      align="left"
                      height="20px"
                      className="items-center"
                      style={{ paddingLeft: '36px' }}
                    >
                      <a
                        href={`/admin/course/edit/${e.course_id}`}
                        className="cursor-pointer"
                      >
                        <span>{e.course_name}</span>
                      </a>
                    </TableCell>
                    <TableCell
                      align="left"
                      height="20px"
                      className="items-center"
                      style={{ textAlign: 'center' }}
                    >
                      <span>{`${('0' + new Date(e.end_date).getDate()).slice(
                        -2,
                      )}/${('0' + (new Date(e.end_date).getMonth() + 1)).slice(
                        -2,
                      )}/${new Date(e.end_date).getFullYear()}`}</span>
                    </TableCell>
                    <TableCell
                      align="left"
                      height="20px"
                      className="items-center "
                      style={{ textAlign: 'center' }}
                    >
                      <span>{e.view}</span>
                    </TableCell>
                    <TableCell
                      align="left"
                      height="20px"
                      className="items-center"
                      style={{ textAlign: 'center' }}
                    >
                      <span>{e.applied}</span>
                    </TableCell>
                    <TableCell
                      align="left"
                      height="20px"
                      width="180px"
                      className="items-center"
                      style={{ textAlign: 'center' }}
                    >
                      <LF_StatusRecuit interview={e.status} type="course" />
                    </TableCell>
                    <TableCell
                      align="left"
                      height="20px"
                      className="items-center text-right"
                    >
                      {e.status === 2 && (
                        <button
                          onClick={() => {
                            history.push(
                              `/admin/course/register?course_id=${e.course_id}`,
                            );
                          }}
                          style={{
                            color: '#B50829',
                          }}
                          className={`px-1 cursor-pointer hover:underline focus:outline-none font-sans text-base`}
                        >
                          Đã đăng ký
                        </button>
                      )}
                      {e.status !== 2 && (
                        <button
                          style={{
                            color: '#B50829',
                          }}
                          className={`px-1 cursor-pointer hover:underline focus:outline-none font-sans text-base `}
                          onClick={(f) => {
                            onDelete(e.course_id);
                          }}
                        >
                          Xóa
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <div className="mx-auto absolute left-1/2 text-xl py-2">
                Chưa có danh sách khóa học
              </div>
            )}
          </TableBody>
        </Table>
        <div className={`${classes.root} flex justify-end`}>
          <Pagination
            count={Math.ceil(getPageTotal() / 20)}
            variant="outlined"
            onChange={handleChangePage}
            shape="rounded"
          />
        </div>
      </TableContainer>
    </div>
  );
};

const columns = [
  {
    field: 'Mã Tin',
    width: '7%',
  },
  {
    field: 'Tiêu Đề',
    width: '25%',
  },
  {
    field: 'Ngày Hết Hạn',
    width: '10%',
  },
  {
    field: 'Xem',
    width: '10%',
  },
  {
    field: 'Ứng Tuyển',
    width: '10%',
  },
  {
    field: 'Trạng Thái',
    width: '14%',
  },
  {
    field: '',
    width: '10%',
  },
];
