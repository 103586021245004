import React, { useEffect, useState } from 'react';
import { LF_DataTable } from '../DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { getIPoint, getIPointHistory, getIPointOrder } from '../../../../redux/actions';
import { RootState } from '../../../../redux/store';
import { formatDate } from '../../../../utils/commons';
import _ from 'lodash';
import { IPointRepository } from '../../../../services/repositories/ipoint.repository';
import { RepositoryFactory } from '../../../../services/repositories';
const ipointRepositoryTotal: IPointRepository = RepositoryFactory.get(
  'ipoint',
) as IPointRepository;

export const CLC_IPointOrder = () => {
  const dispatch = useDispatch();
  const p_corporate_code = localStorage.getItem('corporate_code');

  const [search, setSearch] = useState({
    p_corporate_code: p_corporate_code,
    p_skip: 0,
    p_limit: 20,
  });
  const [page, setPage] = React.useState(1);
  const handlePage = (data) => {
    setPage(data);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };
  const handleSearch = async (name, value) => {
    setSearch({ ...search, [name]: value });
  };
  useEffect(() => {
    dispatch(getIPointOrder(search));
  }, [search]);
  const { ipoint } = useSelector((state: RootState) => state);
  return (
    <div className={'w-full p-5'}>
      <h3 className={'mt-3 text-xl font-bold '}>LỊCH SỬ MUA iPOINT</h3>
      <LF_DataTable
        rows={ipoint?.ipointOrder?.data}
        type='IPointOrder'
        per_page={String(ipoint?.ipointOrder?.total)}
        handlePage={handlePage}
        fromSearch={handleSearch}
        page={page}
      />
    </div>
  )
}