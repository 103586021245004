import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CoursesRepository } from 'services/repositories/course.repository';

//Repository
const courseRepository = RepositoryFactory.get('course') as CoursesRepository;

const callApiCreateCourse = async (data) => {
  const response = await courseRepository.create(data?.submitValues);
  const id = response[Object.keys(response)[0]];
  if (data.submitFile?.p_doc_name) {
    await courseRepository.uploadCourse(id, data?.submitFile);
  }

  return id;
};

function* handlerCreateCourse(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const CourseID = yield call(callApiCreateCourse, action.payload);
    yield put({ type: types.CREATE_COURSES_SUCCESS, payload: CourseID });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.CREATE_COURSES_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchCreateCourse() {
  yield takeLatest(types.CREATE_COURSES_REQUEST, handlerCreateCourse);
}
