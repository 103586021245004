/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useHistory } from 'react-router-dom';
import ihrLogo from 'assets/images/ihr_logo.png';
import LogoiHR from './../../../assets/images/Logo_rounded_512.png';

//Context
import { useAuth } from './../../wrapper/Auth.wrapper';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Action
import { forgetPassword, clearStoreEmp } from 'redux/actions/employee.action';

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state['employee']);
  const history = useHistory();
  const [form, setForm] = React.useState({ email: '' });

  const [valid, setValid] = React.useState({ email: '' });
  const [notice, setNotice] = React.useState(status);

  //Effect
  React.useEffect(() => {
    if (status?.message) {
      setNotice(status);
    }

    return () => {
      dispatch(clearStoreEmp());
    };
  }, [status]);

  //Hander Action
  const onValidation = (data) => {
    let validation = { ...valid };
    let check = true;
    if (data.email === '') {
      validation.email = 'Email không được để trống';
      check = false;
    } else if (!/[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}/gim.test(data.email)) {
      validation.email = 'Email không hợp lệ';
      check = false;
    } else {
      validation.email = '';
    }

    //End
    if (check) {
      setValid({ email: '' });
      return true;
    } else {
      setValid(validation);
      return false;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const result = onValidation(form);
    if (result) {
      dispatch(forgetPassword(form));
    }
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  return (
    <React.Fragment>
      <div className="w-full min-h-screen">
        <div
          className="mx-auto flex flex-col items-center justify-center min-h-screen h-auto"
          style={{ background: '#E5E5E5' }}
        >
          <div className="relative flex flex-col items-center pb-4">
            <img src={LogoiHR} alt="..." className="h-28 w-28" />
          </div>
          <div
            className="w-11/12 relative flex flex-col items-center bg-white rounded-xl border border-gray-500 border-solid pb-6 lg:max-w-[400px] lg:min-h-[512px]"
          // style={{ minHeight: ' 415px', width: '545px' }}
          >
            <div className="px-16 py-10">
              <p className=" normal-case text-center  font-normal text-4xl">
                Khôi phục mật khẩu
              </p>
              <p className=" normal-case  font-normal text-sm text-black text-opacity-50 pt-4 text-center">
                Vui lòng nhập địa chỉ email mà bạn dùng để đăng ký tài khoản,
                chúng tôi sẽ gửi thông tin khôi phục cho bạn
              </p>
              <p
                className="text-sm text-center pt-2"
                style={{ color: '#B50829' }}
              >
                {notice?.message !== '' ? notice?.message : ''}
              </p>
            </div>
            <form
              action=""
              className=" flex flex-col w-[75%] lg:w-[85%]"
            //   onSubmit={onSubmitLogin}
            >
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-base font-bold mb-2 "
                  htmlFor="username"
                >
                  Email <span style={{ color: '#B50829' }}>*</span>
                </label>
                <input
                  className=" p-3 appearance-none border border-gray-400 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  name="email"
                  type="text"
                  onChange={(e) => onChange(e)}
                  placeholder="Email"
                />
                <span className="text-sm" style={{ color: '#B50829' }}>
                  {valid.email !== '' ? valid.email : ''}
                </span>
              </div>

              <div className="mt-12 text-center">
                <button
                  type="submit"
                  className=" bg-[#B50829] uppercase text-[18px] text-white px-3  w-3/4 rounded py-2  focus:outline-none"
                  onClick={(e) => onSubmit(e)}
                >
                  GỬI YÊU CẦU
                </button>
              </div>
              <div className="mt-6 text-center">
                <a
                  href="#"
                  className="font-bold text-sm  hover:text-[#B50829] cursor-pointer text-black text-opacity-75"
                  onClick={() => {
                    history.push('/auth/login');
                  }}
                >
                  Đăng nhập hệ thống
                </a>
              </div>
            </form>
          </div>
          <div
            className={
              ' flex flex-col lg:flex-row justify-center text-black text-opacity-75 text-sm lg:min-w-[400px] text-center'
            }
          >
            <div className="p-3">
              <a href="#">Trợ giúp</a>
            </div>
            <div className="p-3">
              <a href="#">Chính sách bảo mật</a>
            </div>
            <div className="p-3">
              <a href="#">Điều khoản sử dụng</a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
