import React from 'react';

type LF_ModalProps = {
  className: string;
  header: string;
  children: any;
  closeHandler: any;
  color: string;
  btns: {
    label: string; //Button label
    disabled?: boolean;
    className?: string; // Customing that button
    action?: any; //Whenever user click on this button, trigger action()
    closeAfterClicked?: boolean; //After the btn is clicked => Close the modal
  }[];
  state: boolean;
};

export const LF_Modal = ({
  className = '',
  header,
  children,
  color = 'light',
  btns,
  closeHandler,
}: Partial<LF_ModalProps>) => {
  return (
    <React.Fragment>
      <div
        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-0 w-screen h-screen right-0 z-50 outline-none focus:outline-none `}
      >
        <div className={`relative w-auto my-6 mx-auto ${className}`}>
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full">
            {/*header*/}
            <div>
              <button
                className="hover:text-red leading-3 bg-transparent border-0 text-black float-right text-xl font-semibold outline-none focus:outline-none"
                onClick={() => closeHandler(false)}
                style={{ padding: '10px' }}
              >
                <span
                  className="bg-transparent text-black text-xl block outline-none focus:outline-none leading-3 hover:text-red"
                  onClick={() => closeHandler(false)}
                >
                  ×
                </span>
              </button>
            </div>
            <div className="flex justify-between items-center px-4 py-2 border-b border-solid border-gray-300 rounded-t">
              <h3 className="text-xl font-normal">{header}</h3>
            </div>
            {/*body*/}
            {children}
            {/*footer*/}
            <div className="flex items-center justify-end px-4 py-2 border-t border-solid border-gray-300 text-black-500 rounded-b">
              {btns.map((btn, index) => (
                <button
                  key={index}
                  className={`
                      font-bold uppercase px-6 py-2 text-sm outline-none
                      focus:outline-none mr-1 mb-1 ease-linear transition-all
                      duration-150 rounded ${
                        btn.disabled &&
                        'bg-gray-600 text-white opacity-40 cursor-not-allowed'
                      } ${btn.className || ''}`}
                  type="button"
                  onClick={() => {
                    if (btn.action) btn.action();
                    if (btn.closeAfterClicked) closeHandler(false);
                  }}
                  disabled={btn.disabled}
                >
                  {btn.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </React.Fragment>
  );
};
