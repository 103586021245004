import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import Cancel from '../Icon/Cancel';
import CheckCircle from '../Icon/CheckCircle';
import ipointFormat from "../../../utils/commons/math/ipointFormat";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const table = [
  {
    'information': 'Hình thức hiển thị nội dung tin đăng. (Gói VIP cao sẽ luôn nằm trên gói thấp hơn)',
    'free': 'Tiêu đề bằng chữ thường',
    'default': 'Tiêu đề bằng chữ thường',
    'vip1': 'Tiêu đề bằng chữ thường in đậm',
    'vip2': 'Tiêu đề bằng chữ thường in đậm',
    'vip3': 'Tiêu đề bằng chữ hoa in đậm màu đen',
  },
  {
    'information': 'Xuất hiện trong mục Việc làm HOT trên trang chủ',
    'free': <Cancel/>,
    'default': <Cancel/>,
    'vip1': <Cancel/>,
    'vip2': <Cancel/>,
    'vip3': <Cancel/>,
  },
  {
    'information': 'Xuất hiện trên cùng trong mục Việc làm gợi ý',
    'free': <Cancel/>,
    'default': <CheckCircle/>,
    'vip1': <CheckCircle/>,
    'vip2': <CheckCircle/>,
    'vip3': <CheckCircle/>,
  },
  {
    'information': 'Xuất hiện trên cùng trong các lĩnh vực việc làm',
    'free': <Cancel/>,
    'default': <CheckCircle/>,
    'vip1': <CheckCircle/>,
    'vip2': <CheckCircle/>,
    'vip3': <CheckCircle/>,
  },
  {
    'information': 'Xuất hiện trong kết quả tìm kiếm theo thứ tự các gói VIP',
    'free': <CheckCircle/>,
    'default': <CheckCircle/>,
    'vip1': <CheckCircle/>,
    'vip2': <CheckCircle/>,
    'vip3': <CheckCircle/>,
  },
  {
    'information': 'Đưa vào "EMAIL GIỚI THIỆU JOB PHÙ HỢP" gửi cho ứng viên hàng tuần',
    'free': <CheckCircle/>,
    'default': <CheckCircle/>,
    'vip1': <CheckCircle/>,
    'vip2': <CheckCircle/>,
    'vip3': <CheckCircle/>,
  },
  {
    'information': 'Hỗ trợ đăng tin tuyển dụng trên Fanpage của i-HR',
    'free': <Cancel/>,
    'default': <Cancel/>,
    'vip1': <CheckCircle/>,
    'vip2': <CheckCircle/>,
    'vip3': <CheckCircle/>,
  },
  {
    'information': 'Hỗ trợ CHẠY QUẢNG CÁO FACEBOOK dưới danh nghĩa của i-HR ',
    'free': <Cancel/>,
    'default': <Cancel/>,
    'vip1': <Cancel/>,
    'vip2': <Cancel/>,
    'vip3': <Cancel/>,
  },
  {
    'information': 'Gửi thông báo trên mobile (PUSH NOTIFICATION) đến ứng viên PHÙ HỢP trên ứng dụng i-HR. Chỉ áp dụng cho gói 30 ngày. Gói VIP cao sẽ nhận được số lượng CV phù hợp ',
    'free': <Cancel/>,
    'default': <Cancel/>,
    'vip1': <CheckCircle/>,
    'vip2': <CheckCircle/>,
    'vip3': <CheckCircle/>,
  },
]
export default function TableListServices() {
  const classes = useStyles();
  const { packageVip } = useSelector((state: RootState) => state);
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Tên gói</TableCell>
            <TableCell align="left">Miễn phí</TableCell>
            <TableCell align="left">Tiêu chuẩn</TableCell>
            <TableCell align="left">VIP 1</TableCell>
            <TableCell align="left">VIP 2</TableCell>
            <TableCell align="left">VIP 3</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            packageVip ? Object.keys(packageVip.packageVip).map((e, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>Giá dịch vụ đăng tin {e}</TableCell>
                  {
                    packageVip.packageVip[e].map((item, i) => {
                      return <TableCell key={i}>{ipointFormat(item.ipoint * 1000)} đ</TableCell>
                    })
                  }
                </TableRow>
              );
            }) : ""
          }
          {
            table.map((e, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{e.information}</TableCell>
                  <TableCell align={`${index !==0 ? 'center' : 'left'}`}>{e.free}</TableCell>
                  <TableCell align={`${index !==0 ? 'center' : 'left'}`}>{e.default}</TableCell>
                  <TableCell align={`${index !==0 ? 'center' : 'left'}`}>{e.vip1}</TableCell>
                  <TableCell align={`${index !==0 ? 'center' : 'left'}`}>{e.vip2}</TableCell>
                  <TableCell align={`${index !==0 ? 'center' : 'left'}`}>{e.vip3}</TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}