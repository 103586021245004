import { EmployeeInterface } from 'utils/interfaces/employee.interface';
import { BaseRepository } from './base';
import Repository from './base/repository';

export class EmployeeRepository extends BaseRepository<EmployeeInterface> {
  resource = 'employees';
  token = localStorage.getItem('access-token');

  async updateEmployee(data) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.put(`${this.resource}`, data, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }
  async changePass(data) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.put(`${this.resource}/change-password`, data, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }
  async forgetPass(data) {
    const response = await Repository.post(
      `${this.resource}/request-reset-password`,
      data,
    );
    return response.data;
  }
  async resetPass(data) {
    const response = await Repository.put(
      `${this.resource}/reset-password`,
      data,
    );
    return response.data;
  }
  async checkToken(data) {
    const response = await Repository.post(
      `${this.resource}/check-token`,
      data,
    );
    return response.data;
  }
}
