/* eslint-disable react/jsx-pascal-case */
import React from 'react';

// Components
import { LF_Breadcumbs, LF_ConfirmModal } from 'components/molecules';
import { RecruitmentList } from 'components/organisms/Modules/Recruitment';
import { LF_DataTable } from 'components/organisms';

// React- Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { deleteJob, exportExcelJob, getJobs, restoreJob } from 'redux/actions';
import { useHistory } from 'react-router-dom';

export const Recruitment = () => {
  //React- Redux
  const dispatch = useDispatch();
  const history = useHistory();
  const { jobs, job, status } = useSelector((state: RootState) => state.jobs);
  const dataExcel = useSelector((state) => state['exportExcel'].job);
  //Page
  const [page, setPage] = React.useState(1);
  const handlePage = (data) => {
    setPage(data);
  };
  //State
  const [deleteItem, setDeleteItem] = React.useState(undefined);
  const [restoreItem, setRestoreItem] = React.useState(undefined);
  const [search, setSearch] = React.useState({
    start_date: '1970-1-1',
    end_date: '2100-1-1',
    status: 0,
    skip: 0,
    limit: 20,
    search_string: '',
    p_order_title: 8,
  });
  const getPageTotal = (statusEmp) => {
    switch (statusEmp) {
      case 1:
        return jobs?.total_job?.cho_duyet;
      case 2:
        return jobs?.total_job?.dang_ht;
      case 5:
        return jobs?.total_job?.het_han;
      case 6:
        return jobs?.total_job?.da_xoa;
      default:
        return jobs?.total_job?.total;
    }
  };

  // Function setSearch
  const handleSearch = (name, value) => {
    if (typeof name === 'object') {
      setSearch((prevState) => {
        return { ...prevState, ...name };
      });
    } else {
      setSearch({
        ...search,
        [name]: value,
      });
    }
  };

  // useEffect
  React.useEffect(() => {
    dispatch(getJobs(search));
  }, [search]);

  React.useEffect(() => {
    dispatch(getJobs(search));
  }, [status]);
  React.useEffect(() => {
    dispatch(
      exportExcelJob({
        ...search,
        limit: getPageTotal(search.status),
        skip: 0,
      }),
    );
  }, [jobs]);
  // Handler Action
  const handleClick = () => {
    history.push('/admin/job/post');
  };

  const handleDelete = (id: string) => {
    dispatch(deleteJob(id));
  };

  const handlerDeleteFunc = (value) => {
    const state = value;
    setDeleteItem(state);
  };

  const onRestore = (id: string) => {
    dispatch(restoreJob(id));
  };

  const handlerRestoreFunc = (value) => {
    const state = value;
    setRestoreItem(state);
  };
  return (
    <React.Fragment>
      {/* Confirm Delete */}
      <LF_ConfirmModal
        open={deleteItem !== undefined}
        title="Bạn xác nhận xóa tin tuyển dụng này?"
        onProceed={() => {
          if (deleteItem) {
            handleDelete(deleteItem);
            setDeleteItem(undefined);
          }
        }}
        onCancel={() => setDeleteItem(undefined)}
      />
      <LF_ConfirmModal
        open={restoreItem !== undefined}
        title="Bạn xác nhận khôi phục tin tuyển dụng này?"
        onProceed={() => {
          if (restoreItem) {
            onRestore(restoreItem);
            setRestoreItem(undefined);
          }
        }}
        onCancel={() => setRestoreItem(undefined)}
      />

      <LF_Breadcumbs
        route={`/admin/job/recruitment`}
        routeName={'Danh sách tuyển dụng'}
        className="font-bold text-lg"
      />
      {/* Search */}
      <RecruitmentList onClick={handleClick} fromSearch={handleSearch} />

      {/* Data Table */}
      <div
        className=" flex flex-row items-center w-full bg-white"
        style={{
          padding: '10px 15px 10px 15px',
        }}
      >
        <LF_DataTable
          rows={jobs.data}
          per_page={jobs.total_job}
          type="JobRecruitment"
          fromSearch={handleSearch}
          onDelete={handlerDeleteFunc}
          onRestore={handlerRestoreFunc}
          page={page}
          handlePage={handlePage}
          dataExportExcel={dataExcel && dataExcel}
        />
      </div>
    </React.Fragment>
  );
};
