/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react';

import { LF_Breadcumbs, LF_ConfirmModal } from 'components/molecules';
import { LF_DataTable } from 'components/organisms';
import { LF_FindEmployee } from '../../../../../components/organisms';
import { RootState } from 'redux/store';
import { getMatchCandidateSearch, getCorporate, viewProfile, getIPoint } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { set } from "lodash";
import { TITLE } from "../../../../../constants";


export const SearchEmployee = () => {
  const dispatch = useDispatch();
  const candidate = useSelector((state: RootState) => state.candidate);
  const { corporates, corporate, status } = useSelector((state: RootState) => state.corporate);
  const { ipoint } = useSelector((state: RootState) => state.ipoint);
  const { loading } = useSelector((state) => state['loading']);
  const [showTable, setShowTable] = React.useState(false);
  //Page
  const [per_page, setPer_page] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [openModal, setOpenModal] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [title, setTitle] = React.useState(TITLE[0]);
  const handlePage = (data) => {
    setPage(data);
  };
  const onSearch = (data) => {
    dispatch(getMatchCandidateSearch(data));
    setShowTable(true);
  };

  const [search, setSearch] = React.useState({
    p_careers_code: '',
    p_skills: '',
    p_provinces: '',
    p_degrees: '',
    p_gender: 0,
    p_discharged_army: 0,
    p_min_salary: 0,
    p_max_salary: 0,
    p_from_age: 0,
    p_to_age: 0,
    p_heightFrom: 0,
    p_heightTo: 0,
    p_weightFrom: 0,
    p_weightTo: 0,
    p_eyes: 0,
    p_chung_nhan: '',
    p_skip: '0',
    p_limit: '20',
  });

  // Function setSearch
  const handleSearch = (name, value) => {
    if (typeof name === 'object') {
      setSearch((prevState) => {
        return { ...prevState, ...name };
      });
      dispatch(getMatchCandidateSearch(name));
    } else {
      setSearch({
        ...search,
        [name]: value,
      });
      dispatch(getMatchCandidateSearch({ ...search, [name]: value }));
    }
  };
  const seeCandidate = (user) => {
    if (ipoint?.ipoint < user?.ipoint) {
      setTitle(TITLE[1]);
      setUser(user);
      setOpenModal(true);
      return;
    } else {
      TITLE[0].title = `Để xem thông tin của ứng viên này bạn cần tiêu ${user?.ipoint} iPoint Bạn có muốn mở thông tin ứng viên không?`;
      setTitle(TITLE[0])
      setUser(user);
      setOpenModal(true);
    }
  }
  const acceptSee = () => {
    if (ipoint?.ipoint < user?.ipoint) {
      window.location.href = '/admin/ipoint/buy-ipoint';
    } else {
      console.log('user', user);
      dispatch(
        viewProfile(user.user_id, {
          p_corporate_code: corporates && corporates[0].corporate_code,
          p_user_id: user.user_id,
          p_corporate_name: corporates && corporates[0].corporate_name,
          p_corporate_slug: corporates && corporates[0].slug,
          p_image_name: corporates && corporates[0].logo,
          p_user_slug: user.slug,
          p_user_name: `${user.last_name} ${user.first_name}`,
          p_user_email: user.email,
        }),
      );
      setTimeout(() => {
        dispatch(getIPoint(`${corporate.corporate_code}`));
      }, 200);
      setOpenModal(false);
    }
  }
  // React.useEffect(() => {
  //   const a = candidate?.candidateMatch?.total_data;
  //   let total = a[Object.keys(a)[0]];
  // })


  // useEffect
  React.useEffect(() => {
    dispatch(getCorporate());
  }, []);
  //useEffect

  useEffect(() => {
    if (candidate?.candidateMatch?.total_data?.length > 0) {
      const total =
        candidate?.candidateMatch?.total_data[
        Object.keys(candidate?.candidateMatch?.total_data)[0]
        ];
      const totalData = total[Object.keys(total)[0]];
      setPer_page(totalData);
    }
  }, [candidate?.candidateMatch?.total_data]);
  return (
    <React.Fragment>
      <LF_Breadcumbs
        route={`/admin/job/searchEmployee`}
        routeName={'Tìm ứng viên phù hợp'}
        className="font-bold text-lg"
      />
      <section className="p-4 lg:pt-6 lg:pb-4 lg:px-10 ">
        <LF_FindEmployee onSearch={onSearch} fromSearch={handleSearch} />
      </section>
      <div
        className="flex flex-row items-center w-full bg-white mx-auto"
        style={{
          padding: '10px 10px 10px 35px',
        }}
      >
        <React.Fragment>
          <div className="container pt-6">
            <div className="px-2 text-base leading-5">
              Tìm thấy: {per_page && per_page} kết quả phù hợp
            </div>
            <LF_DataTable
              rows={candidate?.candidateMatch?.data}
              fromSearch={handleSearch}
              corporates={corporates}
              per_page={candidate?.candidateMatch?.total_data}
              type="JobSearch"
              loading={loading}
              page={page}
              handlePage={handlePage}
              seeCandidate={seeCandidate}
            />
          </div>
        </React.Fragment>
        <LF_ConfirmModal
          open={openModal}
          title={title.title}
          onProceed={acceptSee}
          onCancel={() => setOpenModal(false)}
        />
      </div>
    </React.Fragment>
  );
};
