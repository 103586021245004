//* Sometype *//
export const LOADING_REQUEST = 'LOADING_REQUEST';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';

//Register
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

//Province
export const PROVINCE_REQUEST = 'PROVINCE_REQUEST';
export const PROVINCE_SUCESS = 'PROVINCE_SUCESS';
export const PROVINCE_ERROR = 'PROVINCE_ERROR';

//Districts
export const DISTRICT_REQUEST = 'DISTRICT_REQUEST';
export const DISTRICT_SUCCESS = 'DISTRICT_SUCCESS';
export const DISTRICT_ERROR = 'DISTRICT_ERROR';

//Commune
export const COMMUNE_REQUEST = 'COMMUNE_REQUEST';
export const COMMUNE_SUCCESS = 'COMMUNE_SUCCESS';
export const COMMUNE_ERROR = 'COMMUNE_ERROR';

//Career - Group
export const SEARCH_CAREER_GR_REQUEST = 'SEARCH_CAREER_GR_REQUEST';
export const SEARCH_CAREER_GR_SUCCESS = 'SEARCH_CAREER_GR_SUCCESS';
export const SEARCH_CAREER_GR_ERROR = 'SEARCH_CAREER_GR_ERROR';

//Career
export const SEARCH_CAREER_REQUEST = 'SEARCH_CAREER_REQUEST';
export const SEARCH_CAREER_SUCCESS = 'SEARCH_CAREER_SUCCESS';
export const SEARCH_CAREER_ERROR = 'SEARCH_CAREER_ERROR';

//Corporate
export const CORPORATE_REQUEST = 'CORPORATE_REQUEST';
export const CORPORATE_SUCCESS = 'CORPORATE_SUCCESS';
export const CORPORATE_ERROR = 'CORPORATE_ERROR';

//Skill
export const SEARCH_SKILL_REQUEST = 'SEARCH_SKILL_REQUEST';
export const SEARCH_SKILL_SUCCESS = 'SEARCH_SKILL_SUCCESS';
export const SEARCH_SKILL_ERROR = 'SEARCH_SKILL_ERROR';

//Address
export const SEARCH_ADDRESS_REQUEST = 'SEARCH_ADDRESS_REQUEST';
export const SEARCH_ADDRESS_SUCCESS = 'SEARCH_ADDRESS_SUCCESS';
export const SEARCH_ADDRESS_ERROR = 'SEARCH_ADDRESS_ERROR';

//Position
export const SEARCH_POSITION_REQUEST = 'SEARCH_POSITION_REQUEST';
export const SEARCH_POSITION_SUCCESS = 'SEARCH_POSITION_SUCCESS';
export const SEARCH_POSITION_ERROR = 'SEARCH_POSITION_ERROR';
export const UPDATE_CORPORATE_REQUEST = 'UPDATE_CORPORATE_REQUEST';
export const UPDATE_CORPORATE_SUCCESS = 'UPDATE_CORPORATE_SUCCESS';
export const UPDATE_CORPORATE_ERROR = 'UPDATE_CORPORATE_ERROR';

//Jobs
export const GET_JOBS_REQUEST = 'GET_JOBS_REQUEST';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_JOBS_ERROR = 'GET_JOBS_ERROR';
export const GET_JOB_BY_ID_REQUEST = 'GET_JOB_BY_ID_REQUEST';
export const GET_JOB_BY_ID_SUCCESS = 'GET_JOB_BY_ID_SUCCESS';
export const GET_JOB_BY_ID_ERROR = 'GET_JOB_BY_ID_ERROR';
export const CREATE_JOB_REQUEST = 'CREATE_JOB_REQUEST';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_ERROR = 'CREATE_JOB_ERROR';
export const UPDATE_JOB_REQUEST = 'UPDATE_JOB_REQUEST';
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_EMPLOYEE_REQUEST = 'UPDATE_JOB_EMPLOYEE_REQUEST';
export const UPDATE_JOB_EMPLOYEE_SUCCESS = 'UPDATE_JOB_EMPLOYEE_SUCCESS';
export const UPDATE_JOB_ERROR = 'UPDATE_JOB_ERROR';
export const DELETE_JOB_REQUEST = 'DELETE_JOB_REQUEST';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const DELETE_JOB_ERROR = 'DELETE_JOB_ERROR';
export const GET_JOB_ACTIVE_REQUEST = 'GET_JOB_ACTIVE_REQUEST';
export const GET_JOB_ACTIVE_SUCCESS = 'GET_JOB_ACTIVE_SUCCESS';
export const GET_JOB_ACTIVE_ERROR = 'GET_JOB_ACTIVE_ERROR';
export const RESTORE_JOB_REQUEST = 'RESTORE_JOB_REQUEST';
export const RESTORE_JOB_SUCCESS = 'RESTORE_JOB_SUCCESS';
export const RESTORE_JOB_ERROR = 'RESTORE_JOB_ERROR';

//Employee
export const EMPLOYEE_REQUEST = 'EMPLOYEE_REQUEST';
export const EMPLOYEE_SUCCESS = 'EMPLOYEE_SUCCESS';
export const EMPLOYEE_ERROR = 'EMPLOYEE_ERROR';
export const UPDATE_EMPLOYEE_REQUEST = 'UPDATE_EMPLOYEE_REQUEST';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_ERROR = 'UPDATE_EMPLOYEE_ERROR';
export const CHANGE_PASS_REQUEST = 'CHANGE_PASS_REQUEST';
export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS';
export const CHANGE_PASS_ERROR = 'CHANGE_PASS_ERROR';

//Course
export const GET_COURSES_REQUEST = 'GET_COURSES_REQUEST';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_ERROR = 'GET_COURSES_ERROR';
export const UPDATE_COURSES_REQUEST = 'UPDATE_COURSES_REQUEST';
export const UPDATE_COURSES_SUCCESS = 'UPDATE_COURSES_SUCCESS';
export const UPDATE_COURSES_ERROR = 'UPDATE_COURSES_ERROR';
export const CREATE_COURSES_REQUEST = 'CREATE_COURSES_REQUEST';
export const CREATE_COURSES_SUCCESS = 'CREATE_COURSES_SUCCESS';
export const CREATE_COURSES_ERROR = 'CREATE_COURSES_ERROR';
export const DELETE_COURSES_REQUEST = 'DELETE_COURSES_REQUEST';
export const DELETE_COURSES_SUCCESS = 'DELETE_COURSES_SUCCESS';
export const DELETE_COURSES_ERROR = 'DELETE_COURSES_ERROR';
export const GET_COURSES_BY_ID_REQUEST = 'GET_COURSES_BY_ID_REQUEST';
export const GET_COURSES_BY_ID_SUCCESS = 'GET_COURSES_BY_ID_SUCCESS';
export const GET_COURSES_BY_ID_ERROR = 'GET_COURSES_BY_ID_ERROR';
export const GET_ACTIVE_COURSE_REQUEST = 'GET_ACTIVE_COURSE_REQUEST';
export const GET_ACTIVE_COURSE_SUCCESS = 'GET_ACTIVE_COURSE_SUCCESS';
export const GET_ACTIVE_COURSE_ERROR = 'GET_ACTIVE_COURSE_ERROR';
export const UPDATE_APPLY_COURSE_REQUEST = 'UPDATE_APPLY_COURSE_REQUEST';
export const UPDATE_APPLY_COURSE_SUCCESS = 'UPDATE_APPLY_COURSE_SUCCESS';
export const UPDATE_APPLY_COURSE_ERROR = 'UPDATE_APPLY_COURSE_ERROR';

// Home Dashboard
export const HOME_REQUEST = 'HOME_REQUEST';
export const HOME_SUCCESS = 'HOME_SUCCESS';
export const HOME_ERROR = 'HOME_ERROR';

// Major
export const SEARCH_MAJOR_REQUEST = 'SEARCH_MAJOR_REQUEST';
export const SEARCH_MAJOR_SUCCESS = 'SEARCH_MAJOR_SUCCESS';
export const SEARCH_MAJOR_ERROR = 'SEARCH_MAJOR_ERROR';

//Candidate
export const GET_CANDIDATE_REQUEST = 'GET_CANDIDATE_REQUEST';
export const GET_CANDIDATE_SUCCESS = 'GET_CANDIDATE_SUCCESS';
export const GET_CANDIDATE_ERROR = 'GET_CANDIDATE_ERROR';
export const GET_STUDENT_BY_ID_REQUEST = 'GET_STUDENT_BY_ID_REQUEST';
export const GET_STUDENT_BY_ID_SUCCESS = 'GET_STUDENT_BY_ID_SUCCESS';
export const GET_STUDENT_BY_ID_ERROR = 'GET_STUDENT_BY_ID_ERROR';
export const GET_MATCHCANDIDATESEARCH_REQUEST =
  'GET_MATCHCANDIDATESEARCH_REQUEST';
export const GET_MATCHCANDIDATESEARCH_SUCCESS =
  'GET_MATCHCANDIDATESEARCH_SUCCESS';
export const GET_MATCHCANDIDATESEARCH_ERROR = 'GET_MATCHCANDIDATESEARCH_ERROR';
export const POST_USER_VIEW_REQUEST = 'POST_USER_VIEW_REQUEST';
export const POST_USER_VIEW_SUCCESS = 'POST_USER_VIEW_SUCCESS';
export const POST_USER_VIEW_ERROR = 'POST_USER_VIEW_ERROR';

//UPLOAD IMAGE
export const UPLOAD_MEDIA_REQUEST = 'UPLOAD_MEDIA_REQUEST';
export const UPLOAD_MEDIA_SUCCESS = 'UPLOAD_MEDIA_SUCCESS';
export const UPLOAD_MEDIA_ERROR = 'UPLOAD_MEDIA_ERROR';

//Reset Store
export const RESET_STORE_CANDIDATE = 'RESET_STORE_CANDIDATE';
export const RESET_STORE_EMPLOYEE = 'RESET_STORE_EMPLOYEE';
export const RESET_STORE_COURSE = 'RESET_STORE_COURSE';

//Reset Password
export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';
export const CHECK_RESET_PASSWORD_REQUEST = 'CHECK_RESET_PASSWORD_REQUEST';
export const CHECK_RESET_PASSWORD_SUCCESS = 'CHECK_RESET_PASSWORD_SUCCESS';
export const CHECK_RESET_PASSWORD_ERROR = 'CHECK_RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

//Career Code
export const GET_CAREER_CODE_REQUEST = 'GET_CAREER_CODE_REQUEST';
export const GET_CAREER_CODE_SUCCESS = 'GET_CAREER_CODE_SUCCESS';
export const GET_CAREER_CODE_ERROR = 'GET_CAREER_CODE_ERROR';

//Trainee
export const GET_TRAINEE_REQUEST = 'GET_TRAINEE_REQUEST';
export const GET_TRAINEE_SUCCESS = 'GET_TRAINEE_SUCCESS';
export const GET_TRAINEE_ERROR = 'GET_TRAINEE_ERROR';
export const POST_VIEW_TRAINEE_REQUEST = 'POST_VIEW_TRAINEE_REQUEST';
export const POST_VIEW_TRAINEE_SUCCESS = 'POST_VIEW_TRAINEE_SUCCESS';
export const POST_VIEW_TRAINEE_ERROR = 'POST_VIEW_TRAINEE_ERROR';

//Export Excel
export const EXPORT_EXCEL_JOB_REQUEST = 'EXPORT_EXCEL_JOB_REQUEST';
export const EXPORT_EXCEL_JOB_SUCCESS = 'EXPORT_EXCEL_JOB_SUCCESS';
export const EXPORT_EXCEL_JOB_ERROR = 'EXPORT_EXCEL_JOB_ERROR';
export const EXPORT_EXCEL_COURSE_REQUEST = 'EXPORT_EXCEL_COURSE_REQUEST';
export const EXPORT_EXCEL_COURSE_SUCCESS = 'EXPORT_EXCEL_COURSE_SUCCESS';
export const EXPORT_EXCEL_COURSE_ERROR = 'EXPORT_EXCEL_COURSE_ERROR';
export const EXPORT_EXCEL_CANDIDATE_REQUEST = 'EXPORT_EXCEL_CANDIDATE_REQUEST';
export const EXPORT_EXCEL_CANDIDATE_SUCCESS = 'EXPORT_EXCEL_CANDIDATE_SUCCESS';
export const EXPORT_EXCEL_CANDIDATE_ERROR = 'EXPORT_EXCEL_CANDIDATE_ERROR';
export const EXPORT_EXCEL_STUDENT_REQUEST = 'EXPORT_EXCEL_STUDENT_REQUEST';
export const EXPORT_EXCEL_STUDENT_SUCCESS = 'EXPORT_EXCEL_STUDENT_SUCCESS';
export const EXPORT_EXCEL_STUDENT_ERROR = 'EXPORT_EXCEL_STUDENT_ERROR';

//ipoint Package
export const GET_PACKAGES_ERROR = 'GET_PACKAGES_ERROR';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_REQUEST = 'GET_PACKAGES_REQUEST';

export const GET_PACKAGES_TIME_ERROR = 'GET_PACKAGES_TIME_ERROR';
export const GET_PACKAGES_TIME_SUCCESS = 'GET_PACKAGES_TIME_SUCCESS';
export const GET_PACKAGES_TIME_REQUEST = 'GET_PACKAGES_TIME_REQUEST';

export const GET_PACKAGE_BUY_ERROR = 'GET_PACKAGE_BUY_ERROR';
export const GET_PACKAGE_BUY_SUCCESS = 'GET_PACKAGE_BUY_SUCCESS';
export const GET_PACKAGE_BUY_REQUEST = 'GET_PACKAGE_BUY_REQUEST';

export const GET_IPOINT_TOTAL_ERROR = 'GET_IPOINT_TOTAL_ERROR';
export const GET_IPOINT_TOTAL_SUCCESS = 'GET_IPOINT_TOTAL_SUCCESS';
export const GET_IPOINT_TOTAL_REQUEST = 'GET_IPOINT_TOTAL_REQUEST';

export const GET_IPOINT_HISTORY_ERROR = 'GET_IPOINT_HISTORY_ERROR';
export const GET_IPOINT_HISTORY_SUCCESS = 'GET_IPOINT_HISTORY_SUCCESS';
export const GET_IPOINT_HISTORY_REQUEST = 'GET_IPOINT_HISTORY_REQUEST';

export const GET_VIP_PACKAGES_ERROR = 'GET_VIP_PACKAGES_ERROR';
export const GET_VIP_PACKAGES_SUCCESS = 'GET_VIP_PACKAGES_SUCCESS';
export const GET_VIP_PACKAGES_REQUEST = 'GET_VIP_PACKAGES_REQUEST';

export const GET_OLD_PACKAGES_ERROR = 'GET_OLD_PACKAGES_ERROR';
export const GET_OLD_PACKAGES_SUCCESS = 'GET_OLD_PACKAGES_SUCCESS';
export const GET_OLD_PACKAGES_REQUEST = 'GET_OLD_PACKAGES_REQUEST';

export const GET_IPOINT_ORDER_ERROR = 'GET_IPOINT_ORDER_ERROR';
export const GET_IPOINT_ORDER_SUCCESS = 'GET_IPOINT_ORDER_SUCCESS';
export const GET_IPOINT_ORDER_REQUEST = 'GET_IPOINT_ORDER_REQUEST';

