import { IAddress } from 'utils/interfaces';
import { BaseRepository } from './base';
import Repository from './base/repository';

export class AddressRepository extends BaseRepository<IAddress> {
  resource = 'address';
  token = localStorage.getItem('access-token');

  async search(query: string) {
    return (
      await Repository.get(`${this.resource}/search?q=${query}`, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }
}
