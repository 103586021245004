import AuthenticationServices from "./token.authentication";

const Authenticate = (function () {
  let instance;

  function createInstance() {
    var object = new AuthenticationServices();
    return object;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default Authenticate.getInstance();
