import { makeStyles } from '@material-ui/core';

export const TagMaker = makeStyles({
  tag: {
    width: '130px',
    height: '32px',
    fontSize: '14px',
    lineHeight: '17px',
    borderRadius: '9999px',
    fontFamily: 'Roboto',
  },
  tagGreen: {
    color: '#ffffff',
    backgroundColor: '#01A930',
    '&:hover': {
      backgroundColor: '#01A930',
    },
  },
  tagBlue: {
    color: '#ffffff',
    backgroundColor: '#4A4AFF',
    '&:hover': {
      backgroundColor: '#4A4AFF',
    },
  },
  tagRed: {
    color: '#ffffff',
    backgroundColor: '#B50829',
    '&:hover': {
      backgroundColor: '#B50829',
    },
  },
  tagBlack: {
    color: '#ffffff !important',
    backgroundColor: '#000000 !important',
    '&:hover': {
      backgroundColor: '#000000 !important',
    },
  },
  tagGary: {
    color: '#ffffff !important',
    backgroundColor: '#808080 !important',
    '&:hover': {
      backgroundColor: '#808080 !important',
    },
  },
  tagOrg: {
    color: '#ffffff !important',
    backgroundColor: '#B50829 !important',
    '&:hover': {
      backgroundColor: '#B50829 !important',
    },
  },
});
