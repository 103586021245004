import * as types from '../../type';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { IPointPackageRepository } from '../../../services/repositories/ipointPackage.repository';

//Repository
const ipointRepository: IPointPackageRepository = RepositoryFactory.get(
  'package',
) as IPointPackageRepository;

const fetchPackageTime = async () => {
  const response = await ipointRepository.getIPointPackageTime();
  return response;
};

function* handlerPackageTime(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const packageTime = yield call(fetchPackageTime);
    yield put({ type: types.GET_PACKAGES_TIME_SUCCESS, payload: packageTime });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.GET_PACKAGES_TIME_ERROR,
      payload: err.response,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetPackageTime() {
  yield takeLatest(types.GET_PACKAGES_TIME_REQUEST, handlerPackageTime);
}