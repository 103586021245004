import Repository from './repository';

export class BaseRepository<T> {
  // @var BaseRepository
  // @return Methods
  // @author Hoàng Duy <duy.hoang@lofivn.com>
  resource: string;
  token: string = localStorage.getItem('access-token');

  /**
   * Method get with query
   * @param query
   * @author Hoàng Duy <duy.hoang@lofivn.com>
   */
  async getWithQuery(query?) {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.get(
      `${this.resource}${query ? query.path : ''}?page=${
        query && query.page ? query.page : 1
      }&per_page=${query && query.per_page ? query.per_page : 10}&sort_by=${
        query && query.sort_by ? query.sort_by : 'updated_at'
      }&sort_type=${query && query.sort_type ? query.sort_type : 'desc'}`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );

    return response.data;
  }

  /**
   * Get Methods
   * @return Array
   * @author Hoàng Duy <duy.hoang@lofivn.com>
   */
  async get(): Promise<T[]> {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.get(`${this.resource}`, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }

  /**
   * Get One Methods
   * @return Object
   * @author Hoàng Duy <duy.hoang@lofivn.com>
   */
  async getOne(id) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.get(`${this.resource}/${id}`, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }

  /**
   * Create Methods
   * @return Object || Status
   * @author Hoàng Duy <duy.hoang@lofivn.com>
   */
  async create(body: T) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.post(`${this.resource}`, body, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }

  /**
   * Update Methods
   * @return Object || Status
   * @author Hoàng Duy <duy.hoang@lofivn.com>
   */
  async update(id, data) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.put(`${this.resource}/${id}`, data, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }

  /**
   * Delete Methods
   * @return Object || Status
   * @author Hoàng Duy <duy.hoang@lofivn.com>
   */
  async delete(id: string): Promise<T[]> {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.delete(`${this.resource}/${id}`, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }
}
