import { all } from 'redux-saga/effects';

//Register
import watchRegisterAction from './registers/register.saga';

//Provinces
import watchGetProvinces from './provinces/getProvinces.saga';

//Districts
import watchGetDistrict from './districts/getDistricts.saga';

//Communes
import watchGetCommunes from './communes/getCommunes.saga';

//Carerr GR
import watchSearchCareersGr from './careers/searchCareer.saga';

//Skills
import watchSearch from './skills/searchSkill.saga';

//Positions
import watchSearchPosition from './positions/searchPosition.saga';

//Jobs
import watchGetJobs from './jobs/getJobs.saga';
import watchGetJobById from './jobs/getJobById.saga';
import watchCreateJob from './jobs/create.saga';
import watchDeleteJob from './jobs/delete.saga';
import watchGetJobActive from './jobs/getJobActive';
import watchUpdateJob from './jobs/edit.saga';
import watchUpdateStatusJobs from './jobs/update.saga';

//Employee
import watchGetEmployee from './employee/getEmployee.saga';
import watchUpdateEmployee from './employee/updateEmployee.saga';

//Corporate
import watchGetCorporate from './corporate/getCorporate.saga';
import watchUpdateCorporate from './corporate/updateCorporate.saga';

// Home
import watchGetHome from './home/getHome.saga';

// Career
import watchSearchCareers from './searchcareers/searchCareer.saga';

//Courses
import watchGetCourses from './courses/getCourses.saga';
import watchUpdateCourses from './courses/updateCourses.saga';
import watchSearchMajor from './major/major.saga';
import watchCreateCourse from './courses/create.saga';
import watchDeleteCourse from './courses/delete.sage';
import watchGetCoursesById from './courses/getCoursesById.saga';
import watchGetStudentById from './courses/getStudentById.saga';
import watchGetCoursesActive from './courses/getActiveCourse.saga';

//Candidate
import watchGetCandidate from './candidate/getCandidate.saga';
import watchChangePassword from './employee/changePass.saga';

// Address
import watchSearchAddress from './address/searchAddress.saga';
import watchMatchCandidate from './candidate/getMatchCandidateSearch.saga';
import watchUserViewProfile from './candidate/userViewProfile.saga';
import watchUploadImage from './upload/uploadMedia.saga';

import watchForgetPass from './employee/forgetPass.saga';
import watchCheckForgetPass from './employee/checkForgetPass.saga';
import watchResetPassword from './employee/resetPassword.saga';
import watchRestoreJob from './jobs/restore.saga';
import watchCareerCode from './careerCode/getCareerCode.saga';
import watchTrainee from './trainee/getTrainee.saga';
import watchUpdateApplyCourse from './courses/updateApplyCourse.saga';
import watchExportExcelJob from './exportExcel/exportExcelJob.saga';
import watchExportExcelCourse from './exportExcel/exportExcelCourse.saga';
import watchExportExcelCandidate from './exportExcel/exportExcelCandidate.saga';
import watchExportExcelStudent from './exportExcel/exportExcelStudent.saga';
// Package
import watchGetPackages from "./iPointPackage/getPackages.saga";
import watchGetPackageTime from './iPointPackage/getPackageTime.saga';
import watchGetPackageVip from './iPointPackage/getPackageVip.saga';
import watchGetPackageBuy from './iPointPackage/getPackageBuy.saga';
//IPoint
import watchGetIPoint from './ipoint/getIPointTotal.saga';
import watchGetIPointHistory from './ipoint/getIPointHistory.saga';
import watchGetIPointOrder from './ipoint/getIPointOrder.saga';
import watchTraineeViewProfile from "./trainee/viewProfile.saga";
import watchGetOldPackages from "./iPointPackage/getOldPackages.saga";

function* rootSaga() {
  yield all([
    //Provinces
    watchGetProvinces(),

    //District
    watchGetDistrict(),

    //Communes
    watchGetCommunes(),

    //Careers Group
    watchSearchCareersGr(),

    //Register
    watchRegisterAction(),

    //Skills
    watchSearch(),

    //Address
    watchSearchAddress(),

    //Package
    watchGetPackages(),
    watchGetPackageTime(),
    watchGetPackageVip(),
    watchGetPackageBuy(),
    watchGetOldPackages(),
    //IPoint
    watchGetIPoint(),
    watchGetIPointHistory(),
    watchGetIPointOrder(),
    //Positions
    watchSearchPosition(),

    //Jobs
    watchGetJobs(),
    watchGetJobById(),
    watchCreateJob(),
    watchDeleteJob(),
    watchGetJobActive(),
    watchUpdateJob(),
    watchUpdateStatusJobs(),
    watchRestoreJob(),

    //Employee
    watchGetEmployee(),
    watchUpdateEmployee(),
    watchChangePassword(),
    watchForgetPass(),
    watchCheckForgetPass(),
    watchResetPassword(),

    //Corporate
    watchGetCorporate(),
    watchUpdateCorporate(),

    //Courses
    watchGetCourses(),
    watchUpdateCourses(),
    watchCreateCourse(),
    watchDeleteCourse(),
    watchGetCoursesById(),
    watchGetCoursesActive(),
    watchUpdateApplyCourse(),

    // Careers
    watchSearchCareers(),

    // Home
    watchGetHome(),

    //Major
    watchSearchMajor(),

    //Candidate
    watchGetCandidate(),
    watchGetStudentById(),
    watchMatchCandidate(),
    watchUserViewProfile(),

    //Media
    watchUploadImage(),

    //Career Code
    watchCareerCode(),

    //Trainee
    watchTrainee(),
    watchTraineeViewProfile(),

    //Export Excel
    watchExportExcelJob(),
    watchExportExcelCourse(),
    watchExportExcelCandidate(),
    watchExportExcelStudent(),
  ]);
}

export default rootSaga;
