import React from 'react';
import { convertDate } from 'utils/commons';

export const LF_DesiredPosition = (props) => {
  const data = props.props;
  return data && data ? (
    <div>
        <a href={`edit/${props?.id}`} target="_blank">
            <span className="text-sm font-normal">{props?.title}</span>
        </a>
      <div className="flex flex-row justify-between items-center pt-3">
        <span>Mã tin: {props?.id}</span>
        <span>Ngày: {convertDate(props?.start_date?.substr(0, 10))}</span>
      </div>
    </div>
  ) : null;
};
