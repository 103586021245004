import React from 'react';
import cx from 'classnames';

//Type
import { AvatarProps } from './Avatar.type';

//Component
import { Avatar } from '@material-ui/core';

export const LF_Avatar = (props: Partial<AvatarProps>) => {
  const { userName, src, className } = props;

  return (
    <React.Fragment>
      <div className="hidden lg:flex w-full text-center mx-auto  flex-row items-center justify-end">
        <Avatar alt={userName} src={src} />
        <span className={cx([className, 'hidden lg:block text-base pl-2 font-sans'])}>
          {userName}
        </span>
          <a className={"hidden lg:block text-black bg-white lg:px-4 lg:py-2 2xl:px-8 2xl:py-3 font-sans rounded-3xl ml-4 font-semibold hover:text-white hover:bg-black transition"} target={"_blank"} href="https://i-hr.vn/" >
              TRANG TÌM VIỆC
          </a>
      </div>
    </React.Fragment>
  );
};
