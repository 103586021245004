import { BaseRepository } from './base';
import { IProvince } from '../../utils/interfaces';
import Repository from './base/repository';

export class ProvinceRepository extends BaseRepository<IProvince> {
  resource = 'province';
  token = localStorage.getItem('access-token');

  /**
   * Method get district from Province
   * @param ProvinceCode string
   * @return Array
   */
  async getDistrict(province_code: string) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.get(
        `${this.resource}/${province_code}/district`,
        //  {
        //   headers: { Authorization: `Bearer ${this.token}` },
        // }
      )
    ).data;
  }

  /**
   * Method get Commune from province_code and district_code
   * @param province_code
   * @param district_code
   * @return Array
   */
  async getCommune(province_code: string, district_code: string) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.get(
        `${this.resource}/${province_code}/district/${district_code}/commune`,
        // {
        //   headers: { Authorization: `Bearer ${this.token}` },
        // },
      )
    ).data;
  }

  /**
   * Method get Search Province by Name
   * @param province_code
   * @param district_code
   * @return Array
   */
  async getSearchProvince(province_name) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.get(
        `${this.resource}/search?q=${province_name}`,
        //  {
        //   headers: { Authorization: `Bearer ${this.token}` },
        // }
      )
    ).data;
  }
}
