import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { JobsRepository } from 'services/repositories/jobs.repository';

//Repository
const jobsRepository = RepositoryFactory.get('jobs') as JobsRepository;

export const fetchJobs = async (query?) => {
  const response = await jobsRepository.getQueryJob(query);

  return response;
};

function* handlerGetJobs(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const jobs = yield call(fetchJobs, action.payload);

    yield put({
      type: types.GET_JOBS_SUCCESS,
      payload: jobs,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.GET_JOBS_ERROR,
      payload: err.response,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetJobs() {
  yield takeLatest(types.GET_JOBS_REQUEST, handlerGetJobs);
}
