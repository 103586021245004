/* eslint-disable react/jsx-pascal-case */
import { LF_CkEditor } from 'components/molecules';
import { countLengthNotHTML } from './../../../../utils/commons/';

export const LF_BasicInfo = ({
  data,
  onChangeCKE,
  onChangeInput,
  onClickNextStep,
  msgCheckValid,
}) => {
  return (
    <>
      <div className="flex flex-col lg:flex-row ">
        <label htmlFor="job-title" className="font-bold mr-3 w-40 mt-4 lg:mt-0">
          Tiêu đề công việc <span className="text-[#B50829]">*</span>
        </label>
        <div className="flex-1 flex flex-col mt-4 lg:mt-0">
          <input
            name="jobTitle"
            type="text"
            id="job-title"
            className="border border-gray-300 border-solid flex-grow h-11 block"
            value={data.basicInfo.jobTitle}
            onChange={(e) =>
              onChangeInput(e.target.name, e.target.value, 'basicInfo')
            }
          />
          <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5">
            {msgCheckValid && msgCheckValid.jobTitle}
          </span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row mt-5">
        <label htmlFor="desc-job" className="font-bold mr-3 w-40">
          Mô tả công việc <span className="text-[#B50829]">*</span>
        </label>
        <div className="flex-grow flex flex-col lg:w-min mt-5">
          <div className="flex-grow">
            <LF_CkEditor
              name="desc"
              data={data.basicInfo.desc}
              onChange={onChangeCKE}
            />
          </div>
          <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5">
            {msgCheckValid && msgCheckValid.desc}
          </span>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row mt-4 justify-between">
        <span
          className=" text-sm font-thin text text-left  "
          style={{ color: '#B50829' }}
        >
          {/* {validate.textareaTime !== '' ? validate.textareaTime : ''} */}
        </span>
        {data.basicInfo.desc &&
          countLengthNotHTML(data.basicInfo.desc) >= 2000 ? (
          <span className=" text-right  text-[#B50829]">
            {countLengthNotHTML(data.basicInfo.desc)}
            /2000 ký tự
          </span>
        ) : (
          <span className=" text-right text-gray-500 ">
            {countLengthNotHTML(data.basicInfo.desc)}
            /2000 ký tự
          </span>
        )}
      </div>

      <div className="flex flex-col lg:flex-row mt-4">
        <label htmlFor="desc-job" className="font-bold mr-3 w-40">
          Yêu cầu công việc <span className="text-[#B50829]">*</span>
        </label>
        <div className="flex-grow flex flex-col lg:w-min mt-4">
          <div className="flex-grow">
            <LF_CkEditor
              name="require"
              data={data.basicInfo.require}
              onChange={onChangeCKE}
            />
          </div>
          <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5">
            {msgCheckValid && msgCheckValid.require}
          </span>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row mt-4 justify-between">
        <span
          className=" text-sm font-thin text text-left  "
          style={{ color: '#B50829' }}
        >
          {/* {validate.textareaTime !== '' ? validate.textareaTime : ''} */}
        </span>
        {data.basicInfo.require &&
          countLengthNotHTML(data.basicInfo.require) >= 2000 ? (
          <span className=" text-right  text-[#B50829]">
            {countLengthNotHTML(data.basicInfo.require)}
            /2000 ký tự
          </span>
        ) : (
          <span className=" text-right text-gray-500 ">
            {countLengthNotHTML(data.basicInfo.require)}
            /2000 ký tự
          </span>
        )}
      </div>

      <div className="flex flex-col lg:flex-row mt-4">
        <label htmlFor="desc-job" className="font-bold mr-3 w-40">
          Phúc lợi <span className="text-[#B50829]">*</span>
        </label>
        <div className="flex-grow flex flex-col lg:w-min mt-4">
          <div className="flex-grow">
            <LF_CkEditor
              name="welfare"
              data={data.basicInfo.welfare}
              onChange={onChangeCKE}
            />
          </div>
          <span className="text-xs leading-5 h-5 text-[#B50829] pt-0.5">
            {msgCheckValid && msgCheckValid.welfare}
          </span>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row mt-4 justify-between">
        <span
          className=" text-sm font-thin text text-left  "
          style={{ color: '#B50829' }}
        >
          {/* {validate.textareaTime !== '' ? validate.textareaTime : ''} */}
        </span>
        {data.basicInfo.welfare &&
          countLengthNotHTML(data.basicInfo.welfare) >= 2000 ? (
          <span className=" text-right  text-[#B50829]">
            {countLengthNotHTML(data.basicInfo.welfare)}
            /2000 ký tự
          </span>
        ) : (
          <span className=" text-right text-gray-500 ">
            {countLengthNotHTML(data.basicInfo.welfare)}
            /2000 ký tự
          </span>
        )}
      </div>

      {/* Btn click next step */}
      <div
        className="flex mt-4 lg:my-10 lg:ml-4-5 w-max rounded-lg bg-[#B50829] text-white custom-btn cursor-pointer"
        onClick={() => onClickNextStep('basicInfo')}
      >
        <span className="pr-2 font-medium">Tiếp tục</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0377 6.34277L13.6268 7.76029L16.897 11.0153L3.29199 11.029L3.294 13.0289L16.8618 13.0153L13.6466 16.2455L15.0641 17.6564L20.7078 11.9864L15.0377 6.34277Z"
            fill="white"
          />
        </svg>
      </div>
    </>
  );
};
