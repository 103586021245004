import { call, put, takeLatest } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { EmployeeRepository } from 'services/repositories/employee.repository';
import { AlertType, showNotification } from 'utils/commons/Alert';
import * as types from '../../type';

const employeeRepository: EmployeeRepository = RepositoryFactory.get(
  'emp',
) as EmployeeRepository;

const fetchChange = async (data) => {
  const response = await employeeRepository.changePass(data);

  return response;
};

function* changePassword(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const change = yield call(fetchChange, action.payload);
    if (change.status !== 403) {
      yield put({ type: types.CHANGE_PASS_SUCCESS, payload: change });
      showNotification({
        type: AlertType.SUCCESS,
        msg: 'Cập nhật mật khẩu thành công!',
      });
      yield put({ type: types.LOADING_SUCCESS, payload: false });
    }
    if (change.status === 403) {
      yield put({ type: types.CHANGE_PASS_ERROR, payload: change.message });
      showNotification({
        type: AlertType.ERROR,
        msg: 'Cập nhật thất bại! Vui lòng kiểm tra mật khẩu cũ!',
      });
      yield put({ type: types.LOADING_SUCCESS, payload: false });
    }
  } catch (err) {}
}

export default function* watchChangePassword() {
  yield takeLatest(types.CHANGE_PASS_REQUEST, changePassword);
}
