import { BaseRepository } from './base';
import Repository from './base/repository';

export class TraineeRepository extends BaseRepository<any> {
  resource = 'trainees';
  token = localStorage.getItem('access-token');

  /**
   * Method search Skill
   * @param query string
   * @return Array
   */
  async getTrainee(query?: any) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.get(
        `${this.resource}/search?p_careers_code=${query && query.p_careers_code ? query.p_careers_code : ''
        }&p_skills=${query && query.p_skills ? query.p_skills : ''
        }&p_learning_form=${query && query.p_learning_form ? query.p_learning_form : ''
        }&p_discharged_army=${query && query.p_discharged_army ? query.p_discharged_army : ''
        }&p_schedule=${query && query.p_schedule ? query.p_schedule : ''
        }&p_skip=${query && query.p_skip ? query.p_skip : '0'}&p_limit=${query && query.p_limit ? query.p_limit : '20'
        }`,
        {
          headers: { Authorization: `Bearer ${this.token}` },
        },
      )
    ).data;
  }

  async getTraineeLocation(user_id) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.get(`${this.resource}/${user_id}/location`, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }

  async viewTraineeProfile(data) {
    const response = await Repository.post(`${this.resource}/view-profile`,
      data,
      {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    return response.data;
  }
}
