import { CoursesInterface } from 'utils/interfaces';
import { BaseRepository } from './base';
import Repository from './base/repository';

export class CoursesRepository extends BaseRepository<CoursesInterface> {
  resource = 'courses';
  token = localStorage.getItem('access-token');

  async updateCourses(data) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.put(`${this.resource}`, data, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }

  async getQueryCourse(query?: any) {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.get(
      `${this.resource}?start_date=${
        query && query.start_date ? query.start_date : '1970-01-01'
      }&end_date=${
        query && query.end_date ? query.end_date : '2100-01-01'
      }&status=${query && query.status ? query.status : '0'}&skip=${
        query && query.skip ? query.skip : '0'
      }&limit=${query && query.limit ? query.limit : '20'}&search_string=${
        query && query.search_string ? query.search_string : ''
      }`,
      {
        headers: { Authorization: `Bearer ${this.token}` },
      },
    );
    return response.data;
  }

  async getCourseByID(id?: string) {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.get(`${this.resource}/${id}`, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return response.data;
  }

  /**
   * Method search Student Group
   * @param query string
   * @return Array
   * @author ThucKaito
   */
  async getStudentById(query?: any) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.get(
        `${this.resource}/${query && query.course_id}/students?&status=${
          query && query.status ? query.status : '0'
        }&p_order=${query && query.p_order ? query.p_order : '1'}&p_skip=${
          query && query.p_skip ? query.p_skip : '0'
        }&p_limit=${
          query && query.p_limit ? query.p_limit : '20'
        }&search_string=${
          query && query.search_string ? query.search_string : ''
        }`,
        { headers: { Authorization: `Bearer ${this.token}` } },
      )
    ).data;
  }

  async getActiveCourse() {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.get(`${this.resource}/status/active`, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return response.data;
  }

  async updateApplyCourse(data) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.put(`${this.resource}/applies/users`, data, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }

  async uploadCourse(id, data) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.put(`${this.resource}/${id}/attachment`, data, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }
}
