import React, { useEffect, useState, useRef } from 'react';
import { LF_DataTable } from '../DataTable';
import iPointYellow from 'assets/images/iPointYellow.png';
import { useDispatch, useSelector } from 'react-redux';
import { TRANSACTION_TYPES } from '../../../../constants';
import { getIPoint, getIPointHistory } from '../../../../redux/actions';
import { RootState } from '../../../../redux/store';
import {formatDate, formatDateDDMMYYY} from '../../../../utils/commons';
import _ from 'lodash';
import { CSVLink } from 'react-csv';
import ipointFormat from 'utils/commons/math/ipointFormat';
import { IPointRepository } from '../../../../services/repositories/ipoint.repository';
import { RepositoryFactory } from '../../../../services/repositories';
import { getUserDetailsWithToken } from 'views/wrapper/Auth.wrapper';

const ipointRepositoryTotal: IPointRepository = RepositoryFactory.get(
  'ipoint',
) as IPointRepository;
const headers = [
  { label: 'Thời gian', key: 'created_at' },
  { label: 'Loại', key: 'transaction_type' },
  { label: 'Dịch vụ', key: 'service' },
  { label: 'iPoint', key: 'ipoint' },
  { label: 'Nội dung', key: 'description' },
];
export const CLC_IPoint = () => {

  const csvInstance = useRef();
  const dispatch = useDispatch();
  const corporate_code  = getUserDetailsWithToken()?.corporate_code;

  const [search, setSearch] = useState({
    p_corporate_code: corporate_code,
    p_tran_type: 0,
    p_from_date: '2021-01-01',
    p_to_date: formatDateDDMMYYY(new Date()),
    p_skip: 0,
    p_limit: 20,
  });
  const [dataExcel, setDataExcel] = useState([]);
  const [page, setPage] = React.useState(1);
  const exportExcel = async (e) => {
    const response = await ipointRepositoryTotal.getIPointHistoryExcel(search.p_corporate_code, search.p_tran_type);
    if (!response) {
      return;
    }
    setDataExcel(response?.data);
    // @ts-ignore
    csvInstance?.current?.link?.click();
  };
  const handlePage = (data) => {
    setPage(data);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };
  const filterData = _.debounce((e) => {
    dispatch(getIPointHistory(search));
    setDataExcel([]);
  }, 250);
  const handleSearch = async (name, value) => {
    setSearch({ ...search, [name]: value });
  };
  useEffect(() => {
    dispatch(getIPointHistory(search));
  }, [page]);
  useEffect(() => {
    dispatch(getIPoint(corporate_code));
  }, []);
  const { ipoint } = useSelector((state: RootState) => state);
  return (
    <div className={'w-full p-5'}>
      <div className={'w-full flex flex-col items-center justify-center rounded-2xl py-10 mt-5'}
        style={{ backgroundColor: '#B50829' }}>
        <span className={'text-white'}>Số dư iPoint của bạn</span>
        <p className={'text-white font-semibold text-5xl mt-3'}>
          {ipointFormat(ipoint?.ipoint?.ipoint)}
          <img className={'inline ml-2'} src={iPointYellow} alt='iPoint' />
        </p>
      </div>
      <h3 className={'mt-3 text-xl font-bold uppercase'}>lịch sử chi tiêu</h3>
      <div className='flex mt-5 flex-col xl:flex-row items-start xl:items-center justify-between'>
        <div className={'flex flex-row justify-between items-center w-full xl:w-auto'}>
          <label className={'lg:w-auto'} htmlFor='type'>Loại giao dịch</label>
          <select id={'type'} value={search.p_tran_type} onChange={handleChange} className={'ml-3 min-w-[180px]'}
            name='p_tran_type' style={{ border: '1px solid #ccc', borderRadius: '5px' }}>

            {
              TRANSACTION_TYPES.map((e, index) => {
                return <option key={index} value={e.value}>{e.title}</option>;
              })
            }
          </select>
        </div>
        <div className={'flex w-full xl:w-auto flex-col xl:flex-row justify-between'}>
          <div className={'flex flex-row justify-between items-center w-full ml-0 lg:w-auto xl:ml-3 mt-4 xl:mt-0'}>
            <label htmlFor='from-date'>Từ ngày</label>
            <input id={'from-date'} value={search.p_from_date} onChange={handleChange} name={'p_from_date'}
              className={'lg:ml-3'} type='date' style={{ border: '1px solid #ccc', borderRadius: '5px' }} />
          </div>
          <div className={'flex flex-row justify-between items-center w-full ml-0 lg:w-auto xl:ml-3 mt-4 xl:mt-0'}>
            <label htmlFor='to-date'>Đến ngày</label>
            <input id={'to-date'} value={search.p_to_date} onChange={handleChange} name={'p_to_date'}
              className={'lg:ml-3'} type='date' style={{ border: '1px solid #ccc', borderRadius: '5px' }} />
          </div>
        </div>
        <div className={'flex flex-row justify-between min-w-[250px] mt-4 lg:mt-0'}>
          <span className={'font-bold cursor-pointer'} onClick={filterData}
            style={{ color: '#B50829' }}>Lọc dữ liệu</span>
          {
            dataExcel.length > 0 ?
              <CSVLink
                data={dataExcel}
                headers={headers}
                filename={'lich-su-chi-tieu.csv'}
                ref={csvInstance}
              >
                <span className={'font-bold cursor-pointer'} style={{ color: '#B50829' }}>Xuất Excel</span>
              </CSVLink>
              :
              <span onClick={exportExcel} className={'font-bold cursor-pointer'}
                style={{ color: '#B50829' }}>Xuất Excel</span>
          }

        </div>
      </div>
      <LF_DataTable
        rows={ipoint?.ipointHistory?.data}
        type='IPointHistory'
        per_page={String(ipoint?.ipointHistory?.total)}
        handlePage={handlePage}
        fromSearch={handleSearch}
        page={page}
      />
    </div>
  );
};