import * as types from '../type';

interface homeInterface {
  home: [];
  status: any;
}

const initialState: homeInterface = {
  home: [],
  status: null,
};

const homeReducer = (state = initialState, action): homeInterface => {
  switch (action.type) {
    case types.HOME_SUCCESS:
      return {
        ...state,
        home: action.payload,
      };
    default:
      return { ...state };
  }
};

export default homeReducer;
