import * as types from '../type';
import { ICareer } from '../../utils/interfaces/searchcareer.interface';

interface IInitialState {
  searchCareers: ICareer[];
  status: any;
}

const initialState: IInitialState = {
    searchCareers: [],
  status: null,
};

const careerReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.SEARCH_CAREER_SUCCESS:
      return { ...state, searchCareers: action.payload };
    case types.SEARCH_CAREER_ERROR:
      return { ...state, status: action.payload };
    default:
      return {
        ...state,
      };
  }
};

export default careerReducer;
