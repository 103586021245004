import * as types from '../type';
import { IDtoRegister } from '../../utils/dto';

interface IInitialState {
  form: IDtoRegister;
  status: any;
}

const initialState: IInitialState = {
  form: {
    username: '',
    password: '',
    passwordConfirmation: '',
    phone: '',
    email: '',
    companyName: '',
    companyAddress: '',
    city: '',
    district: '',
    wards: '',
    field: [],
    legal: '',
    personalTaxCode: '',
    personalTaxCodeDate: '',
    personalTaxCodePlace: '',
  },
  status: null,
};

const registerReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.REGISTER_SUCCESS: {
      return {
        ...state,
        status: action.payload,
      };
    }
    case types.REGISTER_ERROR: {
      return {
        ...state,
        status: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default registerReducer;
