import React from 'react';
import {List} from '@material-ui/core';

export const LF_Exp = ({props, lcs, province}) => {
    return (
        <React.Fragment>
            <List>
                <div className="mb-2 list-disc">
                    {
                        lcs ? <li>LCS: {lcs}</li> : ''
                    }
                    {
                        province ? <li>Chỗ ở:  {province}</li> : ''
                    }
                    {props?.map((e, index) => {
                        return (
                            <div key={index}>
                                {e?.corporate_name === '' ? '' : <li>{e.corporate_name}</li>}
                            </div>
                        );
                    })}
                </div>
            </List>
        </React.Fragment>
    );
};
