import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { AlertType, showNotification } from 'utils/commons/Alert';
import { CoursesRepository } from 'services/repositories/course.repository';
import { fetchStudentCourse } from './getStudentById.saga';

//Initial State
const coursesRepository: CoursesRepository = RepositoryFactory.get(
  'course',
) as CoursesRepository;

const fetchUpdateApplyCourse = async (data) => {
  const response = await coursesRepository.updateApplyCourse(data);
  return response;
};

function* updateApplyCourse(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const update = yield call(fetchUpdateApplyCourse, action.payload.data);
    const candidate = yield call(fetchStudentCourse, action.payload.query);
    yield put({ type: types.UPDATE_APPLY_COURSE_SUCCESS, payload: update });

    yield put({ type: types.GET_STUDENT_BY_ID_SUCCESS, payload: candidate });
    showNotification({
      type: AlertType.SUCCESS,
      msg: 'Cập nhật thành công!',
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    //yield put({ type: types.UPDATE_JOB_ERROR, payload: err.response.data });
    showNotification({
      type: AlertType.ERROR,
      msg: 'Cập nhật thất bại! Vui lòng kiểm tra lại thông tin!',
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchUpdateApplyCourse() {
  yield takeLatest(types.UPDATE_APPLY_COURSE_REQUEST, updateApplyCourse);
}
