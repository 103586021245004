import * as types from '../type';
import { EmployeeInterface } from 'utils/interfaces';

interface empInterface {
  employees: EmployeeInterface[];
  employee: EmployeeInterface | {};
  status: any;
  statusReset: null;
}

const initialState: empInterface = {
  employees: [],
  employee: {},
  status: null,
  statusReset: null,
};

const employeeReducer = (state = initialState, action): empInterface => {
  switch (action.type) {
    case types.EMPLOYEE_SUCCESS:
      return {
        ...state,
        employee: action.payload,
      };
    case types.UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case types.UPDATE_EMPLOYEE_ERROR:
      return {
        ...state,
        status: action.payload,
      };
    case types.CHANGE_PASS_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case types.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case types.FORGET_PASSWORD_ERROR:
      return {
        ...state,
        status: action.payload,
      };
    case types.CHECK_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        statusReset: action.payload,
      };
    case types.RESET_PASSWORD_ERROR:
      return {
        ...state,
        statusReset: action.payload,
      };
    case types.RESET_STORE_EMPLOYEE:
      return {
        ...state,
        status: null,
        statusReset: null,
      };
    default:
      return { ...state };
  }
};

export default employeeReducer;
