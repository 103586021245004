import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';

//Commons
import { AlertType, showNotification } from '../../../utils/commons/Alert';
import history from '../../../utils/guards/history.guard';

//Initial
const repository = RepositoryFactory.get('corporate');

const fetchRegister = async (data) => {
  const response = await repository.create(data);
  return response;
};

function* handlerRegister(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const response = yield call(fetchRegister, action.payload);
    yield put({ type: types.REGISTER_SUCCESS, payload: response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
    showNotification({
      type: AlertType.SUCCESS,
      msg: 'Register successfully!',
    });
    setTimeout(() => {
      history.push('/auth/login');
      return history.go(0);
    }, 3000);
  } catch (err) {
    yield put({ type: types.LOADING_SUCCESS, payload: false });
    yield put({ type: types.REGISTER_ERROR, payload: err.response.data });
    showNotification({
      type: AlertType.ERROR,
      msg: 'Có lỗi xảy ra, vui lòng kiểm tra lại!',
    });
  }
}

export default function* watchRegisterAction() {
  yield takeLatest(types.REGISTER_REQUEST, handlerRegister);
}
