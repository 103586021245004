import * as types from '../type';

interface IInitialState {
  image: any;
}

const inititalState: IInitialState = {
  image: null,
};

const uploadReducer = (state = inititalState, action) => {
  switch (action.type) {
    case types.UPLOAD_MEDIA_SUCCESS:
      return {
        ...state,
        image: action.payload,
      };
    default:
      return state;
  }
};

export default uploadReducer;
