import * as types from '../type';
// import { IPackage } from '../../utils/interfaces/package.interface';
interface IInitialState {
    packages: null,
}

const initialState: IInitialState = {
    packages: null,
}

const packageReducer = (state = initialState, action): IInitialState => {
    switch (action.type) {
        case types.GET_PACKAGES_SUCCESS:
            return {...state, packages: action.payload};
        case types.GET_PACKAGES_ERROR:
            return {...state, packages: action.payload};
        case types.GET_PACKAGES_REQUEST:
            return {...state, packages: action.payload};
        default:
            return {
                ...state,
            }
    }
}

export default packageReducer;