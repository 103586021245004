import React from 'react';
import { LF_Breadcumbs } from '../../../../components/molecules';
import { CLC_IPoint } from '../../../../components/organisms';
export const IPoint = () => {
  return(
    <React.Fragment>
      <LF_Breadcumbs
        route={`/admin/ipoint`}
        routeName={'Lịch sử chi tiêu'}
        className="font-bold text-lg"
      />
      <CLC_IPoint />
    </React.Fragment>
  )
}