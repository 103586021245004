import * as types from '../type';
import { IPosition } from '../../utils/interfaces/position.interface';

interface IInitialState {
  positions: IPosition[];
  position: IPosition | {};
  status: any;
}

const initialState: IInitialState = {
  positions: [],
  position: {},
  status: null,
};

const positionReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.SEARCH_POSITION_SUCCESS:
      return { ...state, positions: action.payload };
    case types.SEARCH_POSITION_ERROR:
      return { ...state, status: action.payload };
    default:
      return {
        ...state,
      };
  }
};

export default positionReducer;
