import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { TraineeRepository } from 'services/repositories/trainee.repository';
import { CandidateRepository } from '../../../services/repositories/candidate.repository';

//Repository
const traineeRepository: TraineeRepository = RepositoryFactory.get(
  'trainee',
) as TraineeRepository;
const candidateRepository: CandidateRepository = RepositoryFactory.get(
  'candidate',
) as CandidateRepository;

const fetchTrainee = async (query) => {
  const response: any = await traineeRepository.getTrainee(query);
  let result = {
    data: [],
    total_data: response.total_data[0][Object.keys(response.total_data[0])[0]],
  };
  if (response.data && response.data.length > 0) {
    for (const item of response.data) {
      const getInfo = await Promise.all([
        candidateRepository.getCandidateCareers(item.user_id),
        traineeRepository.getTraineeLocation(item.user_id),
      ]);
      result.data.push({
        ...item,
        infor: getInfo[0].data,
        location: getInfo[1].data,
      });
    }
  }
  return result;
};

function* handlerTrainee(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const trainee = yield call(fetchTrainee, action.payload);

    yield put({
      type: types.GET_TRAINEE_SUCCESS,
      payload: trainee,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.GET_TRAINEE_REQUEST,
      payload: err.response.data,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchTrainee() {
  yield takeLatest(types.GET_TRAINEE_REQUEST, handlerTrainee);
}
