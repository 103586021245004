import React, {useEffect, useRef, useState} from 'react';
import ipointFormat from "../../../../utils/commons/math/ipointFormat";
import IPointLogo from "../../../../assets/images/iPoint.jpg";
import {Checkbox} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";
import {addDay, formatDate} from "../../../../utils/commons";
import {URL_IMG_PACKAGE} from "../../../../constants";
import {getIPoint} from "../../../../redux/actions";
import {Payment} from "../Payment";
import {IPointPackageRepository} from "../../../../services/repositories/ipointPackage.repository";
import {RepositoryFactory} from "../../../../services/repositories";
import {IPointRepository} from "../../../../services/repositories/ipoint.repository";
import ModalListServices from "../../../molecules/Modal/ModalListServices";
import {Loading} from "../../../atoms/Spinner/Loading.atom";

const packageRepository: IPointPackageRepository = RepositoryFactory.get(
    'package',
) as IPointPackageRepository;

const ipointRepositoryTotal: IPointRepository = RepositoryFactory.get(
    'ipoint',
) as IPointRepository;
const LIST_PACKAGE_TIME = [
    {package_option_id: 1, name: '1 ngày', day: 1},
    {package_option_id: 2, name: '2 ngày', day: 2},
    {package_option_id: 3, name: '3 ngày', day: 3},
    {package_option_id: 4, name: '4 ngày', day: 4},
    {package_option_id: 5, name: '5 ngày', day: 5},
    {package_option_id: 6, name: '6 ngày', day: 6},
    {package_option_id: 7, name: '7 ngày', day: 7},
    {package_option_id: 8, name: '8 ngày', day: 8},
    {package_option_id: 9, name: '9 ngày', day: 9},
    {package_option_id: 10, name: '10 ngày', day: 10},
    {package_option_id: 11, name: '11 ngày', day: 11},
    {package_option_id: 12, name: '12 ngày', day: 12},
    {package_option_id: 13, name: '13 ngày', day: 13},
    {package_option_id: 14, name: '14 ngày', day: 14},
    {package_option_id: 15, name: '15 ngày', day: 15},
    {package_option_id: 16, name: '16 ngày', day: 16},
    {package_option_id: 17, name: '17 ngày', day: 17},
    {package_option_id: 18, name: '18 ngày', day: 18},
    {package_option_id: 19, name: '19 ngày', day: 19},
    {package_option_id: 20, name: '20 ngày', day: 20},
    {package_option_id: 21, name: '21 ngày', day: 21},
    {package_option_id: 22, name: '22 ngày', day: 22},
    {package_option_id: 23, name: '23 ngày', day: 23},
    {package_option_id: 24, name: '24 ngày', day: 24},
    {package_option_id: 25, name: '25 ngày', day: 25},
    {package_option_id: 26, name: '26 ngày', day: 26},
    {package_option_id: 27, name: '27 ngày', day: 27},
    {package_option_id: 28, name: '28 ngày', day: 28},
    {package_option_id: 29, name: '29 ngày', day: 29},
    {package_option_id: 30, name: '30 ngày', day: 30},
    {package_option_id: 31, name: '31 ngày', day: 31},
    {package_option_id: 60, name: '60 ngày', day: 60},
    {package_option_id: 90, name: '90 ngày', day: 90},
    {package_option_id: 180, name: '180 ngày', day: 180},
];
interface DataProps {
    listPackage?: any;
    listPackageTime?: any;
    statusJob?: any;
    ipoint?: any;
    setStatusJobPayment?: any;
    object?: any;
    status?: any;
    name?: any;
    oldPackages?: any;
}
export const BuyPackageOrganism = ({
                                       listPackage,
                                       listPackageTime,
                                       statusJob,
                                       ipoint,
                                       setStatusJobPayment,
                                       object,
                                       status,
                                       name,
                                       oldPackages,
                                   }: Partial<DataProps>) => {
    const dispatch = useDispatch();
    const {employee} = useSelector((state: RootState) => state.employee);
    const initialState = (name) => {
        switch (name) {
            case 'vip':
                return oldPackages.vip ? oldPackages.vip?.package_id : ( listPackage ? listPackage[0].package_id : 1);
            case 'time-vip':
                return oldPackages.vip ? oldPackages.vip?.package_option_id : (listPackageTime ? listPackageTime[0].package_option_id : 1);
            case 'start-vip':
                return oldPackages.vip ? formatDate(oldPackages.vip?.from_date) : formatDate(null);
            case 'end-vip':
                return oldPackages.vip ? formatDate(oldPackages.vip?.to_date) : addDay(null, 30, "new");
            case 'start-hot':
                return oldPackages.hot ? formatDate(oldPackages.hot?.from_date) : formatDate(null);
            case 'end-hot':
                return oldPackages.hot ? formatDate(oldPackages.hot?.to_date) : addDay(null, 7, "new");
            case 'time-hot':
                return oldPackages.hot ? oldPackages.hot?.quantity : LIST_PACKAGE_TIME[6].package_option_id;
            case 'day-hot':
                return oldPackages.hot ? oldPackages.hot?.quantity :  LIST_PACKAGE_TIME[6].day;
            case 'package-hot':
                return oldPackages.hot ? oldPackages.hot?.package_detail_id :  null;
            case 'ipoint-recruitment':
                return 0;
            case 'ipoint-hot':
                return 0;
            case 'image-package':
                return oldPackages.vip ? URL_IMG_PACKAGE[(oldPackages.vip?.package_id-1)].url : URL_IMG_PACKAGE[0].url;
            default:
                return null;
        }
    }
    const [loading, setLoading] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [checkHotRec, setCheckHotRec] = useState(oldPackages?.hot !== null);
    const [chooseRecruitment, setChooseRecruitment] = useState(initialState('vip'));
    const [timeToDisplayRec, setTimeToDisplayRec] = useState(initialState('time-vip'));
    const [startRecruitment, setStartRecruiment] = useState(initialState('start-vip'));
    const [endRecruitment, setEndRecruiment] = useState(initialState('end-vip'));
    const [timeToDisplayHot, setTimeToDisplayHot] = useState(initialState('time-hot'));
    const [startHot, setStartHot] = useState(initialState('start-hot'));
    const [endHot, setEndHot] = useState(initialState('end-hot'));
    const [packageHot, setPackageHot] = useState(initialState('package-hot'));
    const [dayHot, setDayHot] = useState(initialState('day-hot'));

    const [error, setError] = useState({
        startRecruitment: '',
        startHot: '',
    });

    const [pointRecruitment, setPointRecruitment] = useState(initialState('ipoint-recruitment'));
    const [pointHot, setPointHot] = useState(initialState('ipoint-hot'));

    const [imgPackage, setImgPackage] = useState(initialState('image-package'));

    const [paymentList, setPaymentList] = useState([]);
    const [packageList, setPackageList] = useState([]);

    const changeDate = async (e, name) => {
        const value = e.target.value;

        if (name == 'recruitment') {
            const checkDate =
                new Date(value) >=
                new Date()
            if (!checkDate) {
                setError({...error, startRecruitment: 'Ngày tuyển dụng phải từ hôm nay!'});
            } else {
                setStartRecruiment(value);
                setError({...error, startRecruitment: ''});
                for (let item of listPackageTime) {
                    if (item.package_option_id == timeToDisplayRec) {
                        await setEndRecruiment(addDay(value, item.day, "add"));
                    }
                }
            }
        } else {
            const checkDate =
                new Date(value) >= new Date(startRecruitment);
            if (!checkDate) {
                setError({...error, startHot: 'Tin HOT phải trong khoảng thời gian đăng tin!'});
                return;
            }
            setStartHot(value);
            setError({...error, startHot: ''});
            for (let item of LIST_PACKAGE_TIME) {
                if (item.package_option_id == timeToDisplayHot) {
                    await setEndHot(addDay(value, item.day, "add"));
                }
            }

        }
    };
    const changeRecruitment = async (e, name) => {
        setLoading(true);
        const value = e.target.value;
        if (value ===0 ) return;
        if (name === 'recruitment') {
            setChooseRecruitment(value);
            const response = await packageRepository.getPackageIPoint(value, timeToDisplayRec);
            for (let i = 0; i < URL_IMG_PACKAGE.length; i++) {
                if (value == URL_IMG_PACKAGE[i]?.id) {
                    setImgPackage(URL_IMG_PACKAGE[i]?.url);
                }
            }

            setPointRecruitment(response?.data);
        } else {
            setTimeToDisplayRec(value);
            const response = await packageRepository.getPackageIPoint(chooseRecruitment, value);
            for (let item of listPackageTime) {
                if (item.package_option_id == value) {
                    await setEndRecruiment(addDay(startRecruitment, item.day, "add"));
                }
            }
            setPointRecruitment(response?.data);
        }

        setLoading(false);
    };
    const changeHot = async (e) => {
        const value = e.target.value;
        setLoading(true);
        const response = await packageRepository.getPackageHotIPoint(value);
        setTimeToDisplayHot(value);
        setPackageHot(response?.data?.package_detail_id);
        LIST_PACKAGE_TIME.forEach(e => {
            if (e.package_option_id == value) {
                setPointHot(Number(response?.data?.ipoint) * Number(e.day));
                setEndHot(addDay(startHot, e.day, "add"));
                setDayHot(e.day);
            }
        });
        setLoading(false);
    };
    const changeRecruitmentHot = async (e) => {
        setLoading(true);
        setCheckHotRec(!checkHotRec);
        if (!checkHotRec) {
            const response = await packageRepository.getPackageHotIPoint(null);
            setPackageHot(response?.data?.package_detail_id);
            LIST_PACKAGE_TIME.forEach(e => {
                if (e.package_option_id == timeToDisplayHot) {
                    setPointHot(Number(response?.data?.ipoint) * Number(e.day));
                }
            });
        } else {
            setPointHot(0);
            setPackageHot(null);
        }
        setLoading(false);
    };
    const submitBuyPackage = async () => {
        if (chooseRecruitment == 0) {
            alert("Vui lòng chọn gói tin mà bạn muốn!")
            return;
        }
        const missingPoints = getMissingPoints();
        const checkDateVip =
            new Date() <= new Date(startRecruitment);
        const checkDateHot =
            new Date(startRecruitment) <= new Date(startHot);
        if (!checkDateVip) {
            setError({...error, startRecruitment: 'Ngày tuyển dụng phải từ hôm nay!'});
            return;
        }
        if (!checkDateHot && checkHotRec) {
            setError({...error, startHot: 'Tin HOT phải trong khoảng thời gian đăng tin!'});
            return;
        }
        if (missingPoints <= 0) {
            const body = {
                'p_wallet_id': ipoint?.wallet_id,
                'p_spend_ipoint': pointRecruitment + pointHot,
                'p_description': statusJob === 'create' ? status : object[name === "job" ? 'job_id' : 'course_id'],
                'p_object_id': statusJob === 'create' ? status : object[name === "job" ? 'job_id' : 'course_id'],
                'p_from_date': startRecruitment,
                'p_to_date': endRecruitment,
                'p_package_id': chooseRecruitment,
                'p_package_option_id': timeToDisplayRec,
                'p_package_hot': packageHot,
                'p_start_hot': startHot,
                'p_end_hot': endHot,
                'p_name': name
            };
            const response = await ipointRepositoryTotal.buyRecruitment(body);
            if (response?.data) {
                setStatusJobPayment(true);
                // @ts-ignore
                dispatch(getIPoint(employee.corporate_code))
            } else {
                alert('Số dư i-Point không đủ!');
                // @ts-ignore
                dispatch(getIPoint(employee.corporate_code))
            }
        } else {
            getPaymentLists();
            setOpenPayment(true);
        }
    };

    const [showModal, setShowModal] = useState(false);

    const handleModal = (e, name: string) => {
        setShowModal(name !== 'close')
    }

    const getMissingPoints = () => {
        return Number(pointRecruitment) + Number(pointHot) - Number(ipoint?.ipoint);
    }
    const getRoundingPoints = () => {
        const missingPoints = getMissingPoints();
        return missingPoints >= 10 ? missingPoints : 10;
    }

    const getPaymentLists = () => {
        const packageList = [];
        const paymentList = [];
        const missingPoints = getRoundingPoints();
        const _chooseRecruitment = Number(chooseRecruitment);
        if (_chooseRecruitment !== 1) {
            const choosedPackage = listPackage.find((_package) => _package.package_id === _chooseRecruitment);
            packageList.push(
                {
                    name: choosedPackage.package_name,
                    sale: 0,
                    quantity: 1,
                    point: pointRecruitment,
                    amount: pointRecruitment * 1000,
                    package_id: chooseRecruitment,
                    package_option_id: timeToDisplayRec,
                    from_date: startRecruitment,
                    to_date: endRecruitment,
                },
            );
        }
        if (checkHotRec) {
            packageList.push({
                name: 'Tin hot',
                sale: 0,
                quantity: dayHot,
                point: pointHot,
                amount: pointHot * 1000,
                package_id: 11, // TODO: resolve hard coding
                package_option_id: null,
                from_date: startHot,
                to_date: endHot,
            });
        }
        paymentList.push({
            name: 'Tin đăng',
            point: 'Số lượng iPoint',
            // sale: 'Khuyến mại',
            amount: 'Thành tiền',
        });
        paymentList.push({
            name: 'Nạp tiền đăng tin',
            point: missingPoints,
            // sale: 0,
            amount: missingPoints * 1000,
        });
        setPaymentList(paymentList);
        setPackageList(packageList);
    }

    const fetchData = async () => {
        const response1 = await packageRepository.getPackageIPoint(oldPackages.vip?.package_id, oldPackages.vip?.package_option_id);
        setPointRecruitment(response1?.data);
        if (oldPackages?.hot !== null) {
            const response2 = await packageRepository.getPackageHotIPoint(null);
            setPackageHot(response2?.data?.package_detail_id);
            LIST_PACKAGE_TIME.forEach(e => {
                if (e.package_option_id == timeToDisplayHot) {
                    setPointHot(Number(response2?.data?.ipoint) * Number(e.day));
                }
            });
        }
    }
    React.useEffect(() => {
        if (oldPackages.vip !== null) {
            fetchData().then(r => r);
        }
    }, []);
    return !openPayment ?
        (
            <>
                {/* CHỌN GÓI TIN */}
                <div className=' h-358 flex flex-col border border-gray-250 '>
                    <h2 className='font-bold bg-gray-200 height-48 border-b border-gray-250 p-3'>
                        CHỌN GÓI TIN
                    </h2>
                    {/* VIP */}
                    <div className='flex flex-col lg:flex-row p-2 lg:p-3 w-full items-start lg:items-center'>
                        <label className='font-bold w-full lg:w-52'>Chọn gói tin</label>
                        <select
                            onChange={(e) => changeRecruitment(e, 'recruitment')}
                            value={chooseRecruitment}
                            className='rounded border-gray-300 w-full mt-2 lg:mt-0 lg:w-1/2 focus:outline-none '
                        >
                            {
                                listPackage && listPackage.map((e, index) => {
                                    return <option value={e?.package_id} key={index}>{e?.package_name}</option>;
                                })
                            }
                        </select>
                        <label className='text-[#B50829]  mt-2 lg:mt-0 lg:ml-10 cursor-pointer'
                               onClick={(e) => handleModal(e, 'open')}>Xem chi tiết gói</label>
                    </div>

                    {/* time */}
                    <div className='flex flex-col lg:flex-row p-3 items-start lg:items-center'>
                        <div className={'flex-1 flex lg:items-center flex-col lg:flex-row items-start'}>
                            <label className='font-bold w-52'>Thời gian hiển thị</label>
                            <select
                                onChange={(e) => changeRecruitment(e, 'recruitment-time')}
                                value={timeToDisplayRec}
                                className=' rounded border-gray-300 focus:outline-none '
                                disabled={chooseRecruitment === 0}
                            >
                                {
                                    listPackageTime && listPackageTime.map((e, index) => {
                                        return <option value={e?.package_option_id} key={index}>{e?.name}</option>;
                                    })
                                }
                            </select>
                        </div>
                        <div className='flex-1 flex relative xl:ml-10 mt-2 lg:mt-0 flex-col'>
                            <div className='flex flex-col  lg:flex-row items-start lg:items-center'>
                                <label className=''>Từ ngày</label>
                                <input
                                    name='startDay'
                                    type='datetime-local'
                                    className='h-8 ml-2 border-gray-300 rounded focus:outline-none'
                                    disabled={chooseRecruitment === 1}
                                    style={{color: `${chooseRecruitment === 1 ? "#aaa" : "black"}`}}
                                    value={startRecruitment}
                                    onChange={(e) => changeDate(e, 'recruitment')}
                                />

                            </div>
                            {error && error.startRecruitment && (
                                <span
                                    className='text-xs leading-5 h-5 text-[#B50829] pt-0.5 initial lg:absolute bottom-[-25px]'>
                      {error.startRecruitment}
                    </span>
                            )}
                        </div>
                        <div className='flex-1 flex xl:ml-5  mt-2 lg:mt-0 flex-col lg:flex-row items-start lg:items-center'>
                            <label className=''>đến ngày</label>
                            <div className='flex flex-col'>
                                <input
                                    name='deadline'
                                    type='datetime-local'
                                    style={{color: "#aaa"}}
                                    disabled={true}
                                    className='h-8 ml-2  border-gray-300 rounded focus:outline-none'
                                    value={endRecruitment}
                                />
                            </div>
                        </div>

                    </div>

                    {/* ipoint */}
                    <div className='flex flex-row items-center p-3'>
                        <label className='font-bold w-52'>Tổng số iPoint bị trừ</label>
                        <div className='flex-col text-[#B50829] font-bold p-3'>
                            {ipointFormat(Number(pointRecruitment))}
                        </div>
                        <div className='relative flex flex-col items-center'>
                            <img src={IPointLogo} alt='...' className='h-18 w-18'/>
                        </div>
                    </div>

                    {/* hình thức hiển thị */}
                    <div className='flex flex-col lg:flex-row items-start lg:items-center p-3'>
                        <label className='font-bold w-52 '>Hình thức hiển thị</label>
                        <div className='relative flex flex-col items-center max-w-full'>
                            <img src={imgPackage} alt='i-hr' className='h-101 w-477 max-w-[100%] lg:max-w-[550px]'/>
                        </div>
                    </div>
                </div>
                {/* TIN HOT */}
                <div className='mt-5 h-358 flex flex-col border border-gray-250 '>
                    <h2 className='font-bold bg-gray-200 height-48 border-b border-gray-250 p-3'>
                        TIN HOT
                    </h2>
                    {/* checked */}
                    <div className='flex flex-row mt-3 items-center'>
                        <Checkbox
                            id={'tin-hot'}
                            checked={checkHotRec}
                            onChange={(e) => changeRecruitmentHot(e)}
                        />
                        <label className='font-bold w-52' htmlFor={'tin-hot'}>Mua tin HOT</label>
                    </div>
                    {/* Mô tả dịch vụ */}
                    <div className='flex p-3 flex-col lg:flex-row items-start lg:items-center'>
                        <label className='font-bold w-52'>Mô tả dịch vụ</label>
                        <label className='text-[#B50829] '>
                            Hiển thị tin đăng trong mục tin HOT trên Trang chủ của Website và
                            APP giúp tiếp cận với nhiều người hơn
                        </label>
                    </div>

                    {/* time */}
                    <div className='flex p-3 flex-col lg:flex-row items-start lg:items-center'>
                        <div className={'flex-1 flex lg:items-center flex-col lg:flex-row items-start'}>
                            <label className='font-bold w-52'>Thời gian hiển thị</label>
                            <select
                                disabled={!checkHotRec}
                                onChange={(e) => changeHot(e)}
                                value={timeToDisplayHot}
                                className=' rounded border-gray-300 focus:outline-none '
                            >
                                {
                                    LIST_PACKAGE_TIME.map((e, index) => {
                                        return <option value={e.package_option_id} key={index}>{e.name}</option>;
                                    })
                                }
                            </select>
                        </div>
                        <div className='flex-1 flex relative flex-col lg:ml-10  mt-2 lg:mt-0'>
                            <div className='flex flex-col lg:flex-row items-start lg:items-center'>
                                <label className=''>Từ ngày</label>
                                <input
                                    name='startDay'
                                    type='datetime-local'
                                    className='h-8 lg:ml-2 w-60 border-gray-300 rounded focus:outline-none'
                                    disabled={!checkHotRec}
                                    style={{color: `${!checkHotRec ? "#aaa" : "black"}`}}
                                    value={startHot}
                                    onChange={(e) => changeDate(e, 'hot')}
                                />
                            </div>
                            {error && error.startHot && (
                                <span className='text-xs leading-5 h-5 lg:absolute bottom-[-25px] text-[#B50829] pt-0.5'>
                      {error.startHot}
                    </span>
                            )}
                        </div>
                        <div className='flex-1 flex flex-col lg:flex-row items-start lg:items-center'>
                            <label className='lg:ml-5 lg:mr-4 mt-2 lg:mt-0'>đến ngày</label>
                            <div className='flex flex-col'>
                                <input
                                    name='deadline'
                                    type='datetime-local'
                                    style={{color: "#aaa"}}
                                    className='h-8 lg:ml-2  border-gray-300 rounded focus:outline-none'
                                    disabled={true}
                                    value={endHot}
                                />
                            </div>
                        </div>
                    </div>

                    {/* ipoint */}
                    <div className='flex flex-row items-center p-3'>
                        <label className='font-bold w-52'>Tổng số iPoint bị trừ</label>
                        <div className='flex-col text-[#B50829] font-bold p-3'>
                            {ipointFormat(pointHot)}
                        </div>
                        <div className='relative flex flex-col items-center'>
                            <img src={IPointLogo} alt='...' className='h-18 w-18'/>
                        </div>
                    </div>
                </div>
                {/* THANH TOAN */}
                <div className='mt-5 h-358 flex flex-col border border-gray-250 '>
                    <h2 className='font-bold bg-gray-200 height-48 border-b border-gray-250 p-3'>
                        THANH TOÁN
                    </h2>
                    {/* ipoint */}
                    <div className='flex flex-row items-center p-3'>
                        <label className='font-bold w-52'>Tổng số iPoint tạm tính</label>
                        <div className='flex-col text-[#B50829] font-bold p-3'>
                            {ipointFormat(Number(pointRecruitment) + Number(pointHot))}
                        </div>
                        <div className='relative flex flex-col items-center'>
                            <img src={IPointLogo} alt='...' className='h-18 w-18'/>
                        </div>
                    </div>
                    {/* ipoint */}
                    <div className='flex flex-row items-center p-3'>
                        <label className='font-bold w-52'>IPoint bạn đang có</label>
                        <div className='flex-col text-[#B50829] font-bold p-3'>{ipointFormat(ipoint?.ipoint)}</div>
                        <div className='relative flex flex-col items-center'>
                            <img src={IPointLogo} alt='...' className='h-18 w-18'/>
                        </div>
                    </div>
                    <div className={'p-3'}>
                        {
                            getMissingPoints() > 0 ? <p>
                                {`Số dư iPoint của bạn không đủ để đăng tin, vui lòng mua thêm ${getMissingPoints()} ipoint nữa để hoàn tất tin đăng`}
                            </p> : ''
                        }
                        <p className="text-[#B50829] mt-2">
                            {
                                getMissingPoints() > 0 && getMissingPoints() < 10 ? 'Số tiền thanh toán tối thiểu của Cổng thanh toán VNPAY là 10,000 VNĐ' : ''
                            }
                        </p>
                    </div>
                </div>
                {/* nap tien ngay */}
                <div className='flex justify-center'>
                    <div
                        className='mt-5 mb-20 flex w-max rounded-lg bg-[#B50829] text-white custom-btn cursor-pointer'
                        onClick={submitBuyPackage}
                    >
                        {getMissingPoints() > 0 ? 'MUA IPOINT VÀ THỰC HIỆN' : 'THỰC HIỆN'}
                    </div>
                </div>
                <ModalListServices showModal={showModal} handleModal={handleModal}/>
                <Loading isLoading={loading}/>
            </>
        )
        :
        <Payment
        paymentList={paymentList}
        packageList={packageList}
        object={name}
        object_id={statusJob === 'create' ? status : object[name === "job" ? 'job_id' : 'course_id']}
        />
}