import React, {useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {calcIPoint, formatSaleBuyIpoint} from '../../../utils/commons';
import {Count} from '../../organisms/Modules/IPointBuy/Count';
import IPointLogo from '../../../assets/images/iPoint.jpg';
import ipointFormat from '../../../utils/commons/math/ipointFormat';


const OPTION = 'option';
const COUNT = 'count';

export default function OptionPackageItem({packageName, option, handleChangePackage}) {
    const optionReverse = [...option].reverse();
    const [ipoint, setIPoint] = useState(optionReverse[0].ipoint);
    const [count, setCount] = useState(0);
    const handleChangeOption = (e) => {
        const {name, value} = e.target;
        for (let i = 0; i < optionReverse.length; i++) {
            if (optionReverse[i].package_option_id == value) {
                setIPoint(optionReverse[i].ipoint);
                let packageObject = {
                    option: optionReverse[i].ipoint,
                    packageName: packageName,
                    optionName: OPTION,
                    count: count,
                    sale: optionReverse[0]['sale_off'],
                    packageOption: optionReverse[i].package_option_name,
                    package_option_id: optionReverse[i].package_option_id,
                }
                handleChangePackage(packageObject);
            }
        }
    }
    const handleCount = (count) => {
        setCount(count);
        let packageObject = {
            option: ipoint,
            packageName: packageName,
            optionName: COUNT,
            count: count,
            sale: option[0]['sale_off']
        }
        handleChangePackage(packageObject);
    }
    return (
        <TableRow>
            <TableCell
                height='20px'
                className='items-center text-left'
            >
        <span>
          {
              option[0].package_name
          }
        </span>
            </TableCell>
            <TableCell
                align='left'
                height='20px'
                className='items-center text-left'
                style={{textAlign: 'right'}}
            >
                {
                    optionReverse[0].type === "1" ? (<select onChange={(e) => handleChangeOption(e)}
                                                      style={{borderRadius: '5px', border: '1px solid #CED4DA'}}
                                                      name='package-time'>
                        {
                            optionReverse?.map((item, index) => {
                                return (
                                    <option key={index}
                                            value={item.package_option_id}>{item.package_option_name}</option>
                                )
                            })
                        }
                    </select>) : ""
                }

            </TableCell>
            <TableCell
                align='left'
                height='20px'
                className='items-center text-left'
                style={{textAlign: 'right'}}
            >
                <span>{ipointFormat(ipoint * 1000)} đ</span>
            </TableCell>
            <TableCell
                align='left'
                height='20px'
                className='items-center text-right'
                style={{textAlign: 'right'}}
            >
                <span>{formatSaleBuyIpoint(option[0]['sale_off'])}</span>
            </TableCell>
            <TableCell
                align='left'
                height='20px'
                className='items-center text-right'
                style={{textAlign: 'right'}}
            >
                {calcIPoint(ipoint, option[0]['sale_off'], count)}
                <img className={'ml-2 inline h-18 w-18'} src={IPointLogo} alt='...'/>
            </TableCell>
            <TableCell
                align='left'
                height='20px'
                className='items-center text-right'
                style={{textAlign: 'right'}}
            >
                <Count handleCount={handleCount}/>
            </TableCell>
        </TableRow>
    )
}
