/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LF_Avatar,
  LF_Education,
  LF_Infor,
  LF_LiList,
  LF_Exp,
} from 'components/atoms';
import {
  makeStyles,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  CircularProgress,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { RootState } from 'redux/store';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      '&:focus': {
        outline: 'none',
      },
    },
  },
  tab: {
    '&:focus': {
      backgroundColor: '#B50829',
    },
  },
  border: {
    border: '2px solid #ECEAF5',
    padding: '2px',
  },
}));

export const LF_JobSearch = ({
  rows,
  fromSearch,
  corporates,
  per_page,
  loading,
  page,
  handlePage,
  seeCandidate
}) => {
  const classes = useStyles();
  const [totalData, setTotalData] = React.useState(0);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    let a = (value - 1) * 20;
    handlePage(value);
    fromSearch('p_skip', a);
  };

  useEffect(() => {
    if (per_page?.length > 0) {
      const total = per_page[Object.keys(per_page)[0]];
      const totalData = total[Object.keys(total)[0]];
      setTotalData(totalData);
    }
  }, [per_page]);

  const onClick = (user) => {
    seeCandidate(user);
  };
  return (
    <div>
      <TableContainer className="pt-7 mx-auto">
        {loading ? (
          <div className="flex justify-center py-10">
            <CircularProgress />
          </div>
        ) : (
          <Table aria-label="simple table" className="border border-solid">
            <TableHead style={{ backgroundColor: '#F4F2FF', fontWeight: 600 }}>
              <TableRow>
                {columns &&
                  columns.map((e, key) => {
                    return (
                      <StyledTableCell key={key} width={e.width}>
                        <span className="font-semibold">{e.field}</span>
                      </StyledTableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows &&
                rows.map((e, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell height="20px" className="items-center">
                        <LF_Avatar src={e.avatar} />
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <LF_Infor
                          to={'search'}
                          name={(e?.last_name !== null || e?.first_name !== null)  ? `${e.last_name} ${e?.first_name}` : `Ứng viên`}
                          date={e.dob}
                          gender={e.gender}
                          address={e.province_name}
                          slug={e.slug}
                          covidStatus={e.covid_status}
                          onClick={(event) => onClick(e)}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <LF_Exp props={e?.exp.data ? e?.exp.data : ''} lcs={e?.lcs} province={e?.province_name} />
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center "
                      >
                        <LF_LiList props={e?.skill.data ? e?.skill.data : ''} />
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <LF_Education
                          props={e?.educations.data ? e?.educations.data : ''}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <span className={"text-[#03AB00]"}>{e.ipoint}</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <a
                          style={{
                            fontFamily: 'roboto',
                            color: '#B50829',
                            fontSize: '15px',
                          }}
                          className=" px-1  cursor-pointer hover:underline focus:outline-none"
                          // target="_blank"
                          // href={`https://i-hr.vn/profile/${e.slug}`}
                          onClick={(event) => onClick(e)}
                        >
                          Xem
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <div className="mx-auto lg:absolute lg:left-1/2 lg:text-xl">
                  <p className="py-2 whitespace-nowrap">Không tìm thấy ứng viên phù hợp</p>
                </div>
              )}
            </TableBody>
          </Table>
        )}

        <div className={`${classes.root} flex justify-end`}>
          <Pagination
            count={rows.length >= 1 ? Math.ceil(totalData / 20) : 0}
            variant="outlined"
            page={page}
            onChange={handleChangePage}
            shape="rounded"
          />
        </div>
      </TableContainer>
    </div>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ECEAF5',
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const columns = [
  {
    field: 'Ảnh',
    width: '5%',
  },
  {
    field: 'Họ và tên',
    width: '15%',
  },
  {
    field: 'Thông tin',
    width: '15%',
  },
  {
    field: 'Kỹ năng',
    width: '20%',
  },
  {
    field: 'Học vấn',
    width: '20%',
  },
  {
    field: 'i-Point',
    width: '10%',
  },
  {
    field: '',
    width: '1%',
  },
];
