import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CandidateRepository } from '../../../services/repositories/candidate.repository';

//Repository
const candidateRepository: CandidateRepository = RepositoryFactory.get(
  'candidate',
) as CandidateRepository;

const setStatus = (status) => {
  switch (status) {
    case 1:
      return 'Mới ứng tuyển';
    case 2:
      return 'Phỏng vấn';
    case 3:
      return 'Đã chọn';
    case 4:
      return 'Từ chối';
    case 5:
      return 'Đã xóa';
    default:
      return;
  }
};

//Async Fetch
export const exportExcelCandidate = async (query?: any) => {
  const response = await candidateRepository.getCandidate(query);

  let result = { data: [], total_job: response.total_job };
  if (response.data && response.data.length > 0) {
    for (const item of response.data) {
      const fetchAll = await Promise.all([
        candidateRepository.getCandidateSkill(item.user_id),
        candidateRepository.getCandidateEducations(item.user_id),
      ]);
      result.data.push({
        ...item,
        skill: fetchAll[0].data.map((item) => item.skill_name).join(),
        educations: fetchAll[1].data.map((item) => item.school_name).join(),
        statusName: setStatus(item.status),
        genderText: item.gender == 1 ? 'Nam' : 'Nữ',
      });
    }
  }

  return result;
};

function* handlerExportExcelCandidate(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const candidate = yield call(exportExcelCandidate, action.payload);
    yield put({
      type: types.EXPORT_EXCEL_CANDIDATE_SUCCESS,
      payload: candidate,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.EXPORT_EXCEL_CANDIDATE_ERROR,
      payload: {},
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchExportExcelCandidate() {
  yield takeLatest(
    types.EXPORT_EXCEL_CANDIDATE_REQUEST,
    handlerExportExcelCandidate,
  );
}
