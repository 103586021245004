import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { JobsRepository } from 'services/repositories/jobs.repository';
import { AlertType, showNotification } from 'utils/commons/Alert';
import { fetchCandidate } from './../candidate/getCandidate.saga';

//Initial State
const jobsRepository: JobsRepository = RepositoryFactory.get(
  'jobs',
) as JobsRepository;

const fetchJobs = async (data) => {
  const response = await jobsRepository.updateJobs(data);
  return response;
};

function* updateJobs(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const jobs = yield call(fetchJobs, action.payload.data);
    const candidate = yield call(fetchCandidate, action.payload.query);
    yield put({ type: types.UPDATE_JOB_EMPLOYEE_SUCCESS, payload: jobs });

    yield put({ type: types.GET_CANDIDATE_SUCCESS, payload: candidate });
    showNotification({
      type: AlertType.SUCCESS,
      msg: 'Cập nhật thành công!',
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    //yield put({ type: types.UPDATE_JOB_ERROR, payload: err.response.data });
    showNotification({
      type: AlertType.ERROR,
      msg: 'Cập nhật thất bại! Vui lòng kiểm tra lại thông tin!',
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchUpdateStatusJobs() {
  yield takeLatest(types.UPDATE_JOB_EMPLOYEE_REQUEST, updateJobs);
}
