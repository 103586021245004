/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useHistory } from 'react-router-dom';
import ihrLogo from 'assets/images/ihr_logo.png';
import LogoiHR from './../../../assets/images/Logo_rounded_512.png';

//Context
import { useAuth } from './../../wrapper/Auth.wrapper';

export default function Login() {
  //Router
  const history = useHistory();

  //State
  const [user, setUser] = React.useState({
    username: '',
    password: '',
  });
  const [show, setShow] = React.useState(false);
  const [validate, setValidate] = React.useState({
    username: '',
    password: '',
  });

  //Context
  const auth = useAuth();

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmitLogin = (e) => {
    const checkValid = onValidation(user);
    if (checkValid) {
      e.preventDefault();
      auth
        .signIn(user)
        .then((res) => {
          if (res) {
            return window.location.replace('/admin/dashboard');
          }
        })
        .catch((err) => { });
    }
  };

  const onValidation = (data) => {
    let validation = { ...validate };
    let check = true;
    if (data.username.length === 0) {
      validation.username = 'Vui lòng nhập tài khoản';
      check = false;
    }
    // if (
    //   data.username.length > 0 &&
    //   !/[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}/gim.test(data.username)
    // ) {
    //   validation.username = 'Email không đúng định dạng';
    //   check = false;
    // }
    if (data.password.length === 0) {
      validation.password = 'Vui lòng nhập mật khẩu';
      check = false;
    }

    if (check) {
      setValidate({
        username: '',
        password: '',
      });
      return true;
    } else {
      setValidate(validation);
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-full min-h-screen">
        <div
          className="mx-auto flex flex-col items-center justify-center min-h-screen h-auto"
          style={{ background: '#E5E5E5' }}
        >
          <div className="relative flex flex-col items-center pb-4">
            <img src={LogoiHR} alt="..." className="h-28 w-28" />
          </div>
          <div
            className="relative flex flex-col items-center bg-white rounded-xl border border-gray-500 border-solid pb-6 lg:min-w-[400px] lg:min-h-[512px]"
          // style={{ minHeight: '512px', width: '413px' }}
          >
            <div className="py-10">
              <p className="capitalize text-center  text-4xl text-black">
                Đăng nhập
              </p>
              <p className="capitalize font-normal text-sm text-gray-500 pt-4 text-center">
                Dành cho nhà tuyển dụng
              </p>
            </div>
            <form
              action=""
              className="w-full px-10 flex flex-col"
              style={{ padding: '0 50px' }}
              onSubmit={onSubmitLogin}
            >
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="username"
                >
                  Tên đăng nhập <span style={{ color: '#B50829' }}>*</span>
                </label>
                <input
                  className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  name="username"
                  type="text"
                  onChange={onChange}
                  placeholder="Tên đăng nhập"
                />
                <span className="text-sm" style={{ color: '#B50829' }}>
                  {validate.username !== '' ? validate.username : ''}
                </span>
              </div>
              <div className="mb-2 items-center">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Mật khẩu <span style={{ color: '#B50829' }}>*</span>
                </label>
                <div className="w-full relative overflow-hidden">
                  <input
                    className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="*********"
                    type={show ? 'text' : 'password'}
                    name="password"
                    onChange={onChange}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setShow(!show);
                    }}
                    className="absolute flex flex-col pb-1 right-2 top-4 focus:outline-none"
                  >
                    <i className="transition-none text-gray-400 hover:text-[#B50829]">
                      {show ? (
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth={0}
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                          />
                        </svg>
                      ) : (
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth={0}
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                          />
                        </svg>
                      )}
                    </i>
                  </button>
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.password !== '' ? validate.password : ''}
                  </span>
                </div>
              </div>
              <a
                href="#"
                onClick={() => {
                  history.push('/auth/forgotpassword');
                }}
                className="capitalize text-sm text-right cursor-pointer"
                style={{ color: '#B50829' }}
              >
                Quên mật khẩu ?
              </a>
              <div className="mt-7 text-center">
                <button
                  type="submit"
                  className="uppercase text-[18px] text-white px-3 w-3/4 rounded py-3 focus:outline-none"
                  style={{ background: '#B50829' }}
                >
                  Đăng nhập
                </button>
              </div>
              <div className="mt-6 text-center">
                <a
                  href="#"
                  className="font-bold text-sm text-opacity-75 text-black  hover:text-[#B50829] cursor-pointer"
                  onClick={() => {
                    history.push('/auth/register');
                  }}
                >
                  Đăng ký tài khoản nhà tuyển dụng
                </a>
              </div>
            </form>
          </div>
          <div
            className={
              ' flex flex-col lg:flex-row justify-center text-black text-opacity-75 text-sm lg:min-w-[400px] text-center'
            }
          >
            <div className="p-3">
              <a href="#">Trợ giúp</a>
            </div>
            <div className="p-3">
              <a href="#">Chính sách bảo mật</a>
            </div>
            <div className="p-3">
              <a href="#">Điều khoản sử dụng</a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
