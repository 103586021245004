import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CandidateRepository } from '../../../services/repositories/candidate.repository';
import { CoursesRepository } from 'services/repositories/course.repository';

//Repository
const studentRepository = RepositoryFactory.get('course') as CoursesRepository;
const candidateRepository: CandidateRepository = RepositoryFactory.get(
  'candidate',
) as CandidateRepository;

const setStatus = (status) => {
  switch (status) {
    case 1:
      return 'Đã đăng ký';
    case 2:
      return 'Trúng tuyển';
    case 3:
      return 'Đã chọn';
    case 4:
      return 'Từ chối';
    case 5:
      return 'Đã xóa';
    default:
      return;
  }
};

const renderLearningForm = (value) => {
  switch (value) {
    case '0':
      return 'Học trực tuyến (Online)';
    case '1':
      return 'Học tập trung (Offline)';
    default:
      return 'Học bất kỳ (Online hoặc Offline)';
  }
};

//Async Fetch
export const exportExcelStudent = async (query?: any) => {
  const response = await studentRepository.getStudentById(query);
  let result = { data: [], total_data: response.total_job };
  if (response.data && response.data.length > 0) {
    for (const item of response.data) {
      const getInfo = await candidateRepository.getCandidateCareers(
        item.user_id,
      );
      result.data.push({
        ...item,
        infor: getInfo.data.map((item) => item.name).join(),
        statusName: setStatus(item.status),
        genderText: item.gender == 1 ? 'Nam' : 'Nữ',
        dob: `${('0' + new Date(item.dob).getDate()).slice(-2)}/${(
          '0' +
          (new Date(item.dob).getMonth() + 1)
        ).slice(-2)}/${new Date(item.dob).getFullYear()}`,
        applied_date: `${('0' + new Date(item.applied_date).getDate()).slice(
          -2,
        )}/${('0' + (new Date(item.applied_date).getMonth() + 1)).slice(
          -2,
        )}/${new Date(item.applied_date).getFullYear()}`,
        information: `${item.discharged_army === 1 ? 'Bộ đội xuất ngũ,' : ''} ${
          item.study_ticket ? `${item.study_ticket},` : ''
        } ${renderLearningForm(item.learning_form)}`,
      });
    }
  }
  return result;
};

function* handlerExportExcelStudent(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const candidate = yield call(exportExcelStudent, action.payload);

    yield put({ type: types.EXPORT_EXCEL_STUDENT_SUCCESS, payload: candidate });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.EXPORT_EXCEL_STUDENT_ERROR,
      payload: err.response.data,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchExportExcelStudent() {
  yield takeLatest(
    types.EXPORT_EXCEL_STUDENT_REQUEST,
    handlerExportExcelStudent,
  );
}
