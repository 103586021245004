import { CoursesInterface } from 'utils/interfaces';
import * as types from '../type';

interface IInitialState {
  courses: { data: []; total_course: any };
  course: {} | any;
  courseActive: [];
  students: { data: []; total_data: any };
  status: any;
  statusApply: any;
  oldPackages: null;
}
const initialState: IInitialState = {
  courses: { data: [], total_course: 0 },
  course: {},
  courseActive: [],
  students: { data: [], total_data: null },
  status: null,
  statusApply: null,
  oldPackages: null,
};

const coursesReducers = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        status: null,
      };
    case types.GET_COURSES_BY_ID_SUCCESS:
      return {
        ...state,
        course: action.payload,
        status: null,
      };
    case types.GET_COURSES_BY_ID_ERROR:
      return {
        ...state,
        status: action.payload,
      };
    case types.CREATE_COURSES_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case types.CREATE_COURSES_ERROR:
      return {
        ...state,
        status: action.payload,
      };
    case types.DELETE_COURSES_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case types.DELETE_COURSES_ERROR:
      return {
        ...state,
        status: action.payload,
      };
    case types.GET_STUDENT_BY_ID_SUCCESS:
      return { ...state, students: action.payload };
    case types.GET_ACTIVE_COURSE_SUCCESS:
      return { ...state, courseActive: action.payload };
    case types.UPDATE_COURSES_SUCCESS:
      return { ...state, status: action.payload };
    case types.UPDATE_COURSES_ERROR:
      return { ...state, status: action.payload };
    case types.RESET_STORE_COURSE:
      return {
        ...state,
        students: { ...state.students, data: null },
      };
    case types.UPDATE_APPLY_COURSE_SUCCESS:
      return {
        ...state,
        statusApply: action.payload,
      };
    case types.GET_OLD_PACKAGES_SUCCESS:
      return {
        ...state,
        oldPackages: action.payload,
      };
    case types.GET_OLD_PACKAGES_ERROR:
      return {
        ...state,
        oldPackages: action.payload,
      };
    default:
      return { ...state };
  }
};

export default coursesReducers;
