/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';

//Components
import { LF_Breadcumbs } from 'components/molecules';
import { LF_Dashboard } from 'components/organisms';

// React-Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getHome } from 'redux/actions';

export const DashBoard = () => {
  // react-redux
  const dispatch = useDispatch();
  const { home } = useSelector((state: RootState) => state.home);
  // useEffect
  React.useEffect(() => {
    dispatch(getHome());
  }, []);

  return (
    <React.Fragment>
      <LF_Breadcumbs
        route={`/admin/dashboard`}
        routeName={'Dashboard'}
        className="font-bold text-lg"
      />
      <LF_Dashboard data={home} />
    </React.Fragment>
  );
};
