/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { LF_ConfirmationProps } from './ConfirmModal.type';

import { LF_Modal } from '../Modal.molecules';

export const LF_ConfirmModal = ({
  open,
  className,
  title,
  onProceed,
  onCancel,
}: LF_ConfirmationProps) => {
  const modalBtns = [
    {
      label: 'Xác nhận',
      className: 'bg-[#B50829] text-white',
      action: () => {
        if (onProceed) onProceed();
      },
    },
    {
      label: 'Hủy',
      action: () => {
        if (onCancel) onCancel();
      },
    },
  ];
  return (
    open && (
      <LF_Modal
    btns={modalBtns}
    closeHandler={() => onCancel && onCancel()}
    className={`${className}`}
    header={title}
    />
    )
  );
};
