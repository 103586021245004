import { BaseRepository } from './base';
import { ICareerGroup } from '../../utils/interfaces';
import Repository from './base/repository';

export class CareerGroupRepository extends BaseRepository<ICareerGroup> {
  resource = 'career-group';
  token = localStorage.getItem('access-token');

  /**
   * Method search Career Group
   * @param query string
   * @return Array
   */
  async search(query: string) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.get(`${this.resource}/search?q=${query}`, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }
}
