import React from 'react';

// Component
import {
  InputBase,
  Grid,
  Theme,
  makeStyles,
  createStyles,
  Button,
} from '@material-ui/core';
import DatePicker from 'react-date-picker';
import { formatDateDDMMYYY } from "../../../../utils/commons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      padding: '4px 0 4px 17px',
      flex: 1,
      border: '1px solid rgba(0, 0, 0, 0.25);',
    },
    button: {
      backgroundColor: '#B50829 ',
      color: 'white',
      padding: '8px 14px',
      '&:hover': {
        backgroundColor: '#B50829 ',
      },
      '&:focus': {
        outline: '2px solid transparent',
        outlineOffset: '2px',
      },
    },
    buttonTab: {
      backgroundColor: 'white !improtant',
      color: '#000000 !important',
      fontWeight: 'bold',
      fontSize: '14px',
      minWidth: 100,
      textTransform: 'none',
      padding: '0 12px',
      opacity: 1,
      '&:focus': {
        outline: '2px solid transparent',
        outlineOffset: '2px',
        backgroundColor: '#B50829 !important',
        color: 'white !important',
      },
      '&:active': {
        backgroundColor: '#B50829 !important',
        color: 'white !important',
      },
      '&:hover': {
        backgroundColor: '#B50829 !important',
        color: 'white !important',
      },
    },
  }),
);

export const RecruitmentList = ({ onClick, fromSearch }) => {
  // Style
  const classes = useStyles();

  // State
  const [state, setState] = React.useState({
    start_date: new Date('2021-01-01'),
    end_date: new Date(),
    search_string: "",
  });

  // lấy data ngày hôm nay
  const date = new Date(Date.now());
  var req_date = date.getFullYear().toString() + '-';
  const mon = (date.getMonth() + 1).toString();
  if (mon.length == 1) {
    req_date += '0' + mon + '-';
  } else {
    req_date += mon + '-';
  }
  var da = date.getDate().toString();
  if (da.length == 1) {
    req_date += '0' + da;
  } else {
    req_date += da;
  }

  // Handle event
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = () => {
    fromSearch({ ...state, start_date: formatDateDDMMYYY(state.start_date), end_date: formatDateDDMMYYY(state.end_date) });
  };

  return (
    <>
      <div className=" list-table xl:pl-5 lg:px-3">
        <div className={"flex flex-col xl:flex-row mt-10"}>
          <div className={"w-full xl:max-w-[200px] xl:max-w-[400px]"}>
            <InputBase
              style={{ minHeight: '52px' }}
              name="search_string"
              className={`${classes.input}`}
              onChange={handleChange}
              placeholder="Tìm kiếm ..."
              inputProps={{ 'aria-label': 'Tìm kiếm ...' }}
            />
          </div>
          <div className={'flex items-center w-full xl:max-w-[400px] mt-4 xl:ml-4 xl:mt-0 p-1'} style={{ border: '1px solid rgba(0, 0, 0, 0.25)', justifyContent: 'space-evenly' }}>
            <DatePicker
              format={"dd-MM-yyyy"}
              value={state.start_date}
              onChange={(value) => setState({ ...state, start_date: value })}
            />
            <i className="fas fa-arrow-right text-lg "></i>
            <DatePicker
              format={"dd-MM-yyyy"}
              value={state.end_date}
              onChange={(value) => setState({ ...state, end_date: value })}
            />
          </div>
          <div className="mx-4 mt-4 lg:mx-0 xl:ml-4 xl:mt-0">
            <Button
              className={`${classes.button}`}
              onClick={handleClick}
              style={{ textTransform: 'none', padding: '14px 16px' }}
            >
              Tìm kiếm
            </Button>
          </div>
          <div className="mx-4 mt-4 lg:mx-0 xl:mt-0 xl:ml-auto" >
            <Button
              variant="contained"
              color="default"
              className={`${classes.button} `}
              style={{ padding: '14px 16px' }}
              startIcon={<i className="fas fa-plus"></i>}
              onClick={() => {
                onClick();
              }}
            >
              Đăng tuyển
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
