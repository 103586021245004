import * as types from '../type';

interface IInitialState {
  careerCodes: [];
  careerCode: any | {};
  status: any;
}

const initialState: IInitialState = {
  careerCodes: [],
  careerCode: {},
  status: null,
};

const careerCodeReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.GET_CAREER_CODE_SUCCESS: {
      const career_code: [] = action.payload;

      return { ...state, careerCodes: career_code.length > 0 && career_code };
    }

    default:
      return {
        ...state,
      };
  }
};

export default careerCodeReducer;
