import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { PositionRepository } from '../../../services/repositories/position.repository';

//Repository
const positionRepository: PositionRepository = RepositoryFactory.get(
  'position',
) as PositionRepository;

//Async Fetch
const fetchPositions = async (query: string) => {
  const response = await positionRepository.search(query);
  return response;
};

function* handlerSearchPosition(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const positions = yield call(fetchPositions, action.payload);

    yield put({ type: types.SEARCH_POSITION_SUCCESS, payload: positions });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.SEARCH_POSITION_ERROR,
      payload: err.response.data,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchSearchPosition() {
  yield takeLatest(types.SEARCH_POSITION_REQUEST, handlerSearchPosition);
}
