/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LF_Breadcumbs } from 'components/molecules';
import {
  LF_BasicInfo,
  LF_DetailsInfo,
  LF_Done,
  LF_Review,
} from 'components/organisms';

//Types
import { RootState } from '../../../../../redux/store';

//Actions
import {
  getProvince,
  searchCareer,
  searchSkill,
  searchPosition,
  createJob,
  getDistrictFromProvince,
  getPackages,
  getPackageTime, getIPoint, getVipPackage, getOldPackages,
} from '../../../../../redux/actions';

// Token
import { getUserDetailsWithToken } from 'views/wrapper/Auth.wrapper';

// Interfaces
import { IJobs } from 'utils/interfaces';

// Commons
import { stringToSlug, countLengthNotHTML, validURL, formatDate, formatDateDDMMYYY } from 'utils/commons';

export const Post = () => {
  // Get token -> get corporate_code
  const corporate_code = getUserDetailsWithToken()?.corporate_code;

  // Dispatch
  const dispatch = useDispatch();
  const { searchcareers, provinces, skills, positions, jobs, districts, packages, packageTime, ipoint } =
    useSelector((state: RootState) => state);
  const { status, job, oldPackages } = jobs;
  // State
  const [listDistrict, setListDistrict] = useState([]);
  const [openBegin, setOpenBegin] = useState(true);
  const [open, setOpen] = useState({
    basicInfo: false,
    detailsInfo: false,
    review: false,
  });

  const [listStep, setListStep] = useState([
    { name: 'Thông tin cơ bản', isActive: false },
    { name: 'Thông tin chi tiết', isActive: false },
    { name: 'Xem lại', isActive: false },
    { name: 'Hoàn tất', isActive: false },
  ]);

  const [formValue, setFormValue] = useState({
    basicInfo: {
      jobTitle: '',
      desc: '',
      require: '',
      welfare: '',
    },
    detailsInfo: {
      // Required
      listChooseCareers: [],
      listChooseSkills: [],
      level: '',

      // Non - required
      position: null,
      experience: null,
      startDay: new Date(),
      deadline: new Date(),
      address: '',
      city: '',
      city_text: '',
      district: '',
      district_text: '',
      agreedSalary: 0,
      salaryFrom: null,
      salaryTo: null,
      jobType: 'jot01',

      //New
      degree: '',
      gender: 0,
      minAge: null,
      maxAge: null,
    },
  });

  const [valid, setValid] = useState({
    jobTitle: '',
    desc: '',
    require: '',
    welfare: '',

    startDay: '',
    deadline: '',
    salaryFrom: '',
    salaryTo: '',
    address: '',
    city: '',
    district: '',

    listChooseCareers: '',
    listChooseSkills: '',

    minAge: '',
    maxAge: '',
  });

  // State add field address
  // const [quantityAddress, setQuantityAddress] = useState([1]);

  // State list add choose
  // Careers
  const [listChoose, setListChoose] = useState([]);
  // Skills
  const [listChooseSkill, setListChooseSkill] = useState([]);

  // State check is posted
  const [isPosted, setIsPosted] = useState(false);

  // Handle CkEditor5
  const handleCKEditor = (name, data) => {
    setFormValue((prevState) => {
      Object.assign(prevState.basicInfo, { [name]: data });
      return { ...prevState };
    });
  };

  // Handle on change input
  const onChangeInput = (name, value, keyInState) => {
    setFormValue((prevState) => {
      Object.assign(prevState[keyInState], {
        [name]: value,
      });
      return { ...prevState };
    });
  };

  // Handle on change attach code
  const handleOnChangeAttachCode = (name, value, text, keyInState) => {
    // console.log({name, value, text, keyInState})
    if (name === 'city' && value.includes('pro')) {
      dispatch(getDistrictFromProvince(value));
      setFormValue((prevState) => {
        Object.assign(prevState[keyInState], {
          district: '',
          district_text: '',
        });
        return { ...prevState };
      });
    }

    setFormValue((prevState) => {
      Object.assign(prevState[keyInState], {
        [name]: value,
        [`${name}_text`]: text,
      });
      return { ...prevState };
    });
  };

  //Query
  const [query, setQuery] = useState(null);
  const [querySkill, setQuerySkill] = useState(null);

  // Handle click step menu
  const changeCssMenu = (index) => {
    const newList = [...listStep];
    setListStep((prevState) => {
      for (let i = 0; i < newList.length; i++) {
        if (index === i) {
          Object.assign(newList[i], { isActive: true });
          continue;
        }
        Object.assign(newList[i], { isActive: false });
      }
      return [...prevState];
    });
  };

  // Handle click next step
  const handleClickNextStep = (key: string) => {
    const isPass = messageValidator(formValue[key]);

    if (isPass && key === 'basicInfo') {
      setOpen((prevState) => ({ ...prevState, [key]: true }));
      setOpenBegin(false);

      // Set menu step
      changeCssMenu(1);
    }
    if (isPass && key === 'detailsInfo') {
      setOpen((prevState) => ({ ...prevState, [key]: true, basicInfo: false }));
      setOpenBegin(false);

      // Set menu step
      changeCssMenu(2);
    }
    if (isPass && key === 'review') {
      // Form value submit
      if (!isPosted) {
        const { basicInfo, detailsInfo } = { ...formValue };
        const skills = detailsInfo.listChooseSkills
          ?.map((skill) => skill.skill_code)
          .join();

        const careers = detailsInfo.listChooseCareers
          ?.map((career) => career.career_code)
          .join();

        const slug = `${stringToSlug(basicInfo.jobTitle)}-${Math.round(
          Date.now() / 1000,
        )}`;
        const submitValues: IJobs = {
          p_slug: slug,
          p_corporate_code: corporate_code,
          p_title: basicInfo.jobTitle,
          p_job_position_code: detailsInfo.position,
          p_position: detailsInfo.level,
          p_description: basicInfo.desc,
          p_requirement: basicInfo.require,
          p_benefit: basicInfo.welfare,
          p_job_type_code: detailsInfo.jobType,
          p_career_code: careers,
          p_skills: skills,
          p_experience: detailsInfo.experience || null,
          p_start_date: formatDateDDMMYYY(detailsInfo.startDay),
          p_end_date: formatDateDDMMYYY(detailsInfo.deadline),
          p_min_salary: Number(detailsInfo.salaryFrom),
          p_max_salary: Number(detailsInfo.salaryTo),
          p_agreed_salary: detailsInfo.agreedSalary,
          address: detailsInfo.address,
          province_code: detailsInfo.city,
          district_code: detailsInfo.district,
          p_required_degree: detailsInfo.degree,
          p_required_gender: detailsInfo.gender,
          p_min_age: Number(detailsInfo.minAge),
          p_max_age: Number(detailsInfo.maxAge),
        };
        dispatch(createJob(submitValues));
      }

      setOpen((prevState) => ({
        ...prevState,
        [key]: true,
        detailsInfo: false,
      }));
      setOpenBegin(false);

      // Set menu step
      changeCssMenu(3);
    }
  };

  // Handle click back
  const handleClickBack = (key: string) => {
    if (key === 'detailsInfo') {
      setOpen((prevState) => ({
        ...prevState,
        basicInfo: false,
      }));
      setOpenBegin(true);

      // Set menu step
      changeCssMenu(0);
    }

    if (key === 'review') {
      setOpen((prevState) => ({
        ...prevState,
        basicInfo: true,
        detailsInfo: false,
      }));

      // Set menu step
      changeCssMenu(1);
    }
  };

  // Handle add field
  // const handleAddField = () => {
  //   const newArray = [...quantityAddress];
  //   newArray.push(1);

  //   setFormValue((prevState) => {
  //     Object.assign(prevState.detailsInfo, {
  //       [`address${newArray.length}`]: '',
  //       [`city${newArray.length}`]: '',
  //     });
  //     return { ...prevState };
  //   });

  //   setValid((prevState) => ({
  //     ...prevState,
  //     [`address${newArray.length}`]: '',
  //     [`city${newArray.length}`]: '',
  //   }));

  //   setQuantityAddress(newArray);
  // };

  // Handle delete field
  // const handleDeleteField = async () => {
  //   const newArray = [...quantityAddress];

  //   if (newArray.length > 1) {
  //     await setFormValue((prevState) => {
  //       delete prevState.detailsInfo[`address${newArray.length}`];
  //       delete prevState.detailsInfo[`city${newArray.length}`];
  //       return { ...prevState };
  //     });

  //     await setValid((prevState) => {
  //       delete prevState[`address${newArray.length}`];
  //       delete prevState[`city${newArray.length}`];
  //       return { ...prevState };
  //     });

  //     newArray.pop();
  //   }

  //   setQuantityAddress(newArray);
  // };

  // Handle on change salary field
  const handleOnChangeSalary = (isChecked: boolean) => {
    if (isChecked) {
      setFormValue((prevState) => {
        Object.assign(prevState.detailsInfo, {
          agreedSalary: 1,
          salaryFrom: 0,
          salaryTo: 0,
        });
        return { ...prevState };
      });
    }
    if (!isChecked) {
      setFormValue((prevState) => {
        Object.assign(prevState.detailsInfo, {
          agreedSalary: 0,
          salaryFrom: null,
          salaryTo: null,
        });
        return { ...prevState };
      });
    }
  };

  // Handle search field
  const handleSearch = (value) => {
    if (value && value !== '') dispatch(searchCareer(value));
    if (value === '') dispatch(searchCareer(''));
  };

  const handleSearchSkill = (value) => {
    if (value && value !== '') dispatch(searchSkill(value));
    if (value === '') dispatch(searchSkill(''));
  };

  // Handle add choose
  const handleAddChoose = (data) => {
    const mapList = [...listChoose];
    const checkDuplicate = mapList?.filter(
      (item) => item.career_code === data.career_code,
    );

    if (checkDuplicate.length === 0)
      setListChoose((prevState) => [...prevState, data]);
  };

  const handleAddChooseSkill = (data) => {
    const mapList = [...listChooseSkill];
    const checkDuplicate = mapList?.filter(
      (item) => item.skill_code === data.skill_code,
    );

    if (checkDuplicate.length === 0)
      setListChooseSkill((prevState) => [...prevState, data]);
  };

  // Handle remove choose
  const handleRemoveChoose = (code: string) => {
    const mapList = [...listChoose];
    const newList = mapList?.filter((item) => item.career_code !== code);
    setListChoose(newList);
  };

  const handleRemoveChooseSkill = (code: string) => {
    const mapList = [...listChooseSkill];
    const newList = mapList?.filter((item) => item.skill_code !== code);
    setListChooseSkill(newList);
  };

  const handleClickStepMenu = (index) => {
    if (index === 0) {
      changeCssMenu(index);
      setOpen({
        basicInfo: false,
        detailsInfo: false,
        review: false,
      });
      setOpenBegin(true);
    }
    if (!isPosted && index === 3) return;
    if (isPosted && index === 3) {
      changeCssMenu(index);
      setOpen({
        basicInfo: false,
        detailsInfo: false,
        review: true,
      });
      setOpenBegin(false);
    }
    if (index !== 0 && index !== 3) {
      const isPass =
        index === 1
          ? messageValidator(formValue.basicInfo)
          : messageValidator(formValue.detailsInfo) &&
          messageValidator(formValue.basicInfo);
      if (isPass) {
        changeCssMenu(index);
        setOpen({
          basicInfo: index === 1 ? true : false,
          detailsInfo: index === 2 ? true : false,
          review: false,
        });
        setOpenBegin(false);
      }
    }
  };

  // Handle show message validator
  const messageValidator = (object) => {
    let flag = true;
    let validation = { ...valid };

    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const element = object[key];

        // Dùng else để khi next step sẽ clear msg invalid
        if (key === 'jobTitle') {
          if (element === '') {
            validation.jobTitle = 'Tiêu đề công việc không được để trống!';
            flag = false;
          } else if (element !== '' && element.length < 6) {
            validation.jobTitle = 'Tiêu đề công việc chứa tối thiểu 6 ký tự!';
            flag = false;
          } else {
            validation.jobTitle = '';
          }
        }

        if (key === 'desc') {
          if (element === '') {
            validation.desc = 'Mô tả công việc không được để trống!';
            flag = false;
          } else if (countLengthNotHTML(element) > 2000) {
            validation.desc = 'Mô tả công việc không được lớn hơn 2000 ký tự';
            flag = false;
          } else if (!validURL(element)) {
            validation.desc = 'Nội dung không được chứa liên kết';
            flag = false;
          } else {
            validation.desc = '';
          }
        }

        if (key === 'require') {
          if (element === '') {
            validation.require = 'Yêu cầu công việc không được để trống!';
            flag = false;
          } else if (countLengthNotHTML(element) > 2000) {
            validation.require =
              'Yêu cầu công việc không được lớn hơn 2000 ký tự';
            flag = false;
          } else if (!validURL(element)) {
            validation.require = 'Nội dung không được chứa liên kết';
            flag = false;
          } else {
            validation.require = '';
          }
        }

        if (key === 'welfare') {
          if (element === '') {
            validation.welfare = 'Phúc lợi không được để trống!';
            flag = false;
          } else if (countLengthNotHTML(element) > 2000) {
            validation.welfare = 'Phúc lợi không được lớn hơn 2000 ký tự';
            flag = false;
          } else if (!validURL(element)) {
            validation.welfare = 'Nội dung không được chứa liên kết';
            flag = false;
          } else {
            validation.welfare = '';
          }
        }

        if (key === 'listChooseCareers') {
          if (element.length === 0) {
            validation.listChooseCareers = 'Vui lòng chọn ít nhất 1 lĩnh vực';
            flag = false;
          }
          if (element.length > 0) {
            validation.listChooseCareers = '';
          }
        }

        if (key === 'listChooseSkills') {
          if (element.length === 0) {
            validation.listChooseSkills =
              'Vui lòng chọn ít nhất 1 kỹ năng chuyên môn';
            flag = false;
          }
          if (element.length > 0) {
            validation.listChooseSkills = '';
          }
        }
        /*
        if (key === 'startDay') {
          if (element !== null) {
            const checkDate =
              new Date(formValue.detailsInfo.startDay).setHours(0, 0, 0, 0) >=
              new Date().setHours(0, 0, 0, 0);
            if (!checkDate) {
              validation.startDay = 'Ngày tuyển dụng phải từ hôm nay!';
              flag = false;
            }
            if (checkDate) {
              validation.startDay = '';
            }
          }
        }

        if (key === 'deadline') {
          if (element === null) {
            validation.deadline = 'Vui lòng chọn hạn tuyển dụng';
            flag = false;
          } else if (element !== null) {
            const checkDate =
              new Date(formValue.detailsInfo.deadline).getTime() >
              new Date(formValue.detailsInfo.startDay).getTime();
            if (!checkDate) {
              validation.deadline =
                'Hạn tuyển dụng phải lớn hơn ngày tuyển dụng!';
              flag = false;
            }
            if (checkDate) {
              validation.deadline = '';
            }
          }
        }
        */
        if (key.includes('address')) {
          if (element === '') {
            validation[key] = 'Địa chỉ không được để trống!';
            flag = false;
          }
          if (element !== '') {
            validation[key] = '';
          }
        }

        if (key.includes('city')) {
          if (element === '') {
            validation[key] = 'Thành phố không được để trống!';
            flag = false;
          }
          if (element !== '') {
            validation[key] = '';
          }
        }

        if (key === 'district') {
          if (element === '') {
            validation.district = 'Quận không được để trống!';
            flag = false;
          }
          if (element !== '') {
            validation.district = '';
          }
        }

        if (key === 'salaryFrom') {
          if (element === null || element === '') {
            validation.salaryFrom = 'Nhập mức lương khởi điểm!';
            flag = false;
          } else if (element < 0) {
            validation.salaryFrom = 'Mức lương tối thiểu không được nhỏ hơn 0';
            flag = false;
          } else {
            validation.salaryFrom = '';
          }
        }

        if (key === 'salaryTo') {
          if (element === null || element === '') {
            validation.salaryTo = 'Nhập mức lương tối đa!';
            flag = false;
          } else if (element > 999) {
            validation.salaryTo = 'Mức lương tối đa không được lớn hơn 999';
            flag = false;
          } else if (parseInt(element) < parseInt(object['salaryFrom'])) {
            validation.salaryTo =
              'Mức lương tối đa không được nhỏ hơn mức lương tối thiểu';
            flag = false;
          } else {
            validation.salaryTo = '';
          }
        }

        if (key === 'minAge') {
          if (element && element < 16) {
            validation.minAge = 'Tuổi tối thiểu không được nhỏ hơn 16 tuổi';
            flag = false;
          } else if (element < 0) {
            validation.minAge = 'Tuổi tối thiểu không được nhỏ hơn 0';
            flag = false;
          } else {
            validation.minAge = '';
          }
        }

        if (key === 'maxAge') {
          if (element && element > 99) {
            validation.maxAge = 'Tuổi tối đa không được lớn hơn 99 tuổi';
            flag = false;
          } else if (element < 0) {
            validation.maxAge = 'Tuổi tối đa không được nhỏ hơn 0';
            flag = false;
          } else if (parseInt(element) < parseInt(object['minAge'])) {
            validation.maxAge = 'Tuổi tối đa không được nhỏ hơn tuổi tối thiểu';
            flag = false;
          } else {
            validation.maxAge = '';
          }
        }
      }
    }

    if (flag) {
      setValid({
        jobTitle: '',
        desc: '',
        require: '',
        welfare: '',

        startDay: '',
        deadline: '',
        salaryFrom: '',
        salaryTo: '',
        address: '',
        city: '',
        district: '',

        listChooseCareers: '',
        listChooseSkills: '',

        minAge: '',
        maxAge: '',
      });
      return true;
    } else {
      setValid(validation);
      return false;
    }
  };

  // Effect
  useEffect(() => {
    const newList = [...listStep];
    setListStep((prevState) => {
      Object.assign(newList[0], { isActive: true });
      return [...prevState];
    });

    // Get provinces
    dispatch(getProvince());
    dispatch(getPackages());
    dispatch(getPackageTime());
    dispatch(getVipPackage());
    dispatch(getIPoint(corporate_code));
    // Get positions
    dispatch(searchPosition(''));
    dispatch(searchCareer(''));
    dispatch(searchSkill(''));
    dispatch(getOldPackages(null, "job"));
  }, []);
  useEffect(() => {
    setFormValue((prevState) => {
      Object.assign(prevState.detailsInfo, { listChooseCareers: listChoose });
      return { ...prevState };
    });
  }, [listChoose]);

  useEffect(() => {
    setFormValue((prevState) => {
      Object.assign(prevState.detailsInfo, {
        listChooseSkills: listChooseSkill,
      });
      return { ...prevState };
    });
  }, [listChooseSkill]);

  useEffect(() => {
    setListDistrict(districts.districts);
  }, [districts]);

  useEffect(() => {
    if (typeof status === 'object' && status?.status === 400)
      setIsPosted(false);
    else if (status === null) setIsPosted(false);
    else if (!isNaN(status)) setIsPosted(true);
  }, [status]);

  return (
    <div className={"w-full max-w-full"}>
      <LF_Breadcumbs
        route={`/admin/job/post`}
        routeName={'Đăng tin tuyển dụng'}
        className="font-bold text-lg"
      />

      <section className="py-5">
        <div className="flex justify-start lg:justify-center items-center relative">
          <hr className="absolute border-t-2 border-gray-400 border-solid w-full lg:w-3/5 hidden lg:block"></hr>
          <div className="flex flex-col lg:flex-row items-center justify-between w-full lg:w-3/5">
            {listStep &&
              listStep.length > 0 &&
              listStep?.map((step, index) => (
                <div
                  key={index}
                  className="lg:px-8 mt-8 lg:mt-0 bg-white z-10 relative cursor-pointer"
                  onClick={() => handleClickStepMenu(index)}
                >
                  <p
                    className={`w-8 h-8 leading-8 rounded-full text-white flex justify-center items-center ${step.isActive ? 'bg-[#B50829]' : 'bg-gray-400'
                      }`}
                  >
                    <span>{index + 1}</span>
                  </p>
                  <p
                    className={`absolute w-max left-1/2 transform -translate-x-1/2 pt-1 ${step.isActive ? 'font-bold' : ''
                      }`}
                  >
                    {step.name}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </section>

      {/* Body */}
      <section className="p-4 lg:pt-10 lg:px-14">
        {openBegin && (
          <LF_BasicInfo
            data={formValue}
            onChangeCKE={handleCKEditor}
            onChangeInput={onChangeInput}
            onClickNextStep={handleClickNextStep}
            msgCheckValid={valid}
          />
        )}

        {/* Nó = true thì component kế sẽ show */}
        {open.basicInfo === true && (
          <LF_DetailsInfo
            data={formValue}
            onChangeInput={onChangeInput}
            onClickNextStep={handleClickNextStep}
            onClickBack={handleClickBack}
            // onClickAddField={handleAddField}
            // onClickDeleteField={handleDeleteField}
            // quantityAddress={quantityAddress}
            onChangeSalaryField={handleOnChangeSalary}
            onChangeAttachCode={handleOnChangeAttachCode}
            msgCheckValid={valid}
            listProvinces={provinces && provinces.provinces}
            listDistricts={listDistrict}
            listPositions={positions && positions.positions}
            // ----------- //
            listShow={searchcareers && searchcareers.searchCareers}
            listChoose={listChoose}
            onClickAddChoose={handleAddChoose}
            onClickRemoveChoose={handleRemoveChoose}
            query={query}
            setQuery={setQuery}
            onChangeSearch={handleSearch}
            // ----------- //
            listShowSkill={skills && skills.skills}
            listChooseSkill={listChooseSkill}
            onClickAddChooseSkill={handleAddChooseSkill}
            onClickRemoveChooseSkill={handleRemoveChooseSkill}
            querySkill={querySkill}
            setQuerySkill={setQuerySkill}
            onChangeSearchSkill={handleSearchSkill}
          />
        )}
        {open.detailsInfo === true && (
          <LF_Review
            data={formValue}
            onClickNextStep={handleClickNextStep}
            onClickBack={handleClickBack}
          // quantityAddress={quantityAddress}
          />
        )}
        {open.review === true && (
          <LF_Done
            ipoint={ipoint && ipoint.ipoint}
            listPackage={packages && packages.packages}
            listPackageTime={packageTime && packageTime.packageTime}
            statusJob={'create'}
            job={job && job}
            status={status && status}
            oldPackages={oldPackages && oldPackages}
          />
        )}
      </section>
    </div>
  );
};
