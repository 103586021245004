import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import ModalDetailTransaction from '../../../molecules/Modal/ModalDetailTransaction';
import OptionPackageItem from '../../../molecules/Table/OptionPackageItem';
import ipointFormat from '../../../../utils/commons/math/ipointFormat';
import { Payment } from "../Payment";

export const CLC_IPointBuy = ({ rows }) => {
  const { loading } = useSelector((state) => state['loading']);
  const [showModal, setShowModal] = useState(false);
  const [totalIPoint, setTotalIPoint] = useState(0);
  const [historyItem, setHistoryItem] = useState({});
  const [listPackage, setListPackage] = useState([]);
  const [openPayment, setOpenPayment] = useState(false);

  const handleModal = (e, name: string, item) => {
    setHistoryItem(item ? item : {});
    setShowModal(name !== 'close')
  }

  const handleChangePackage = (packageObject) => {
    const _listPackage = listPackage.map(_package => {
      if (_package.name !== packageObject.packageName) {
        return _package;
      }
      return {
        ..._package,
        amount: packageObject.option * packageObject.count * 1000,
        point: ((packageObject.option * packageObject.sale / 100) + packageObject.option) * packageObject.count,
        quantity: packageObject.count,
        sale: packageObject.sale,
        packageOption: packageObject.packageOption || _package.packageOption,
        package_option_id: packageObject.package_option_id || _package.package_option_id,
      }
    });
    const total = _listPackage.reduce((_total, _package) => {
      if (_package.name === packageObject.packageName) {
        _total += packageObject.option * packageObject.count * 1000;
      } else {
        _total += _package.amount;
      }
      return _total;
    }, 0);
    setTotalIPoint(total);
    setListPackage(_listPackage);
  }
  const payment = () => {
    if (totalIPoint === 0) {
      alert('Bạn chưa chọn gói nào để thanh toán!');
      return;
    }
    setOpenPayment(true);
  }

  useEffect(() => {
    if (rows) {
      const _listPackage = Object.keys(rows).map(e => ({
        name: e,
        quantity: 0,
        sale: 0,
        point: 0,//rows[e].length > 1 ? rows[e][2].ipoint : rows[e][0].ipoint
        amount: 0,
        package_id: rows[e].length > 1 ? rows[e][2].package_id : rows[e][0].package_id,
        package_option_id: rows[e].length > 1 ? rows[e][2].package_option_id : rows[e][0].package_option_id,
        packageOption: rows[e].length > 1 ? rows[e][2].package_option_name : rows[e][0].package_option_name,
      }));
      setListPackage(_listPackage);
    }
  }, [rows]);

  const renderPayment = () => {
    const paymentList = listPackage.map(_package => {
      if (!_package.quantity) {
        return;
      }
      return {
        ..._package,
        sale: `${_package.sale || 0}%`,
      };
    }).filter(_package => _package);
    const packageList = paymentList.map(_package => ({
      package_id: _package.package_id,
      package_option_id: _package.package_option_id,
      quantity: _package.quantity,
    }));
    paymentList.unshift({
      name: 'Tên gói',
      packageOption: 'Loại',
      quantity: 'Số lượng',
      sale: 'Khuyến mại',
      point: 'iPoint nhận được',
      amount: 'Giá niêm yết',
    });
    return (
      <Payment
        packageList={packageList}
        paymentList={paymentList}
        object={"buy_ipoint"}
        object_id={null}
      />
    );
  }
  return (
    !openPayment ? (
      <TableContainer className='pt-7'>
        {loading || rows == null ? (
          <div className="flex justify-center py-10">
            <CircularProgress />
          </div>
        ) : (
          <Table aria-label='simple table' className='border border-solid'>
            <TableHead style={{ backgroundColor: '#F4F2FF' }}>
              <TableRow>
                {columns &&
                  columns.map((e, key) => {
                    return (
                      <TableCell
                        align={'left'}
                        key={key}
                        style={{
                          width: `${e.width}`,
                          textAlign: `${e.field == 'Tên gói' ? 'left' : 'right'}`,
                        }}
                      >
                        <span className='font-semibold text-left'>{e.field}</span>
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(rows).map((item, index) => {
                return (
                  <OptionPackageItem packageName={item} key={index} option={rows[item]} handleChangePackage={handleChangePackage} />
                );
              })}
            </TableBody>
          </Table>
        )}
        <div className={'flex flex-row justify-end mt-5'}>
          <div>
            <span className={'text-black font-bold text-xl mr-10'}>Tổng tiền tạm tính: </span>
            <span className={'text-[#B50829] font-bold text-xl'}>{ipointFormat(totalIPoint)} đ</span>
          </div>
        </div>
        <div onClick={payment} style={{ margin: '0 auto' }} className="flex mt-4 lg:my-10 lg:ml-4-5 w-max rounded-lg bg-[#B50829] text-white custom-btn cursor-pointer">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 18.5C5.9 18.5 5.01 19.4 5.01 20.5C5.01 21.6 5.9 22.5 7 22.5C8.1 22.5 9 21.6 9 20.5C9 19.4 8.1 18.5 7 18.5ZM1 3.5C1 4.05 1.45 4.5 2 4.5H3L6.6 12.09L5.25 14.53C4.52 15.87 5.48 17.5 7 17.5H18C18.55 17.5 19 17.05 19 16.5C19 15.95 18.55 15.5 18 15.5H7L8.1 13.5H15.55C16.3 13.5 16.96 13.09 17.3 12.47L20.88 5.98C21.25 5.32 20.77 4.5 20.01 4.5H5.21L4.54 3.07C4.38 2.72 4.02 2.5 3.64 2.5H2C1.45 2.5 1 2.95 1 3.5ZM17 18.5C15.9 18.5 15.01 19.4 15.01 20.5C15.01 21.6 15.9 22.5 17 22.5C18.1 22.5 19 21.6 19 20.5C19 19.4 18.1 18.5 17 18.5Z" fill="white" />
          </svg>
          <span style={{ marginTop: '4px' }} className="ml-5 pr-2 uppercase font-medium">THANH TOÁN</span>
        </div>
        <ModalDetailTransaction showModal={showModal} handleModal={handleModal} historyItem={historyItem} />
      </TableContainer>
    ) : renderPayment()
  );
};


const columns = [
  {
    field: 'Tên gói',
    width: '15%',
  },
  {
    field: 'Thời gian đăng tin',
    width: '15%',
  },
  {
    field: 'Giá niêm yết',
    width: '15%',
  },
  {
    field: 'Khuyến mại',
    width: '15%',
  },
  {
    field: 'iPoint nhận được',
    width: '15%',
  },
  {
    field: 'Số lượng cần mua',
    width: '15%',
  },
  // {
  //   field: '',
  //   width: '15%',
  // },
];