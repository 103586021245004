import { BaseRepository } from './base';
import { IJobs } from 'utils/interfaces';
import Repository from './base/repository';

export class JobsRepository extends BaseRepository<IJobs> {
  resource = 'jobs';
  token = localStorage.getItem('access-token');

  async getQueryJob(query?: any) {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.get(
      `${this.resource}?start_date=${
        query && query.start_date ? query.start_date : '1970-1-1'
      }&end_date=${
        query && query.end_date ? query.end_date : '2100-1-1'
      }&status=${query && query.status ? query.status : '0'}&skip=${
        query && query.skip ? query.skip : '0'
      }&limit=${query && query.limit ? query.limit : '20'}&search_string=${
        query && query.search_string ? query.search_string : ''
      }&p_order_title=${
        query && query.p_order_title ? query.p_order_title : 1
      }`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );

    return response.data;
  }

  async updateJobs(data) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.put(`${this.resource}/applies/users`, data, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }

  async getJobActive() {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.get(`${this.resource}/status/active`, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return response.data;
  }

  async restoreJob(id) {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.put(`${this.resource}/restore/${id}`, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return response.data;
  }
}
