import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { JobsRepository } from 'services/repositories/jobs.repository';

//Repository
const jobsRepository = RepositoryFactory.get('jobs') as JobsRepository;

const fetchJobById = async (id: string) => {
  let data = {};
  const response = await jobsRepository.getOne(id);

  if (response) {
    const job = response.data.job[0][0];
    const jobCareers = response.data.job_careers[0];
    const jobSkills = response.data.job_skills[0];
    data = { ...job, job_careers: jobCareers, skills: jobSkills };
  }
  return data;
};

function* getJobById(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const job = yield call(fetchJobById, action.payload);
    yield put({ type: types.GET_JOB_BY_ID_SUCCESS, payload: job });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.GET_JOB_BY_ID_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetJobById() {
  yield takeLatest(types.GET_JOB_BY_ID_REQUEST, getJobById);
}
