import * as types from '../type';
import { IJobs } from '../../utils/interfaces';

export const getJobs = (query?) => ({
  type: types.GET_JOBS_REQUEST,
  payload: query,
});

export const getJobById = (id: string) => ({
  type: types.GET_JOB_BY_ID_REQUEST,
  payload: id,
});

export const createJob = (data: IJobs) => ({
  type: types.CREATE_JOB_REQUEST,
  payload: data,
});

export const updateJob = (data, id) => ({
  type: types.UPDATE_JOB_REQUEST,
  payload: { data, id },
});

export const updateJobEmployee = (data, query) => ({
  type: types.UPDATE_JOB_EMPLOYEE_REQUEST,
  payload: { data: data, query: query },
});

export const deleteJob = (id: string) => ({
  type: types.DELETE_JOB_REQUEST,
  payload: id,
});

export const getJobActive = () => ({
  type: types.GET_JOB_ACTIVE_REQUEST,
});

export const restoreJob = (id) => ({
  type: types.RESTORE_JOB_REQUEST,
  payload: id,
});
