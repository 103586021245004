import * as types from '../type';

export const getIPoint = (p_corporate_code: string) => ({
  type: types.GET_IPOINT_TOTAL_REQUEST,
  payload: p_corporate_code
});
export const getIPointHistory = (query?) => ({
  type: types.GET_IPOINT_HISTORY_REQUEST,
  payload: query,
})
export const getIPointOrder = (query?) => ({
  type: types.GET_IPOINT_ORDER_REQUEST,
  payload: query,
})