import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { ProvinceRepository } from './../../../services/repositories/province.repository';

//Repository
const searchRepository: ProvinceRepository = RepositoryFactory.get(
  'province',
) as ProvinceRepository;

//Async Fetch
const fetchAddress = async (query: string) => {
  const response = await searchRepository.getSearchProvince(query);

  return response;
};

function* handlerAddress(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const address = yield call(fetchAddress, action.payload);

    yield put({ type: types.SEARCH_ADDRESS_SUCCESS, payload: address });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.SEARCH_ADDRESS_ERROR,
      payload: err.response.data,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchSearchAddress() {
  yield takeLatest(types.SEARCH_ADDRESS_REQUEST, handlerAddress);
}
