import { BaseRepository } from './base';
import { ICorporate } from '../../utils/interfaces';
import Repository from './base/repository';

export class CorporateRepository extends BaseRepository<ICorporate> {
  resource = 'corporate';
  token = localStorage.getItem('access-token');

  async updateCorporate(data) {
    this.token = localStorage.getItem('access-token');
    return (
      await Repository.put(`${this.resource}`, data, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
    ).data;
  }
}
