import * as types from '../type';

export const getEmployee = () => ({
  type: types.EMPLOYEE_REQUEST,
});
export const updateEmployee = (data) => ({
  type: types.UPDATE_EMPLOYEE_REQUEST,
  payload: data,
});
export const changePassword = (data) => ({
  type: types.CHANGE_PASS_REQUEST,
  payload: data,
});
export const forgetPassword = (data) => ({
  type: types.FORGET_PASSWORD_REQUEST,
  payload: data,
});
export const checkToken = (token) => ({
  type: types.CHECK_RESET_PASSWORD_REQUEST,
  payload: token,
});
export const resetPassword = (data) => ({
  type: types.RESET_PASSWORD_REQUEST,
  payload: data,
});
export const clearStoreEmp = () => ({
  type: types.RESET_STORE_EMPLOYEE,
});
