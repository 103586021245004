import * as types from '../type';
import { IAddress } from '../../utils/interfaces/address.interface';

interface IInitialState {
  addresses: IAddress[];
  address: IAddress | {};
  status: any;
}

const initialState: IInitialState = {
  addresses: [],
  address: {},
  status: null,
};

const addressReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.SEARCH_ADDRESS_SUCCESS:
      return { ...state, addresses: action.payload };
    case types.SEARCH_ADDRESS_ERROR:
      return { ...state, status: action.payload };
    default:
      return {
        ...state,
      };
  }
};

export default addressReducer;
