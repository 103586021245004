import React from "react";
import { Link } from 'react-router-dom';

export default function StatusPost({status, id, name = "job"}) {
    return (
        <div className={'flex flex-col items-center justify-center'}>
            <svg
                width='61'
                height='60'
                viewBox='0 0 61 60'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <g opacity='0.9' clipPath='url(#clip0)'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M30.5 60.001C47.0687 60.001 60.5 46.5697 60.5 30.001C60.5 13.4322 47.0687 0.000976562 30.5 0.000976562C13.9312 0.000976562 0.5 13.4322 0.5 30.001C0.5 46.5697 13.9312 60.001 30.5 60.001ZM16.875 29.001L14.125 31.626L26.625 44.001L48.125 18.751L45.375 16.251L26.375 38.501L16.875 29.001Z'
                        fill='#1890FF'
                    />
                </g>
                <defs>
                    <clipPath id='clip0'>
                        <rect
                            width='60'
                            height='60'
                            fill='white'
                            transform='translate(0.5 0.000976562)'
                        />
                    </clipPath>
                </defs>
            </svg>
            <p className='py-4 text-black text-xl font-medium'>
                Thành công
            </p>
            <p className='text-gray-500'>
                Tin đăng đã được gửi lên hệ thống thành công
            </p>
            <div className="flex flex-col items-center text-white mt-10">
                <a
                    className="rounded bg-gray-700 py-3 text-center cursor-pointer text-lg font-medium custom-btn-done"
                    href={
                        !isNaN(status)
                            ? `/admin/${name}/edit/${status}`
                            : `/admin/${name}/edit/${id}`
                    }
                >
                    Xem tin đăng
                </a>
                <Link to={{pathname: `/admin/${name === "job" ? "job/recruitment" : "course/list"}`}} className="custom-btn-done">
                    <div className="rounded bg-[#B50829] py-3 text-center cursor-pointer text-lg font-medium">
                        Quay về danh sách
                    </div>
                </Link>
            </div>
        </div>
    )
}