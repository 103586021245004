/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  getCommune,
  getDistrictFromProvince,
  getProvince,
} from 'redux/actions';
import { convertDate } from 'utils/commons';
import { CropImage, CropImageBanner, LF_CkEditor } from 'components/molecules';
import axios from 'axios';
import { RepositoryFactory } from '../../../../services/repositories';
import { StorageRepository } from '../../../../services/repositories/storage.repository';
import {result} from "lodash";

const storageRepository: StorageRepository = RepositoryFactory.get(
  'storage',
) as StorageRepository;

export const ProfileCompanyFrom = ({ data, onUpdate }) => {
  // React-Redux
  const dispatch = useDispatch();

  //State Validate
  const [validate, setValid] = React.useState({
    p_province_code: '',
    p_district_code: '',
    p_commune_code: '',
    p_address: '',
  });

  const [fileImg, setFileImg] = React.useState([]);

  const corporate_code = localStorage.getItem('corporate_code');

  const handleSliderImages = async (e) => {
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    const { accessToken } = await storageRepository.getStorageAccessToken(corporate_code);
    if (accessToken) {
      const response = await axios.post(
        `https://assets.i-hr.vn/upload?accessToken=${accessToken}`,
        formData,
      );
      if (response && response.status === 200) {
        setFileImg([...fileImg, response.data.data.url]);
      }
    }
  };

  const removeSliderImages = (e) => {
    let arr = [...fileImg];
    var index = arr.indexOf(e);
    if (index > -1) {
      arr.splice(index, 1);
    }
    setFileImg(arr);
  };

  let inputFileRef = null;

  //setState
  const [state, setState] = useState({
    p_corporate_code: '',
    p_corporate_phone: '',
    p_scope_id: null,
    p_instroduction: '',
    p_start_working_time: '',
    p_end_working_time: '',
    p_branch_number: null,
    p_workingday_id: null,
    p_working_note: '',
    p_image_list: '',
    p_facebook: '',
    p_youtube: '',
    p_logo: '',
    p_image: '',
    p_website: '',
    p_email: '',
    p_province_code: '',
    p_district_code: '',
    p_commune_code: '',
    p_address: '',
  });

  // useEffect
  React.useEffect(() => {
    dispatch(getProvince());
  }, [data]);

  React.useEffect(() => {
    setState({
      ...state,
      p_corporate_code: data && data?.corporate_code,
      p_corporate_phone: data && data?.corporate_phone,
      p_scope_id: data && data?.scope_id,
      p_instroduction: data && data?.introduction,
      p_start_working_time: data && data?.start_working_time,
      p_end_working_time: data && data?.end_working_time,
      p_branch_number: data && data?.branch_number,
      p_workingday_id: data && data?.workingday_id,
      p_working_note: data && data?.working_note,
      p_image_list: data && data?.image_list,
      p_facebook: data && data?.facebook,
      p_youtube: data && data?.youtube,
      p_logo: data && data?.logo,
      p_image: data && data?.image,
      p_website: data && data?.website,
      p_email: data && data?.corporate_email,
      p_province_code: data && data?.province_code,
      p_district_code: data && data?.district_code,
      p_commune_code: data && data?.commune_code,
      p_address: data && data?.address,
    });
    setFileImg(data?.image_list ? data?.image_list.split(',') : []);
  }, [data]);

  //Handle even
  const hanldOnChange = (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    if (name === 'p_province_code') {
      setState({ ...state, [name]: value, ['p_district_code']: '', ['p_commune_code']: '' });
    } else if (name === 'p_district_code') {
      setState({ ...state, [name]: value, ['p_commune_code']: '' });
    } else {
      setState({ ...state, [name]: value });
    }
  };
  // Handle CkEditor5
  const handleCKEditor = (name, data) => {
    setState((prevState) => {
      Object.assign(prevState, { [name]: data });
      return { ...prevState };
    });
  };

  //Provinces
  const provinces = useSelector((state: RootState) => state.provinces);

  //District
  const districts = useSelector((state: RootState) => state.districts);

  //Communes
  const communes = useSelector((state: RootState) => state.communes);

  React.useEffect(() => {
    dispatch(getProvince());
  }, []);

  React.useEffect(() => {
    state.p_province_code && dispatch(getDistrictFromProvince(state.p_province_code));
  }, [state.p_province_code]);

  React.useEffect(() => {
    state.p_province_code && state.p_district_code && dispatch(getCommune(state.p_province_code, state.p_district_code));
  }, [state.p_district_code]);

  const onSubmit = () => {
    const result = onValidation(state);
    if (result) {
      onUpdate({ ...state, p_image_list: fileImg.toString() });
    } else {
      alert("Vui lòng điền đầy đủ thông tin!")
    }
  };

  const onValidation = (state) => {
    let validation = { ...validate };
    let check = true;

    if (state.p_province_code === '') {
      validation.p_province_code = 'Vui lòng chọn tỉnh / thành phố';
      check = false;
    } else {
      validation.p_province_code = '';
    }

    if (state.p_district_code === '') {
      validation.p_district_code = 'Vui lòng chọn quận / huyện';
      check = false;
    } else {
      validation.p_district_code = '';
    }

    if (state.p_commune_code === '') {
      validation.p_commune_code = 'Vui lòng chọn xã / phường';
      check = false;
    } else {
      validation.p_commune_code = '';
    }

    if (state.p_address === '') {
      validation.p_address = 'Vui lòng nhập địa chỉ';
      check = false;
    } else {
      validation.p_address = '';
    }

    if (check) {
      setValid({
        p_province_code: '',
        p_district_code: '',
        p_commune_code: '',
        p_address: '',
      });
      return true;
    } else {
      setValid(validation);
      return false;
    }
  }

  return (
    <React.Fragment>
      <div
        className=""
        style={{ padding: '25px', color: 'rgba(0, 0, 0, 0.75)' }}
      >
        {/* Thông tin cơ bản */}
        <div
          className="uppercase text-lg py-4 font-bold"
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
          }}
        >
          Thông tin cơ bản
        </div>
        <div className="flex flex-wrap items-baseline">
          <div className="w-1/3" style={{ padding: '1rem 1rem 0 0' }}>
            <label className="block text-black font-bold mb-2 ">
              Pháp nhân
            </label>
          </div>
          <div className="w-2/3 " style={{ padding: '1rem 1rem 0 1rem' }}>
            <span className="text-gray-700 font-normal">
              {data && data.legal === 0 ? 'Công ty / Tổ chức' : 'Cá Nhân'}
            </span>
          </div>
          <div className="w-1/3" style={{ padding: '.5rem 1rem 0 0' }}>
            <label className="block text-black font-bold mb-2 ">
              Số ĐKKD / Mã số thuế cá nhân
            </label>
          </div>
          <div className="w-2/3 " style={{ padding: '.5rem 1rem 0 1rem' }}>
            <span className="text-gray-700 font-normal">
              {data ? data.so_dkkd : ''}
            </span>
          </div>
          <div className="w-1/3" style={{ padding: '.5rem 1rem 0 0' }}>
            <label className="block text-black font-bold mb-2 ">Ngày cấp</label>
          </div>
          <div className="w-2/3 " style={{ padding: '.5rem 1rem 0 1rem' }}>
            <span className="text-gray-700 font-normal">
              {data ? convertDate(data.assigned_date.slice(0, 10)) : ''}
            </span>
          </div>
          <div className="w-1/3" style={{ padding: '.5rem 1rem 0 0' }}>
            <label className="block text-black font-bold mb-2 ">Nơi cấp</label>
          </div>
          <div className="w-2/3 " style={{ padding: '.5rem 1rem 0 1rem' }}>
            <span className="text-gray-700 font-normal">
              {data ? data.assigned_location : ''}
            </span>
          </div>
          <div className="w-1/3" style={{ padding: '.5rem 1rem 0 0' }}>
            <label className="block text-black font-bold mb-2 ">
              Tên cửa hàng, doanh nghiệp
            </label>
          </div>
          <div className="w-2/3 " style={{ padding: '.5rem 1rem 0 1rem' }}>
            <span className="text-gray-700 font-normal">
              {data ? data.corporate_name : ''}
            </span>
          </div>
        </div>
        {/* City & District & Wards Block  */}

        <div className="flex flex-wrap items-baseline justify-between">
          <div className="w-full" style={{ padding: '1rem 0 0 0' }}>
            <label className="block text-black font-bold mb-2 ">
              Giới thiệu công ty
            </label>
            <LF_CkEditor
              name="p_instroduction"
              data={state.p_instroduction}
              onChange={handleCKEditor}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <span
            className=" text-sm font-thin text text-left  "
            style={{ color: '#B50829' }}
          >
          </span>
          {state &&
            state.p_instroduction &&
            state.p_instroduction.length >= 1000 ? (
            <span className=" text-right  text-[#B50829]">
              {state && state.p_instroduction && state.p_instroduction.length}
              /1000 ký tự
            </span>
          ) : (
            <span className=" text-right text-gray-500 ">
              {state && state.p_instroduction && state.p_instroduction.length}
              /1000 ký tự
            </span>
          )}
        </div>
        {/* thông tin khác */}
        <div
            className="uppercase text-lg py-4 font-bold"
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
            }}
        >
          thông tin khác
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/3 py-4">
            <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="Tỉnh thành"
            >
              Tỉnh thành <span style={{ color: '#B50829' }}>*</span>
            </label>
            <select
                className="appearance-none border border-gray-400 rounded w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="province_code"
                name="p_province_code"
                value={state?.p_province_code}
                onChange={(e) => hanldOnChange(e)}
            >
              <option value={''}>Chọn tỉnh / thành phố</option>
              {provinces &&
              provinces?.provinces.map((province) => (
                  <option
                      key={province.province_code}
                      value={province.province_code}
                  >
                    {province.province_name}
                  </option>
              ))}
            </select>
            <span className="text-sm" style={{ color: '#B50829' }}>
              {validate.p_province_code !== '' ? validate.p_province_code : ''}
            </span>
          </div>
          <div className="w-full lg:w-1/3 lg:p-4">
            <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="Quận Huyện"
            >
              Quận Huyện <span style={{ color: '#B50829' }}>*</span>
            </label>
            <select
                className="appearance-none border border-gray-400 rounded w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="district_code"
                name="p_district_code"
                value={state?.p_district_code}
                onChange={(e) => hanldOnChange(e)}
            >
              <option value={''}>Chọn quận / huyện</option>
              {districts &&
              districts?.districts?.map((district) => (
                  <option
                      key={district.district_code}
                      value={district.district_code}
                  >
                    {district.name}
                  </option>
              ))}
            </select>
            <span className="text-sm" style={{ color: '#B50829' }}>
              {validate.p_district_code !== '' ? validate.p_district_code : ''}
            </span>
          </div>
          <div className="w-full lg:w-1/3 py-4">
            <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="Phường Xã"
            >
              Phường Xã <span style={{ color: '#B50829' }}>*</span>
            </label>
            <select
                className="appearance-none border border-gray-400 rounded w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="commune_code"
                name="p_commune_code"
                value={state?.p_commune_code}
                onChange={(e) => hanldOnChange(e)}
            >
              <option value={''}>Chọn phường / xã</option>
              {communes &&
              communes?.communes?.map((commune) => (
                  <option
                      key={commune.commune_code}
                      value={commune.commune_code}
                  >
                    {commune.commune_name}
                  </option>
              ))}
            </select>
            <span className="text-sm" style={{ color: '#B50829' }}>
              {validate.p_commune_code !== '' ? validate.p_commune_code : ''}
            </span>
          </div>
        </div>
        <div className="w-full mt-3">
          <label className="block text-black font-bold mb-2 ">Địa chỉ công ty (Số nhà, đường phố) <span style={{ color: '#B50829' }}>*</span></label>
        </div>
        <div className="w-full mt-3">
          <input type="text" className={'w-full appearance-none border border-gray-400 rounded'} value={state.p_address} name="p_address" onChange={(e) => hanldOnChange(e)}/>
        </div>
        <span className="text-sm" style={{ color: '#B50829' }}>
          {validate.p_address !== '' ? validate.p_address : ''}
        </span>
        {/* QUY MÔ / VĂN HOÁ CÔNG TY */}
        <div
          className="uppercase text-lg py-4 font-bold"
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
          }}
        >
          QUY MÔ / VĂN HOÁ CÔNG TY
        </div>
        <div className="flex  flex-col xl:flex-row  items-baseline">
          <div className="w-full xl:w-1/3 xl:p-4 xl:pl-0 mt-4">
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="Điện thoại công ty"
            >
              Điện thoại công ty
            </label>
            <input
              className="appearance-none border border-gray-400 w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="p_corporate_phone"
              onChange={(e) => {
                setState({
                  ...state,
                  p_corporate_phone: e.target.value,
                });
              }}
              value={state ? state.p_corporate_phone : ''}
              placeholder="Điện thoại công ty"
            />
          </div>
          <div className="w-full xl:w-1/3 xl:p-4 mt-4">
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="Quy mô công ty"
            >
              Quy mô công ty
            </label>
            <select
              name="p_scope_id"
              onChange={(e) => {
                setState({
                  ...state,
                  p_scope_id: parseInt(e.target.value),
                });
              }}
              value={state.p_scope_id}
              className="appearance-none border border-gray-400 w-full py-3 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value={1}>&lt; 100 nhân sự</option>
              <option value={2}>100-200 nhân sự</option>
              <option value={3}>200-500 nhân sự</option>
              <option value={4}>500-1000 nhân sự</option>
              <option value={5}>&gt; 1000 nhân sự</option>
            </select>
          </div>
          <div className="w-full xl:w-1/3 xl:p-4 mt-4">
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="Số chi nhánh"
            >
              Số chi nhánh
            </label>
            <input
              className="appearance-none border border-gray-400 w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              name="p_branch_number"
              onChange={(e) => {
                setState({
                  ...state,
                  p_branch_number: parseInt(e.target.value),
                });
              }}
              value={state ? state.p_branch_number : ''}
              placeholder="Số chi nhánh"
            />
            {/* <span className=" text-sm font-thin" style={{ color: '#B50829' }}>
              {validate.branch !== '' ? validate.branch : ''}
            </span> */}
          </div>
          <div className="w-full xl:w-1/2 xl:p-4 mt-4">
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="Thời gian làm việc"
            >
              Thời gian làm việc
            </label>
            <div className="grid grid-cols-5 items-center">
              <select
                name="p_start_working_time"
                onChange={hanldOnChange}
                value={state.p_start_working_time}
                className=" appearance-none border border-gray-400 col-span-2  py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option>Chọn giờ</option>
                {[
                  '0:00',
                  '0:30',
                  '1:00',
                  '1:30',
                  '2:00',
                  '2:30',
                  '3:00',
                  '3:30',
                  '4:00',
                  '4:30',
                  '5:00',
                  '5:30',
                  '6:00',
                  '6:30',
                  '7:00',
                  '7:30',
                  '8:00',
                  '8:30',
                  '9:00',
                  '9:30',
                  '10:00',
                  '10:30',
                  '11:00',
                  '11:30',
                  '12:00',
                  '12:30',
                  '13:00',
                  '13:30',
                  '14:00',
                  '14:30',
                  '15:00',
                  '15:30',
                  '16:00',
                  '16:30',
                  '17:00',
                  '17:30',
                  '18:00',
                  '18:30',
                  '19:00',
                  '19:30',
                  '20:00',
                  '20:30',
                  '21:00',
                  '21:30',
                  '22:00',
                  '22:30',
                  '23:00',
                  '23:30',
                ].map((item, key) => (
                  <option key={key} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <div className=" text-center">đến</div>
              <select
                name="p_end_working_time"
                onChange={hanldOnChange}
                value={state.p_end_working_time}
                className=" appearance-none border border-gray-400 col-span-2 py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option>Chọn giờ</option>
                {[
                  '0:00',
                  '0:30',
                  '1:00',
                  '1:30',
                  '2:00',
                  '2:30',
                  '3:00',
                  '3:30',
                  '4:00',
                  '4:30',
                  '5:00',
                  '5:30',
                  '6:00',
                  '6:30',
                  '7:00',
                  '7:30',
                  '8:00',
                  '8:30',
                  '9:00',
                  '9:30',
                  '10:00',
                  '10:30',
                  '11:00',
                  '11:30',
                  '12:00',
                  '12:30',
                  '13:00',
                  '13:30',
                  '14:00',
                  '14:30',
                  '15:00',
                  '15:30',
                  '16:00',
                  '16:30',
                  '17:00',
                  '17:30',
                  '18:00',
                  '18:30',
                  '19:00',
                  '19:30',
                  '20.00',
                  '20:30',
                  '21:00',
                  '21:30',
                  '22:00',
                  '22:30',
                  '23:00',
                  '23:30',
                ].map((item, key) => (
                  <option key={key} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="w-full xl:w-1/2 xl:p-4 xl:pr-0 mt-4">
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="Số ngày làm việc / tuần"
            >
              Số ngày làm việc / tuần
            </label>
            <select
              name="p_workingday_id"
              onChange={(e) => {
                setState({
                  ...state,
                  p_workingday_id: parseInt(e.target.value),
                });
              }}
              value={state.p_workingday_id}
              className="appearance-none border border-gray-400 w-full py-3 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value={1}>Thứ 2 - Thứ 6</option>
              <option value={2}>Thứ 2 - Thứ 7</option>
              <option value={3}>Thứ 2 - CN</option>
              <option value={4}>Khác</option>
            </select>
          </div>
        </div>
        <div className="flex flex-wrap items-baseline">
          <div className="w-full" style={{ padding: '1rem 0 0 0' }}>
            <label className="block text-black font-bold mb-2 ">
              Công ty có khung giờ làm việc khác
            </label>
            <textarea
              className="w-full px-5 py-3  text-base font-medium text-black leading-normal focus:outline-none focus:shadow-outline appearance-none border border-gray-400"
              id="p_working_note"
              name="p_working_note"
              style={{
                height: '100px',
                resize: 'none',
              }}
              value={state.p_working_note}
              onChange={hanldOnChange}
              placeholder="Ghi rõ thời gian và khung giờ làm việc của công ty bạn..."
              maxLength={500}
            ></textarea>
          </div>
        </div>
        <div className=" flex justify-between">
          <span
            className=" text-sm font-thin text text-left  "
            style={{ color: '#B50829' }}
          >
            {/* {validate.textareaTime !== '' ? validate.textareaTime : ''} */}
          </span>
          {state &&
            state.p_working_note &&
            state.p_working_note.length >= 500 ? (
            <span className=" text-right  text-[#B50829]">
              {state && state.p_working_note && state.p_working_note.length}/500
              ký tự
            </span>
          ) : (
            <span className=" text-right text-gray-500 ">
              {state && state.p_working_note && state.p_working_note.length}/500
              ký tự
            </span>
          )}
        </div>
        <div className="flex flex-wrap items-baseline">
          <div className="w-full lg:w-1/2 lg:py-3 mt-4">
            <label className="block text-black font-bold mb-3 " htmlFor="">
              Website
            </label>
            <input
              className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="p_website"
              name="p_website"
              type="text"
              value={state.p_website}
              onChange={hanldOnChange}
            />
          </div>
          <div className="w-full lg:w-1/2 lg:py-3 lg:pl-2 mt-4">
            <label className="block text-black font-bold mb-3 " htmlFor="">
              Email
            </label>
            <input
              className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="p_email"
              name="p_email"
              type="email"
              value={state.p_email}
              onChange={hanldOnChange}
            />
          </div>
          <div className="w-full lg:w-1/2 lg:py-3 mt-4">
            <label className="block text-black font-bold mb-3 " htmlFor="">
              Facebook Page / Group
            </label>
            <input
              className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="p_facebook"
              name="p_facebook"
              type="text"
              value={state.p_facebook}
              onChange={hanldOnChange}
            />
          </div>
          <div className="w-full lg:w-1/2 lg:py-3 lg:pl-2 mt-4">
            <label className="block text-black font-bold mb-3 " htmlFor="">
              Youtube Channel
            </label>
            <input
              className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="p_youtube"
              name="p_youtube"
              type="text"
              value={state.p_youtube}
              onChange={hanldOnChange}
            />
          </div>
          <div className="w-full" style={{ padding: '1rem 0 0 0' }}>
            <div className="block text-base text-black font-bold mb-2 ">
              Logo công ty
            </div>
            <div className="block text-sm leading-6 text-[#B50829]">
              Ảnh logo có hình vuông kích thước 128 x 128 pixel
            </div>
            <CropImage state={state} setState={setState} />
          </div>
          <div className="w-full" style={{ padding: '1rem 0 0 0' }}>
            <div className="block text-base text-black font-bold mb-2 ">
              Ảnh bìa công ty
            </div>
            <div className="block text-sm leading-6 text-[#B50829]">
              Ảnh bìa là hình chữ nhật kích thước 1024 x 360 pixel
            </div>
            <CropImageBanner state={state} setState={setState} />
          </div>
          <div className="w-full" style={{ padding: '1rem 0 0 0' }}>
            <div className="block text-base text-black font-bold mb-2 ">
              Hình ảnh công ty
            </div>
            <div className="flex flex-wrap -mx-4 px-4 my-6">
              {fileImg && fileImg.length > 0 ? (
                fileImg.map((ele, index) => {
                  return (
                    <div className="md:w-1/3 px-4 mb-8 " key={index}>
                      <img
                        className=" shadow-md bg-cover max-w-full max-h-[432px] mx-auto"
                        src={`https://assets.i-hr.vn/${ele}`}
                        alt={ele}
                        onClick={(e) => {
                          removeSliderImages(ele);
                        }}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="lg:mx-auto block lg:text-center left-1/2 text-xl">
                  Chưa có hình ảnh Công ty
                </div>
              )}
            </div>
            <div
              className="flex font-semibold mb-2  uppercase text-lg"
              style={{ color: '#B50829' }}
            >
              <label className=" flex flex-wrap items-center cursor-pointer my-2">
                <input
                  name="flies"
                  className="hidden"
                  type="file"
                  accept="image/*"
                  // value={data.basicInfo.background}
                  onChange={handleSliderImages}
                  ref={(input) => (inputFileRef = input)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                // onChange={(e) => handleChangePhotoButton(e)}
                >
                  <path
                    fillRule="evenodd"
                    d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                CHỌN ẢNH
              </label>
            </div>
          </div>
        </div>

        {/* Button */}
        <div className="text-center p-5">
          <Button
            type="submit"
            onClick={onSubmit}
            className="uppercase px-20 py-3 focus:outline-none focus:shadow-outline"
            style={{
              color: 'white',
              fontSize: '1rem',
              paddingLeft: '5rem',
              paddingRight: '5rem',
              background: '#B50829',
            }}
          >
            Lưu Lại
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};
