import { SelectScheduleGroup } from 'components/molecules/SelectButton';
import React from 'react';

export const LF_Review = ({ data, onClickNextStep, onClickBack }) => {
  const createMarkup = (innerHTML) => {
    return { __html: innerHTML };
  };
  const [status, setStatus] = React.useState('');
  React.useEffect(() => {
    if (data.basicInfo.p_online === 1) {
      setStatus('Online / Trực tuyến qua internet');
    }
    if (data.basicInfo.p_online === 2) {
      setStatus('Offline / Tại trung tâm');
    }
    if (data.basicInfo.p_online === 0) {
      setStatus('Bất kì');
    }
  }, [data]);

  // console.log(data.basicInfo);

  return (
    <>
      <h3 className="uppercase text-lg font-bold pb-2 mb-5 border-b border-gray-400">
        Chi tiết khóa học
      </h3>

      <div className="flex">
        <label className="font-bold mr-3 w-32 lg:w-56">Tên khóa học</label>
        <p className="flex-1">{data.basicInfo.p_course_name}</p>
      </div>

      <div className="flex mt-4">
        <label className="font-bold mr-3 w-32 lg:w-56">Mô tả công việc</label>
        <div
          className="flex-1"
          dangerouslySetInnerHTML={createMarkup(data.basicInfo.p_course_desc)}
        />
      </div>

      <div className="flex mt-4">
        <label className="font-bold mr-3 w-32 lg:w-56">Hồ sơ</label>
        <div
          className="flex-1"
          dangerouslySetInnerHTML={createMarkup(data.basicInfo.p_cv_content)}
        />
      </div>

      <div className="flex flex-col lg:flex-row mt-4">
        <div className="flex flex-1 mt-4 lg:mt-0">
          <label className="font-bold mr-3 w-32 lg:w-56">Hạn đăng ký</label>
          <div
            className=""
            dangerouslySetInnerHTML={createMarkup(
              `${new Date(data.basicInfo.p_end_date).getFullYear()}-${(
                '0' +
                (new Date(data.basicInfo.p_end_date).getMonth() + 1)
              ).slice(-2)}-${(
                '0' + new Date(data.basicInfo.p_end_date).getDate()
              ).slice(-2)}`,
            )}
          />
        </div>

        <div className="flex flex-1 mt-4 lg:mt-0">
          <label className="font-bold mr-3 w-32 lg:w-56">Thời gian đào tạo</label>
          <div className="flex">
            <div
              className=""
              dangerouslySetInnerHTML={createMarkup(data.basicInfo.p_timeTrain)}
            />
            <span className="pl-2">Tháng</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row mt-4">
        <div className="flex flex-1 mt-4 lg:mt-0">
          <label className="font-bold mr-3 w-32 lg:w-56">Ngày khai giảng</label>
          <div className="flex">
            <div
              className=""
              dangerouslySetInnerHTML={createMarkup(
                `${new Date(data.basicInfo.p_openning_date).getFullYear()}-${(
                  '0' +
                  (new Date(data.basicInfo.p_openning_date).getMonth() + 1)
                ).slice(-2)}-${(
                  '0' + new Date(data.basicInfo.p_openning_date).getDate()
                ).slice(-2)}`,
              )}
            />
          </div>
        </div>

        <div className="flex flex-1 mt-4 lg:mt-0">
          <label className="font-bold mr-3 w-32 lg:w-56">Học phí</label>
          <div
            className="flex"
            dangerouslySetInnerHTML={createMarkup(
              Number(data.basicInfo.p_price).toLocaleString('en-US'),
            )}
          />
          <span className="ml-2">VNĐ</span>
        </div>
      </div>

      <div className="flex mt-4">
        <label className="font-bold mr-3 w-32 lg:w-56">Ngành đào tạo</label>
        <div className="flex">
          <ul className="list-disc flex-1 pl-5 leading-6">
            {data.basicInfo.listChooseCareers &&
              data.basicInfo.listChooseCareers.length > 0 &&
              data.basicInfo.listChooseCareers?.map((career, index) => (
                <li key={index}>{career.career_name}</li>
              ))}
          </ul>
        </div>
      </div>

      <div className="flex mt-4">
        <div className="flex flex-1">
          <label className="font-bold mr-3 w-32 lg:w-56">Hình thức đào tạo</label>
          <div className="flex">
            <div className="" dangerouslySetInnerHTML={createMarkup(status)} />
          </div>
        </div>
      </div>

      <div className="flex mt-4">
        <div className="flex flex-1">
          <label className="font-bold mr-3 w-32 lg:w-56">Địa điểm học</label>
          <div
            className="flex-1"
            dangerouslySetInnerHTML={createMarkup(
              data.basicInfo?.p_address
                ? data.basicInfo?.p_address +
                ', ' +
                data.basicInfo?.p_district_text +
                ', ' +
                data.basicInfo?.p_province_text
                : 'Không yêu cầu',
            )}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-full lg:w-56">
          Lịch học dự tính hàng tuần
        </label>
        <SelectScheduleGroup
          action={() => { }}
          disable={true}
          data={data?.basicInfo?.p_schedule}
        />
      </div>

      <div className="flex mt-4">
        <label htmlFor="desc-job" className="font-bold mr-3 w-32 lg:w-56">
          Tài liệu
        </label>
        <div>
          {data.basicInfo.p_file.length > 0
            ? data.basicInfo.p_file.map((e: string, index) => {
              if (e !== '') {
                return (
                  <span className="flex mr-6 text-blue-500" key={index}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-black mr-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                    {e
                      ? e.replace(
                        /^static[/]d[/]corporate[/]docs[/][0-9]{8}[/]/g,
                        ' ',
                      )
                      : null}
                  </span>
                );
              }
            })
            : 'Không có tài liệu'}
        </div>
      </div>
      <div className="flex mt-4">
        <label className="font-bold mr-3 w-32 lg:w-56">Ảnh bìa</label>
        <div>
          {data?.basicInfo?.p_background ? (
            <img
              className=" shadow-md max-w-[192px] max-h-[108px] mx-auto w-52 h-32"
              src={`https://assets.i-hr.vn/${data?.basicInfo?.p_background}`}
              alt={'demoBanner'}
            />
          ) : (
            'Không có ảnh bìa'
          )}
        </div>
      </div>

      <div className="mt-24 mb-14 flex items-center justify-center">
        <div
          className="flex w-max rounded-lg bg-gray-400 text-white custom-btn cursor-pointer"
          onClick={() => onClickBack('review')}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.3285 10.9997V12.9997L7.50017 12.9997L10.7427 16.2423L9.32849 17.6565L3.67163 11.9996L9.32849 6.34277L10.7427 7.75699L7.49994 10.9997L20.3285 10.9997Z"
              fill="white"
            />
          </svg>
          <span className="pl-2 font-medium">Quay lại</span>
        </div>
        <div
          className="flex w-max ml-40 rounded-lg bg-[#B50829] text-white custom-btn cursor-pointer"
          onClick={() => onClickNextStep('review')}
        >
          <span className="pr-2 font-medium">Hoàn tất</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0377 6.34277L13.6268 7.75629L16.897 11.0153L3.29199 11.029L3.294 13.0289L16.8618 13.0153L13.6466 16.2455L15.0641 17.6564L20.7078 11.9864L15.0377 6.34277Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </>
  );
};
