import * as types from '../type';

interface exportExcelInterFace {
  job: [];
  course: [];
  candidate: [];
  student: [];
}

const initialState: exportExcelInterFace = {
  job: [],
  course: [],
  candidate: [],
  student: [],
};

const exportExcelReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EXPORT_EXCEL_JOB_SUCCESS:
      return { ...state, job: action.payload };
    case types.EXPORT_EXCEL_COURSE_SUCCESS:
      return { ...state, course: action.payload };
    case types.EXPORT_EXCEL_CANDIDATE_SUCCESS:
      return { ...state, candidate: action.payload };
    case types.EXPORT_EXCEL_STUDENT_SUCCESS:
      return { ...state, student: action.payload };
    default:
      return state;
  }
};

export default exportExcelReducer;
