import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { JobsRepository } from 'services/repositories/jobs.repository';

//Repository
const jobsRepository = RepositoryFactory.get('jobs') as JobsRepository;

const setStatus = (status) => {
  switch (status) {
    case 1:
      return 'Chờ duyệt';
    case 2:
      return 'Đang hoạt động';
    case 5:
      return 'Hết hạn';
    case 6:
      return 'Đã xóa';
    default:
      return;
  }
};

export const exportExcelJob = async (query?) => {
  const response = await jobsRepository.getQueryJob(query);
  let result = [];
  if (response) {
    response.data.map((item) => {
      result.push({
        ...item,
        statusName: setStatus(item.status),
        start_date: `${('0' + new Date(item.start_date).getDate()).slice(
          -2,
        )}/${('0' + (new Date(item.start_date).getMonth() + 1)).slice(
          -2,
        )}/${new Date(item.start_date).getFullYear()}`,
        end_date: `${('0' + new Date(item.end_date).getDate()).slice(-2)}/${(
          '0' +
          (new Date(item.end_date).getMonth() + 1)
        ).slice(-2)}/${new Date(item.end_date).getFullYear()}`,
      });
    });
  }
  return result;
};

function* handlerExportExcelJob(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const jobs = yield call(exportExcelJob, action.payload);

    yield put({
      type: types.EXPORT_EXCEL_JOB_SUCCESS,
      payload: jobs,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.EXPORT_EXCEL_JOB_ERROR,
      payload: err.response,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchExportExcelJob() {
  yield takeLatest(types.EXPORT_EXCEL_JOB_REQUEST, handlerExportExcelJob);
}
