import * as types from '../type';

interface IInitialState {
  jobs: { data: []; total_job: string | any };
  job: any;
  oldPackages: null;
  jobActive: [];
  status: any;
}

const initialState: IInitialState = {
  jobs: { data: [], total_job: '0' },
  job: {},
  oldPackages: null,
  jobActive: [],
  status: null,
};

const jobsReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
        status: null,
      };
    case types.GET_JOB_BY_ID_SUCCESS:
      return {
        ...state,
        job: action.payload,
        status: null,
      };
    case types.GET_JOB_BY_ID_ERROR:
      return {
        ...state,
        status: action.payload,
      };
    case types.CREATE_JOB_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case types.CREATE_JOB_ERROR:
      return {
        ...state,
        status: action.payload,
      };
    case types.UPDATE_JOB_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case types.UPDATE_JOB_EMPLOYEE_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case types.UPDATE_JOB_ERROR:
      return {
        ...state,
        status: action.payload,
      };
    case types.DELETE_JOB_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case types.GET_JOB_ACTIVE_SUCCESS:
      return {
        ...state,
        jobActive: action.payload,
      };
    case types.RESTORE_JOB_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case types.GET_OLD_PACKAGES_SUCCESS:
      return {
        ...state,
        oldPackages: action.payload,
      };
    case types.GET_OLD_PACKAGES_ERROR:
      return {
        ...state,
        oldPackages: action.payload,
      };
    default:
      return { ...state };
  }
};

export default jobsReducer;
