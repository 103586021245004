import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { BuyPackageOrganism } from "../BuyPackage";
import StatusPost from 'components/molecules/StatusPost/StatusPost';

interface DataProps {
  statusCourse?,
  ipoint?,
  listPackage?,
  listPackageTime?,
  course?,
  status?,
  oldPackages?
}
export const LF_Done = ({
  statusCourse,
  ipoint,
  listPackage,
  listPackageTime,
  course,
  status,
  oldPackages
}: Partial<DataProps>) => {
  const [statusCoursePayment, setStatusCoursePayment] = useState(statusCourse === 'create' ? false : !!course?.payment_status);
  return (
    <>
      {
        !statusCoursePayment ? (
          <BuyPackageOrganism
            listPackage={listPackage}
            listPackageTime={listPackageTime}
            setStatusJobPayment={setStatusCoursePayment}
            statusJob={statusCourse}
            ipoint={ipoint}
            name={"course"}
            object={course}
            status={status}
            oldPackages={oldPackages}
          />
        ) : <StatusPost status={status} id={course?.course_id} name={"course"} />
      }

    </>
  );
};
