import axios from 'axios';
import { APP_CONFIG } from '../../../constants';

const baseURL = `${APP_CONFIG.BACKEND_DOMAIN}/${APP_CONFIG.BACKEND_API_END_POINT}/${APP_CONFIG.BACKEND_API_VERSION}`;

const token = localStorage.getItem('access-token');
const refreshToken = localStorage.getItem('refresh-token');

const repository = axios.create({
  baseURL,
  // headers: { Authorization: `Bearer ${token}` },
});

repository.interceptors.response.use(
  (response) => {
    return response;
  },
  function (err) {
    //Todo: HAndle error middle ware
    const originalRequest = err.config;
    if (
      (err.response.status === 401 && originalRequest._retry) ||
      originalRequest.url === `${baseURL}/auth/refresh-token`
    ) {
      // location.replace("/admin");
      return Promise.reject(err);
    }
    if (err.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return repository
        .post(`/auth/refresh-token`, { refresh_token: refreshToken })
        .then((res) => {
          if (res.status === 201) {
            localStorage.setItem('access-token', res.data.access_token);
            //window.location.reload();
            //Như nó bị mất cái khúc refresh xong chạy tiếp
            return repository({
              ...originalRequest,
              headers: {
                ...originalRequest.headers,
                Authorization: `Bearer ${res.data.access_token}`,
              },
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            window.location.replace('/auth/login');
          }
          //window.location.reload();
        });
    }
    return Promise.reject(err);
  },
);

export default repository;
