import React, { useState } from 'react';
// Components
import { Button } from '@material-ui/core';

export const ProfileUserFrom = ({ onUpdate, data, onChangePass, status }) => {
  const [state, setState] = useState({
    p_corporate_code: data && data.corporate_code,
    p_emp_id: data && data.employee_id,
    p_emp_name: data && data.employee_name,
    p_emp_email: data && data.employee_email,
    p_emp_mobile: data && data.employee_mobile,
  });
  const [formPassword, setFormPassword] = useState({
    p_corporate_code: '',
    p_emp_id: 0,
    p_old_password: '',
    p_new_password: '',
    confirm_password: '',
  });

  const [validate, setValid] = React.useState({
    p_old_password: '',
    p_new_password: '',
    confirm_password: '',
  });

  const [validation, setValidation] = React.useState({
    email: '',
  });

  const duplicate = (regex) => {
    if (/Duplicate/.test(regex)) {
      if (/UNIQUE_employee_email/.test(regex)) {
        setValidation({
          email: 'Email bị trùng',
        });
      }
    } else {
      setValidation({
        email: '',
      });
    }
  };

  React.useEffect(() => {
    const regex = status && status.message;

    duplicate(regex);
  }, [status]);

  // useEffect setState
  React.useEffect(() => {
    setState({
      ...state,
      p_corporate_code: data && data.corporate_code,
      p_emp_id: data && data.employee_id,
      p_emp_name: data && data.employee_name,
      p_emp_email: data && data.employee_email,
      p_emp_mobile: data && data.employee_mobile,
    });
    setFormPassword({
      ...formPassword,
      p_corporate_code: data && data.corporate_code,
      p_emp_id: data && data.employee_id,
    });
  }, [data]);

  // Handel Action
  const handleOnChange = (e) => {
    e.preventDefault();
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePass = (e) => {
    e.preventDefault();
    setFormPassword({ ...formPassword, [e.target.name]: e.target.value });
  };

  // Action Update
  const onSubmit = (dataState, fromPass) => {
    if (fromPass.p_old_password !== '' || fromPass.p_new_password !== '') {
      const result = onValidation(formPassword);
      if (result) {
        onChangePass(formPassword);
      }
    }
    if (
      dataState.p_emp_email !== data.employee_email ||
      dataState.p_emp_mobile !== data.employee_mobile ||
      dataState.p_emp_name !== data.employee_name
    ) {
      onUpdate(state);
    }
  };

  // Function Validate
  const onValidation = (data) => {
    let validation = { ...validate };
    let check = true;
    if (data.p_old_password.length > 0 && data.p_new_password.length < 6) {
      validation.p_new_password = 'Mật khẩu phải có 6 ký tự trở lên';
      check = false;
    } else {
      validation.p_new_password = '';
    }

    if (data.confirm_password !== data.p_new_password) {
      validation.confirm_password =
        'Mật khẩu mới và Xác nhận mật khẩu mới không khớp nhau';
      check = false;
    }
    if (check) {
      setValid({
        p_old_password: '',
        p_new_password: '',
        confirm_password: '',
      });
      return true;
    } else {
      setValid(validation);
      return false;
    }
  };

  return (
    <React.Fragment>
      <div
        className=" font-bold text-base "
        style={{ padding: '25px', color: 'rgba(0, 0, 0, 0.75)' }}
      >
        {/* Thông tin tài khoản */}
        <div
          className="uppercase text-lg py-4"
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
          }}
        >
          Thông tin tài khoản
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/3 lg:p-4 lg:pr-0  mt-4">
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="Họ tên người đại diện"
            >
              Họ tên người đại diện <span style={{ color: '#B50829' }}>*</span>
            </label>
            <input
              className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="p_emp_name"
              name="p_emp_name"
              type="text"
              value={state.p_emp_name}
              onChange={handleOnChange}
            />
          </div>
          <div className="lg:w-1/3 lg:p-4 mt-4">
            <label className="block text-black font-bold mb-2 " htmlFor="Email">
              Email cá nhân <span style={{ color: '#B50829' }}>*</span>
            </label>
            <input
              className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="p_emp_email"
              name="p_emp_email"
              type="email"
              value={state.p_emp_email}
              onChange={handleOnChange}
            />
            <span
              className="text-sm  font-normal  "
              style={{ color: '#B50829' }}
            >
              {validation.email !== '' ? validation.email : ''}
            </span>
          </div>
          <div className="lg:w-1/3 lg:p-4 mt-4">
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="Số điện thoại"
            >
              Điện thoại cá nhân <span style={{ color: '#B50829' }}>*</span>
            </label>
            <input
              className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="p_emp_mobile"
              name="p_emp_mobile"
              type="text"
              value={state.p_emp_mobile}
              onChange={handleOnChange}
            />
          </div>
        </div>
        {/* Đổi mật khẩu */}
        <div
          className="uppercase text-lg py-4"
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
          }}
        >
          Đổi mật khẩu
        </div>
        <div className="flex justify-between flex-wrap lg:justify-start items-baseline">
          <div className="w-1/3 lg:p-4 lg:pr-0 mt-4">
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="username"
            >
              Tên đăng nhập
            </label>
          </div>
          <div className="w-1/3 lg:p-4 lg:pr-0 mt-4">
            <span className="text-gray-500 text-[20px] font-normal">
              {data.username}
            </span>
          </div>
        </div>
        <span
          className="text-[16px] leading-[18px]  font-normal  "
          style={{ color: '#B50829' }}
        >
          Nếu không muốn đổi mật khẩu, vui lòng để trống
        </span>
        <form
          className="flex flex-col lg:flex-row"
          onSubmit={() => {
            onSubmit(state, formPassword);
          }}
        >
          <div className="lg:w-1/3 lg:p-4 lg:pr-0 mt-4" >
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="Mật khẩu hiện tại"
            >
              Mật khẩu hiện tại
            </label>
            <input
              className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="p_old_password"
              name="p_old_password"
              type="password"
              onChange={handleChangePass}
            />
          </div>
          <div className="lg:w-1/3 lg:p-4 mt-4">
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="Mật khẩu mới"
            >
              Mật khẩu mới <span style={{ color: '#B50829' }}>*</span>
            </label>
            <input
              className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="p_new_password"
              name="p_new_password"
              type="password"
              onChange={handleChangePass}
            />
            <span
              className="text-sm  font-normal  "
              style={{ color: '#B50829' }}
            >
              {validate.p_new_password !== '' ? validate.p_new_password : ''}
            </span>
          </div>
          <div className="lg:w-1/3 lg:p-4 mt-4">
            <label
              className="block text-black font-bold mb-2 "
              htmlFor="Xác nhận mật khẩu mới"
            >
              Xác nhận mật khẩu mới <span style={{ color: '#B50829' }}>*</span>
            </label>
            <input
              className="appearance-none border border-gray-400  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="confirm_password"
              name="confirm_password"
              type="password"
              onChange={handleChangePass}
            />
            <span className="text-sm font-normal" style={{ color: '#B50829' }}>
              {validate.confirm_password !== ''
                ? validate.confirm_password
                : ''}
            </span>
          </div>
        </form>
        {/* Button */}
        <div
          className="text-center p-5"
          style={{
            boxShadow: '0px 0px 4px rgba(38, 44, 58, 0.1)',
          }}
        >
          <Button
            onClick={() => {
              onSubmit(state, formPassword);
            }}
            className="uppercase px-20 focus:outline-none focus:shadow-outline"
            style={{
              color: 'white',
              fontSize: '1rem',
              paddingLeft: '5rem',
              paddingRight: '5rem',
              background: '#B50829',
            }}
          >
            Lưu Lại
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};
