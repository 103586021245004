import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { CoursesRepository } from 'services/repositories/course.repository';

//Initial State
const courseRepository: CoursesRepository = RepositoryFactory.get(
  'course',
) as CoursesRepository;

const fetchCourses = async (action) => {
  const response = await courseRepository.update(
    action.id,
    action.data?.submitValues,
  );

  if (
    action.data?.submitFile?.p_doc_name ||
    action.data?.submitFile?.p_course_document_id !== 0
  ) {
    await courseRepository.uploadCourse(action.id, action.data?.submitFile);
  }

  return response;
};

function* updateCourses(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const courses = yield call(fetchCourses, action.payload);
    yield put({ type: types.UPDATE_COURSES_SUCCESS, payload: courses });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.UPDATE_COURSES_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchUpdateCourses() {
  yield takeLatest(types.UPDATE_COURSES_REQUEST, updateCourses);
}
