/* eslint-disable react/jsx-pascal-case */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { LF_Avatar, LF_Education, LF_Infor, LF_LiList } from 'components/atoms';
import {
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      '&:focus': {
        outline: 'none',
      },
    },
  },
  tab: {
    '&:focus': {
      backgroundColor: 'DB2017',
    },
  },
}));

export const LF_CourseSearch = ({
  rows,
  page,
  handlePage,
  loading,
  fromSearch,
  per_page,
  seeStudent
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [totalData, setTotalData] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // React.useEffect(() => {
  //   if (per_page?.length > 0) {
  //     const total = per_page[Object.keys(per_page)[0]];
  //     const totalData = total[Object.keys(total)[0]];
  //     setTotalData(totalData);
  //   }
  // }, [per_page]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    let a = (value - 1) * 20;
    handlePage(value);
    fromSearch('p_skip', a);
  };

  const renderLearningForm = (value) => {
    switch (value) {
      case '0':
        return 'Học trực tuyến (Online)';
      case '1':
        return 'Học tập trung (Offline)';
      default:
        return 'Học bất kỳ (Online hoặc Offline)';
    }
  };

  const onClick = (user) => {
    seeStudent(user);
  };
  return (
    <div>
      <TableContainer className="pt-7">
        {loading ? (
          <div className="flex justify-center py-10">
            <CircularProgress />
          </div>
        ) : (
          <Table aria-label="simple table" className="border border-solid">
            <TableHead style={{ backgroundColor: '#F4F2FF' }}>
              <TableRow>
                {columns &&
                  columns.map((e, key) => {
                    return (
                      <TableCell key={key} width={e.width}>
                        <span>{e.field}</span>
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length > 0 ? (
                rows.map((e, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell height="20px" className="items-center">
                        <LF_Avatar src={e.avatar} />
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <LF_Infor
                          to={'search'}
                          name={(e?.last_name !== null || e?.first_name !== null) ? `${e.last_name} ${e.first_name}` : 'Học viên'}
                          date={e.dob}
                          slug={e.slug}
                          gender={e.gender}
                          address={e.province_name}
                          userId={e.user_id}
                          onClick={() => onClick(e)}
                          covidStatus={e.covid_status}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <ul className="list-inside list-disc">
                          {e?.infor &&
                            e?.infor.map((item, index) => {
                              return <li key={index}>{item.name}</li>;
                            })}
                        </ul>
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <ul>
                          {e.discharged_army === 1 && (
                            <li className="text-red-500 list-disc">
                              Bộ đội xuất ngũ
                            </li>
                          )}
                          {e.study_ticket !== null && e.study_ticket !== '' && (
                            <li className="text-green-500 list-disc">
                              Thẻ học nghề
                            </li>
                          )}
                          {e.learning_form && (
                            <li className="list-disc">
                              {renderLearningForm(e.learning_form)}
                            </li>
                          )}
                        </ul>
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <ul className="list-inside list-disc">
                          {e?.location &&
                            e?.location.map((item, index) => {
                              return <li key={index}>{item.province_name}</li>;
                            })}
                        </ul>
                      </TableCell>
                      <TableCell>
                        <span className={'text-black'}>{e.lcs}</span>
                      </TableCell>
                      <TableCell>
                        <span className={'text-[#03AB00]'}>{e.ipoint}</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <a
                          style={{
                            fontFamily: 'roboto',
                            color: '#B50829',
                            fontSize: '15px',
                          }}
                          className=" px-1  cursor-pointer hover:underline focus:outline-none"
                          // target="_blank"
                          // href={`https://i-hr.vn/profile/${e.slug}`}
                          onClick={(event) => onClick(e)}
                        >
                          Xem
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <div className="mx-auto whitespace-nowrap lg:absolute lg:left-1/2 lg:text-xl py-2">
                  Không tìm thấy học viên
                </div>
              )}
            </TableBody>
          </Table>
        )
        }
        <div className={`${classes.root} flex justify-end`}>
          <Pagination
            count={rows.length >= 1 ? Math.ceil(per_page / 20) : 0}
            variant="outlined"
            page={page}
            onChange={handleChangePage}
            shape="rounded"
          />
        </div>
      </TableContainer >
    </div >
  );
};

const columns = [
  {
    field: 'Ảnh',
    width: '5%',
  },
  {
    field: 'Họ và tên',
    width: '15%',
  },
  {
    field: 'Nhu cầu học',
    width: '15%',
  },
  {
    field: 'Thông tin',
    width: '15%',
  },
  {
    field: 'Nơi học',
    width: '15%',
  },
  {
    field: 'LCS',
    width: '10%',
  },
  {
    field: 'i-Point',
    width: '10%',
  },
  {
    field: '',
    width: '1%',
  },
];
