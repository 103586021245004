import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CoursesRepository } from 'services/repositories/course.repository';

//Repository
const coursesRepository = RepositoryFactory.get('course') as CoursesRepository;

const fetchCoursesById = async (id: string) => {
  const response = await coursesRepository.getOne(id);
  return {
    data: response.data.course[0][0],
    attachment: response.data.attachment[0][0],
  };
};

function* getCoursesById(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const courses = yield call(fetchCoursesById, action.payload);
    yield put({ type: types.GET_COURSES_BY_ID_SUCCESS, payload: courses });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.GET_COURSES_BY_ID_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetCoursesById() {
  yield takeLatest(types.GET_COURSES_BY_ID_REQUEST, getCoursesById);
}
