import * as types from '../type';
interface IInitialState {
  packageVip: [],
}

const initialState: IInitialState = {
  packageVip: [],
}

const packageVipReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.GET_VIP_PACKAGES_SUCCESS:
      return {...state, packageVip: action.payload};
    case types.GET_VIP_PACKAGES_ERROR:
      return {...state, packageVip: action.payload};
    case types.GET_VIP_PACKAGES_REQUEST:
      return {...state, packageVip: action.payload};
    default:
      return {
        ...state,
      }
  }
}

export default packageVipReducer;