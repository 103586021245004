import { BaseRepository } from './base';
import { IPackage } from '../../utils/interfaces';
import Repository from './base/repository';

export class StorageRepository extends BaseRepository<IPackage> {
  resource = 'storage';
  token = localStorage.getItem('access-token');

  async getStorageAccessToken(corporateCode) {
    const response = await Repository.get(
      `${this.resource}/access-token`,
      {
        params: { role: 'corporate', objectId: corporateCode },
        headers: { Authorization: `Bearer ${this.token}` }
      },
    );
    return response.data;
  }
}