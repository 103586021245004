import React from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Portal,
} from '@material-ui/core';
import { TagMaker } from './Status.maker';

export const LF_StatusCourse = (props) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.interview);
  const [title, setTitle] = React.useState('');
  const classes = TagMaker();
  React.useEffect(() => {
    setValue(props.interview);
  }, [props.interview]);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const JSX = () => {
    return (
      <div className="absolute z-50 bg-white " onClick={handleClickAway}>
        <ButtonGroup className=" w-[180px]" orientation="vertical">
          <Button
            className="min-w-[180px] focus:outline-none border-none"
            style={{ justifyContent: 'start', border: 'none' }}
            onClick={() => {
              setValue(2);
              props.onUpdateStatus({
                p_user_id: `${props.userId}`,
                p_course_id: props.course_id,
                p_status: 2,
              });
            }}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 "
                style={{ color: '#007521' }}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
            }
          >
            {'Trúng Tuyển'}
          </Button>
          <Button
            className="min-w-[180px] focus:outline-none border-none"
            style={{ justifyContent: 'start', border: 'none' }}
            onClick={() => {
              setValue(3);
              props.onUpdateStatus({
                p_user_id: `${props.userId}`,
                p_course_id: props.course_id,
                p_status: 3,
              });
            }}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 "
                style={{ color: '#4A4AFF' }}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
            }
          >
            Đã chọn
          </Button>
          <Button
            className="min-w-[180px] focus:outline-none border-none"
            style={{ justifyContent: 'start', border: 'none' }}
            onClick={() => {
              setValue(4);
              props.onUpdateStatus({
                p_user_id: `${props.userId}`,
                p_course_id: props.course_id,
                p_status: 4,
              });
            }}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                style={{ color: '#B50829' }}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
            }
          >
            Từ chối
          </Button>
          <Button
            className="min-w-[180px] focus:outline-none border-none"
            style={{ justifyContent: 'start', border: 'none' }}
            onClick={() => {
              setValue(5);
              props.onUpdateStatus({
                p_user_id: `${props.userId}`,
                p_course_id: props.course_id,
                p_status: 5,
              });
            }}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
            }
          >
            xoá
          </Button>
        </ButtonGroup>
      </div>
    );
  };

  React.useEffect(() => {
    Value(value);
  }, [value]);

  const Value = (e) => {
    if (!e || e === 1) return setTitle('Đã đăng ký');
    if (e === 2) return setTitle('Trúng tuyển');
    if (e === 3) return setTitle('Đã chọn');
    if (e === 4) return setTitle('Từ chối');
    if (e === 5) return setTitle('Đã xóa');
  };

  if (!props.status) {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="static">
          <Button
            // disabled={value === 4 ? true : false}
            variant="contained"
            style={{
              textTransform: 'none',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '15px',
            }}
            className={`${classes.tag} + 
            ${
              value === 1
                ? classes.tagOrg
                : value === 2
                ? classes.tagGreen
                : value === 3
                ? classes.tagBlue
                : value === 4
                ? classes.tagRed
                : classes.tagBlack
            }
             static focus:outline-none	  `}
            onClick={handleClick}
          >
            {title}
          </Button>
          {open ? JSX() : null}
        </div>
      </ClickAwayListener>
    );
  }
};
