import * as types from "../type";

interface StateType {
  loading: boolean;
}

const initialState: StateType = {
  loading: false,
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOADING_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case types.LOADING_SUCCESS:
      return {
        ...state,
        loading: action.payload,
      };
    
    default:
      return state;
  }
};

export default loadingReducer;
