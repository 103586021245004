// React - Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { useState } from 'react';
import React from 'react';

import StatusPost from "../../../molecules/StatusPost/StatusPost";
import {BuyPackageOrganism} from "../BuyPackage";
import {IPointPackageRepository} from "../../../../services/repositories/ipointPackage.repository";
import {RepositoryFactory} from "../../../../services/repositories";

interface DataProps {
  listPackage?: any;
  listPackageTime?: any;
  statusJob?: any;
  ipoint?: any;
  setStatusJobPayment?: any;
  object?: any;
  status?: any;
  name?: any;
  job?: any;
  oldPackages?: any;
}
const packageRepository: IPointPackageRepository = RepositoryFactory.get(
    'package',
) as IPointPackageRepository;
export const LF_Done = ({
  ipoint,
  listPackage,
  listPackageTime,
  statusJob,
  status,
  job,
  oldPackages
}: Partial<DataProps>) => {
  const [statusJobPayment, setStatusJobPayment] = useState(statusJob === 'create' ? false : !! job?.payment_status);
  return (
    <>
      {
        !statusJobPayment ?
            <BuyPackageOrganism
                status={status}
                object={job}
                name={"job"}
                listPackage={listPackage}
                listPackageTime={listPackageTime}
                setStatusJobPayment={setStatusJobPayment}
                statusJob={statusJob}
                oldPackages={oldPackages}
                ipoint={ipoint}
            />
            : <StatusPost status={status} id={job?.job_id} name={"job"} />
      }
    </>
  );
};
