import React, { useEffect } from 'react';
import { LF_DataTable } from '../DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPackageBuy,
  getPackageTime,
} from '../../../../redux/actions';
import { RootState } from '../../../../redux/store';

export const CLC_IPointBuy = () => {
  const dispatch = useDispatch();
  const { packageBuy, packageTime } = useSelector((state: RootState) => state);
  useEffect(() => {
    dispatch(getPackageBuy());
    dispatch(getPackageTime());
  }, []);
  return (
    <div className={'w-full p-5'}>
      <h3 className={'mt-3 text-xl font-bold uppercase'}>chọn mệnh giá</h3>
      <LF_DataTable
        rows={packageBuy?.packageBuy}
        packageTime={packageTime?.packageTime}
        type='IPointBuy'
      />
    </div>
  )
}