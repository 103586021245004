/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

// Components
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  CircularProgress,
} from '@material-ui/core';
import { LF_StatusRecuit } from 'components/atoms/Status/StatusRecuit.atom';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
// React - Redux
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Commons
import { convertDate } from 'utils/commons';
//csv
import { CSVLink } from 'react-csv';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      '&:focus': {
        outline: 'none',
      },
    },
  },
  buttonTab: {
    backgroundColor: 'white !improtant',
    color: '#000000 !important',
    fontWeight: 'bold',
    fontSize: '14px',
    minWidth: 100,
    textTransform: 'none',
    padding: '0 12px',
    opacity: 1,
    '&:focus': {
      outline: '2px solid transparent',
      outlineOffset: '2px',
      backgroundColor: '#B50829 !important',
      color: 'white !important',
    },
    '&:active': {
      backgroundColor: '#B50829 !important',
      color: 'white !important',
    },
  },
  Roboto: {
    fontFamily: 'Roboto',
  },
}));

export const LF_JobRecruitment = ({
  rows,
  per_page,
  fromSearch,
  onDelete,
  page,
  handlePage,
  onRestore,
  dataExportExcel,
}) => {
  // React- Redux
  const history = useHistory();
  // Style
  const classes = useStyles();

  const { loading } = useSelector((state) => state['loading']);

  // State
  const [title, setTitle] = React.useState(false);
  const [dateSt, setDateSt] = React.useState(false);
  const [dateEn, setDateEn] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [see, setSee] = React.useState(false);
  const [statusEmp, setStatusEmp] = React.useState(0);
  // Handel event
  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    let a = (value - 1) * 20;
    fromSearch('skip', a);
  };

  const getPageTotal = () => {
    switch (statusEmp) {
      case 1:
        return per_page?.cho_duyet;
      case 2:
        return per_page?.da_xoa;
      case 3:
        return per_page?.dang_ht;
      case 4:
        return per_page?.het_han;
      default:
        return per_page?.total;
    }
  };

  //View HTML
  const titleTab = (label, totalData) => {
    return (
      <React.Fragment>
        <span className="flex">
          <span>{label}</span>
          <span className="pl-2">{totalData}</span>
        </span>
      </React.Fragment>
    );
  };

  //Export Excel
  const headers = [
    { label: 'Mã tin', key: 'job_id' },
    { label: 'Tiêu đề', key: 'title' },
    { label: 'Ngày đăng', key: 'start_date' },
    { label: 'Ngày hết hạn', key: 'end_date' },
    { label: 'Đã xem', key: 'view' },
    { label: 'Ứng tuyển', key: 'applied' },
    { label: 'Trạng thái', key: 'statusName' },
  ];
  return (
    <div className={"w-full mt-6"}>
      <div
        className="text-right uppercase"
        style={{
          color: '#B50829',
        }}
      >
        <CSVLink
          data={dataExportExcel}
          headers={headers}
          filename={'danh-sach-tuyen-dung.csv'}
        >
          Xuất ra Excel
        </CSVLink>
      </div>
      <TableContainer className="pt-3">
        <Tabs
          value={value}
          onChange={handleChangeTabs}
          style={{ width: '100%', border: ' 1px solid rgba(0, 0, 0, 0.25)' }}
        >
          <Tab
            label={titleTab('Tất cả', per_page?.total)}
            onClick={() => {
              fromSearch('status', 0);
              setStatusEmp(0);
            }}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '20%' }}
          />
          <Tab
            label={titleTab('Chờ xử lý', per_page?.cho_duyet)}
            className={`${classes.buttonTab} focus:outline-none`}
            onClick={() => {
              fromSearch({ status: 1, skip: 0 });
              setStatusEmp(1);
            }}
            style={{ width: '20%' }}
          />
          <Tab
            label={titleTab('Đang hoạt động', per_page?.dang_ht)}
            className={`${classes.buttonTab} focus:outline-none`}
            onClick={() => {
              fromSearch({ status: 2, skip: 0 });
              setStatusEmp(3);
            }}
            style={{ width: '20%' }}
          />
          <Tab
            label={titleTab('Hết hạn', per_page?.het_han)}
            className={`${classes.buttonTab} focus:outline-none`}
            onClick={() => {
              fromSearch({ status: 5, skip: 0 });
              setStatusEmp(3);
            }}
            style={{ width: '20%' }}
          />
          <Tab
            label={titleTab('Đã xóa', per_page?.da_xoa)}
            className={`${classes.buttonTab} focus:outline-none`}
            onClick={() => {
              fromSearch({ status: 6, skip: 0 });
              setStatusEmp(2);
            }}
            style={{ width: '20%' }}
          />
        </Tabs>
        {/* Table Data */}
        {loading ? (
          <div className="flex justify-center py-10">
            <CircularProgress />
          </div>
        ) : (
          <Table aria-label="simple table" className="border border-solid">
            <TableHead style={{ backgroundColor: '#F4F2FF' }}>
              <TableRow>
                {columns &&
                  columns.map((e, key) => {
                    return (
                      <TableCell
                        key={key}
                        style={{
                          width: `${e.width}`,
                          textAlign: 'start',
                        }}
                      >
                        {e.field === 'Tiêu Đề' ? (
                          <button
                            className=" focus:outline-none"
                            onClick={() => {
                              setTitle(!title);
                              if (title) {
                                fromSearch('p_order_title', 1);
                              } else {
                                fromSearch('p_order_title', 2);
                              }
                            }}
                          >
                            <span className="font-semibold">
                              {e.field}
                              {title ? (
                                <i className="px-2 fas fa-angle-down text-[#B50829]"></i>
                              ) : (
                                <i className="px-2 fas fa-angle-up text-[#B50829]"></i>
                              )}
                            </span>
                          </button>
                        ) : e.field === 'Ngày Đăng' ? (
                          <button
                            className=" focus:outline-none"
                            onClick={() => {
                              setDateSt(!dateSt);
                              if (dateSt) {
                                fromSearch('p_order_title', 7);
                              } else {
                                fromSearch('p_order_title', 8);
                              }
                            }}
                          >
                            <span className="font-semibold">
                              {e.field}
                              {dateSt ? (
                                <i className="px-2 fas fa-angle-down text-[#B50829]"></i>
                              ) : (
                                <i className="px-2 fas fa-angle-up text-[#B50829]"></i>
                              )}
                            </span>
                          </button>
                        ) : e.field === 'Ngày Hết Hạn' ? (
                          <button
                            className=" focus:outline-none"
                            onClick={() => {
                              setDateEn(!dateEn);
                              if (dateEn) {
                                fromSearch('p_order_title', 3);
                              } else {
                                fromSearch('p_order_title', 4);
                              }
                            }}
                          >
                            <span className="font-semibold">
                              {e.field}
                              {dateEn ? (
                                <i className="px-2 fas fa-angle-down text-[#B50829]"></i>
                              ) : (
                                <i className="px-2 fas fa-angle-up text-[#B50829]"></i>
                              )}
                            </span>
                          </button>
                        ) : e.field === 'Xem' ? (
                          <button
                            className=" focus:outline-none"
                            onClick={() => {
                              setSee(!see);
                              if (see) {
                                fromSearch('p_order_title', 5);
                              } else {
                                fromSearch('p_order_title', 6);
                              }
                            }}
                          >
                            <span className="font-semibold">
                              {e.field}
                              {see ? (
                                <i className="px-2 fas fa-angle-down text-[#B50829]"></i>
                              ) : (
                                <i className="px-2 fas fa-angle-up text-[#B50829]"></i>
                              )}
                            </span>
                          </button>
                        ) : (
                          <span className="font-semibold">{e.field}</span>
                        )}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows &&
                rows.map((ele, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell height="20px" className="items-center">
                        <span> {ele.job_id}</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <div>
                          <a href={`/admin/job/edit/${ele.job_id}`}>
                            {ele.title}
                          </a>
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <span>{`${(
                          '0' + new Date(ele.start_date).getDate()
                        ).slice(-2)}/${(
                          '0' +
                          (new Date(ele.start_date).getMonth() + 1)
                        ).slice(-2)}/${new Date(
                          ele.start_date,
                        ).getFullYear()}`}</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <span>{`${(
                          '0' + new Date(ele.end_date).getDate()
                        ).slice(-2)}/${(
                          '0' +
                          (new Date(ele.end_date).getMonth() + 1)
                        ).slice(-2)}/${new Date(
                          ele.end_date,
                        ).getFullYear()}`}</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center "
                      >
                        <span>{ele.view}</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        className="items-center"
                      >
                        <span>{ele.applied}</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        height="20px"
                        width="180px"
                        className="items-center"
                      >
                        {/* Satus */}
                        <LF_StatusRecuit interview={ele.status} />
                      </TableCell>

                      <TableCell
                        align="center"
                        height="20px"
                        className="items-center texr-right"
                      >
                        {ele.status === 2 ? (
                          <button
                            style={{
                              color: '#B50829',
                            }}
                            onClick={() => {
                              history.push(
                                `/admin/job/employee?job_id=${ele.job_id}`,
                              );
                            }}
                            className={`px-1 cursor-pointer hover:underline focus:outline-none text-base`}
                          >
                            Ứng Viên
                          </button>
                        ) : (
                          ''
                        )}
                        {ele.status !== 2 && ele.status !== 6 ? (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              onDelete(ele.job_id);
                            }}
                            style={{
                              color: '#B50829',
                            }}
                            className=" cursor-pointer hover:underline focus:outline-none  text-base"
                          >
                            Xóa
                          </button>
                        ) : (
                          ''
                        )}
                        {ele.status === 6 ? (
                          <React.Fragment>
                            {/*<button*/}
                            {/*  onClick={(e) => {*/}
                            {/*    e.preventDefault();*/}
                            {/*    onRestore(ele.job_id);*/}
                            {/*  }}*/}
                            {/*  style={{*/}
                            {/*    color: '#B50829',*/}
                            {/*  }}*/}
                            {/*  className=" cursor-pointer hover:underline focus:outline-none  text-base"*/}
                            {/*>*/}
                            {/*  Khôi phục*/}
                            {/*</button>*/}
                            {statusEmp === 2 && (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  onDelete(ele.job_id);
                                }}
                                style={{
                                  color: '#B50829',
                                }}
                                className=" cursor-pointer hover:underline focus:outline-none text-base pl-2"
                              >
                                Xóa hẳn
                              </button>
                            )}
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <div className="mx-auto absolute left-1/2 text-xl">
                  Chưa có danh sách tuyển dụng
                </div>
              )}
            </TableBody>
          </Table>
        )}

        {/* Pagination */}
        <div className={`${classes.root} flex justify-end`}>
          <Pagination
            count={rows.length >= 1 ? Math.ceil(getPageTotal() / 20) : 0}
            variant="outlined"
            onChange={handleChangePage}
            shape="rounded"
          />
        </div>
      </TableContainer>
    </div>
  );
};

// Header Table
const columns = [
  {
    field: 'Mã Tin',
    width: '10%',
  },
  {
    field: 'Tiêu Đề',

    width: '23%',
  },
  {
    field: 'Ngày Đăng',
    width: '15%',
  },
  {
    field: 'Ngày Hết Hạn',
    width: '15%',
  },
  {
    field: 'Xem',
    width: '8%',
  },
  {
    field: 'Ứng Tuyển',
    width: '10%',
  },
  {
    field: 'Trạng Thái',
    width: '5%',
  },

  {
    field: '',
    width: '12%',
  },
];
