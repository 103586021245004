import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { ProvinceRepository } from '../../../services/repositories/province.repository';

//Initial State
const provinceRepository: ProvinceRepository = RepositoryFactory.get(
  'province',
) as ProvinceRepository;

const fetchGetCommunes = async ({ province_code, district_code }) => {
  const response = await provinceRepository.getCommune(
    province_code,
    district_code,
  );

  return response;
};

function* getCommunes(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const districts = yield call(fetchGetCommunes, action.payload);

    yield put({ type: types.COMMUNE_SUCCESS, payload: districts });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.COMMUNE_ERROR, payload: err.response.data });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetCommunes() {
  yield takeLatest(types.COMMUNE_REQUEST, getCommunes);
}
