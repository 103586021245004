import React from 'react';
import { TagMaker } from './Status.maker';

export const LF_StatusText = (props) => {
  const { status } = props;
  //const statusText = status[Object.keys(status)[0]];

  const classes = TagMaker();
  // return <div style={{ color: '#00C637' }}>{statusText && statusText} %</div>;
  if (status >= 75) {
    return (
      <div
        className="text-sm leading-4 font-semibold"
        style={{ color: '#00C637' }}
      >
        {status}%
      </div>
    );
  } else if (status > 25) {
    return (
      <div
        className="text-sm leading-4 font-semibold"
        style={{ color: '#4A4AFF' }}
      >
        {status}%
      </div>
    );
  } else if (status <= 25 && status > 0) {
    return (
      <div
        className="text-sm leading-4 font-semibold"
        style={{ color: '#DB2017' }}
      >
        {status}%
      </div>
    );
  } else {
    return (
      <div
        className="text-sm leading-4 font-semibold"
        style={{ color: '#000000' }}
      >
        0%
      </div>
    );
  }
};
