import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { GridCellParams } from '@material-ui/x-grid';

interface Avt {
  src: string;
  alt?: string;
  width?: string;
  height?: string;
  any?: any;
}

export function LF_Avatar(props: Partial<Avt>) {
  return (
    <Avatar
      alt={props?.alt}
      src={`https://assets.i-hr.vn/` + props.src}
      style={{
        width: `${props?.width ? props?.width : '64px'}`,
        height: `${props?.height ? props?.height : '64px'}`,
      }}
    />
  );
}
