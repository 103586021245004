/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { LF_JobRecruitment } from './JobRecruitment';
import { LF_JobEmployee } from './JobEmployee';
import { LF_JobSearch } from './JobSearchJob';
import { LF_CourseList } from './Course';
import { LF_CourseMember } from './CourseMember';
import { LF_CourseSearch } from './CourseSearch';
import { CLC_IPointHistory } from './IPointHistory';
import { CLC_IPointOrder } from './IPointOrder';
import { CLC_IPointBuy } from './IPointBuy';

//component

interface DataProps {
  rows: any;
  columns: any[];
  type: string;
  per_page?: string;
  fromSearch?: any;
  onDelete?: any;
  onFilter?: any;
  student?: any[];
  data: any;
  corporates: any;
  onChangeStatusEmp?: any;
  onChangeStatusStudent?: any;
  loading: any;
  page: any;
  handlePage?: any;
  onRestore?: any;
  dataExportExcel?: any;
  packageTime?: [];
  seeCandidate?: any;
  seeStudent?: any
}

export const LF_DataTable = ({
  rows,
  per_page,
  type,
  fromSearch,
  onDelete,
  onFilter,
  student,
  data,
  corporates,
  onChangeStatusEmp,
  onChangeStatusStudent,
  loading,
  page,
  handlePage,
  onRestore,
  dataExportExcel,
  packageTime,
  seeCandidate,
  seeStudent
}: Partial<DataProps>) => {
  // Danh sách ứng tuyển
  if (type === 'JobEmployee') {
    return (
      <LF_JobEmployee
        rows={rows}
        per_page={per_page}
        data={data}
        fromSearch={fromSearch}
        corporates={corporates}
        onChangeStatusEmp={onChangeStatusEmp}
        page={page}
        handlePage={handlePage}
        dataExportExcel={dataExportExcel}
      />
    );
  }
  // Danh sách tuyển dụng
  if (type === 'JobRecruitment') {
    return (
      <LF_JobRecruitment
        rows={rows}
        per_page={per_page}
        fromSearch={fromSearch}
        onDelete={onDelete}
        page={page}
        handlePage={handlePage}
        onRestore={onRestore}
        dataExportExcel={dataExportExcel}
      />
    );
  }
  if (type === 'JobSearch') {
    return (
      <LF_JobSearch
        rows={rows}
        fromSearch={fromSearch}
        corporates={corporates}
        per_page={per_page}
        loading={loading}
        page={page}
        handlePage={handlePage}
        seeCandidate={seeCandidate}
      />
    );
  }
  if (type === 'CourseList') {
    return (
      <LF_CourseList
        fromSearch={fromSearch}
        per_page={per_page}
        rows={rows}
        student={student}
        onChangeStatusStudent={onChangeStatusStudent}
        page={page}
        handlePage={handlePage}
        dataExportExcel={dataExportExcel}
      />
    );
  }
  if (type === 'CourseMember') {
    return (
      <LF_CourseMember
        rows={rows}
        per_page={per_page}
        fromSearch={fromSearch}
        onDelete={onDelete}
        onFilter={onFilter}
        dataExportExcel={dataExportExcel}
      />
    );
  }
  if (type === 'CourseSearch') {
    return (
      <LF_CourseSearch
        rows={rows}
        page={page}
        per_page={per_page}
        handlePage={handlePage}
        loading={loading}
        fromSearch={fromSearch}
        seeStudent={seeStudent}
      />
    );
  }
  if (type === 'IPointHistory') {
    return (
      <CLC_IPointHistory
        rows={rows}
        page={page}
        per_page={per_page}
        fromSearch={fromSearch}
        handlePage={handlePage}
      />
    );
  }
  if (type === 'IPointOrder') {
    return (
      <CLC_IPointOrder
        rows={rows}
        page={page}
        per_page={per_page}
        fromSearch={fromSearch}
        handlePage={handlePage}
      />
    );
  }
  if (type === 'IPointBuy') {
    return (
      <CLC_IPointBuy
        rows={rows}
      />
    );
  }
};
