import { BaseRepository } from './base';
import Repository from './base/repository';

export class CareerCodeRepository extends BaseRepository<any> {
  resource = 'career-by-code';
  token = localStorage.getItem('access-token');

  /**
   * Method search Career Group
   * @param query string
   * @return Array
   */
  async getCareerCode(career_code: string) {
    this.token = localStorage.getItem('access-token');
    return (await Repository.get(`${this.resource}/${career_code}`)).data;
  }
}
