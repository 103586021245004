import * as types from '../../type';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { IPointRepository } from '../../../services/repositories/ipoint.repository';


//Repository
const ipointRepositoryTotal: IPointRepository = RepositoryFactory.get(
  'ipoint',
) as IPointRepository;

const fetchIPointOrder = async (query) => {
  const response = await ipointRepositoryTotal.getIPointOrder(query);
  return response;
};

function* handleIPointOrder(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const order = yield call(fetchIPointOrder, action.payload);
    yield put({ type: types.GET_IPOINT_ORDER_SUCCESS, payload: order });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.GET_IPOINT_ORDER_ERROR,
      payload: err.response,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetIPointHistory() {
  yield takeLatest(types.GET_IPOINT_ORDER_REQUEST, handleIPointOrder);
}