import { call, put, takeLatest } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { EmployeeRepository } from 'services/repositories/employee.repository';
import { AlertType, showNotification } from 'utils/commons/Alert';
import history from '../../../utils/guards/history.guard';
import * as types from '../../type';

const employeeRepository: EmployeeRepository = RepositoryFactory.get(
  'emp',
) as EmployeeRepository;

const fetchResetPass = async (data) => {
  const response = await employeeRepository.resetPass(data);

  return response;
};

function* resetPassword(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const change = yield call(fetchResetPass, action.payload);
    yield put({
      type: types.RESET_PASSWORD_SUCCESS,
      payload: change,
    });
    showNotification({
      type: AlertType.SUCCESS,
      msg: 'Cật nhập mật khẩu thành công!',
    });
    setTimeout(() => {
      history.push('/auth/login');
      return history.go(0);
    }, 3000);
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {}
}

export default function* watchResetPassword() {
  yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword);
}
