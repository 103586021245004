import { BaseRepository } from "./base";
import Repository from './base/repository';
import { IBase } from '../../utils/interfaces/base';

export class IPointRepository extends BaseRepository<IBase> {

  resource = 'ipoint';
  token = localStorage.getItem('access-token');

  async getIPointTotal(p_corporate_code: string) {
    const response = await Repository.get(
      `${this.resource}/wallet-balance?p_corporate_code=${p_corporate_code}`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }

  async getIPointHistory(query?: any) {
    const response = await Repository.get(
      `${this.resource}/history?p_corporate_code=${
        query?.p_corporate_code ? query.p_corporate_code : null
      }&p_tran_type=${
        query?.p_tran_type ? query.p_tran_type : 0
      }&p_from_date=${
        query?.p_from_date ? query.p_from_date : '1970-01-01'
      }&p_to_date=${query?.p_to_date ? query.p_to_date : '2100-01-01'
      }&p_skip=${
        query?.p_skip ? query.p_skip : 0
      }&p_limit=${
        query?.p_limit ? query.p_limit : 20
      }`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }

  async buyRecruitment(body) {
    const response = await Repository.post(
      `${this.resource}/buy-recruitment`,
      body,
      { headers: { Authorization: `Bearer ${this.token}` } },
    )
    return response;
  }
  async getIPointHistoryExcel(p_corporate_code: String, p_tran_type: Number) {
    const response = await Repository.get(
      `${this.resource}/history?p_corporate_code=${p_corporate_code}&p_tran_type=${p_tran_type}&p_skip=0&p_limit=1000`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }
  async getIPointOrder(query?: any) {
    const response = await Repository.get(
      `${this.resource}/order?p_corporate_code=${
        query?.p_corporate_code ? query.p_corporate_code : null}&p_skip=${
        query?.p_skip ? query.p_skip : 0}&p_limit=${
        query?.p_limit ? query.p_limit : 0}`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }

  async getPaymentUrl(data) {
    this.token = localStorage.getItem('access-token');
    const response = await Repository.post(
      `${this.resource}/payment-url`,
      data,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }
}
