//Auth Services
import React from 'react';
import Authenticate from '../../services/authentication';

export type DataAuth = { username: string; password: string };

export type UserDetail = {
  corporate_code: string;
  employee_email: string;
  employee_id: any;
  employee_name: string;
  exp: number;
  iat: number;
  is_deteled: boolean;
  username: string;
};

const AuthContext = React.createContext(null);

export const AuthProvider = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAuthen, setIsAuthen] = React.useState(false);
  const [infor, setInfor] = React.useState(null);
  const isLoggedIn = async () => {
    await Authenticate.refreshToken()
      .then((res) => {
        res ? setIsAuthen(true) : setIsAuthen(false);
        setInfor(res);
      })
      .catch(() => setIsAuthen(false))
      .then(() => setIsLoading(false));

    // const user = getUserDetailsWithToken();
    // đổi cái nayf thành get api refresh token đi anh nó nhuw thg kia thoi khác cái là này frontend phải tự lưu r lấy
    // if (user) {
    //   user.exp > Date.now() / 1000 ? setIsAuthen(true) : setIsAuthen(false);
    //   setIsLoading(false);
    // } else {
    //   setIsAuthen(false);
    //   setIsLoading(false);
    // }
  };

  const value = {
    signIn: props.signIn || signIn,
    logOut: props.logOut || logOut,
    isLoggedIn: props.isLoggedIn || isLoggedIn,
    isLoading: props.isLoading || isLoading,
    isAuthenticate: props.isAuthen || isAuthen,
    infor: props.infor || infor,
  };
  React.useEffect(() => {
    isLoggedIn();
  }, []);
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

const signIn = async (body: DataAuth): Promise<any> => {
  try {
    //Todo: sign in
    let data = await Authenticate.login(body);
    localStorage.setItem('access-token', data.access_token);
    localStorage.setItem('refresh-token', data.refresh_token);
    return data;
  } catch (err) {
    alert('Thông tin đăng nhập không đúng hoặc tài khoản chưa được kích hoạt');
  }
};

const clearCache = async () => {
  //TODO: remove cache
  return true;
};

const getToken = (): string | null => {
  const token = localStorage.getItem('access-token');
  return token;
};

export const getUserDetailsWithToken = (): UserDetail => {
  const token = getToken();
  let payload;
  if (token) {
    // payload = token.split('.')[1];
    // payload = window.atob(payload);
    // return JSON.parse(payload);
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  } else {
    return null;
  }
};

export const logOut = async (): Promise<void> => {
  window.localStorage.removeItem('access-token');
  window.localStorage.removeItem('refresh-token');
  return window.location.replace('/');
};
