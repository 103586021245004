import React from 'react';
import { List } from '@material-ui/core';

export const LF_Education = ({ props }) => {
  return (
    <React.Fragment>
      <List>
        {props?.map((e, index) => {
          return (
            <div className="mb-2 list-disc" key={index}>
              {e?.school_name === '' ? '' : <li>{e.school_name}</li>}
            </div>
          );
        })}
      </List>
    </React.Fragment>
  );
};
