import React from 'react';
import { LF_BreadcumbsProps } from './Breadcumbs.type';

export const LF_Breadcumbs = ({
  className = '',
  activeClassName = 'text-blue-400',
  normalClassName = '',
  routeName,
  route,
}: LF_BreadcumbsProps) => {
  return (
    <div
      className="flex flex-row items-center w-full p-3 lg:p-4"
      style={{
        position: 'static',
        height: '60px',
        left: '0px',
        top: '0px',
        background: '#F4F2FF',
      }}
    >
      <ul
        className={`${className} flex text-gray-500 `}
        style={{
          lineHeight: '28px;',
          position: 'static',
          width: '340px;',
          height: '28px;',
          left: '35px;',
          color: 'rgba(0, 0, 0, 0.75)',
          top: '21.5px;',
        }}
      >
        <li className={'inline-flex items-center'}>
          <a href="/">Trang chủ &gt; </a>
        </li>

        <li className={'inline-flex items-center '}>
          <a href={route}>&nbsp;{routeName}</a>
        </li>
      </ul>
    </div>
  );
};
