import React from "react";


export const DetailOrder = ({status, paymentMethod, vnpTransactionNo}) => {
    switch (status) {
        case 1:
            return <span className={'text-[#B50829]'}>Chưa thanh toán</span>;
        case 2:
            if (paymentMethod === 1) {
                return <span className={'text-[#03AB00]'}>Giao dịch thành công - Mã GD: {vnpTransactionNo}</span>
            } else {
                return <span className={'text-[#03AB00]'}>Đã thanh toán</span>;
            }
        default:
            return <span className={'text-[#B50829]'}>Chưa có thông tin</span>;
    }
}