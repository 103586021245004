import * as types from '../type';
import { ISkill } from '../../utils/interfaces/skill.interface';

interface IInitialState {
  skills: ISkill[];
  skill: ISkill | {};
  status: any;
}

const initialState: IInitialState = {
  skills: [],
  skill: {},
  status: null,
};

const skillReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.SEARCH_SKILL_SUCCESS:
      return { ...state, skills: action.payload };
    case types.SEARCH_SKILL_ERROR:
      return { ...state, status: action.payload };
    default:
      return {
        ...state,
      };
  }
};

export default skillReducer;
