import axios from 'axios';
import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { RepositoryFactory } from '../../../services/repositories';
import { StorageRepository } from '../../../services/repositories/storage.repository';

interface CropImage {
  state?: any;
  setState?: any;
}

const storageRepository: StorageRepository = RepositoryFactory.get(
  'storage',
) as StorageRepository;

export const CropImageBannerCourse = ({
  state,
  setState,
}: Partial<CropImage>) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [srcImg, setSrcImg] = useState(null);
  const corporate_code = localStorage.getItem('corporate_code');
  const selectRef: any = React.useRef();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  //get Cropped Image
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, fileName) {
    const image: any = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob((file: any) => {
        if (!file) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        file.name = fileName;
        resolve(file);
      }, 'image/jpeg');
    });
  }
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(
        srcImg,
        croppedAreaPixels,
        rotation,
        'image_logo.jpeg',
      );
      if (croppedImage) {
        setSrcImg(null);
        let formData = new FormData();
        formData.append('file', croppedImage);
        const { accessToken } = await storageRepository.getStorageAccessToken(corporate_code);
        if (accessToken) {
          const response = await axios.post(
            `https://assets.i-hr.vn/upload?accessToken=${accessToken}`,
            formData,
          );
          if (response && response.status === 200) {
            setState('p_background', response.data.data.url, 'basicInfo');
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, srcImg]);

  //Handle
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setSrcImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const deleteImage = () => {
    selectRef.current.value = null;
    setState('p_background', '', 'basicInfo');
  };

  return (
    <React.Fragment>
      {srcImg && (
        <div
          className="py-2"
          style={{
            position: 'relative',
            width: '100%',
            height: 400,
            background: '#333',
          }}
        >
          <Cropper
            image={srcImg}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={16 / 9}
            // cropSize={{ width: 1024, height: 360 }}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      )}
      {state.p_background && (
        <div className=" px-4 mb-8 mt-4">
          <img
            className=" shadow-md w-[1024px] h-[360px]"
            src={`https://assets.i-hr.vn/${state.p_background}`}
            alt={'demoBanner'}
          />
        </div>
      )}

      <div
        className="flex font-semibold mb-2  uppercase text-lg"
        style={{ color: '#B50829' }}
      >
        <label className=" flex flex-wrap items-center cursor-pointer">
          <input
            name="flies"
            className="hidden"
            type="file"
            accept="image/*"
            onChange={onSelectFile}
            ref={selectRef}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          CHỌN ẢNH
        </label>
        <label
          className=" flex flex-wrap items-center cursor-pointer ml-3"
          onClick={showCroppedImage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
          SAVE ẢNH
        </label>
        <label
          className=" flex flex-wrap items-center cursor-pointer ml-3"
          onClick={deleteImage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          XÓA ẢNH
        </label>
      </div>
    </React.Fragment>
  );
};
