import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CandidateRepository } from '../../../services/repositories/candidate.repository';

//Repository
const candidateRepository: CandidateRepository = RepositoryFactory.get(
  'candidate',
) as CandidateRepository;

//Async Fetch
const fetchMatchCandidateSearch = async (query?: any) => {
  const response = await candidateRepository.getMatchCandidateSearch(query);
  let result = { data: [], total_data: response.total_data };
  if (response.data) {
    for (const item of response.data) {
      const fetchAll = await Promise.all([
        candidateRepository.getCandidateSkill(item.user_id),
        candidateRepository.getCandidateEducations(item.user_id),
        candidateRepository.getCandidateExp(item.user_id),
      ]);
      result.data.push({
        ...item,
        skill: fetchAll[0],
        educations: fetchAll[1],
        exp: fetchAll[2],
      });
    }
  }

  return result;
};

function* handlerMatchCandidate(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const matchCandidate = yield call(
      fetchMatchCandidateSearch,
      action.payload,
    );

    yield put({
      type: types.GET_MATCHCANDIDATESEARCH_SUCCESS,
      payload: matchCandidate,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.GET_MATCHCANDIDATESEARCH_ERROR,
      payload: err.response.data,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchMatchCandidate() {
  yield takeLatest(
    types.GET_MATCHCANDIDATESEARCH_REQUEST,
    handlerMatchCandidate,
  );
}
