import React, {useState} from 'react';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { logOut } from '../../../views/wrapper/Auth.wrapper';
import { SidebarProps } from './Sidebar.type';
import iPointYellow from '../../../assets/images/iPointYellow.png';
import Wallet from '../../molecules/Icon/Wallet';
import ipointFormat from '../../../utils/commons/math/ipointFormat';

export const LF_Sidebar = ({
  menus,
  count,
  countCourse,
  ipoint,
}: Partial<SidebarProps>) => {
  const location = useLocation();
  const [menu, setMenu] = useState('');
  return (
    <React.Fragment>
      <div  className={`menu w-0 lg:w-3/12 xl:w-[23%] 2xl:w-2/12 ${menu}`} style={{ background: '#253A43', minHeight: '1200px' }}>
        <div className="general pt-10">
          <p className="text-sm uppercase text-white px-5">
            tài khoản thanh toán
          </p>
          <ul className="pt-3 list-none	">
            {menus &&
            menus?.root?.ipoint.map((item, index) => (
              <li
                key={index}
                className={cx([
                  ' text-base overflow-hidden',
                  { 'bg-[#B50829]': location.pathname === item.path },
                ])}
              >
                <Link className={'flex items-center justify-between hover:bg-[#B50829]'} onClick={() => setMenu('')} to={item.path}>
                    <span className="flex items-center justify-start text-white p-3 lg:py-3 lg:px-5 xl:px-9 xxl:px-9 block hover:bg-[#B50829] hover:text-white">
                      <span className={"whitespace-nowrap"}>{item.menu_name}</span>
                    </span>
                  {item.menu_name === 'iPoint' ? (
                    <span
                      className={`mr-3 font-bold`}
                      style={{ color: '#FAFF00' }}
                    >
                       <img className={'inline ml-2 w-[20px]'} src={iPointYellow} alt='iPoint' /> {ipointFormat(ipoint)}
                      </span>
                  ) : null}
                  {item.menu_name === 'Mua ipoint' ? (
                    <span
                      className={`mr-3 font-bold`}
                      style={{ color: '#FAFF00' }}
                    >
                       <Wallet />
                      </span>
                  ) : null}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="general pt-10">
          <p className="text-sm uppercase text-white px-5">
            Home
          </p>
          <ul className="pt-3 list-none	">
            {menus &&
              menus?.root?.home.map((item, index) => (
                <li
                  key={index}
                  className={cx([
                    ' text-base overflow-hidden',
                    { 'bg-[#B50829]': location.pathname === item.path },
                  ])}
                >
                  <Link onClick={() => setMenu('')} to={item.path}>
                    <span className="flex items-center justify-start text-white p-3 lg:py-3 lg:px-5 xl:px-9 xxl:px-9 block hover:bg-[#B50829] hover:text-white">
                      <span className={"whitespace-nowrap"}>{item.menu_name}</span>
                    </span>

                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <div className="general pt-10">
          <p className="text-sm uppercase text-white px-5">
            QUẢN LÝ VIỆC LÀM
          </p>
          <ul className="pt-3 list-none	">
            {menus &&
              menus?.root?.job.map((item, index) => (
                <li
                  key={index}
                  className={cx([
                    ' text-base overflow-hidden',
                    { 'bg-[#B50829]': location.pathname === item.path },
                  ])}
                >
                  <Link  onClick={() => setMenu('')}
                    className="flex items-center justify-between hover:bg-[#B50829]"
                    to={item.path}
                  >
                    <span className="flex items-center justify-start text-white p-3 lg:py-3 lg:px-5 xl:px-9 xxl:px-9 block hover:bg-[#B50829] hover:text-white">
                      <span className={"whitespace-nowrap"}>{item.menu_name}</span>
                    </span>
                    {item.menu_name === 'Danh Sách Tuyển Dụng' ? (
                      <span
                        className={`mr-3`}
                        style={{ color: '#FAFF00' }}
                      >
                        {count?.total}
                      </span>
                    ) : null}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <div className="general pt-10">
          <p className="text-sm  uppercase text-white px-5">
            QUẢN LÝ KHÓA HỌC
          </p>
          <ul className="pt-3 list-none	">
            {menus &&
              menus?.root?.course.map((item, index) => (
                <li
                  key={index}
                  className={cx([
                    ' text-base overflow-hidden',
                    { 'bg-[#B50829]': location.pathname === item.path },
                  ])}
                >
                  <Link onClick={() => setMenu('')}
                    to={item.path}
                    className="flex items-center justify-between hover:bg-[#B50829]"
                  >
                   <span className="flex items-center justify-start text-white p-3 lg:py-3 lg:px-5 xl:px-9 xxl:px-9 block hover:bg-[#B50829] hover:text-white">
                      {item.menu_name}
                    </span>
                    {item.menu_name === 'Danh sách khóa học' ? (
                      <span
                          className={`mr-3`}
                        style={{ color: '#FAFF00' }}
                      >
                        {countCourse?.total}
                      </span>
                    ) : null}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <div className="general pt-10">
          <p className="text-sm uppercase text-white px-5">
            Hệ thống
          </p>
          <ul className="pt-3 list-none	">
            {/* <li
              className={cx([
                ' text-base hover:bg-[#B50829] hover:text-white',
                // { 'bg-[#B50829]': location.pathname == '/admin/dashboard' },
              ])}
            >
              <a href="#" className="text-white py-3 px-9 block">
                Hướng dẫn sử dụng
              </a>
            </li>
            <li
              className={cx([
                ' text-base hover:bg-[#B50829] hover:text-white',
                // { 'bg-[#B50829]': location.pathname == '/admin/dashboard' },
              ])}
            >
              <a href="#" className="text-white py-3 px-9 block">
                Giới thiệu
              </a>
            </li> */}
            <li
              className={cx([
                ' text-base hover:bg-[#B50829] hover:text-white',
                // { 'bg-[#B50829]': location.pathname == '/admin/dashboard' },
              ])}
            >
              <a
                href={'#logout'}
                className="text-white  overflow-hidden whitespace-nowrap p-3 lg:py-3 lg:px-9 block"
                onClick={logOut}
              >
                Đăng xuất
              </a>
            </li>
          </ul>
        </div>
      </div>
      <svg onClick={() => {menu === '' ? setMenu('animated') : setMenu('') }} xmlns="http://www.w3.org/2000/svg" className="lg:hidden w-[50px] h-[50px] top-[10px] right-[10px] text-white absolute" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
      </svg>
    </React.Fragment>
  );
};
