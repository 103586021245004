import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CoursesRepository } from 'services/repositories/course.repository';

//Repository
const coursesRepository = RepositoryFactory.get('course') as CoursesRepository;

const fetchCoursesById = async () => {
  const response = await coursesRepository.getActiveCourse();

  return response;
};

function* getActiveCourse(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const courses = yield call(fetchCoursesById);
    yield put({ type: types.GET_ACTIVE_COURSE_SUCCESS, payload: courses });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.GET_ACTIVE_COURSE_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetCoursesActive() {
  yield takeLatest(types.GET_ACTIVE_COURSE_REQUEST, getActiveCourse);
}
