import * as types from './../../type';
import { RepositoryFactory } from 'services/repositories';
import { JobsRepository } from './../../../services/repositories/jobs.repository';
import { call, put, takeLatest } from 'redux-saga/effects';

const jobsRepository = RepositoryFactory.get('jobs') as JobsRepository;

const restoreJobs = async (id) => {
  const response = await jobsRepository.restoreJob(id);
  return response;
};

function* handlerRestoreJob(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const jobs = yield call(restoreJobs, action.payload);
    yield put({ type: types.RESTORE_JOB_SUCCESS, action: jobs });
    // const refetchJobs =  yield call(fetchJobs, action.payload);
    // yield put({ type: types.GET_JOBS_SUCCESS, action: refetchJobs });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.RESTORE_JOB_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}
export default function* watchRestoreJob() {
  yield takeLatest(types.RESTORE_JOB_REQUEST, handlerRestoreJob);
}
