import React, { useEffect, useState } from 'react';
import { LF_Breadcumbs } from 'components/molecules';
import {
  LF_BasicInfo,
  LF_Done,
  LF_Review,
} from 'components/organisms/Modules/PostCourse';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCourse,
  getCareerCode,
  getCoursesById,
  getDistrictFromProvince, getIPoint, getOldPackages, getPackages, getPackageTime,
  getProvince, getVipPackage,
  searchCareer,
  searchSkill,
  updateCoursesById,
} from 'redux/actions';
import { RootState } from 'redux/store';
import { getUserDetailsWithToken } from 'views/wrapper/Auth.wrapper';
import { useParams } from 'react-router-dom';

import { validURL } from 'utils/commons';

export const LF_EditCourse = () => {
  const { id } = useParams();
  // Get token -> get corporate_code
  const corporate_code = getUserDetailsWithToken()?.corporate_code;

  // Dispatch
  const dispatch = useDispatch();

  const {
    searchcareers,
    provinces,
    positions,
    districts,
    courses,
    careerCode,
    packages,
    packageTime,
    ipoint
  } = useSelector((state: RootState) => state);

  const { status, course, oldPackages } = courses;
  useEffect(() => {
    setListDistrict(districts.districts);
  }, [districts]);

  useEffect(() => {
    //ipoint
    dispatch(getPackages());
    dispatch(getPackageTime());
    dispatch(getIPoint(corporate_code));
    dispatch(getVipPackage());

    dispatch(getProvince());
    dispatch(searchCareer(''));
    dispatch(searchSkill(''));
    dispatch(getCoursesById(id && id));
  }, []);

  useEffect(() => {
    if (typeof status === 'object' && status?.status === 400)
      setIsPosted(false);
    else if (status === null) setIsPosted(false);
    else if (!isNaN(status)) setIsPosted(true);
  }, [status]);

  //state
  const [queryCareers, setQueryCareers] = useState(null);
  const [openBegin, setOpenBegin] = useState(true);
  const [open, setOpen] = useState({
    basicInfo: false,
    review: false,
  });
  const [listDistrict, setListDistrict] = useState([]);
  const [listChoose, setListChoose] = useState([]);
  const [isPosted, setIsPosted] = useState(false);
  const [listStep, setListStep] = useState([
    { name: 'Thông tin cơ bản', isActive: true },
    { name: 'Xem lại', isActive: false },
    { name: 'Hoàn tất', isActive: false },
  ]);

  const [formValue, setFormValue] = React.useState({
    basicInfo: {
      p_corporate_code: corporate_code,
      p_course_name: '',
      p_course_desc: '',
      p_cv_content: '',
      p_address: '',
      p_province_code: '',
      p_district_code: '',
      p_online: null,
      p_price: null,
      p_careers: '',
      p_end_date: 0,
      p_openning_date: 0,
      p_package_id: null,
      p_schedule: '',

      p_timeTrain: 0,
      p_background: '',
      p_file: [],

      p_district_text: '',
      p_province_text: '',
      listChooseCareers: [],
    },
  });

  React.useEffect(() => {
    if (courses.course?.data) {
      dispatch(getCareerCode(courses?.course?.data.careers));
      dispatch(getDistrictFromProvince(courses?.course?.data.province_code));
      dispatch(getOldPackages(courses.course?.data?.course_id, "course"))
      setFormValue({
        ...formValue,
        basicInfo: {
          p_corporate_code: corporate_code,
          p_course_name: courses.course?.data.course_name,
          p_course_desc: courses.course?.data.course_description,
          p_cv_content: courses.course?.data.cv_content,
          p_address: courses.course?.data.address,
          p_province_code: courses.course?.data.province_code,
          p_district_code: courses.course?.data.district_code,
          p_online: courses.course?.data.online,
          p_price: courses.course?.data.price,
          p_careers: courses.course?.data.careers,
          p_end_date: new Date(
            `${new Date(courses.course?.data.end_date).getFullYear()}-${(
              '0' +
              (new Date(courses.course?.data.end_date).getMonth() + 1)
            ).slice(-2)}-${(
              '0' + new Date(courses.course?.data.end_date).getDate()
            ).slice(-2)}`,
          ).getTime(),
          p_openning_date: new Date(
            `${new Date(courses.course?.data.opening_date).getFullYear()}-${(
              '0' +
              (new Date(courses.course?.data.opening_date).getMonth() + 1)
            ).slice(-2)}-${(
              '0' + new Date(courses.course?.data.opening_date).getDate()
            ).slice(-2)}`,
          ).getTime(),
          p_package_id: null,
          p_schedule: courses.course?.data.schedule,

          p_timeTrain: courses.course?.data.duration,
          p_background: courses.course?.data.image_url,
          p_file: courses.course?.attachment?.doc_url.split(',') || [],

          p_district_text: '',
          p_province_text: '',
          listChooseCareers: [],
        },
      });
    }
  }, [courses.course]);

  React.useEffect(() => {
    const arr = careerCode?.careerCodes;
    setListChoose([...arr]);
  }, [careerCode?.careerCodes]);

  React.useEffect(() => {
    if (districts) {
      districts?.districts.find((e) => {
        if (e.district_code === formValue?.basicInfo?.p_district_code) {
          onChangeInput('p_district_text', e.name, 'basicInfo');
        }
      });
      provinces?.provinces.find((e) => {
        if (e.province_code === formValue?.basicInfo?.p_province_code) {
          onChangeInput('p_province_text', e.province_name, 'basicInfo');
        }
      });
    }
  }, [districts]);

  const [valid, setValid] = React.useState({
    p_course_id: '',
    p_course_name: '',
    p_course_desc: '',
    p_cv_content: '',
    p_address: '',
    p_province_code: '',
    p_district_code: '',
    p_online: '',
    p_price: '',
    p_careers: '',
    p_end_date: '',
    p_openning_date: '',
    p_package_id: '',
    p_schedule: '',
    p_district_text: '',
    p_province_text: '',
    listChooseCareers: '',
    p_timeTrain: '',
  });

  const handleClickStepMenu = (index) => {
    if (index === 0) {
      changeCssMenu(index);
      setOpen({
        basicInfo: false,
        review: false,
      });
      setOpenBegin(true);
    }
    if (!isPosted && index === 3) return;
    if (isPosted && index === 3) {
      changeCssMenu(index);
      setOpen({
        basicInfo: false,
        review: true,
      });
      setOpenBegin(false);
    }
    if (index !== 0 && index !== 3) {
      const isPass = index === 1 ? messageValidator(formValue.basicInfo) : '';
      if (isPass) {
        changeCssMenu(index);
        setOpen({
          basicInfo: index === 1 ? true : false,
          review: false,
        });
        setOpenBegin(false);
      }
    }
  };

  const messageValidator = (object) => {
    let flag = true;
    let validation = { ...valid };

    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const element = object[key];

        // Dùng else để khi next step sẽ clear msg invalid
        if (key === 'p_course_name') {
          if (element === '') {
            validation.p_course_name = 'Tên khóa học không được để trống!';
            flag = false;
          } else if (element !== '' && element.length < 6) {
            validation.p_course_name =
              'Tên khóa học phải chứa tối thiểu 6 ký tự!';
            flag = false;
          } else {
            validation.p_course_name = '';
          }
        }

        if (key === 'p_course_desc') {
          if (element === '') {
            validation.p_course_desc = 'Nội dung khóa học không được để trống!';
            flag = false;
          } else if (!validURL(element)) {
            validation.p_course_desc = 'Nội dung không được chứa liên kết';
            flag = false;
          } else {
            validation.p_course_desc = '';
          }
        }

        if (key === 'p_cv_content') {
          if (element === '') {
            validation.p_cv_content = 'Hồ sơ yêu cầu không được để trống!';
            flag = false;
          } else if (!validURL(element)) {
            validation.p_cv_content = 'Nội dung không được chứa liên kết';
            flag = false;
          } else {
            validation.p_cv_content = '';
          }
        }

        if (key === 'p_end_date') {
          if (element === null) {
            validation.p_end_date = 'Vui lòng chọn hạn đăng ký khóa học';
            flag = false;
          } else if (element !== null) {
            const checkDate =
              new Date(formValue.basicInfo.p_end_date).getTime() >
              new Date().getTime();
            // if (!checkDate) {
            //   validation.p_end_date =
            //     'Hạn đăng ký khóa học phải lớn hơn ngày hiện tại!';
            //   flag = false;
            // }
            if (checkDate) {
              validation.p_end_date = '';
            }
          }
        }

        if (key === 'p_openning_date') {
          if (element === null) {
            validation.p_openning_date = 'Vui lòng chọn hạn ngày khai giảng';
            flag = false;
          } else if (element !== null) {
            const checkDate =
              new Date(formValue.basicInfo.p_openning_date).getTime() >
              new Date().getTime();
            // if (!checkDate) {
            //   validation.p_openning_date =
            //     'Ngày khai giảng khóa học phải lớn hơn ngày hiện tại!';
            //   flag = false;
            // }
            if (checkDate) {
              validation.p_openning_date = '';
            }
          }
        }

        if (key === 'p_price') {
          if (element === null || element === '') {
            validation.p_price = 'Vui lòng nhập mức học phí!';
            flag = false;
          } else if (parseInt(element) < 0) {
            validation.p_price = 'Vui lòng nhập mức học phí không nhỏ hơn 0';
            flag = false;
          } else if (element.length > 10) {
            validation.p_price =
              'Vui lòng nhập mức học phí không lớn hơn 10 số';
            flag = false;
          } else if (!/([0-9])\b/.test(element)) {
            validation.p_price = 'Vui lòng nhập mức học phí là số';
            flag = false;
          } else {
            validation.p_price = '';
          }
        }

        if (key === 'p_timeTrain') {
          if (element === null || element === '') {
            validation.p_timeTrain = 'Vui lòng nhập thời gian khóa học!';
            flag = false;
          } else if (element < 0) {
            validation.p_timeTrain =
              'Vui lòng nhập thời gian khóa học lớn hơn 0';
            flag = false;
          } else if (!/([0-9])\b/.test(element)) {
            validation.p_timeTrain =
              'Vui lòng nhập mức thời gian khóa học là số';
            flag = false;
          } else {
            validation.p_timeTrain = '';
          }
        }

        if (object['p_online'] !== 1) {
          if (key === 'p_address') {
            if (element === '') {
              validation.p_address = 'Địa chỉ học không được để trống!';
              flag = false;
            } else if (element !== '' && element.length < 6) {
              validation.p_address = 'Địa chỉ học phải chứa tối thiểu 6 ký tự!';
              flag = false;
            } else {
              validation.p_address = '';
            }
          }
          if (key === 'p_province_text') {
            if (element === '') {
              validation.p_province_text = 'Vui lòng chọn tỉnh thành!';
              flag = false;
            } else {
              validation.p_province_text = '';
            }
          }

          if (key === 'p_district_text') {
            if (element === '') {
              validation.p_district_text = 'Vui lòng chọn quận/huyện!';
              flag = false;
            } else {
              validation.p_district_text = '';
            }
          }
        }

        if (key === 'listChooseCareers') {
          if (element.length === 0) {
            validation.listChooseCareers =
              'Vui lòng chọn ít nhất 1 ngành đạo tạo';
            flag = false;
          }
          if (element.length > 0) {
            validation.listChooseCareers = '';
          }
        }

        if (key === 'p_online') {
          if (element === '0,0') {
            validation.p_online = 'Vui lòng chọn ít nhất 1 hình thức học!';
            flag = false;
          }
          if (element !== '0,0') {
            validation.p_online = '';
          }
        }
      }
    }

    if (flag) {
      setValid({
        p_course_id: '',
        p_course_name: '',
        p_course_desc: '',
        p_cv_content: '',
        p_address: '',
        p_province_code: '',
        p_district_code: '',
        p_online: '',
        p_price: '',
        p_careers: '',
        p_end_date: '',
        p_openning_date: '',
        p_package_id: '',
        p_schedule: '',
        listChooseCareers: '',
        p_district_text: '',
        p_province_text: '',
        p_timeTrain: '',
      });
      return true;
    } else {
      setValid(validation);
      return false;
    }
  };

  // Handle click step menu
  const changeCssMenu = (index) => {
    const newList = [...listStep];
    setListStep((prevState) => {
      for (let i = 0; i < newList.length; i++) {
        if (index === i) {
          Object.assign(newList[i], { isActive: true });
          continue;
        }
        Object.assign(newList[i], { isActive: false });
      }
      return [...prevState];
    });
  };

  // Handle CkEditor5
  const handleCKEditor = (name, data) => {
    setFormValue((prevState) => {
      Object.assign(prevState.basicInfo, { [name]: data });
      return { ...prevState };
    });
  };

  // Handle on change input
  const onChangeInput = (name, value, keyInState) => {
    setFormValue((prevState) => {
      Object.assign(prevState[keyInState], {
        [name]: value,
      });
      return { ...prevState };
    });
  };

  const changeTimestamp = (day) => {
    var myDate = day;
    myDate = myDate.split('-');
    var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);
    return newDate.getTime();
  };

  const handleClickNextStep = (key: string) => {
    const isPass = messageValidator(formValue[key]);

    if (isPass && key === 'basicInfo') {
      setOpen((prevState) => ({ ...prevState, [key]: true }));
      setOpenBegin(false);

      // Set menu step
      const newList = [...listStep];
      setListStep((prevState) => {
        Object.assign(newList[0], { isActive: false });
        Object.assign(newList[1], { isActive: true });
        return [...prevState];
      });
    }
    if (isPass && key === 'review') {
      // Form value submit
      const { basicInfo } = { ...formValue };
      const careers = basicInfo.listChooseCareers
        ?.map((careers) => careers.career_code)
        .join();

      const submitValues = {
        p_course_id: id && id,
        p_course_name: basicInfo.p_course_name,
        p_course_desc: basicInfo.p_course_desc,
        p_cv_content: basicInfo.p_cv_content,
        p_address: basicInfo.p_address,
        p_province_code: basicInfo.p_province_code,
        p_district_code: basicInfo.p_district_code,
        p_online: basicInfo.p_online,
        p_price: basicInfo.p_price,
        p_careers: careers,
        p_end_date: new Date(basicInfo.p_end_date).getTime(),
        p_openning_date: new Date(basicInfo.p_openning_date).getTime(),
        p_duration: Number(basicInfo.p_timeTrain),
        p_schedule: basicInfo.p_schedule,
        p_image_url: basicInfo.p_background,
      };
      const submitFile = {
        p_course_document_id:
          courses.course?.attachment?.course_document_id || 0,
        p_doc_name: basicInfo?.p_file
          .map((e) =>
            e.replace(/^static[/]d[/]corporate[/]docs[/][0-9]{8}[/]/g, ''),
          )
          .join(),
        p_doc_url: basicInfo?.p_file.join(),
      };

      dispatch(updateCoursesById(id, { submitValues, submitFile }));

      setOpen((prevState) => ({
        ...prevState,
        [key]: true,
        basicInfo: false,
      }));
      setOpenBegin(false);
      // setIsPosted(true);

      // Set menu step
      const newList = [...listStep];
      setListStep((prevState) => {
        Object.assign(newList[1], { isActive: false });
        Object.assign(newList[2], { isActive: true });
        return [...prevState];
      });
    }
  };
  // Handle click back
  const handleClickBack = (key: string) => {
    if (key === 'review') {
      setOpen((prevState) => ({
        ...prevState,
        basicInfo: false,
      }));
      setOpenBegin(true);

      // Set menu step
      const newList = [...listStep];
      setListStep((prevState) => {
        Object.assign(newList[0], { isActive: true });
        Object.assign(newList[1], { isActive: false });
        return [...prevState];
      });
    }
  };

  // handle Careens
  const handleAddChooseCareers = (data) => {
    const mapList = [...listChoose];
    const checkDuplicate = mapList?.filter(
      (item) => item.career_code === data.career_code,
    );

    if (checkDuplicate.length === 0)
      setListChoose((prevState) => [...prevState, data]);
  };

  const handleRemoveChooseCareers = (code: string) => {
    const mapList = [...listChoose];
    const newList = mapList?.filter((item) => item.career_code !== code);
    setListChoose(newList);
  };

  // Handle search field
  const handleSearchCareers = (value) => {
    if (value && value !== '') dispatch(searchCareer(value));
    if (value === '') dispatch(searchCareer(''));
  };

  useEffect(() => {
    setFormValue((prevState) => {
      Object.assign(prevState.basicInfo, {
        listChooseCareers: listChoose,
      });
      return { ...prevState };
    });
  }, [listChoose]);

  // Handle on change attach code
  const handleOnChangeAttachCode = (name, value, text, keyInState) => {
    if (name === 'p_province' && value.includes('pro')) {
      dispatch(getDistrictFromProvince(value));
    }

    setFormValue((prevState) => {
      Object.assign(prevState[keyInState], {
        [`${name}_code`]: value,
        [`${name}_text`]: text,
      });
      return { ...prevState };
    });
  };
  return (
    <div>
      <LF_Breadcumbs
        route={`/admin/job/post`}
        routeName={'Đăng tin tuyển sinh'}
        className="font-bold text-lg"
      />

      <section className="py-5">
        <div className="flex justify-center items-center relative">
          <hr className="absolute border-t-2 border-gray-400 border-solid w-3/5"></hr>
          <div className="flex justify-between w-3/5">
            {listStep &&
              listStep.length > 0 &&
              listStep?.map((step, index) => (
                <div
                  key={index}
                  className="px-8 bg-white z-10 relative cursor-pointer"
                  onClick={() => handleClickStepMenu(index)}
                >
                  <p
                    className={`w-8 h-8 leading-8 rounded-full text-white flex justify-center items-center ${step.isActive ? 'bg-[#B50829]' : 'bg-gray-400'
                      }`}
                  >
                    <span>{index + 1}</span>
                  </p>
                  <p
                    className={`absolute w-max left-1/2 transform -translate-x-1/2 pt-1 ${step.isActive ? 'font-bold' : ''
                      }`}
                  >
                    {step.name}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </section>
      {/* Body */}
      <section className="p-4 lg:pt-10 lg:px-14">
        {openBegin && (
          <LF_BasicInfo
            data={formValue && formValue}
            onChangeCKE={handleCKEditor}
            onChangeInput={onChangeInput}
            onClickNextStep={handleClickNextStep}
            msgCheckValid={valid}
            onChangeAttachCode={handleOnChangeAttachCode}
            //---------------//
            listShowCareers={searchcareers && searchcareers.searchCareers}
            listChooseCareers={listChoose && listChoose}
            onClickAddChooseCareers={handleAddChooseCareers}
            onClickRemoveChooseCareers={handleRemoveChooseCareers}
            queryCareers={queryCareers}
            setQueryCareers={setQueryCareers}
            onChangeSearchCareers={handleSearchCareers}
            //--------------//
            listProvinces={provinces && provinces.provinces}
            listDistricts={listDistrict}
            listPositions={positions && positions.positions}
          />
        )}
        {/* Nó = true thì component kế sẽ show */}
        {open.basicInfo === true && (
          <LF_Review
            data={formValue}
            onClickNextStep={handleClickNextStep}
            onClickBack={handleClickBack}
          />
        )}

        {open.review === true && <LF_Done
          statusCourse="edit"
          ipoint={ipoint && ipoint.ipoint}
          listPackage={packages && packages.packages}
          listPackageTime={packageTime && packageTime.packageTime}
          status={status && status}
          course={course && course?.data}
          oldPackages={oldPackages && oldPackages}
        />}
      </section>
    </div>
  );
};
