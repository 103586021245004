import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { CoursesRepository } from 'services/repositories/course.repository';

//Initial State
const courseRepository = RepositoryFactory.get('course') as CoursesRepository;

const fetchEmployee = async (query?) => {
  const response = await courseRepository.getQueryCourse(query);
  return response;
};

function* getCourses(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const courses = yield call(fetchEmployee, action.payload);
    yield put({ type: types.GET_COURSES_SUCCESS, payload: courses });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.GET_COURSES_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetCourses() {
  yield takeLatest(types.GET_COURSES_REQUEST, getCourses);
}
