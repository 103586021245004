import * as types from '../type';
interface IInitialState {
  ipoint: {
    ipoint: 0,
    wallet_id: null,
  },
  ipointHistory: {
    data: [],
    total: 0,
  },
  ipointOrder: {
    data: [],
    total: 0,
  }
}

const initialState: IInitialState = {
  ipoint: {
    ipoint: 0,
    wallet_id: null,
  },
  ipointHistory: {
    data: [],
    total: 0,
  },
  ipointOrder: {
    data: [],
    total: 0,
  }
}

const packageReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.GET_IPOINT_TOTAL_SUCCESS:
      return {...state, ipoint: action.payload};
    case types.GET_IPOINT_TOTAL_ERROR:
      return {...state, ipoint: action.payload};
    case types.GET_IPOINT_TOTAL_REQUEST:
      return {...state, ipoint: state.ipoint};
    case types.GET_IPOINT_HISTORY_SUCCESS:
      return {...state, ipointHistory: action.payload};
    case types.GET_IPOINT_HISTORY_ERROR:
      return {...state, ipointHistory: state.ipointHistory};
    case types.GET_IPOINT_HISTORY_REQUEST:
      return {...state, ipointHistory: state.ipointHistory};
    case types.GET_IPOINT_ORDER_SUCCESS:
      return {...state, ipointOrder: action.payload};
    case types.GET_IPOINT_ORDER_ERROR:
      return {...state, ipointOrder: state.ipointOrder};
    case types.GET_IPOINT_ORDER_REQUEST:
      return {...state, ipointOrder: state.ipointOrder};
    default:
      return {
        ...state,
      }
  }
}

export default packageReducer;