import { List } from '@material-ui/core';
import React from 'react';

export const LF_LiList = ({ props }) => {
  const yearSkill = (year) => {
    switch (year) {
      case 'yex00':
        return 'Dưới 1 năm';
      case 'yex01':
        return '1 năm';
      case 'yex02':
        return '2 năm';
      case 'yex03':
        return '3 năm';
      case 'yex04':
        return '4 năm';
      case 'yex05':
        return '5 năm';
      case 'yex06':
        return 'Trên 5 năm';
      default:
        return 'Trên 10 năm';
    }
  };

  return (
    <React.Fragment>
      <List>
        {props?.map((e, index) => {
          return (
            <div className="mb-2 list-disc" key={index}>
              {e?.skill_name === '' ? (
                ''
              ) : (
                <li>
                  {yearSkill(e.year_exp)}: {e.skill_name}
                </li>
              )}
            </div>
          );
        })}
      </List>
    </React.Fragment>
  );
};
