import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { JobsRepository } from 'services/repositories/jobs.repository';

//Repository
const jobsRepository = RepositoryFactory.get('jobs') as JobsRepository;

const callApiCreateJob = async (data) => {
  const response = await jobsRepository.create(data);
  const id = response[Object.keys(response)[0]];
  return id;
};

function* handlerCreateJob(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const jobId = yield call(callApiCreateJob, action.payload);
    yield put({ type: types.CREATE_JOB_SUCCESS, payload: jobId });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.CREATE_JOB_ERROR, payload: err.response });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchCreateJob() {
  yield takeLatest(types.CREATE_JOB_REQUEST, handlerCreateJob);
}
