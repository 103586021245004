import React from 'react';

export const Loading = ({isLoading = false}) => {
  return (
    <React.Fragment>
      <div className={`flex items-center justify-center`} style={{ backgroundColor: "#00000070",
        position:"fixed",
        width:'100%',
        height:'100%',
        top:0,
        left:0,
        backgroundPosition:'center',
        zIndex: 10000,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '70px',
        display: isLoading ? 'block' : 'none'
      }}>
      </div>
      <svg
      className="spinner absolute z-50"
      width="65px"
      height="65px"
      viewBox="0 0 66 66"
      style={{display: isLoading ? 'block' : 'none'}}
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
    </React.Fragment>
  );
};
