import React from 'react';
import cx from 'classnames';
import ihrLogo from 'assets/images/ihr_logo.png';
import LogoiHR from './../../../assets/images/Logo_rounded_512.png';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

//Components
import {
  CustomMultipleSelect,
  MultipleSelect,
} from '../../../components/molecules';

//Types
import { RootState } from '../../../redux/store';

//React Router
import { useLocation } from 'react-router-dom';

//Actions
import {
  getProvince,
  getDistrictFromProvince,
  getCommune,
  register,
  searchCareerGroup,
} from '../../../redux/actions';

//Commons
import { guidGenerator, stringToSlug } from '../../../utils/commons';

//Function Query
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Register() {
  //React Router
  let query = useQuery();

  //React - Redux
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.register);

  //Provinces
  const provinces = useSelector((state: RootState) => state.provinces);

  //District
  const districts = useSelector((state: RootState) => state.districts);

  //Communes
  const communes = useSelector((state: RootState) => state.communes);

  //Career Groups
  const careers = useSelector((state: RootState) => state.careers);

  //Register Store
  const { status } = useSelector((state: RootState) => state.register);

  //History
  const history = useHistory();

  //State
  const [form, setForm] = React.useState({
    username: '',
    password: '',
    passwordConfirmation: '',
    employee_name: '',
    phone: '',
    email: '',
    corporate_name: '',
    corporate_address: '',
    province_code: '',
    district_code: '',
    commune_code: '',
    career_groups: '',
    legal: 0,
    so_dkkd: '',
    assigned_date: 0,
    assigned_location: '',
    p_slug: '',
    p_ref_number: query.get('ref') || '',
  });

  const [show, setShow] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [code, setCode]: [string, Function] = React.useState('');
  const [check, setCheck] = React.useState(`${guidGenerator()}`);
  const [resultCheckCode, setRSCheck] = React.useState('');
  const [careerQ, setCareer] = React.useState(null);

  //List Career Choosen
  const [listCareerChoose, setCareerChoose] = React.useState([]);

  //State - for province form
  const [provinceId, setProvinceId] = React.useState(null);
  const [districtId, setDistrictId] = React.useState(null);

  //State Validate
  const [validate, setValid] = React.useState({
    username: '',
    password: '',
    passwordConfirmation: '',
    employee_name: '',
    phone: '',
    email: '',
    corporate_name: '',
    corporate_address: '',
    province_code: '',
    district_code: '',
    commune_code: '',
    career_groups: '',
    legal: '',
    so_dkkd: '',
    assigned_date: '',
    assigned_location: '',
    p_ref_number: '',
  });

  //Effect
  React.useEffect(() => {
    dispatch(getProvince());
  }, []);

  React.useEffect(() => {
    provinceId && dispatch(getDistrictFromProvince(provinceId));
  }, [provinceId]);

  React.useEffect(() => {
    provinceId && districtId && dispatch(getCommune(provinceId, districtId));
  }, [districtId]);

  // React.useEffect(() => {
  //   if (careerQ && careerQ !== '') {
  //     dispatch(searchCareerGroup(careerQ));
  //   }
  // }, [careerQ]);
  React.useEffect(() => {
    const regex = data && data.status && data.status.message;
    duplicate(regex);
  }, [status]);

  //Action Handler
  const onChange = (e) => {
    if (e.target.name === 'legal') {
      setForm({ ...form, legal: parseInt(e.target.value) });
    } else if (e.target.name === 'corporate_name') {
      const slug = `${stringToSlug(e.target.value)}-${Math.round(
        Date.now() / 1000,
      )}`;
      setForm({ ...form, p_slug: slug, corporate_name: e.target.value });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const duplicate = (regex) => {
    if (/Duplicate/.test(regex)) {
      if (/UNIQUE_corporate_phone/.test(regex)) {
        setValid({
          ...validate,
          phone: 'Số điện thoại bị trùng',
        });
      }
      if (/UNIQUE_employee_email/.test(regex)) {
        setValid({
          ...validate,
          email: 'Email bị trùng',
        });
      }
      if (/UNIQUE_so_dkkd/.test(regex)) {
        setValid({
          ...validate,
          so_dkkd: 'Số ĐKKD / Mã số thuế cá nhân bị trùng',
        });
      }
      if (/UNIQUE_username_employee/.test(regex)) {
        setValid({
          ...validate,
          username: 'Tên tài khoản bị trùng',
        });
      }
    }
  };

  const onChangeCode = (e) => {
    setCode(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // if (code !== check) {
    //   setRSCheck('Mã chứng thực sai vui lòng nhập lại');
    //   setCheck(`${guidGenerator()}`);
    //   return;
    // } else {
    //   setRSCheck('');
    // }

    const result = onValidation(form);
    if (result) {
      if (code !== check) {
        setRSCheck('Mã chứng thực sai vui lòng nhập lại');
        setCheck(`${guidGenerator()}`);
        return;
      } else {
        setRSCheck('');
        const career_gr = listCareerChoose?.map((e) => e.career_group_code);
        dispatch(
          register({
            ...form,
            assigned_date: new Date(form.assigned_date).getTime(),
            career_groups: career_gr.join(','),
          }),
        );
      }
    } else {
      setCheck(`${guidGenerator()}`);
    }
  };
  const onValidation = (data) => {
    let validation = { ...validate };
    let check = true;
    if (data.username.length < 5) {
      validation.username = 'Tên tài khoản phải từ 5 ký tự trở lên';
      check = false;
    } else if (data.username.length > 25) {
      validation.username = 'Tên tài khoản không lớn hơn 25 ký tự';
      check = false;
    } else if (!/^[a-zA-Z0-9]+$/.test(data.username)) {
      validation.username = 'Tên tài khoản không chứa ký tự đặc biệt';
      check = false;
    } else {
      validation.username = '';
    }

    if (data.password.length < 6) {
      validation.password = 'Mật khẩu phải dài hơn 6 ký tự';
      check = false;
    } else {
      validation.password = '';
    }

    if (data.passwordConfirmation !== data.password) {
      validation.passwordConfirmation = 'Vui lòng nhập đúng mật khẩu đã đặt';
      check = false;
    } else {
      validation.passwordConfirmation = '';
    }

    if (data.employee_name.length === 0) {
      validation.employee_name = 'Vui lòng nhập họ tên người đại diện';
      check = false;
    } else {
      validation.employee_name = '';
    }

    if (data.p_ref_number.length > 20) {
      validation.p_ref_number = 'Mã giới thiệu không lớn hơn 20 ký tự';
      check = false;
    } else {
      validation.p_ref_number = '';
    }

    // if (!/(84|0[3|5|7|8|9])+([0-9]{9})\b/.test(data.phone)) {
    //   validation.phone = 'Số điện thoại không hợp lệ';
    //   check = false;
    // }

    if (!/([0-9]{9})\b/.test(data.phone)) {
      validation.phone = 'Số điện thoại không hợp lệ';
      check = false;
    }

    if (!/[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}/gim.test(data.email)) {
      validation.email = 'Email không hợp lệ';
      check = false;
    } else {
      validation.email = '';
    }

    if (data.corporate_name.length === 0) {
      validation.corporate_name =
        'Vui lòng nhập tên công ty/ tổ chức';
      check = false;
    } else {
      validation.corporate_name = '';
    }

    if (data.corporate_address.length === 0) {
      validation.corporate_address = 'Vui lòng nhập địa chỉ';
      check = false;
    } else {
      validation.corporate_address = '';
    }

    if (data.province_code.length === 0) {
      validation.province_code = 'Vui lòng chọn tỉnh / thành phố';
      check = false;
    } else {
      validation.province_code = '';
    }

    if (data.district_code.length === 0) {
      validation.district_code = 'Vui lòng chọn quận / huyện';
      check = false;
    } else {
      validation.district_code = '';
    }

    if (data.commune_code.length === 0) {
      validation.commune_code = 'Vui lòng chọn xã / phường';
      check = false;
    } else {
      validation.commune_code = '';
    }

    if (listCareerChoose.length === 0) {
      validation.career_groups = 'Vui lòng nhập lĩnh vực hoạt động';
      check = false;
    } else {
      validation.career_groups = '';
    }

    if (data.legal === 0) {
      if (data.so_dkkd.length === 0) {
        validation.so_dkkd = 'Vui lòng nhập mã số đăng ký kinh doanh';
        check = false;
      } else {
        validation.so_dkkd = '';
      }

      // if (data.assigned_date === 0) {
      //   validation.assigned_date = 'Vui lòng nhập ngày';
      //   check = false;
      // } else {
      //   validation.assigned_date = '';
      // }

      // if (data.assigned_date) {
      //   const checkDate =
      //     new Date(data.assigned_date).setHours(0, 0, 0, 0) <=
      //     new Date().setHours(0, 0, 0, 0);
      //   if (!checkDate) {
      //     validation.assigned_date = 'Ngày cấp không hợp lệ';
      //     check = false;
      //   } else {
      //     validation.assigned_date = '';
      //   }
      // }

      // if (data.assigned_location.length === 0) {
      //   validation.assigned_location = 'Vui lòng nhập nơi cấp';
      //   check = false;
      // } else {
      //   validation.assigned_location = '';
      // }
    } else {
      validation.so_dkkd = '';
      // validation.assigned_location = '';
      // validation.assigned_date = '';
    }

    //End
    if (check) {
      setValid({
        username: '',
        password: '',
        passwordConfirmation: '',
        employee_name: '',
        phone: '',
        email: '',
        corporate_name: '',
        corporate_address: '',
        province_code: '',
        district_code: '',
        commune_code: '',
        career_groups: '',
        legal: '',
        so_dkkd: '',
        assigned_date: '',
        assigned_location: '',
        p_ref_number: '',
      });
      return true;
    } else {
      setValid(validation);
      return false;
    }
  };

  /**
   * Choose adding Careers
   * @param data
   * @return NewArray & SetState
   */
  const onChooseCareer = (data) => {
    let listCurrent = [...listCareerChoose];
    const check = listCurrent?.filter(
      (e) => e.career_group_code === data.career_group_code,
    );

    if (check && check.length === 0) {
      setCareerChoose([...listCareerChoose, data]);
    }
  };

  /**
   * Delete Choose
   * @param career_group_code
   * @return NewArray & SetState
   */
  const onDeleteChoose = (career_group_code: string) => {
    let listCurrent = [...listCareerChoose];
    const check = listCurrent?.filter(
      (e) => e.career_group_code !== career_group_code,
    );
    setCareerChoose(check);
  };

  // Handle search field
  const handleSearch = (value) => {
    if (value && value !== '') dispatch(searchCareerGroup(value));
    if (value === '') dispatch(searchCareerGroup(''));
  };

  return (
    <React.Fragment>
      <div className="w-full min-h-screen">
        <div
          className="mx-auto flex flex-col items-center justify-center min-h-screen h-auto py-24"
          style={{ background: '#E5E5E5' }}
        >
          <div className="relative flex flex-col items-center pb-4">
            <img src={LogoiHR} alt="..." className="h-28 w-28" />
          </div>
          <div
            className="relative flex flex-col items-center border border-gray-500 border-solid bg-white rounded shadow lg:min-w-[700px] lg:min-h-[1000px] w-11/12"
          >
            <div className="py-4 lg:py-16">
              <p className="capitalize text-center font-medium text-xl lg:text-4xl">
                Đăng ký tài khoản nhà tuyển dụng
              </p>
            </div>
            <form
              action=""
              className="w-full lg:p-12 flex-col lg:p-[0 50px]"
              onSubmit={onSubmit}
            >
              {/* Username & Password & Re-Password  */}
              <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-1/3 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Tên tài khoản <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <input
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    name="username"
                    type="text"
                    value={form.username}
                    onChange={onChange}
                    placeholder={'Chỉ bao gồm chữ không dấu và số'}
                  />
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.username !== '' ? validate.username : ''}
                  </span>
                </div>
                <div className="w-full lg:w-1/3 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Mật khẩu <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <div className="w-full relative overflow-hidden">
                    <input
                      className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type={show ? 'text' : 'password'}
                      name="password"
                      id="password"
                      onChange={onChange}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setShow(!show);
                      }}
                      className="absolute flex flex-col pb-1 right-2 top-3 focus:outline-none"
                    >
                      <i className="transition-none text-gray-400 hover:text-[#B50829]">
                        {show ? (
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth={0}
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                        ) : (
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth={0}
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                            />
                          </svg>
                        )}
                      </i>
                    </button>
                  </div>
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.password !== '' ? validate.password : ''}
                  </span>
                </div>
                <div className="w-full lg:w-1/3 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password_confirm"
                  >
                    Nhập lại mật khẩu{' '}
                    <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <div className="w-full relative overflow-hidden">
                    <input
                      className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type={showConfirm ? 'text' : 'password'}
                      name="passwordConfirmation"
                      id="password_confirm"
                      onChange={onChange}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setShowConfirm(!showConfirm);
                      }}
                      className="absolute flex flex-col pb-1 right-2 top-3 focus:outline-none"
                    >
                      <i className="transition-none text-gray-400 hover:text-[#B50829]">
                        {showConfirm ? (
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth={0}
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                        ) : (
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth={0}
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                            />
                          </svg>
                        )}
                      </i>
                    </button>
                  </div>
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.passwordConfirmation !== ''
                      ? validate.passwordConfirmation
                      : ''}
                  </span>
                </div>
              </div>
              {/* Username & Password & Re-Password  */}
              {/* Phone & Email Block  */}
              <div className="flex flex-wrap">
                <div className="w-full lg:w-1/3 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="employee_name"
                  >
                    Họ và tên <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <input
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="employee_name"
                    name="employee_name"
                    type="text"
                    value={form.employee_name}
                    onChange={onChange}
                  />
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.employee_name !== ''
                      ? validate.employee_name
                      : ''}
                  </span>
                </div>
                <div className="w-full lg:w-1/3 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Email <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <input
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    name="email"
                    type="text"
                    value={form.email}
                    onChange={onChange}
                  />
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.email !== '' ? validate.email : ''}
                  </span>
                </div>
                <div className="w-full lg:w-1/3 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="phone"
                  >
                    Số điện thoại <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <input
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    name="phone"
                    type="text"
                    value={form.phone}
                    onChange={onChange}
                  />
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.phone !== '' ? validate.phone : ''}
                  </span>
                </div>
              </div>
              {/* Phone & Email Block  */}
              {/* Mã giới thiệu */}
              <div className="flex flex-wrap items-center">
                <div className="w-full lg:w-1/3 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="p_ref_number"
                  >
                    Mã giới thiệu
                  </label>
                  <input
                    disabled={query.get('ref') ? true : false}
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="p_ref_number"
                    name="p_ref_number"
                    type="text"
                    value={form.p_ref_number}
                    onChange={onChange}
                  />
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.p_ref_number !== '' ? validate.p_ref_number : ''}
                  </span>
                </div>
                <div className="w-full lg:w-2/3 p-4">
                  <span className="text-base" style={{ color: '#B50829' }}>
                    Nếu bạn có mã giới thiệu, vui lòng nhập vào đây
                  </span>
                </div>
              </div>
              {/* Mã giới thiệu */}
              {/* Company Name Block */}
              <div className="w-full">
                <div className="p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="corporate_name"
                  >
                    Tên công ty / tổ chức{' '}
                    <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <input
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="corporate_name"
                    name="corporate_name"
                    type="text"
                    value={form.corporate_name}
                    onChange={onChange}
                  />
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.corporate_name !== ''
                      ? validate.corporate_name
                      : ''}
                  </span>
                </div>
              </div>
              {/* Company Name Block */}
              {/* City & District & Wards Block  */}
              <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-1/3 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="province_code"
                  >
                    Tỉnh thành <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <select
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="province_code"
                    name="province_code"
                    value={form.province_code}
                    onChange={(e) => {
                      onChange(e);
                      setProvinceId(e.target.value);
                    }}
                  >
                    <option value={null}>Chọn tỉnh / thành phố</option>
                    {provinces &&
                      provinces?.provinces?.map((province) => (
                        <option
                          key={province.province_code}
                          value={province.province_code}
                        >
                          {province.province_name}
                        </option>
                      ))}
                  </select>
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.province_code !== ''
                      ? validate.province_code
                      : ''}
                  </span>
                </div>
                <div className="w-full lg:w-1/3 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="district_code"
                  >
                    Quận Huyện <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <select
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="district_code"
                    name="district_code"
                    value={form.district_code}
                    onChange={(e) => {
                      onChange(e);
                      setDistrictId(e.target.value);
                    }}
                  >
                    <option value={null}>Chọn quận / huyện</option>
                    {districts &&
                      districts?.districts?.map((district) => (
                        <option
                          key={district.district_code}
                          value={district.district_code}
                        >
                          {district.name}
                        </option>
                      ))}
                  </select>
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.district_code !== ''
                      ? validate.district_code
                      : ''}
                  </span>
                </div>
                <div className="w-full lg:w-1/3 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="commune_code"
                  >
                    Phường Xã <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <select
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="commune_code"
                    name="commune_code"
                    value={form.commune_code}
                    onChange={onChange}
                  >
                    <option value={null}>Chọn phường / xã</option>
                    {communes &&
                      communes?.communes?.map((commune) => (
                        <option
                          key={commune.commune_code}
                          value={commune.commune_code}
                        >
                          {commune.commune_name}
                        </option>
                      ))}
                  </select>
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.commune_code !== '' ? validate.commune_code : ''}
                  </span>
                </div>
              </div>
              {/* City & District & Commune  */}
              {/* Address Company Block  */}
              <div className="w-full">
                <div className="p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="corporate_address"
                  >
                    Địa chỉ công ty / tổ chức <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <input
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="corporate_address"
                    name="corporate_address"
                    type="text"
                    value={form.corporate_address}
                    onChange={onChange}
                  />
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.corporate_address !== ''
                      ? validate.corporate_address
                      : ''}
                  </span>
                </div>
              </div>
              {/* Address Company Block  */}
              {/* Career Group Block */}
              <div className="w-full">
                <div className="p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="Lĩnh vực hoạt động"
                  >
                    Lĩnh vực hoạt động{' '}
                    <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <CustomMultipleSelect
                    //careers={careers?.careers}
                    name={'career_group_'}
                    listShow={careers && careers?.careers}
                    listChoose={listCareerChoose}
                    query={careerQ}
                    onAddChoose={onChooseCareer}
                    onDeleteChoose={onDeleteChoose}
                    setQuery={setCareer}
                    onSubmit={handleSearch}
                  />
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.career_groups !== ''
                      ? validate.career_groups
                      : ''}
                  </span>
                </div>
              </div>
              {/* Career Group Block */}
              {/* Legal & Tax  */}
              <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-1/2 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="Legal"
                  >
                    Pháp nhân <span style={{ color: '#B50829' }}>*</span>
                  </label>
                  <div className="flex justify-between pl-1 pr-10 mt-3">
                    <div>
                      <label className="cursor-pointer">
                        <input
                          className="mr-3"
                          type="radio"
                          value={0}
                          name="legal"
                          onChange={onChange}
                        />{' '}
                        Công ty / Tổ chức
                      </label>
                    </div>
                    <div>
                      <label className="cursor-pointer">
                        <input
                          className="mr-3"
                          type="radio"
                          value={1}
                          name="legal"
                          onChange={onChange}
                        />{' '}
                        Cá nhân
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="so_dkkd"
                  >
                    Số ĐKKD / Mã số thuế cá nhân
                    {form.legal === 0 && (
                      <span style={{ color: '#B50829' }}>*</span>
                    )}
                  </label>
                  <input
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="so_dkkd"
                    name="so_dkkd"
                    type="text"
                    onChange={onChange}
                    value={form.so_dkkd}
                  />
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.so_dkkd !== '' ? validate.so_dkkd : ''}
                  </span>
                </div>
              </div>
              {/* Date & Place Personal Tax  */}
              {/* <div className="flex">
                <div className="w-1/2 p-4 relative">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="Ngày cấp"
                  >
                    Ngày cấp
                    {form.legal === 0 && (
                      <span style={{ color: '#B50829' }}>*</span>
                    )}
                  </label>
                  <input
                    type="date"
                    className=" appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="assigned_date"
                    name="assigned_date"
                    value={form.assigned_date}
                    onChange={onChange}
                  />
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.assigned_date !== ''
                      ? validate.assigned_date
                      : ''}
                  </span>
                </div>
                <div className="w-1/2 p-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="Nơi cấp"
                  >
                    Nơi cấp
                    {form.legal === 0 && (
                      <span style={{ color: '#B50829' }}>*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    className="appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="assigned_location"
                    name="assigned_location"
                    onChange={onChange}
                    value={form.assigned_location}
                  />
                  <span className="text-sm" style={{ color: '#B50829' }}>
                    {validate.assigned_location !== ''
                      ? validate.assigned_location
                      : ''}
                  </span>
                </div>
              </div> */}
              {/* Date & Place Personal Tax  */}
              {/* Code Authen  */}
              <div className="flex justify-center items-center">
                <div className="w-full lg:w-1/3 p-4 flex items-center ">
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="codeAuth"
                    >
                      Mã xác thực <span style={{ color: '#B50829' }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={cx([
                        'appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
                        resultCheckCode !== ''
                          ? 'border-[#B50829]'
                          : 'border-gray-400',
                      ])}
                      id="codeAuth"
                      name="codeAuth"
                      onChange={onChangeCode}
                      value={code}
                      placeholder="Mã xác thực"
                    />
                    <span className="text-sm" style={{ color: '#B50829' }}>
                      {resultCheckCode !== '' ? resultCheckCode : ''}
                    </span>
                  </div>
                  <div
                    className={cx([
                      'ml-5',
                      resultCheckCode !== '' ? '' : 'mt-5',
                    ])}
                  >
                    <span className="text-lg font-bold p-3 bg-gray-400">
                      {check}
                    </span>
                  </div>
                </div>
              </div>
              {/* Code Authen  */}
              <div className="text-center p-4">
                <button
                  type="submit"
                  className="uppercase whitespace-nowrap lg:text-[18px] text-white px-3 w-full lg:w-1/3 rounded py-3 focus:outline-none"
                  style={{ background: '#B50829' }}
                >
                  đăng ký tài khoản
                </button>
              </div>
              <div className="text-center px-4 pt-4 pb-10">
                <div
                  className="font-bold text-sm text-opacity-75 text-black  hover:text-[#B50829] cursor-pointer"
                  onClick={() => {
                    history.push('/auth/login');
                  }}
                >
                  Đăng nhập hệ thống
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
