/* eslint-disable react/jsx-pascal-case */
import React from 'react';

//Image
import logiIHR from './../../../assets/images/ihr_logo_text.png';
import LogoiHR from './../../../assets/images/Logo_512.png';
import avatar from './../../../assets/images/Ellipse.png';

//Auth
import { getUserDetailsWithToken } from './../../../views/wrapper/Auth.wrapper';

//Component
import { LF_Search, LF_Avatar } from './../../molecules';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployee } from 'redux/actions/employee.action';

export const LF_Header = () => {
  const user = getUserDetailsWithToken();

  const dispatch = useDispatch();
  const { employee, status } = useSelector(
    (state: RootState) => state.employee,
  );

  React.useEffect(() => {
    dispatch(getEmployee());
  }, []);
  return (
    <React.Fragment>
      <div className="h-[70px] w-full lg:pl-10 xl:pr-10 lg:flex flex-row items-center justify-between lg:justify-center bg-[#B50829]">
        <div className="logo">
          <div className="w-6/12 lg:w-full">
            <a href="/">
              <img className={"max-w-[70px]"} src={LogoiHR} alt="..." />
            </a>
          </div>
        </div>
        <div className="w-6/12">{/* <LF_Search /> */}</div>
        <div className="w-5/12 flex flex-row px-3">
          {/* <div className="flex flex-row justify-between">
            <div>1</div>
            <div>2</div>
          </div> */}
          <LF_Avatar
            src={avatar}
            userName={
              user && user.employee_name ? user.employee_name : user?.username
            }
            className={'text-white'}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
