import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

//Guards
import PublicRoute from '../../utils/guards/PublicRoute.guard';

//Components
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Screen
import Login from '../modules/auth/Login.auth';
import Register from '../modules/auth/Register.auth';
import ForgotPassword from '../modules/auth/ForgotPassword.auth';
import ResetPassword from '../modules/auth/ResetPassword.auth';

export default function Auth() {
  return (
    <div className="w-full h-screen bg-white">
      <Switch>
        <PublicRoute path="/auth/login" exact component={Login} />
        <PublicRoute path="/auth/register" exact component={Register} />
        <PublicRoute
          path="/auth/forgotpassword"
          exact
          component={ForgotPassword}
        />
        <PublicRoute path="/auth/pwdreset" exact component={ResetPassword} />
        <Redirect from="/auth" to="/auth/login" />
      </Switch>
      <ToastContainer />
    </div>
  );
}
