import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, FormGroup } from '@material-ui/core';

interface Iprops {
  action?: (query?: any) => void;
  data: number;
  disabled: boolean;
}

export function SelectButtonsGroup({
  action,
  data,
  disabled,
}: Partial<Iprops>) {
  const [formats, setFormats] = React.useState({
    onl: false,
    off: false,
  });

  React.useEffect(() => {
    if (data !== null) {
      if (Number(data && data) === 0) {
        setFormats({ ...formats, onl: true, off: true });
      }
      if (Number(data && data) === 1) {
        setFormats({ ...formats, onl: true, off: false });
      }
      if (Number(data && data) === 2) {
        setFormats({ ...formats, onl: false, off: true });
      }
    }
    if (data === null) {
      setFormats({ ...formats, onl: false, off: false });
    }
  }, [data]);

  return (
    <FormGroup
      row
      className="flex items-center"
      style={{ flexDirection: 'row' }}
    >
      <Checkbox
        checked={formats.onl}
        onChange={(e) => {
          if (formats.off === true) {
            if (formats.onl === true) {
              action(2);
            }
            if (formats.onl === false) {
              action(0);
            }
          }
          if (formats.off === false) {
            if (formats.onl === true) {
              action(null);
            }
            if (formats.onl === false) {
              action(1);
            }
          }
        }}
        name="onl"
        disabled={disabled}
      />
      <span className="mr-16">Online / Trực tuyến qua internet</span>
      <Checkbox
        checked={formats.off}
        onChange={(e) => {
          if (formats.onl === true) {
            if (formats.off === true) {
              action(1);
            }
            if (formats.off === false) {
              action(0);
            }
          }
          if (formats.onl === false) {
            if (formats.off === true) {
              action(null);
            }
            if (formats.off === false) {
              action(2);
            }
          }
        }}
        name="off"
        disabled={disabled}
      />
      <span className="mr-16">Offline / Tại trung tâm</span>
    </FormGroup>
  );
}
