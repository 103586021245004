import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CandidateRepository } from '../../../services/repositories/candidate.repository';
import {TraineeRepository} from "../../../services/repositories/trainee.repository";

//Repository
const traineeRepository: TraineeRepository = RepositoryFactory.get(
    'trainee',
) as TraineeRepository;

//Async Fetch
const fetchTraineeViewProfile = async (data) => {
    const response = await traineeRepository.viewTraineeProfile(data);
    return response;
};

function* handlerTraineeViewProfile(action) {
    try {
        yield put({ type: types.LOADING_REQUEST, payload: true });
        const userViewProfile = yield call(fetchTraineeViewProfile, action.payload);
        if (userViewProfile.status === 0){
            window.open(`https://i-hr.vn/profile/${userViewProfile.slug}`)
        }else {
            alert('Số dư i-Point không đủ');
        }
        yield put({
            type: types.POST_VIEW_TRAINEE_SUCCESS,
            payload: userViewProfile.status,
        });
        yield put({ type: types.LOADING_SUCCESS, payload: false });
    } catch (err) {
        yield put({
            type: types.POST_VIEW_TRAINEE_ERROR,
            payload: err.response.data,
        });
        yield put({ type: types.LOADING_SUCCESS, payload: false });
    }
}

export default function* watchTraineeViewProfile() {
    yield takeLatest(types.POST_VIEW_TRAINEE_REQUEST, handlerTraineeViewProfile);
}
