import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { SkillRepository } from '../../../services/repositories/skill.repository';

//Repository
const skillRepository: SkillRepository = RepositoryFactory.get(
  'skill',
) as SkillRepository;

//Async Fetch
const fetchSkills = async (query: string) => {
  const response = await skillRepository.search(query);
  return response;
};

function* handlerSearchSkill(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const skills = yield call(fetchSkills, action.payload);

    yield put({ type: types.SEARCH_SKILL_SUCCESS, payload: skills });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.SEARCH_SKILL_ERROR,
      payload: err.response.data,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchSearchSkill() {
  yield takeLatest(types.SEARCH_SKILL_REQUEST, handlerSearchSkill);
}
