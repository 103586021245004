/* eslint-disable react/jsx-no-target-blank */
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { convertDate, checkCovidStatus } from 'utils/commons';

const useStyle = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '14rem !important',
  },
});

const convertAge = (date) => {
  let age = date?.slice(0, 4);
  let year = new Date().getFullYear();
  return year - age;
};

export const LF_Infor = (props) => {
  const { name, date, gender, address, slug, onClick, userId, covidStatus, to, phone, email } = props;
  const classes = useStyle();
  return (
    <div>
        {
            to === 'application' ?
                <a
                    className="text-base font-bold leading-4 mb-2 cursor-pointer"
                    target="_blank"
                    href={`https://i-hr.vn/profile/${slug}`}
                >
                    {name}
                </a> :
                <a
                    className="text-base font-bold leading-4 mb-2 cursor-pointer"
                    onClick={(event) => {
                        if (onClick) onClick();
                    }}
                >
                    {name}
                </a>
        }
      {
        phone && <div>SĐT: {phone}</div>
      }
      {
        email && <div>Email: {email}</div>
      }
      <div>Covid-19: {checkCovidStatus(covidStatus)}</div>
      {date?.length > 0 ? (
          <div>
            Năm sinh: {convertDate(date?.slice(0, 10))} ( {convertAge(date)} tuổi)
          </div>
      ) : (
          ''
      )}
    </div >
  );
};
