import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';

//CSS Global
import './assets/styles/index.css';
import './assets/styles/tailwind.css';
import 'react-toastify/dist/ReactToastify.css';

//Modules
import Auth from './views/layouts/Auth.layout';
import Admin from './views/layouts/Admin.layout';

//Provider Context
import { AuthProvider } from './views/wrapper/Auth.wrapper';

//Stores
import store from './redux/store';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            {/* add routes with layouts IHR */}
            <Route path="/admin" component={Admin} />
            <Route path="/auth" component={Auth} />
            {/* add redirect for first page */}
            <Redirect from="*" to="/admin" />
          </Switch>
          <ToastContainer />
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  );
}

export default App;
