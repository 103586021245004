import * as types from '../../type';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { IPointPackageRepository } from '../../../services/repositories/ipointPackage.repository';

//Repository
const ipointRepository: IPointPackageRepository = RepositoryFactory.get(
  'package',
) as IPointPackageRepository;


const fetchPackages = async () => {
  const response = await ipointRepository.getIPointPackages();
  return response;
};

function* handlerPackages(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const packages = yield call(fetchPackages);
    yield put({ type: types.GET_PACKAGES_SUCCESS, payload: packages });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.GET_PACKAGES_ERROR,
      payload: err.response,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetPackages() {
  yield takeLatest(types.GET_PACKAGES_REQUEST, handlerPackages);
}