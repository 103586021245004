import React, { useEffect, useRef } from 'react';
import cx from 'classnames';

//Types
import { MultipleSelectProps } from './multiple.type';

export const CustomMultipleSelect = ({
  name,
  listShow,
  listChoose,
  onAddChoose,
  onDeleteChoose,
  query,
  setQuery,
  onSubmit,
}: Partial<MultipleSelectProps>) => {
  //State
  const [show, setShow] = React.useState(false);

  //Ref
  const typingRef = useRef(null);
  const clickOutsideRef = useRef(null);

  // Handle on change
  const handleOnChangeSearch = (e) => {
    setQuery(e.target.value);
    if (typingRef.current) clearTimeout(typingRef.current);

    typingRef.current = setTimeout(() => {
      const value = e.target.value;
      onSubmit(value);
    }, 800);
  };

  // Handle on click outside
  const OnClickOutside = (ref, callback) => {
    const handleOnClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    useEffect(() => {
      document.addEventListener('click', handleOnClickOutside);

      // Clean up
      return () => document.removeEventListener('click', handleOnClickOutside);
    });
  };
  OnClickOutside(clickOutsideRef, () => {
    if (show) setShow(false);
  });

  return (
    <div ref={clickOutsideRef}>
      <div
        className={cx([
          'appearance-none border border-gray-300 w-full px-3 text-gray-700 leading-tight cursor-text',
          listChoose?.length > 0 ? 'pt-2' : 'py-6',
        ])}
        onClick={() => setShow(true)}
      >
        {listChoose.length > 0 &&
          listChoose?.map((item) => (
            <span
              className={`rounded-2xl bg-gray-200 mr-3 inline-flex ${
                listChoose?.length > 0 ? 'mb-2' : ''
              }`}
              key={item ? item[`${name}code`] : null}
            >
              <span className="text-black text-sm px-3 py-6px">
                {item ? item[`${name}name`] : null}
              </span>
              <span
                className="text-gray-800 cursor-pointer flex items-center px-2"
                onClick={() => {
                  onDeleteChoose(item[`${name}code`]);
                }}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 1.5C4.8525 1.5 1.5 4.8525 1.5 9C1.5 13.1475 4.8525 16.5 9 16.5C13.1475 16.5 16.5 13.1475 16.5 9C16.5 4.8525 13.1475 1.5 9 1.5ZM12.75 11.6925L11.6925 12.75L9 10.0575L6.3075 12.75L5.25 11.6925L7.9425 9L5.25 6.3075L6.3075 5.25L9 7.9425L11.6925 5.25L12.75 6.3075L10.0575 9L12.75 11.6925Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                </svg>
              </span>
            </span>
          ))}
      </div>
      {show && (
        <div className="w-full p-5 mt-2 custom-box-shadow">
          <div className="flex justify-between mb-1">
            <div className="font-medium">Từ khoá:</div>
            <div
              className="text-gray-800 cursor-pointer"
              onClick={() => setShow(false)}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 1.5C4.8525 1.5 1.5 4.8525 1.5 9C1.5 13.1475 4.8525 16.5 9 16.5C13.1475 16.5 16.5 13.1475 16.5 9C16.5 4.8525 13.1475 1.5 9 1.5ZM12.75 11.6925L11.6925 12.75L9 10.0575L6.3075 12.75L5.25 11.6925L7.9425 9L5.25 6.3075L6.3075 5.25L9 7.9425L11.6925 5.25L12.75 6.3075L10.0575 9L12.75 11.6925Z"
                  fill="black"
                  fillOpacity="0.6"
                />
              </svg>
            </div>
          </div>
          <input
            type="text"
            value={query || ''}
            onChange={handleOnChangeSearch}
            placeholder="Search..."
            className="rounded border border-gray-300 w-full text-gray-700 focus:outline-none focus:shadow-outline"
          />
          {listShow?.length > 0 ? (
            <React.Fragment>
              <div className="mt-5 mb-1 font-medium">Lĩnh vực:</div>
              <div
                className="py-2 border border-gray-300 rounded overflow-y-auto"
                style={{ maxHeight: '300px', height: 'auto' }}
              >
                {listShow.map((item, index) => (
                  <div
                    className="py-2 px-7 hover:bg-gray-600 hover:text-white cursor-pointer"
                    // key={item[`${name}code`]}
                    key={index}
                    onClick={() => {
                      onAddChoose(item);
                    }}
                  >
                    <h4>{item[`${name}name`]}</h4>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ) : (
            <div className="p-5 border border-gray-300 rounded mt-5">
              <h3 className="text-center text-red-500">
                Không tìm thấy kết quả nào!
              </h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
