import { parse } from 'path';

export const LF_Review = ({
  data,
  onClickNextStep,
  onClickBack,
  listProvinces = [],
  listDistricts = [],
  // quantityAddress,
}) => {
  const createMarkup = (innerHTML) => {
    return { __html: innerHTML };
  };

  // Convert code -> [object, object, v...v]
  const codeToList = (code: string, list, keyCode) => {
    if (code) {
      const newList = [...list];
      const listCode = code.split(',');
      const listChooseFromAPI = newList.filter(
        (item) => listCode.indexOf(item[keyCode]) >= 0,
      );

      return listChooseFromAPI;
    }
  };

  const cityText =
    listProvinces.length > 0 &&
    codeToList(data.detailsInfo.city, listProvinces, 'province_code')[0][
    'province_name'
    ];

  const districtText =
    listDistricts.length > 0 &&
    codeToList(data.detailsInfo.district, listDistricts, 'district_code')[0][
    'name'
    ];

  const degree = (value) => {
    switch (value) {
      case 'deg01':
        return 'Trung học cơ sở';
      case 'deg02':
        return 'Trung học phổ thông';
      case 'deg03':
        return 'Đào tạo ngắn hạng';
      case 'deg04':
        return 'Sơ cấp (6 tháng)';
      case 'deg05':
        return 'Trung cấp (2 năm)';
      case 'deg06':
        return 'Cao đẳng (3 năm)';
      case 'deg07':
        return 'Đại học';
      case 'deg08':
        return 'Thạc sĩ';
      case 'deg09':
        return 'Tiến sĩ';
      default:
        return;
    }
  };

  const gender = (value) => {
    switch (value) {
      case 0:
        return 'Không yêu cầu';
      case 1:
        return 'Nam';
      case 2:
        return 'Nữ';
      default:
        return;
    }
  };

  const ageShow = (minAge, maxAge) => {
    if (!minAge && maxAge) {
      if (parseInt(maxAge) != 0) {
        return `Không yêu cầu ~ ${maxAge} tuổi`;
      } else {
        return 'Không yêu cầu';
      }
    } else if (minAge && !maxAge) {
      if (parseInt(minAge) != 0) {
        return `${minAge} ~ Không yêu cầu tuổi`;
      } else {
        return 'Không yêu cầu';
      }
    } else if (!minAge && !maxAge) {
      return 'Không yêu cầu';
    } else {
      return `${minAge} ~ ${maxAge} tuổi`;
    }
  };

  return (
    <>
      <h3 className="uppercase text-lg font-bold pb-2 mb-5 border-b border-gray-400">
        Chi tiết công việc
      </h3>

      <div className="flex flex-col lg:flex-row">
        <label className="font-bold mr-3 w-56">Tiêu đề công việc</label>
        <p className="flex-1 mt-4 lg:mt-0 leading-6">{data.basicInfo.jobTitle}</p>
      </div>

      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56">Mô tả công việc</label>
        <div
          className="flex-1 mt-4 lg:mt-0 leading-6"
          dangerouslySetInnerHTML={createMarkup(data.basicInfo.desc)}
        />
      </div>

      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56">Yêu cầu công việc</label>
        <div
          className="flex-1 mt-4 lg:mt-0 leading-6"
          dangerouslySetInnerHTML={createMarkup(data.basicInfo.require)}
        />
      </div>

      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56">Phúc lợi</label>
        <div
          className="flex-1 mt-4 lg:mt-0 leading-6"
          dangerouslySetInnerHTML={createMarkup(data.basicInfo.welfare)}
        />
      </div>

      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56">Bằng cấp</label>
        <div className="flex-1 mt-4 lg:mt-0 leading-6">
          {/* {`${data.detailsInfo.address}, ${
            listProvinces.length === 0
              ? data.detailsInfo.district_text
              : districtText
          }, ${
            listDistricts.length === 0 ? data.detailsInfo.city_text : cityText
          }`} */}
          <p>{degree(data.detailsInfo.degree)}</p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56">Địa điểm làm việc</label>
        <p className="leading-6">
          {`${data.detailsInfo.address}, ${listProvinces.length === 0
            ? data.detailsInfo.district_text
            : districtText
            }, ${listDistricts.length === 0 ? data.detailsInfo.city_text : cityText
            }`}
        </p>
      </div>
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56">Ngành nghề chuyên môn</label>
        <ul className="list-disc flex-1 pl-5 leading-6">
          {data.detailsInfo.listChooseCareers &&
            data.detailsInfo.listChooseCareers.length > 0 &&
            data.detailsInfo.listChooseCareers?.map((career, index) => (
              <li key={index}>{career.career_name}</li>
            ))}
        </ul>
      </div>
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56">Giới tính</label>
        <p className="flex-1 mt-4 lg:mt-0 leading-6">
          {gender(data.detailsInfo.gender)}
          {/* {data.detailsInfo.gender === 0
            ? 'Bất kỳ'
            : data.detailsInfo.gender === 1
            ? 'Nam'
            : 'Nữ'} */}
        </p>
      </div>
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56">Độ tuổi</label>
        <p className="flex-1  mt-4 lg:mt-0 leading-6">
          {/* {data.detailsInfo.minAge || 0} ~ {data.detailsInfo.maxAge || 0} tuổi */}
          {ageShow(data.detailsInfo.minAge, data.detailsInfo.maxAge)}
        </p>
      </div>
      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56">Mức lương</label>
        <p className="flex-1 mt-4 lg:mt-0 leading-6">
          {data.detailsInfo.agreedSalary === 1
            ? 'Lương thỏa thuận'
            : `${data.detailsInfo.salaryFrom || 0} ~ ${data.detailsInfo.salaryTo || 0
            } triệu`}
        </p>
      </div>

      <div className="flex flex-col lg:flex-row mt-4">
        <label className="font-bold mr-3 w-56">Tính chất công việc</label>
        <p className="flex-1 mt-4 lg:mt-0 leading-6">
          {data.detailsInfo.jobType === 'jot01' && 'Toàn thời gian'}
          {data.detailsInfo.jobType === 'jot02' && 'Bán thời gian'}
          {data.detailsInfo.jobType === 'jot03' && 'Thực tập'}
          {data.detailsInfo.jobType === 'jot04' && 'Cộng tác viên'}
          {data.detailsInfo.jobType === 'jot05' && 'Tình nguyện viên'}
        </p>
      </div>

      <div className="mt-24 mb-14 flex items-center justify-center">
        <div
          className="flex w-max rounded-lg bg-gray-400 text-white custom-btn cursor-pointer"
          onClick={() => onClickBack('review')}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.3285 10.9997V12.9997L7.50017 12.9997L10.7427 16.2423L9.32849 17.6565L3.67163 11.9996L9.32849 6.34277L10.7427 7.75699L7.49994 10.9997L20.3285 10.9997Z"
              fill="white"
            />
          </svg>
          <span className="pl-2 font-medium">Quay lại</span>
        </div>
        <div
          className="flex w-max ml-40 rounded-lg bg-[#B50829] text-white custom-btn cursor-pointer"
          onClick={() => onClickNextStep('review')}
        >
          <span className="pr-2 font-medium">Hoàn tất</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0377 6.34277L13.6268 7.75629L16.897 11.0153L3.29199 11.029L3.294 13.0289L16.8618 13.0153L13.6466 16.2455L15.0641 17.6564L20.7078 11.9864L15.0377 6.34277Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </>
  );
};
