import { BaseRepository } from './base';
import { IPackage } from '../../utils/interfaces';
import Repository from './base/repository';

export class IPointPackageRepository extends BaseRepository<IPackage> {
  resource = 'package';
  token = localStorage.getItem('access-token');

  async getIPointPackages() {
    const response = await Repository.get(
      `${this.resource}`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }

  async getIPointPackageTime() {
    const response = await Repository.get(
      `${this.resource}/options`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }

  async getPackageIPoint(p_package_id: Number, p_package_option_id: Number) {
    const response = await Repository.get(
      `${this.resource}/package-ipoint?p_package_id=${p_package_id}&p_package_option_id=${p_package_option_id}`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }

  async getPackageHotIPoint(p_package_option_id: Number) {
    const response = await Repository.get(
      `${this.resource}/hot/package-ipoint?p_package_option_id=${p_package_option_id}`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }
  async getPackageVip() {
    const response = await Repository.get(
      `${this.resource}/vip-packages`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }
  async getPackageBuy() {
    const response = await Repository.get(
      `${this.resource}/package-buy`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }
  async getOldPackages(object_id, object_name) {
    const response = await Repository.get(
      `${this.resource}/old-packages?object_id=${object_id}&object_name=${object_name}`,
      { headers: { Authorization: `Bearer ${this.token}` } },
    );
    return response.data;
  }

}