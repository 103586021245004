import * as types from '../type';
import { ICorporate } from '../../utils/interfaces';

interface IInitialState {
  corporates: ICorporate[];
  corporate: ICorporate;
  status: any;
}

const initialState: IInitialState = {
  corporates: [],
  corporate: {
    username: "",
    password: "",
    email: "",
    phone: "",
    corporate_name: "",
    corporate_address: "",
    province_code: "",
    district_code: "",
    commune_code: "",
    legal: 0,
    so_dkkd: "",
    career_groups: "",
    assigned_date: 0,
    assigned_location: "",
    address: "",
    corporate_email: "",
  },
  status: null,
};

const corporateReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.CORPORATE_SUCCESS:
      return {
        ...state,
        corporates: [action.payload],
        corporate: action.payload
      };
    case types.UPDATE_CORPORATE_SUCCESS:
      return {
        ...state,
        status: action.payload
      };
    default:
      return {
        ...state,
      };
  }
};

export default corporateReducer;