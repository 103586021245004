import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from 'services/repositories';
import { EmployeeRepository } from 'services/repositories/employee.repository';
import { AlertType, showNotification } from 'utils/commons/Alert';

//Initial State
const employeeRepository: EmployeeRepository = RepositoryFactory.get(
  'emp',
) as EmployeeRepository;

const fetchEmployee = async (data) => {
  const response = await employeeRepository.updateEmployee(data);

  return response;
};

function* updateEmployee(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const employee = yield call(fetchEmployee, action.payload);
    yield put({ type: types.UPDATE_EMPLOYEE_SUCCESS, payload: employee });
    showNotification({
      type: AlertType.SUCCESS,
      msg: 'Cập nhật thành công!',
    });

    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.UPDATE_EMPLOYEE_ERROR,
      payload: err.response.data,
    });
    showNotification({
      type: AlertType.ERROR,
      msg: 'Cập nhật thất bại! Vui lòng kiểm tra lại thông tin!',
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchUpdateEmployee() {
  yield takeLatest(types.UPDATE_EMPLOYEE_REQUEST, updateEmployee);
}
