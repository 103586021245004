import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { CandidateRepository } from '../../../services/repositories/candidate.repository';
import { CoursesRepository } from 'services/repositories/course.repository';

//Repository
const studentRepository = RepositoryFactory.get('course') as CoursesRepository;
const candidateRepository: CandidateRepository = RepositoryFactory.get(
  'candidate',
) as CandidateRepository;

//Async Fetch
export const fetchStudentCourse = async (query?: any) => {
  const response = await studentRepository.getStudentById(query);
  let result = { data: [], total_data: response.total_job };
  if (response.data && response.data.length > 0) {
    for (const item of response.data) {
      const getInfo = await candidateRepository.getCandidateCareers(
        item.user_id,
      );
      result.data.push({
        ...item,
        infor: getInfo.data,
      });
    }
  }
  return result;
};

function* handlerStudent(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const candidate = yield call(fetchStudentCourse, action.payload);

    yield put({ type: types.GET_STUDENT_BY_ID_SUCCESS, payload: candidate });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({
      type: types.GET_STUDENT_BY_ID_ERROR,
      payload: err.response.data,
    });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetStudentById() {
  yield takeLatest(types.GET_STUDENT_BY_ID_REQUEST, handlerStudent);
}
