/* eslint-disable import/no-anonymous-default-export */
export default {
  root: {
    ipoint: [
      {
        path: '/admin/ipoint',
        menu_name: 'iPoint',
        subMenu: [],
      },
      {
        path: '/admin/ipoint/history',
        menu_name: 'Lịch sử thanh toán',
        subMenu: [],
      },
      {
        path: '/admin/ipoint/buy-ipoint',
        menu_name: 'Mua ipoint',
        subMenu: [],
      },
    ],
    home: [
      //Dashboard
      {
        path: '/admin/dashboard',
        menu_name: 'Dashboard',
        subMenu: [],
      },
      {
        path: '/admin/user',
        menu_name: 'Thông tin tài khoản',
        subMenu: [],
      },
      {
        path: '/admin/company',
        menu_name: 'Hồ sơ công ty',
        subMenu: [],
      },
    ],
    job: [
      //Job Management
      {
        path: '/admin/job/post',
        menu_name: 'Đăng Tin Tuyển Dụng',
        subMenu: [],
      },
      {
        path: '/admin/job/recruitment',
        menu_name: 'Danh Sách Tuyển Dụng',
        subMenu: [],
      },
      {
        path: '/admin/job/employee',
        menu_name: 'Danh Sách Ứng Tuyển',
        subMenu: [],
      },
      {
        path: '/admin/job/searchEmployee',
        menu_name: 'Tìm Ứng Viên Phù Hợp',
        subMenu: [],
      },
    ],
    course: [
      {
        path: '/admin/course/post',
        menu_name: 'Đăng Khóa Học',
        subMenu: [],
      },
      {
        path: '/admin/course/list',
        menu_name: 'Danh sách khóa học',
        subMenu: [],
      },
      {
        path: '/admin/course/register',
        menu_name: 'Học viên đăng ký',
        subMenu: [],
      },
      {
        path: '/admin/course/search',
        menu_name: 'Tìm học viên phù hợp',
        subMenu: [],
      },
    ],
  },
};
