/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

//Guards
import PrivateRoute from '../../utils/guards/PrivateRoute.guard';
import { routes } from '../../utils/guards/routes.guard';
import menus from '../../utils/guards/Menu.guard';

//Component
import { LF_AdminLayout } from './../../components/templates';
import { LF_Header, LF_Sidebar } from './../../components/organisms';

// React-Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {getJobs, getCourses, getIPoint, getCorporate} from 'redux/actions';
import { getUserDetailsWithToken } from 'views/wrapper/Auth.wrapper';

export default function Admin() {
  // const [open, setOpen] = useState(false);
  const boardTitle = [].concat(...Object.values(menus.root));

  const dispatch = useDispatch();
  const { jobs } = useSelector((state: RootState) => state.jobs);
  const { courses } = useSelector((state: RootState) => state.courses);
  const { ipoint } = useSelector((state: RootState) => state.ipoint);
  const corporate_code  = getUserDetailsWithToken()?.corporate_code;
  // useEffect
  React.useEffect(() => {
    dispatch(getJobs());
    dispatch(getCourses());
    dispatch(getIPoint(corporate_code));
    dispatch(getCorporate());
  }, []);
  return (
    <React.Fragment>
      <LF_AdminLayout>
        <header className={"h-[70px]"}>
          <LF_Header />
        </header>
        <main>
          <div className="w-full flex flex-row">
            <LF_Sidebar
                menus={menus}
                count={jobs.total_job}
                countCourse={courses.total_course}
                ipoint={ipoint?.ipoint}
            />
            <div className="flex-grow w-full lg:w-9/12 xl:w-9/12 2xl:w-10/12   min-h-screen">
              <Switch>
                {routes.map((route, index) => (
                  <PrivateRoute
                    exact
                    path={route.path}
                    component={route.component}
                    key={index}
                  />
                ))}
                <Redirect exact from="/admin" to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        </main>
      </LF_AdminLayout>
    </React.Fragment>
  );
}
