import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, FormGroup } from '@material-ui/core';

interface iProps {
  action?: (e: string) => any;
  disable?: boolean;
  data?: string;
}

export function SelectScheduleSearch({
  action,
  disable,
  data,
}: Partial<iProps>) {
  const [formats, setFormats] = React.useState({
    mon: { id: '2', name: 'Thứ 2', mor: false, aft: false, evn: false },
    tue: { id: '3', name: 'Thứ 3', mor: false, aft: false, evn: false },
    web: { id: '4', name: 'Thứ 4', mor: false, aft: false, evn: false },
    thu: { id: '5', name: 'Thứ 5', mor: false, aft: false, evn: false },
    fri: { id: '6', name: 'Thứ 6', mor: false, aft: false, evn: false },
    sat: { id: '7', name: 'Thứ 7', mor: false, aft: false, evn: false },
    sun: { id: '1', name: 'Chủ Nhật', mor: false, aft: false, evn: false },
  });
  const [submit, setSubmit] = React.useState('');
  const [first, setFirst] = React.useState(true);

  //for view
  //   React.useEffect(() => {
  //     if (data && first === true) {
  //       for (let i = 0; i < data?.length; i++) {
  //         if (/1[A-Z]/g.test(data)) {
  //           let temp = formats.sun;
  //           if (/1S/g.test(data)) {
  //             temp.mor = true;
  //           }
  //           if (/1C/g.test(data)) {
  //             temp.aft = true;
  //           }
  //           if (/1T/g.test(data)) {
  //             temp.evn = true;
  //           }
  //           setFormats({ ...formats, sun: temp });
  //         }
  //         if (/2[A-Z]/g.test(data)) {
  //           let temp = formats.mon;
  //           if (/2S/g.test(data)) {
  //             temp.mor = true;
  //           }
  //           if (/2C/g.test(data)) {
  //             temp.aft = true;
  //           }
  //           if (/2T/g.test(data)) {
  //             temp.evn = true;
  //           }
  //           setFormats({ ...formats, mon: temp });
  //         }
  //         if (/3[A-Z]/g.test(data)) {
  //           let temp = formats.tue;
  //           if (/3S/g.test(data)) {
  //             temp.mor = true;
  //           }
  //           if (/3C/g.test(data)) {
  //             temp.aft = true;
  //           }
  //           if (/3T/g.test(data)) {
  //             temp.evn = true;
  //           }
  //           setFormats({ ...formats, tue: temp });
  //         }
  //         if (/4[A-Z]/g.test(data)) {
  //           let temp = formats.web;
  //           if (/4S/g.test(data)) {
  //             temp.mor = true;
  //           }
  //           if (/4C/g.test(data)) {
  //             temp.aft = true;
  //           }
  //           if (/4T/g.test(data)) {
  //             temp.evn = true;
  //           }
  //           setFormats({ ...formats, web: temp });
  //         }
  //         if (/5[A-Z]/g.test(data)) {
  //           let temp = formats.thu;
  //           if (/5S/g.test(data)) {
  //             temp.mor = true;
  //           }
  //           if (/5C/g.test(data)) {
  //             temp.aft = true;
  //           }
  //           if (/5T/g.test(data)) {
  //             temp.evn = true;
  //           }
  //           setFormats({ ...formats, thu: temp });
  //         }
  //         if (/6[A-Z]/g.test(data)) {
  //           let temp = formats.fri;
  //           if (/6S/g.test(data)) {
  //             temp.mor = true;
  //           }
  //           if (/6C/g.test(data)) {
  //             temp.aft = true;
  //           }
  //           if (/6T/g.test(data)) {
  //             temp.evn = true;
  //           }
  //           setFormats({ ...formats, fri: temp });
  //         }
  //         if (/7[A-Z]/g.test(data)) {
  //           let temp = formats.sat;
  //           if (/7S/g.test(data)) {
  //             temp.mor = true;
  //           }
  //           if (/7C/g.test(data)) {
  //             temp.aft = true;
  //           }
  //           if (/7T/g.test(data)) {
  //             temp.evn = true;
  //           }
  //           setFormats({ ...formats, sat: temp });
  //         }
  //       }
  //       setFirst(false);
  //       setSubmit(data);
  //     }
  //   }, [data]);

  //for post
  const handle = (data: { value; name; checked }) => {
    if (!data.checked) {
      if (data.name === 'mor') {
        setSubmit(submit.replace(`${formats[data.value].id + 'S,'}`, ''));
      }
      if (data.name === 'aft') {
        setSubmit(submit.replace(`${formats[data.value].id + 'C,'}`, ''));
      }
      if (data.name === 'evn') {
        setSubmit(submit.replace(`${formats[data.value].id + 'T,'}`, ''));
      }
    }
    if (data.checked) {
      if (data.name === 'mor') {
        setSubmit(
          `${
            submit
              ? submit + formats[data.value].id + 'S,'
              : formats[data.value].id + 'S,'
          }`,
        );
      }
      if (data.name === 'aft') {
        setSubmit(
          `${
            submit
              ? submit + formats[data.value].id + 'C,'
              : formats[data.value].id + 'C,'
          }`,
        );
      }
      if (data.name === 'evn') {
        setSubmit(
          `${
            submit
              ? submit + formats[data.value].id + 'T,'
              : formats[data.value].id + 'T,'
          }`,
        );
      }
    }
  };

  React.useEffect(() => {
    action(submit.substr(0, submit.length));
  }, [submit]);

  const handleChange = (event) => {
    const values = {
      value: event.target.value,
      name: event.target.name,
      checked: event.target.checked,
    };

    switch (values.value) {
      case 'mon':
        handle(values);
        return setFormats({
          ...formats,
          mon: {
            ...formats.mon,
            [values.name]: values.checked,
          },
        });
      case 'tue':
        handle(values);
        return setFormats({
          ...formats,
          tue: {
            ...formats.tue,
            [values.name]: values.checked,
          },
        });
      case 'web':
        handle(values);
        return setFormats({
          ...formats,
          web: {
            ...formats.web,
            [values.name]: values.checked,
          },
        });
      case 'thu':
        handle(values);
        return setFormats({
          ...formats,
          thu: {
            ...formats.thu,
            [values.name]: values.checked,
          },
        });
      case 'fri':
        handle(values);
        return setFormats({
          ...formats,
          fri: {
            ...formats.fri,
            [values.name]: values.checked,
          },
        });
      case 'sat':
        handle(values);
        return setFormats({
          ...formats,
          sat: {
            ...formats.sat,
            [values.name]: values.checked,
          },
        });
      case 'sun':
        handle(values);
        return setFormats({
          ...formats,
          sun: {
            ...formats.sun,
            [values.name]: values.checked,
          },
        });
      default: {
        return;
      }
    }
  };

  return (
    <FormGroup row className="flex items-center" style={{ flexDirection: 'column' }}>
      <div className="flex flex-col lg:flex-row items-start lg:items-center">
        <span className="mr-5 w-20">{`${formats.mon.name}`}</span>
        <div  className={'flex items-center'} >
          <Checkbox
            checked={formats.mon.mor}
            onChange={handleChange}
            name="mor"
            value="mon"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Sáng</span>
          <Checkbox
            checked={formats.mon.aft}
            onChange={handleChange}
            name="aft"
            value="mon"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Chiều</span>
          <Checkbox
            checked={formats.mon.evn}
            onChange={handleChange}
            name="evn"
            value="mon"
            disabled={disable}
          />
          <span>Tối</span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-start lg:items-center">
        <span className="mr-5 w-20">{`${formats.tue.name}`}</span>

        <div className={'flex items-center'}>
          <Checkbox
            checked={formats.tue.mor}
            onChange={handleChange}
            name="mor"
            value="tue"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Sáng</span>
          <Checkbox
            checked={formats.tue.aft}
            onChange={handleChange}
            name="aft"
            value="tue"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Chiều</span>
          <Checkbox
            checked={formats.tue.evn}
            onChange={handleChange}
            name="evn"
            value="tue"
            disabled={disable}
          />
          <span>Tối</span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-start lg:items-center">
        <span className="mr-5 w-20">{`${formats.web.name}`}</span>
        <div  className={'flex items-center'}>
          <Checkbox
            checked={formats.web.mor}
            onChange={handleChange}
            name="mor"
            value="web"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Sáng</span>
          <Checkbox
            checked={formats.web.aft}
            onChange={handleChange}
            name="aft"
            value="web"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Chiều</span>
          <Checkbox
            checked={formats.web.evn}
            onChange={handleChange}
            name="evn"
            value="web"
            disabled={disable}
          />
          <span>Tối</span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-start lg:items-center">
        <span className="mr-5 w-20">{`${formats.thu.name}`}</span>
        <div  className={'flex items-center'}>
          <Checkbox
            checked={formats.thu.mor}
            onChange={handleChange}
            name="mor"
            value="thu"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Sáng</span>
          <Checkbox
            checked={formats.thu.aft}
            onChange={handleChange}
            name="aft"
            value="thu"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Chiều</span>
          <Checkbox
            checked={formats.thu.evn}
            onChange={handleChange}
            name="evn"
            value="thu"
            disabled={disable}
          />
          <span>Tối</span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-start lg:items-center">
        <span className="mr-5 w-20">{`${formats.fri.name}`}</span>
        <div  className={'flex items-center'}>
          <Checkbox
            checked={formats.fri.mor}
            onChange={handleChange}
            name="mor"
            value="fri"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Sáng</span>
          <Checkbox
            checked={formats.fri.aft}
            onChange={handleChange}
            name="aft"
            value="fri"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Chiều</span>
          <Checkbox
            checked={formats.fri.evn}
            onChange={handleChange}
            name="evn"
            value="fri"
            disabled={disable}
          />
          <span>Tối</span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-start lg:items-center">
        <span className="mr-5 w-20">{`${formats.sat.name}`}</span>
        <div  className={'flex items-center'}>
          <Checkbox
            checked={formats.sat.mor}
            // onChange={handleChange}
            onClick={handleChange}
            name="mor"
            value="sat"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Sáng</span>
          <Checkbox
            checked={formats.sat.aft}
            onClick={handleChange}
            // onChange={handleChange}
            name="aft"
            value="sat"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Chiều</span>
          <Checkbox
            checked={formats.sat.evn}
            onClick={handleChange}
            // onChange={handleChange}
            name="evn"
            value="sat"
            disabled={disable}
          />
          <span>Tối</span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-start lg:items-center">
        <span className="mr-5 w-20">{`${formats.sun.name}`}</span>
        <div  className={'flex items-center'}>
          <Checkbox
            checked={formats.sun.mor}
            onChange={handleChange}
            name="mor"
            value="sun"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Sáng</span>
          <Checkbox
            checked={formats.sun.aft}
            onChange={handleChange}
            name="aft"
            value="sun"
            disabled={disable}
          />
          <span className="mr-4 lg:mr-16">Chiều</span>
          <Checkbox
            checked={formats.sun.evn}
            onChange={handleChange}
            name="evn"
            value="sun"
            disabled={disable}
          />
          <span>Tối</span>
        </div>
      </div>
    </FormGroup>
  );
}
