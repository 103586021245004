import * as types from '../type';
interface IInitialState {
  packageBuy: [],
}

const initialState: IInitialState = {
  packageBuy: [],
}

const packageBuyReducer = (state = initialState, action): IInitialState => {
  switch (action.type) {
    case types.GET_PACKAGE_BUY_SUCCESS:
      return {...state, packageBuy: action.payload};
    case types.GET_PACKAGE_BUY_ERROR:
      return {...state, packageBuy: action.payload};
    case types.GET_PACKAGE_BUY_REQUEST:
      return {...state, packageBuy: action.payload};
    default:
      return {
        ...state,
      }
  }
}

export default packageBuyReducer;