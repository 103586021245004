import * as types from '../../type';
import { call, put, takeLatest } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { IPointPackageRepository } from '../../../services/repositories/ipointPackage.repository';


//Repository
const packageRepository: IPointPackageRepository = RepositoryFactory.get(
    'package',
) as IPointPackageRepository;


const fetchOldPackages = async ({object_id, object_name}) => {
    const response = await packageRepository.getOldPackages(object_id, object_name);
    return response;
};

function* handlerOldPackages(action) {
    try {
        yield put({ type: types.LOADING_REQUEST, payload: true });
        const oldPackages = yield call(fetchOldPackages, action.payload);
        yield put({ type: types.GET_OLD_PACKAGES_SUCCESS, payload: oldPackages });
        yield put({ type: types.LOADING_SUCCESS, payload: false });
    } catch (err) {
        yield put({
            type: types.GET_OLD_PACKAGES_ERROR,
            payload: err.response,
        });
        yield put({ type: types.LOADING_SUCCESS, payload: false });
    }
}

export default function* watchGetOldPackages() {
    yield takeLatest(types.GET_OLD_PACKAGES_REQUEST, handlerOldPackages);
}