/* eslint-disable react/jsx-pascal-case */
import React from 'react';

import {
  Button,
  Checkbox,
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@material-ui/core';
import { LF_StatusRecuit } from 'components/atoms/Status/StatusRecuit.atom';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import {
  LF_Avatar,
  LF_DesiredPosition,
  LF_Education,
  LF_Infor,
  LF_LiList,
  LF_Status,
  LF_StatusText,
  LF_DesiredPositionCourse,
  LF_StatusCourse,
} from 'components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import { convertDate } from 'utils/commons';

//csv
import { CSVLink } from 'react-csv';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      '&:focus': {
        outline: 'none',
      },
    },
  },
  buttonTab: {
    backgroundColor: 'white !improtant',
    color: '#000000 !important',
    fontWeight: 'bold',
    fontSize: '14px',
    minWidth: 100,
    textTransform: 'none',
    padding: '0 12px',
    opacity: 1,
    '&:focus': {
      outline: '2px solid transparent',
      outlineOffset: '2px',
      backgroundColor: '#B50829 !important',
      color: 'white !important',
    },
    '&:active': {
      backgroundColor: '#B50829 !important',
      color: 'white !important',
    },
  },
  btnRed: {
    backgroundColor: '#B50829',
    '&:hover': {
      backgroundColor: '#B50829',
    },
  },
}));

export const LF_CourseList = ({
  rows,
  student,
  fromSearch,
  per_page,
  page,
  handlePage,
  onChangeStatusStudent,
  dataExportExcel,
}) => {
  const classes = useStyles();
  //React-Redux
  const { loading } = useSelector((state) => state['loading']);
  const dispatch = useDispatch();
  //data
  const [value, setValue] = React.useState(0);
  const [title, setTitle] = React.useState(false);

  //Checkbox
  const [listUserChecked, setListUserChecked] = React.useState([]);

  const [onCheck, setOncheck] = React.useState(false);
  const [rowsCustom, setCustom] = React.useState([]);
  const [statusEmp, setStatusEmp] = React.useState(0);

  //Status Course
  const [status, setChangeStatus] = React.useState({
    p_user_id: null,
    p_course_id: parseInt(student?.course_id),
    p_status: 2,
  });

  React.useEffect(() => {
    setChangeStatus({ ...status, p_course_id: parseInt(student?.course_id) });
  }, [student]);

  React.useEffect(() => {
    if (rows !== null) {
      setCustom(rows);
    } else {
      setCustom([]);
    }
  }, [rows]);

  React.useEffect(() => {
    if (rows && rows.length > 0)
      setCustom(rows.map((e) => ({ ...e, checked: false })));
  }, [rows]);

  const onCheckAll = () => {
    if (!onCheck) {
      setListUserChecked(rows?.map((e) => e.user_id));
      setCustom((prev) => {
        return prev?.map((e) => ({ ...e, checked: true }));
      });
    } else {
      setListUserChecked([]);
      setCustom((prev) => {
        return prev?.map((e) => ({ ...e, checked: false }));
      });
    }
  };
  const onChangeCheckbox = (event, idx) => {
    let nextState = [...rowsCustom];
    if (event.target.checked) {
      nextState[idx] = { ...nextState[idx], checked: true };
    } else {
      nextState[idx] = { ...nextState[idx], checked: false };
    }

    setCustom(nextState);
  };

  React.useEffect(() => {
    function checkAllCustom() {
      let checkAll = true;
      let filter = rowsCustom?.filter((e) => e.checked === false);
      if (filter.length > 0) checkAll = false;
      setOncheck(checkAll);
    }

    checkAllCustom();
  }, [rowsCustom]);

  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    let a = (value - 1) * 20;
    handlePage(value);
    fromSearch('p_skip', a.toString());
  };

  //Update Status
  const changeStatus = () => {
    const users = rowsCustom
        .filter((user) => user.checked === true)
        .map((user) => {
          return {
            user_id: user.user_id,
            course_id: user.course_id
          }
        });
    if (users.length > 0) {
      setChangeStatus({
        ...status,
        p_user_id: users,
      });

      onChangeStatusStudent({
        ...status,
        p_user_id: users,
      });
    }
  };

  const onChangeStatus = (event) => {
    setChangeStatus({
      ...status,
      p_status: parseInt(event.target.value),
    });
  };

  const onUpdateStatus = (data) => {
    onChangeStatusStudent(data);
  };

  //View HTML
  const titleTab = (label, totalData) => {
    return (
      <React.Fragment>
        <span className="flex">
          <span>{label}</span>
          <span className="pl-2">{totalData}</span>
        </span>
      </React.Fragment>
    );
  };

  const getPageTotal = () => {
    switch (statusEmp) {
      case 1:
        return per_page?.dang_ky;
      case 2:
        return per_page?.trung_tuyen;
      case 3:
        return per_page?.da_chon;
      case 4:
        return per_page?.tu_choi;
      case 5:
        return per_page?.da_xoa;
      default:
        return per_page?.total;
    }
  };

  const renderLearningForm = (value) => {
    switch (value) {
      case '0':
        return 'Học trực tuyến (Online)';
      case '1':
        return 'Học tập trung (Offline)';
      default:
        return 'Học bất kỳ (Online hoặc Offline)';
    }
  };

  //Export Excel
  const headers = [
    { label: 'Họ', key: 'last_name' },
    { label: 'Tên', key: 'first_name' },
    { label: 'Ngày sinh', key: 'dob' },
    { label: 'Giới tính', key: 'genderText' },
    { label: 'Số điện thoại', key: 'phone' },
    { label: 'Email', key: 'email' },
    { label: 'Nhu cầu', key: 'infor' },
    { label: 'Thông tin', key: 'information' },
    { label: 'Ngày đăng ký', key: 'applied_date' },
    { label: 'Mức độ phù hợp (%)', key: 'fit_percent' },
    { label: 'Phân loại', key: 'statusName' },
  ];

  return (
    <div className="w-full">
      {rowsCustom.length > 0 && (
        <div
          className="text-right uppercase"
          style={{
            color: '#B50829',
          }}
        >
          <CSVLink
            data={dataExportExcel ?? []}
            headers={headers}
            filename={`danh-sach-hoc-vien-${student?.course_name}.csv`}
          >
            Xuất ra Excel
          </CSVLink>
        </div>
      )}

      <TableContainer className="pt-7">
        <Tabs
          value={value}
          onChange={handleChangeTabs}
          style={{ width: '100%', border: ' 1px solid rgba(0, 0, 0, 0.25)', minWidth: '500px' }}
        >
          <Tab
            label={titleTab('Tất cả', per_page?.total)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '20%' }}
            onClick={() => {
              fromSearch({ status: '0', p_skip: '0' });
              setStatusEmp(0);
            }}
          />
          <Tab
            label={titleTab('Đã đăng ký', per_page?.dang_ky)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '20%' }}
            onClick={() => {
              fromSearch({ status: '1', p_skip: '0' });
              setStatusEmp(1);
            }}
          />
          <Tab
            label={titleTab('Trúng tuyển', per_page?.trung_tuyen)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch({ status: '2', p_skip: '0' });
              setStatusEmp(2);
            }}
          />
          <Tab
            label={titleTab('Đã chọn', per_page?.da_chon)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch({ status: '3', p_skip: '0' });
              setStatusEmp(3);
            }}
          />
          <Tab
            label={titleTab('Từ chối', per_page?.tu_choi)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch({ status: '4', p_skip: '0' });
              setStatusEmp(4);
            }}
          />
          <Tab
            label={titleTab('Đã xóa', per_page?.da_xoa)}
            className={`${classes.buttonTab} focus:outline-none`}
            style={{ width: '15%' }}
            onClick={() => {
              fromSearch({ status: '5', p_skip: '0' });
              setStatusEmp(5);
            }}
          />
        </Tabs>
        {loading || rows == null ? (
          <div className="flex justify-center py-10">
            <CircularProgress />
          </div>
        ) : (
          <Table aria-label="simple table" className="border border-solid">
            <TableHead style={{ backgroundColor: '#F4F2FF' }}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    onChange={onCheckAll}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    checked={onCheck}
                  />
                </TableCell>
                {columns &&
                  columns.map((e, key) => {
                    return (
                      <TableCell
                        key={key}
                        style={{
                          width: `${e.width}`,
                          textAlign: 'start',
                          paddingLeft: '22px',
                        }}
                      >
                        <span className="font-semibold">
                          {e.field === 'Họ & Tên' ? (
                            <button
                              className=" focus:outline-none"
                              onClick={() => {
                                setTitle(!title);
                                if (title) {
                                  fromSearch('p_order', '1');
                                } else {
                                  fromSearch('p_order', '2');
                                }
                              }}
                            >
                              <span className="font-semibold">
                                {e.field}
                                {title ? (
                                  <i className="px-2 fas fa-angle-down text-[#B50829]"></i>
                                ) : (
                                  <i className="px-2 fas fa-angle-up text-[#B50829]"></i>
                                )}
                              </span>
                            </button>
                          ) : e.field === 'Phù Hợp' ? (
                            <button
                              className=" focus:outline-none"
                              onClick={() => {
                                setTitle(!title);
                                if (title) {
                                  fromSearch('p_order', '5');
                                } else {
                                  fromSearch('p_order', '6');
                                }
                              }}
                            >
                              <span className="font-semibold">
                                {e.field}
                                {title ? (
                                  <i className="px-2 fas fa-angle-down text-[#B50829]"></i>
                                ) : (
                                  <i className="px-2 fas fa-angle-up text-[#B50829]"></i>
                                )}
                              </span>
                            </button>
                          ) : e.field === 'Ngày đăng ký' ? (
                            <button
                              className=" focus:outline-none"
                              onClick={() => {
                                setTitle(!title);
                                if (title) {
                                  fromSearch('p_order', '3');
                                } else {
                                  fromSearch('p_order', '4');
                                }
                              }}
                            >
                              <span className="font-semibold">
                                {e.field}
                                {title ? (
                                  <i className="px-2 fas fa-angle-down text-[#B50829]"></i>
                                ) : (
                                  <i className="px-2 fas fa-angle-up text-[#B50829]"></i>
                                )}
                              </span>
                            </button>
                          ) : (
                            <span className="font-semibold">{e.field}</span>
                          )}
                        </span>
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsCustom && rowsCustom.length > 0 ? (
                rowsCustom.map((e, index) => {
                  return (
                    <TableRow key={index} className="h-16 ">
                      <TableCell className="items-center ">
                        <Checkbox
                          inputProps={{ 'aria-label': 'select all desserts' }}
                          onChange={(event) => onChangeCheckbox(event, index)}
                          value={e.checked}
                          checked={e.checked}
                          name={'p_course_id'}
                        />
                      </TableCell>
                      <TableCell className="items-center ">
                        <LF_Avatar
                          src={e.avatar}
                          // width={e.avatar.width}
                          // height={e.avatar.height}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        className="items-center flex justify-center"
                      >
                        <LF_Infor
                          to={'application'}
                          name={e.last_name + ' ' + e.first_name}
                          date={e.dob}
                          gender={e.gender}
                          slug={e.slug}
                          phone={e.phone}
                          email={e.email}
                          address={e.province_name}
                          covidStatus={e.covid_status}
                        />
                      </TableCell>
                      <TableCell align="left" className="items-center">
                        <LF_DesiredPositionCourse course_name={e?.course_name} course_id={e?.course_id} opening_date={e?.opening_date} />
                      </TableCell>
                      <TableCell align="left" className="items-center">
                        <ul className="list-inside list-disc">
                          {e?.infor &&
                            e?.infor.map((item, index) => {
                              return <li key={index}>{item.name}</li>;
                            })}
                        </ul>
                      </TableCell>
                      <TableCell align="left" className="items-center">
                        <ul>
                          {e.discharged_army === 1 && (
                            <li className="text-red-500 py-1 ">
                              Bộ đội xuất ngũ
                            </li>
                          )}
                          {e.study_ticket !== null && e.study_ticket !== '' && (
                            <li className="text-green-500 ">Thẻ học nghề</li>
                          )}
                          {e.learning_form && (
                            <li className="">
                              {renderLearningForm(e.learning_form)}
                            </li>
                          )}
                        </ul>
                      </TableCell>
                      <TableCell align="left" className="items-center">
                        <span>
                          {convertDate(e?.applied_date.substr(0, 10))}
                        </span>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="items-center"
                        style={{ paddingLeft: ' 22px' }}
                      >
                        <LF_StatusText
                          status={e?.fit_percent ? e.fit_percent : ''}
                        />
                      </TableCell>
                      <TableCell align="left" className="items-center">
                        <LF_StatusCourse
                          interview={e.status}
                          userId={e.user_id}
                          course_id={e?.course_id}
                          onUpdateStatus={onUpdateStatus}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <div className="mx-auto text-center text-xl">
                      Chưa có danh sách học viên
                    </div>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={3} width={'30%'}>
                  <span className="font-semibold p-2">
                    Phân loại ứng viên được chọn:
                  </span>
                </TableCell>
                <TableCell className={"min-w-[180px]"} colSpan={1}>
                  <select
                    name="p_status"
                    onChange={onChangeStatus}
                    value={status.p_status}
                    className="appearance-none border border-gray-400 w-full py-3 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value={2}>Trúng tuyển</option>
                    <option value={3}>Đã chọn</option>
                    <option value={4}>Từ chối</option>
                    <option value={5}>Xóa</option>
                  </select>
                </TableCell>
                <TableCell colSpan={4}>
                  <Button
                    variant="outlined"
                    onClick={changeStatus}
                    className={`${classes.btnRed} focus:outline-none `}
                    style={{
                      width: '20%',
                      height: '43px',
                      color: '#ffffff',
                      alignItems: 'center',
                      minWidth: '180px'
                    }}
                  >
                    <span>Thực hiện</span>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <div className={`${classes.root} flex justify-end`}>
          <Pagination
            count={Math.ceil(getPageTotal() / 20)}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={handleChangePage}
          />
        </div>
      </TableContainer>
    </div>
  );
};

const columns = [
  {
    field: 'Ảnh',
    width: '5%',
  },
  {
    field: 'Họ & Tên',
    width: '20%',
  },
  {
    field: 'Khoá học đăng ký',
    width: '15%',
  },
  {
    field: 'Nhu cầu học',
    width: '18%',
  },
  {
    field: 'Thông tin',
    width: '13%',
  },
  {
    field: 'Ngày đăng ký',
    width: '12%',
  },
  {
    field: 'Phù Hợp',
    width: '10%',
    type: 'number',
  },
  {
    field: '',
    width: '10%',
  },
];
