import ipointFormat from './math/ipointFormat';

export const convert = () => {};

/**
 * Generate UUID
 */
export function guidGenerator() {
  var S4 = function () {
    return ((1 + Math.random()) * 0x10000) | 0;
  };
  return S4() + S4() + S4() + S4() + S4();
}

//convertDate
export function convertDate(dateString) {
  var p = dateString.split(/\D/g);

  return [p[2], p[1], p[0]].join('/');
}
// Convert string -> slug
export const stringToSlug = (str) => {
  str = str.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
  str = str.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
  str = str.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
  str = str.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
  str = str.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
  str = str.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
  str = str.replace(/đ/gi, 'd');
  str = str.replace(
    /\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi,
    '',
  );
  str = str.replace(/ /gi, '-');
  str = str.replace(/\-\-\-\-\-/gi, '-');
  str = str.replace(/\-\-\-\-/gi, '-');
  str = str.replace(/\-\-\-/gi, '-');
  str = str.replace(/\-\-/gi, '-');
  str = '@' + str + '@';
  str = str.replace(/\@\-|\-\@|\@/gi, '');
  return str.toLowerCase();
};

export const countLengthNotHTML = (value = '') => {
  var regex = /(<([^>]+)>)/gi;
  var result = value.replace(regex, '');

  return result.length;
};

export const validURL = (userInput) => {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );
  if (res == null) {
    return true;
  } else {
    return false;
  }
};

export const changeTimestamp = (day) => {
  var myDate = day;
  myDate = myDate.split('-');
  var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);
  return newDate.getTime();
};

export const addDay = (now, days, name) => {
  let date = now ? new Date(now) : new Date();
  date.setDate(date.getDate() + days);
  return formatDate(date, name);
};
// export const formatDate = (date) => {
//   let d = date ? new Date(date) : new Date();
//   const time = d.getTime();
//   const nd = new Date(time + (3600000*7));
//   return nd.toISOString().substring(0, 16);
// }
export const formatDate = (date, name = "new") => {
  let d = date ? new Date(date) : new Date();
  const time = d.getTime() + (name !== "add" ? (30 * 60 * 1000) : 0); // +30p
  const nd = new Date(time + (3600000*7)); // GMT +7
  return nd.toISOString().substring(0, 16);
}
export const formatDateDDMMYYY = (date) => {
  let d = date ? new Date(date) : new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}
export const formatDateHHMS = (date) => {
  if (!date) return;
  let dateFormat = new Date(date);
  let dateString = dateFormat.getUTCDate() +"-"+ (dateFormat.getUTCMonth()+1) +"-"+ dateFormat.getUTCFullYear() + " " + dateFormat.getUTCHours() + ":" + dateFormat.getUTCMinutes() + ":" + dateFormat.getUTCSeconds();
  return dateString;
}

export const formatSaleBuyIpoint = (discount) => {
  if (!discount) return;
  return `${discount} %`;
}

export const calcIPoint = (ipoint, sale = 0, count) => {
  const result = (ipoint * sale/100) + ipoint;
  return ipointFormat(Math.round(result * count));
}

export const vnPayVNDFormat = (iPoint) => {
  if (!iPoint) return 0;
  return new Intl.NumberFormat().format(iPoint/100);
};
export const checkCovidStatus = (status) => {
  switch (status) {
    case null:
      return "Chưa có thông tin";
    case 1:
      return "Chưa tiêm phòng";
    case 2:
      return "Đã tiêm mũi 1";
    case 3:
      return "Đã tiêm mũi 2";
    case 4:
      return "Từng nhiễm Covid-19 và đã khỏi"
    default:
      return "Chưa có thông tin";
  }
}

export const checkPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case 1:
      return "VN Pay";
    case 2:
      return "Chuyển khoản";
    default:
      return "Không xác định";
  }
}

