import * as types from '../../type';
import { takeLatest, call, put } from 'redux-saga/effects';
import { RepositoryFactory } from '../../../services/repositories';
import { ProvinceRepository } from '../../../services/repositories/province.repository';

//Initial State
const provinceRepository: ProvinceRepository = RepositoryFactory.get(
  'province',
) as ProvinceRepository;

const fetchDistricts = async (province_code: string) => {
  const response = await provinceRepository.getDistrict(province_code);
  return response;
};

function* getDistricts(action) {
  try {
    yield put({ type: types.LOADING_REQUEST, payload: true });
    const districts = yield call(fetchDistricts, action.payload);

    yield put({ type: types.DISTRICT_SUCCESS, payload: districts });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  } catch (err) {
    yield put({ type: types.DISTRICT_ERROR, payload: err.response.data });
    yield put({ type: types.LOADING_SUCCESS, payload: false });
  }
}

export default function* watchGetDistrict() {
  yield takeLatest(types.DISTRICT_REQUEST, getDistricts);
}
